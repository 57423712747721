import { Button, Card, InputSearch, SelectBox, Skeleton } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { useProfileList } from '@/services'

import type { ProfileListProps } from './profile-list.type'

import styles from './profile-list.module.css'

export const OrderProfileList = ({
  onSelectedProfile,
  profileSelected,
  search,
  onChangeSearch,
  onHandleSubmit,
  onClickBack,
}: ProfileListProps) => {
  const { t } = useTranslation('componentsProfileForm')

  const { data: profiles, isLoading: isLoadingProfiles } = useProfileList({
    params: {
      pagination: 1,
      search,
    },
  })

  const getContent = () => {
    if (isLoadingProfiles) {
      return [0, 1, 2, 3, 4].map((number) => (
        <Card className={styles['loading-items']} key={number}>
          <Skeleton
            width="1rem"
            height="1rem"
            className={styles['loading-items-circle']}
          />

          <div className={styles['loading-items-content']}>
            <Skeleton width="100%" height="1.2rem" />

            <Skeleton width="100%" height="1rem" />
          </div>
        </Card>
      ))
    }

    if (!profiles?.data?.results?.length) {
      return <div>{t('profileListEmpty')}</div>
    }

    return profiles?.data?.results?.map((profile) => (
      <SelectBox
        title={`${t('profileListName')} ${profile?.companyName || t('profileListEmptyName')}`}
        onChange={() => onSelectedProfile(profile)}
        value={profileSelected?.uuid === profile.uuid}
        key={profile.uuid}
      >
        {`${profile.email}`}
      </SelectBox>
    ))
  }

  return (
    <div className={styles.core}>
      <div className={styles.list}>
        <InputSearch
          onChange={onChangeSearch}
          value={search}
          translations={{
            inputPlaceholder: t('tableSearchPlaceholder'),
          }}
        />

        <div className={styles['items-wrap']}>{getContent()}</div>
      </div>

      <div className={styles.footer}>
        <Button variant="secondary" onClick={onClickBack}>
          {t('buttonBack')}
        </Button>

        <Button onClick={onHandleSubmit}>{t('buttonSubmit')}</Button>
      </div>
    </div>
  )
}
