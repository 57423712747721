import styles from './input-mask.module.css'

import { InputMaskSizeEnum } from './input-mask.type'

export const sizes: Record<InputMaskSizeEnum, string> = {
  small: styles['size-small'],
  medium: styles['size-medium'],
  large: styles['size-large'],
}

export const generateClasses = (
  size: keyof typeof InputMaskSizeEnum,
  error?: boolean,
) => {
  return `${styles.core} ${sizes[size]} ${!error || styles['state-danger']}`
}
