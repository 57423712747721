export enum AlertVariantEnum {
  primary = 'primary',
  warning = 'warning',
  danger = 'danger',
  success = 'success',
}

export interface AlertProps {
  title?: string
  children?: React.ReactNode
  className?: string
  variant?: keyof typeof AlertVariantEnum
  leftIcon?: React.ReactNode
}
