import { useMutation, useQuery } from '@tanstack/react-query'

import { convertObjectToCamelCase, convertObjectToSnakeCase } from '@/helpers'

import { QUERIES_ADMIN } from './api.constants'
import { api } from './api.service'

import type {
  screenQuotationRequestListWithPagesProps,
  QuotationRequestProps,
} from '@/types'

import { QUOTATION_REQUESTS_URL, QUOTATION_REQUEST_URL } from './api.urls'

export const useQuotationRequestList = (
  params: Record<string, string | number>,
) => {
  const getQuotationRequestList = async (): Promise<{
    success: boolean
    message: string
    data?: screenQuotationRequestListWithPagesProps
  }> => {
    try {
      const copyParams = { ...params }

      const offset = copyParams?.page
        ? (+(copyParams?.page || 0) - 1) * +copyParams.limit
        : 0

      delete copyParams.page

      const formattedParams = Object.keys(copyParams).reduce((acc, key) => {
        if (copyParams?.[key]) {
          return {
            ...acc,
            [key]: copyParams?.[key],
          }
        }

        return acc
      }, {})

      const { data } = await api.get(QUOTATION_REQUESTS_URL, {
        params: {
          ...(convertObjectToSnakeCase(formattedParams) as Record<
            string,
            string
          >),
          offset,
        },
      })

      return {
        success: true,
        message: 'Quotation requests fetched successfully!',
        data: convertObjectToCamelCase(
          data,
        ) as screenQuotationRequestListWithPagesProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get quotation requests!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.QUOTATION_REQUEST.module, params],
    queryFn: () => getQuotationRequestList(),
  })
}

export const useCreateQuotationRequest = () => {
  const createQuotationRequest = async (
    payload: Partial<QuotationRequestProps>,
  ): Promise<{
    success: boolean
    message: string
    data?: QuotationRequestProps
  }> => {
    try {
      const { data } = await api.post(
        QUOTATION_REQUESTS_URL,
        convertObjectToSnakeCase(payload),
      )

      return {
        success: true,
        message: 'Quotation request created successfully!',
        data: convertObjectToCamelCase(data) as QuotationRequestProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to quotation request!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.QUOTATION_REQUEST.module],
    mutationFn: (payload: Partial<QuotationRequestProps>) =>
      createQuotationRequest(payload),
  })
}

export const useQuotationRequestDetail = (uuid?: string) => {
  const getQuotationDetail = async (): Promise<{
    success: boolean
    message: string
    data?: QuotationRequestProps
  }> => {
    try {
      const { data } = await api.get(QUOTATION_REQUEST_URL(`${uuid}`))

      return {
        success: true,
        message: 'Quotation request fetched successfully!',
        data: convertObjectToCamelCase(data) as QuotationRequestProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get quotation request!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.QUOTATION_REQUEST.module, uuid],
    queryFn: () => getQuotationDetail(),
    enabled: !!uuid,
  })
}

export const useUpdateQuotationRequest = (quotationRequestUuid: string) => {
  const updateQuotationRequest = async (
    payload: Partial<QuotationRequestProps>,
  ): Promise<{
    success: boolean
    message: string
    data?: QuotationRequestProps
  }> => {
    try {
      const { data } = await api.patch(
        QUOTATION_REQUEST_URL(quotationRequestUuid),
        convertObjectToSnakeCase(payload),
      )

      return {
        success: true,
        message: 'Quotation request created successfully!',
        data: convertObjectToCamelCase(data) as QuotationRequestProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to quotation request!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.QUOTATION_REQUEST.module],
    mutationFn: (payload: Partial<QuotationRequestProps>) =>
      updateQuotationRequest(payload),
  })
}

export const useDeleteQuotationRequest = () => {
  const deleteQuotationRequest = async (
    uuid: string,
  ): Promise<{
    success: boolean
    message: string
    data?: undefined
  }> => {
    try {
      await api.delete(QUOTATION_REQUEST_URL(`${uuid}`))

      return {
        success: true,
        message: 'Quotation request deleted successfully!',
        data: undefined,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to delete quotation request!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.QUOTATION_REQUEST.module],
    mutationFn: (uuid: string) => deleteQuotationRequest(uuid),
  })
}
