import { useState, useEffect } from 'react'
import Cookies from 'js-cookie'

export const setAuthToken = (token: string) => {
  Cookies.set('mercai@adminapp.token', token, {
    expires: 7,
    secure: true,
    sameSite: 'strict',
  })
}

export const getAuthToken = () => {
  return Cookies.get('mercai@adminapp.token')
}

export const removeAuthToken = () => {
  Cookies.remove('mercai@adminapp.token')
}

export const setRefreshToken = (token: string) => {
  Cookies.set('mercai@adminapp.refresh', token, {
    expires: 7,
    secure: true,
    sameSite: 'strict',
  })
}

export const getRefreshToken = () => {
  return Cookies.get('mercai@adminapp.refresh')
}

export const removeRefreshToken = () => {
  Cookies.remove('mercai@adminapp.refresh')
}

interface CookieOptions {
  expires?: number | Date
  secure?: boolean
  sameSite?: 'strict' | 'lax' | 'none'
}

export const useCookieState = <T>(
  cookieName: string,
  initialValue: T,
  cookieOptions: CookieOptions = {},
): [T, (newValue: T) => void | T] => {
  const readCookie = (): T => {
    const cookie = Cookies.get(cookieName)
    if (cookie !== undefined) {
      try {
        return JSON.parse(cookie) as T
      } catch (error) {
        console.error('Error parsing cookie value:', error)
        return initialValue
      }
    }
    return initialValue
  }

  const [value, setValue] = useState<T>(readCookie)

  useEffect(() => {
    const cookieValue = JSON.stringify(value)
    Cookies.set(cookieName, cookieValue, {
      expires: 7,
      secure: true,
      sameSite: 'strict',
      ...cookieOptions,
    })
  }, [cookieName, value, cookieOptions])

  return [value, setValue]
}
