import { Pagination, Text } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { ProductListItem, ProductListItemLoading } from '@/components'
import { DEFAULT_LIMIT_PAGE } from '@/constants'
import {
  useCreateKaiProcessorByReference,
  useUpdateImageCatalogProduct,
} from '@/services'

import type { TableProps } from './table.type'

import styles from './table.module.css'

export const CatalogProductTable = ({
  data,
  filter,
  onHandleFilter,
  isLoading,
  countData,
  onEditItem,
  onShowItem,
  kaiProcessorList,
  onRefetchEnhance,
}: TableProps) => {
  const { t } = useTranslation('screenCatalogProductList')

  const { mutateAsync: mutateKaiProcessor, isPending } =
    useCreateKaiProcessorByReference()

  const { mutate: mutateUpdateImage, isPending: isLoadingUpdateImage } =
    useUpdateImageCatalogProduct()

  const onSubmitEnhance = async (reference: string) => {
    const response = await mutateKaiProcessor({ reference })

    if (response.success) {
      onRefetchEnhance()
    }
  }

  const getContent = () => {
    if (isLoading) {
      return (
        <>
          <ProductListItemLoading />
          <ProductListItemLoading />
          <ProductListItemLoading />
          <ProductListItemLoading />
          <ProductListItemLoading />
          <ProductListItemLoading />
          <ProductListItemLoading />
        </>
      )
    }

    if (data?.length === 0) {
      return (
        <div className={styles['empty-state']}>
          <img src="/images/empty-state-inverse.svg" alt="empty-state" />

          <div className={styles['empty-state-texts']}>
            <Text variant="text-large-bold">
              {t('productsEmptyStateTitle')}
            </Text>
            <Text>{t('productsEmptyStateDescription')}</Text>
          </div>
        </div>
      )
    }

    return (
      <>
        {data?.map((item) => (
          <ProductListItem
            key={item.uuid}
            {...item}
            onEditItem={onEditItem}
            onShowItem={onShowItem}
            onEnhanceItem={onSubmitEnhance}
            isLoadingEnhancement={isPending}
            enhancementItem={kaiProcessorList?.find(
              (kaiItem) =>
                kaiItem.reference === item.reference &&
                kaiItem.status !== 'rejected',
            )}
            onUpdateImage={mutateUpdateImage}
            isLoadingUpdateImage={isLoadingUpdateImage}
          />
        ))}

        <Pagination
          className={styles.pagination}
          currentPage={Number(filter?.page || 1)}
          onChange={(page) => onHandleFilter('page', `${page}`)}
          totalPages={Math.ceil(countData / DEFAULT_LIMIT_PAGE)}
        />
      </>
    )
  }

  return (
    <div>
      <div className={styles['list-wrap']}>{getContent()}</div>
    </div>
  )
}
