import { useEffect, useState } from 'react'
import { Button, DatePicker, Text, ArrowRightIcon } from '@mercai/clever'

import type { PeriodDateProps } from './period-date.type'

import styles from './period-date.module.css'

export const PeriodDate = ({
  onPeriodApply,
  initialEndDate,
  initialStartDate,
}: PeriodDateProps) => {
  const [startDate, setStartDate] = useState<string>()
  const [endDate, setEndDate] = useState<string>()

  const handleApply = () => {
    onPeriodApply(startDate, endDate)
  }

  useEffect(() => {
    if (initialStartDate && !startDate) setStartDate(initialStartDate)
    if (initialEndDate && !endDate) setEndDate(initialEndDate)
  }, [initialStartDate, initialEndDate])

  return (
    <div className={styles.core}>
      <DatePicker size="small" value={startDate} onChange={setStartDate} />

      <Text>-</Text>

      <DatePicker size="small" value={endDate} onChange={setEndDate} />

      <Button model="square" size="small" onClick={handleApply}>
        <ArrowRightIcon />
      </Button>
    </div>
  )
}
