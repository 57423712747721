import { TabProps } from './tab.type'

import styles from './tab.module.css'
import { generateClassesItems } from './tabs.constants'

export const Tab = ({ items, onChange, value }: TabProps) => {
  return (
    <div className={styles.core}>
      {items?.map((item) => (
        <button
          key={item.value}
          onClick={() => onChange(item.value)}
          className={generateClassesItems(item.value === value)}
        >
          {item.label}
        </button>
      ))}
    </div>
  )
}
