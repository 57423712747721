import * as Yup from 'yup'

export const shemaProductItemNonCatalogProduct = (
  t: (key: string) => string,
) => {
  return {
    nonCatalogProduct: Yup.object().shape({
      description: Yup.string()
        .required(t('formDescriptionRequiredMessage'))
        .max(128, t('formDescriptionMaxMessage')),
      link: Yup.string().required(t('formLinkRequiredMessage')),
    }),
    price: Yup.number()
      .required(t('formPriceRequiredMessage'))
      .min(0, t('formPriceRequiredMessage')),
    cost: Yup.number()
      .required(t('formPriceRequiredMessage'))
      .min(0, t('formPriceRequiredMessage')),
    quantity: Yup.number()
      .required(t('formQuantityRequiredMessage'))
      .min(0, t('formQuantityRequiredMessage')),
    package: Yup.string().required(t('formPackageRequiredMessage')),
    shipment: Yup.object().shape({
      supplier: Yup.object()
        .shape({
          uuid: Yup.string().required(t('formSupplierRequiredMessage')),
        })
        .required(t('formSupplierRequiredMessage')),
    }),
  }
}

export const shemaProductItemCatalogProduct = (t: (key: string) => string) => {
  return {
    catalogProduct: Yup.object().shape({
      uuid: Yup.string().required(t('formCatalogProductRequiredMessage')),
    }),
    price: Yup.number().required(t('formPriceRequiredMessage')),
    cost: Yup.number()
      .required(t('formPriceRequiredMessage'))
      .min(0, t('formPriceRequiredMessage')),
    quantity: Yup.number()
      .required(t('formQuantityRequiredMessage'))
      .min(0, t('formQuantityRequiredMessage')),
    package: Yup.string().required(t('formPackageRequiredMessage')),
    shipment: Yup.object().shape({
      supplier: Yup.object()
        .shape({
          uuid: Yup.string().required(t('formSupplierRequiredMessage')),
        })
        .required(t('formSupplierRequiredMessage')),
    }),
  }
}
