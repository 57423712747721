import { Text, CloseIcon } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import styles from './header.module.css'

import type { FinanceProfileHeaderProps } from './header.type'

export const FinanceProfileHeader = ({
  onHandleOpen,
}: FinanceProfileHeaderProps) => {
  const { t } = useTranslation('componentsFinanceProfileForm')

  return (
    <div className={styles.core}>
      <div className={styles.content}>
        <Text variant="title-large">{t('headerTitle')}</Text>

        <button
          className={styles.button}
          type="button"
          onClick={() => onHandleOpen(false)}
        >
          <CloseIcon />
        </button>
      </div>
    </div>
  )
}
