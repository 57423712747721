import { useQuery, useMutation } from '@tanstack/react-query'

import { convertObjectToCamelCase, convertObjectToSnakeCase } from '@/helpers'

import { QUERIES_ADMIN } from './api.constants'
import { api } from './api.service'

import type { OrdersListWithPagesProps, OrderDetailProps } from '@/types'

import { ORDERS_URL, ORDER_URL } from './api.urls'

export const useOrderList = (params: Record<string, string | number>) => {
  const getOrderList = async (): Promise<{
    success: boolean
    message: string
    data?: OrdersListWithPagesProps
  }> => {
    try {
      const copyParams = { ...params }

      const offset = copyParams?.page
        ? (+(copyParams?.page || 0) - 1) * +copyParams.limit
        : 0

      delete copyParams.page

      const formattedParams = Object.keys(copyParams).reduce((acc, key) => {
        if (copyParams?.[key]) {
          return {
            ...acc,
            [key]: copyParams?.[key],
          }
        }

        return acc
      }, {})

      const { data } = await api.get(ORDERS_URL, {
        params: {
          ...(convertObjectToSnakeCase(formattedParams) as Record<
            string,
            string
          >),
          offset,
        },
      })

      return {
        success: true,
        message: 'Order requests fetched successfully!',
        data: convertObjectToCamelCase(data) as OrdersListWithPagesProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get order requests!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.ORDER.module, params],
    queryFn: () => getOrderList(),
  })
}

export const useOrderDetail = (uuid?: string) => {
  const getOrderDetail = async (): Promise<{
    success: boolean
    message: string
    data?: OrderDetailProps
  }> => {
    try {
      const { data } = await api.get(ORDER_URL(`${uuid}`))

      return {
        success: true,
        message: 'Order request fetched successfully!',
        data: convertObjectToCamelCase(data) as OrderDetailProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get order request!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.ORDER.module, uuid],
    queryFn: () => getOrderDetail(),
    enabled: !!uuid,
  })
}

export const useOrderUpdate = (uuid?: string) => {
  const updateOrder = async (
    payload: Partial<OrderDetailProps>,
  ): Promise<{
    success: boolean
    message: string
    data?: OrderDetailProps
  }> => {
    try {
      const { data } = await api.patch(
        ORDER_URL(`${uuid}`),
        convertObjectToSnakeCase(payload),
      )

      return {
        success: true,
        message: 'Order updated successfully!',
        data: convertObjectToCamelCase(data) as OrderDetailProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to update order!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.ORDER.module, uuid],
    mutationFn: (payload: Partial<OrderDetailProps>) => updateOrder(payload),
  })
}

export const useDeleteOrder = () => {
  const deleteOrder = async (
    uuid: string,
  ): Promise<{
    success: boolean
    message: string
    data?: undefined
  }> => {
    try {
      await api.delete(ORDER_URL(`${uuid}`))

      return {
        success: true,
        message: 'Order deleted successfully!',
        data: undefined,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to delete order!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.ORDER.module],
    mutationFn: (uuid: string) => deleteOrder(uuid),
  })
}
