import {
  Button,
  Card,
  InputSearch,
  PlusIcon,
  SelectBox,
  Skeleton,
} from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import type { ProfileListProps } from './profile-list.type'

import styles from './profile-list.module.css'

export const ProfileList = ({
  onClickNewProfile,
  onSelectedProfile,
  profileSelected,
  search,
  onChangeSearch,
  profiles,
  isLoading,
}: ProfileListProps) => {
  const { t } = useTranslation('screenQuotationRequestList')

  const getContent = () => {
    if (isLoading) {
      return [0, 1, 2, 3, 4].map((number) => (
        <Card className={styles['loading-items']} key={number}>
          <Skeleton
            width="1rem"
            height="1rem"
            className={styles['loading-items-circle']}
          />

          <div className={styles['loading-items-content']}>
            <Skeleton width="100%" height="1.2rem" />

            <Skeleton width="100%" height="1rem" />
          </div>
        </Card>
      ))
    }

    if (!profiles?.length) {
      return <div>{t('profileListEmpty')}</div>
    }

    return profiles?.map((profile) => (
      <SelectBox
        title={`${t('profileListName')} ${profile?.companyName || t('profileListEmptyName')}`}
        onChange={() => onSelectedProfile(profile)}
        value={profileSelected?.uuid === profile.uuid}
        key={profile.uuid}
      >
        {`${profile.email}`}
      </SelectBox>
    ))
  }

  return (
    <div className={styles.core}>
      <div className={styles.list}>
        <InputSearch
          onChange={onChangeSearch}
          value={search}
          translations={{
            inputPlaceholder: t('tableSearchPlaceholder'),
          }}
        />

        <div className={styles['items-wrap']}>{getContent()}</div>
      </div>

      <Button onClick={onClickNewProfile} variant="light">
        <PlusIcon />
        {t('profileListButton')}
      </Button>
    </div>
  )
}
