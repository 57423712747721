import { SearchIcon, Skeleton, Text, CheckIcon } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { generateClassesOfDropDown } from './drop-down-search.constants'

import styles from './drop-down-search.module.css'

import type { CatalogProductProps } from '@/types'
import type { DropDownSearchProps } from './drop-down-search.type'

export const DropDownSearch = ({
  data,
  isOpen,
  isLoading,
  selectedProduct,
  onSelectProduct,
  maxHeight,
}: DropDownSearchProps) => {
  const { t } = useTranslation('componentCatalogSearch')

  const classes = generateClassesOfDropDown(isOpen)

  const ItemLoading = () => (
    <li className={styles.item}>
      <Skeleton width="1.5rem" height="1.5rem" />

      <div className={styles.content}>
        <Skeleton width="100%" height="1.25rem" />

        <div className={styles.row}>
          <Skeleton width="6.25rem" height="1rem" />
          <Skeleton width="6.25rem" height="1rem" />
          <Skeleton width="6.25rem" height="1rem" />
        </div>
      </div>
    </li>
  )

  const Item = ({ attributes, reference, ...rest }: CatalogProductProps) => (
    <li className={styles['list-style']}>
      <button
        className={styles.item}
        onClick={() =>
          onSelectProduct({
            reference,
            attributes,
            ...rest,
          })
        }
      >
        <SearchIcon className={styles.icon} />

        <div className={styles.content}>
          <Text className={styles.title}>{attributes?.title}</Text>

          <div className={styles.row}>
            <Text className={styles['sub-title']} variant="text-small">
              {`
                ${reference} 
                ${attributes?.density ? `| ${attributes?.density} g/cm³` : ''} 
                ${attributes?.fluidity ? `| ${attributes?.fluidity} g/10min` : ''}
                ${attributes?.size ? `| ${attributes?.size} ` : ''}
                ${attributes?.quantityPerPackage ? `| ${attributes?.quantityPerPackage} ` : ''}
              `}
            </Text>
          </div>
        </div>

        {selectedProduct?.reference === reference && (
          <CheckIcon className={styles.icon} />
        )}
      </button>
    </li>
  )

  if (isLoading) {
    return (
      <div className={classes} style={{ maxHeight }}>
        <ul className={styles.list}>
          <ItemLoading />
          <ItemLoading />
          <ItemLoading />
        </ul>
      </div>
    )
  }

  if (data && data?.length > 0) {
    return (
      <div className={classes} style={{ maxHeight }}>
        {data?.map((item) => <Item key={item?.reference} {...item} />)}
      </div>
    )
  }

  return (
    <div className={classes} style={{ maxHeight }}>
      <Text className={styles.item}>
        {t('catalogSearchEngineEmptyResults')}
      </Text>
    </div>
  )
}
