import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SearchIcon, Input, Label, ChevronDownIcon } from '@mercai/clever'

import { InputSearchProps } from './input-search.type'

export const InputSearch = ({
  onChange,
  value,
  onOpen,
  error,
}: InputSearchProps) => {
  const { t } = useTranslation('componentCatalogSearch')

  const [localValue, setLocalValue] = useState(value)

  useEffect(() => {
    if (value !== localValue) setLocalValue(value)
  }, [value])

  useEffect(() => {
    const handler = setTimeout(() => {
      if (localValue === value) return
      onChange(localValue)
    }, 500)
    return () => clearTimeout(handler)
  }, [localValue, onChange])

  return (
    <>
      <Label>{t('catalogSearchInputSearchLabel')}</Label>
      <Input
        aria-label={t('catalogSearchInputSearchPlaceholder')}
        placeholder={t('catalogSearchInputSearchPlaceholder')}
        iconLeft={<SearchIcon />}
        iconRight={<ChevronDownIcon />}
        value={localValue}
        onChange={setLocalValue}
        onClick={() => onOpen(true)}
        error={error}
      />
    </>
  )
}
