import { useQuery } from '@tanstack/react-query'
import { convertObjectToCamelCase } from '@/helpers'

import { CatalogCategoryWithPagesProps, CatalogCategoryProps } from '@/types'

import { QUERIES_ADMIN } from './api.constants'
import { api } from './api.service'
import { CATALOG_CATEGORIES_URL, CATALOG_CATEGORY_URL } from './api.urls'

export const useCatalogCategoryList = () => {
  const getCatalogCategoryList = async (): Promise<{
    success: boolean
    message: string
    data?: CatalogCategoryWithPagesProps
  }> => {
    try {
      const { data } = await api.get(CATALOG_CATEGORIES_URL, {
        params: { is_leaf: true },
      })

      return {
        success: true,
        message: 'CatalogCategory requests fetched successfully!',
        data: convertObjectToCamelCase(data) as CatalogCategoryWithPagesProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get adresses!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.CATALOG_CATEGORY.module],
    queryFn: () => getCatalogCategoryList(),
    refetchOnWindowFocus: false,
  })
}

export const useCatalogCategoryDetail = (uuid?: string) => {
  const getCatalogCategoryDetail = async (): Promise<{
    success: boolean
    message: string
    data?: CatalogCategoryProps
  }> => {
    try {
      const { data } = await api.get(CATALOG_CATEGORY_URL(`${uuid}`))

      return {
        success: true,
        message: 'Catalog category fetched successfully!',
        data: convertObjectToCamelCase(data) as CatalogCategoryProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get catalog category!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.CATALOG_CATEGORY.module, uuid],
    queryFn: () => getCatalogCategoryDetail(),
    enabled: !!uuid,
    refetchOnWindowFocus: false,
  })
}
