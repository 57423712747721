import { useEffect, ReactNode, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Button } from '@mercai/clever'

import {
  Header,
  OrderCenterShow,
  ProfileTaxSituationForm,
  OrderCenterForm,
} from '@/components'
import { DEFAULT_LIMIT_PAGE } from '@/constants'
import { trackEvent, useParamsState } from '@/helpers'
import { useAuth } from '@/providers'
import { ROUTES_ADMIN } from '@/router'

import {
  OrderCenterTable,
  OrderCenterTableTransaction,
  OrderCenterMetrics,
} from './components'

import {
  useOrderCenterProfileList,
  useOrderCenterFinanceList,
  useOrderCenterLogisticList,
  useOrderCenterSaleOrderList,
  useTransactionList,
  useUserList,
} from '@/services'
import { SaleOrderWithPagesProps } from '@/types'

import styles from './order-center.module.css'

import { OrderCenterPageEnum } from './order-center.type'

export const OrderCenterPage = () => {
  const { t } = useTranslation('screenOrderCenterPage')

  const { whoAmI } = useAuth()
  const { data: dataUserList } = useUserList({
    params: {
      limit: 100,
      page: 1,
      isStaff: 'true',
    },
  })

  const location = useLocation()
  const page = location.pathname.split('/').pop()

  const [filter, setFilter] = useParamsState({
    page: '1',
    limit: `${DEFAULT_LIMIT_PAGE}`,
    search: '',
    taxStatus: '',
    moscow: '',
    createdAtBefore: '',
    createdAtAfter: '',
    ordering: '',
    keyAccountManager: '',
  })

  const [queryParams, setQueryParams] = useParamsState({
    uuid: '',
    action: '',
  })

  const {
    data: dataProfile,
    isLoading: isLoadingProfile,
    refetch: refetchProfile,
  } = useOrderCenterProfileList(filter, page === 'profile')

  const { data: dataFinance, isLoading: isLoadingFinance } =
    useOrderCenterFinanceList(filter, page === 'finance')

  const { data: dataLogistic, isLoading: isLoadingLogistic } =
    useOrderCenterLogisticList(filter, page === 'logistic')

  const { data: dataSaleOrder, isLoading: isLoadginSaleOrder } =
    useOrderCenterSaleOrderList(filter, page === 'sale' || page === 'all')

  const { data: dataTransaction, isLoading: isLoadingTransaction } =
    useTransactionList(filter, page === 'transaction')

  const whoAmIUser = useMemo(() => {
    if (whoAmI?.email) {
      return dataUserList?.data?.results?.find(
        (user) => user.email === whoAmI?.email,
      )
    }
  }, [dataUserList, whoAmI])

  const userFiltered = useMemo(() => {
    if (filter.keyAccountManager && filter.keyAccountManager === 'undefined') {
      return dataUserList?.data?.results?.find(
        (user) => user.email === whoAmI?.email,
      )
    }

    return dataUserList?.data?.results?.find(
      (user) => user.id === +filter?.keyAccountManager,
    )
  }, [dataUserList, filter.keyAccountManager, whoAmI])

  const defaultProps = {
    filter,
    onEditRegister: (uuid: string) =>
      setQueryParams({
        uuid,
        action: 'form',
      }),
    onSetFilter: setFilter,
    onShowRegister: (uuid: string) =>
      setQueryParams({
        uuid,
        action: 'show',
      }),
    formattedFilter: {
      keyAccountManager: userFiltered?.email || '',
    },
  }

  const objectsRoute: Record<string, ReactNode> = {
    all: (
      <OrderCenterTable
        {...defaultProps}
        data={dataSaleOrder?.data}
        isLoading={isLoadginSaleOrder}
        type="all"
      />
    ),
    sale: (
      <OrderCenterTable
        {...defaultProps}
        data={dataSaleOrder?.data}
        isLoading={isLoadginSaleOrder}
        type="sale"
      />
    ),
    profile: (
      <OrderCenterTable
        {...defaultProps}
        data={dataProfile?.data}
        isLoading={isLoadingProfile}
        type="profile"
      />
    ),
    finance: (
      <OrderCenterTable
        {...defaultProps}
        data={dataFinance?.data as SaleOrderWithPagesProps}
        isLoading={isLoadingFinance}
        type="finance"
      />
    ),
    transaction: (
      <OrderCenterTableTransaction
        {...defaultProps}
        data={dataTransaction?.data}
        isLoading={isLoadingTransaction}
      />
    ),
    logistic: (
      <OrderCenterTable
        {...defaultProps}
        data={dataLogistic?.data as SaleOrderWithPagesProps}
        isLoading={isLoadingLogistic}
        type="logistic"
      />
    ),
  }

  useEffect(() => {
    trackEvent('adminOrderCenterPageView', { page })

    if (page === 'sale' && whoAmI?.email) {
      setFilter({
        ...filter,
        page: '1',
        keyAccountManager: `${whoAmIUser?.id}`,
      })
    }

    if (page !== 'sale') {
      setFilter({
        ...filter,
        page: '1',
        keyAccountManager: '',
      })
    }
  }, [page])

  return (
    <div>
      <Header title={t('title')} description={t('description')} />

      <div className={styles.content}>
        <div className={styles.buttons}>
          <Button
            variant="secondary"
            model="pill"
            isActive={page === 'all'}
            href={ROUTES_ADMIN.ORDER_CENTER.fullPath('all')}
          >
            {t('buttonNavAll')}
          </Button>

          <Button
            variant="secondary"
            model="pill"
            isActive={page === 'sale'}
            href={ROUTES_ADMIN.ORDER_CENTER.fullPath('sale')}
          >
            {t('buttonNavSale')}
          </Button>

          <Button
            variant="secondary"
            model="pill"
            isActive={page === 'profile'}
            href={ROUTES_ADMIN.ORDER_CENTER.fullPath('profile')}
          >
            {t('buttonNavProfile')}
          </Button>

          <Button
            variant="secondary"
            model="pill"
            isActive={page === 'finance'}
            href={ROUTES_ADMIN.ORDER_CENTER.fullPath('finance')}
          >
            {t('buttonNavFinance')}
          </Button>

          <Button
            variant="secondary"
            model="pill"
            isActive={page === 'transaction'}
            href={ROUTES_ADMIN.ORDER_CENTER.fullPath('transaction')}
          >
            {t('buttonNavTransaction')}
          </Button>

          <Button
            variant="secondary"
            model="pill"
            isActive={page === 'logistic'}
            href={ROUTES_ADMIN.ORDER_CENTER.fullPath('logistic')}
          >
            {t('buttonNavLogistic')}
          </Button>
        </div>

        <OrderCenterMetrics
          type={page as OrderCenterPageEnum}
          filter={filter}
          onFilterChange={setFilter}
        />

        {objectsRoute?.[page as string]}
      </div>

      <OrderCenterShow
        isOpen={queryParams.action === 'show'}
        onHandleOpen={() =>
          setQueryParams({ ...queryParams, action: '', uuid: '' })
        }
        saleOrderUuid={queryParams.uuid}
        type={page}
      />

      <ProfileTaxSituationForm
        isOpen={queryParams.action === 'form' && page === 'profile'}
        onHandleOpen={() =>
          setQueryParams({ ...queryParams, action: '', uuid: '' })
        }
        saleOrderUuid={queryParams.uuid}
        onSuccessUpdated={() => refetchProfile()}
      />

      <OrderCenterForm
        isOpen={queryParams.action === 'form' && page !== 'profile'}
        onHandleOpen={() =>
          setQueryParams({ ...queryParams, action: '', uuid: '' })
        }
        saleOrderUuid={queryParams.uuid}
        type={page}
      />
    </div>
  )
}
