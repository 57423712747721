import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Tag, Text } from '@mercai/clever'

import { ROUTES_ADMIN } from '@/router'

import styles from './order-status.module.css'

import { SaleOrderStatusEnum } from '@/types'

import { OrderStatusProps } from './order-status.type'

export const OrderStatus = ({
  orderReference,
  orderStatus = 'created',
}: OrderStatusProps) => {
  const { t } = useTranslation('componentOrderCenterShow')
  const { t: tEnums } = useTranslation('enums')

  const tags: Record<SaleOrderStatusEnum, ReactNode> = {
    created: (
      <Tag model="light" variant="primary">
        {tEnums('sale_order_status_created')}
      </Tag>
    ),
    cancelled: (
      <Tag model="light" variant="danger">
        {tEnums('sale_order_status_cancelled')}
      </Tag>
    ),
    processing: (
      <Tag model="light" variant="warning">
        {tEnums('sale_order_status_processing')}
      </Tag>
    ),
    fraudulent: (
      <Tag model="light" variant="danger">
        {tEnums('sale_order_status_fraudulent')}
      </Tag>
    ),
    completed: (
      <Tag model="light" variant="success">
        {tEnums('sale_order_status_completed')}
      </Tag>
    ),
  }

  return (
    <div className={styles.core}>
      <Text variant="title-base" className={styles.label}>
        {t('orderStatusLabel')}
      </Text>

      {tags?.[orderStatus]}

      <Button
        target="_blank"
        variant="primary-outline"
        size="small"
        className={styles.button}
        href={ROUTES_ADMIN.ANALYTICS.ORDER_TRACKER.FILTER.fullPath(
          orderReference || '',
        )}
      >
        {t('orderStatusLink')}
      </Button>
    </div>
  )
}
