const toCamelCase = (str: string): string =>
  str.replace(/(_\w)/g, (matches) => matches[1].toUpperCase())

export const convertObjectToCamelCase = (obj: unknown): unknown => {
  //eslint-disable-line
  if (typeof obj !== 'object' || obj === null) {
    return obj
  }

  if (Array.isArray(obj)) {
    return obj.map(convertObjectToCamelCase)
  }

  return Object.entries(obj).reduce(
    (acc, [key, value]) => {
      const camelCaseKey = toCamelCase(key)
      acc[camelCaseKey] = convertObjectToCamelCase(value)
      return acc
    },
    {} as Record<string, unknown>,
  )
}

export const textToSnakeCase = (str: string): string =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)

export const convertObjectToSnakeCase = (obj: unknown): unknown => {
  if (typeof obj !== 'object' || obj === null) {
    return obj
  }

  if (Array.isArray(obj)) {
    return obj.map(convertObjectToSnakeCase)
  }

  return Object.entries(obj).reduce(
    (acc: Record<string, unknown>, [key, value]) => {
      const snakeCaseKey = textToSnakeCase(key)
      acc[snakeCaseKey] = convertObjectToSnakeCase(value)
      return acc
    },
    {} as Record<string, unknown>,
  )
}

interface PhoneFormat {
  regex: RegExp
  format: string
}

export const phoneFormats: Record<'es-MX' | 'en-US', PhoneFormat> = {
  'es-MX': {
    regex: /^(\d{2})(\d{4})(\d{4})$/,
    format: '($1) $2-$3',
  },
  'en-US': {
    regex: /^(\d{3})(\d{3})(\d{4})$/,
    format: '($1) $2-$3',
  },
}

export const formatPhoneNumber = (
  phoneNumber: string,
  format: 'es-MX' | 'en-US' = 'es-MX',
): string => {
  const phoneFormat = phoneFormats[format]

  if (!phoneFormat || typeof phoneNumber !== 'string') return ''

  return phoneNumber.replace(phoneFormat.regex, phoneFormat.format)
}

export const transformObjectToArrayOfStrings = (
  obj: any, //eslint-disable-line
): string[] => {
  let result: string[] = []

  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      result = result.concat(transformObjectToArrayOfStrings(obj[key]))
    } else {
      result.push(`${obj[key]}`)
    }
  }

  return result
}
