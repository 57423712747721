import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Text, DownloadIcon, CopyIcon, CheckIcon } from '@mercai/clever'

import {
  copyTextToClipboard,
  downloadFile,
  getFileNameAndExtension,
} from '@/helpers'
import { useFileSign } from '@/services'

import styles from './card-tax-situation.module.css'

import type { CardTaxSituationProps } from './card-tax-situation.type'

export const CardTaxSituation = ({ taxSituation }: CardTaxSituationProps) => {
  const { t } = useTranslation('componentsOrderForm')

  const [isCopied, setIsCopied] = useState(false)

  const { mutateAsync, isPending } = useFileSign()

  const getUrl = async () => {
    if (!taxSituation) return ''

    if (!taxSituation.includes('http')) {
      const response = await mutateAsync({
        filePath: taxSituation || '',
      })

      if (!response?.data?.signedUrl) return ''

      return response?.data?.signedUrl || ''
    }

    return taxSituation
  }

  const handleDownloadFile = async () => {
    const url = await getUrl()

    if (!url) return null

    const file = getFileNameAndExtension(url || '')

    downloadFile(url, `${file.fileName}.${file.extension}`)
  }

  const onHandleCopy = async () => {
    const url = await getUrl()

    if (!url) return null

    try {
      const success = copyTextToClipboard(`${url}`)

      setIsCopied(success)

      setTimeout(() => {
        setIsCopied(false)
      }, 5000)
    } catch (err) {
      console.error(err)
    }
  }

  const onHandleClick = async () => {
    const url = await getUrl()

    if (!url) return null

    window.open(url, '_blank')
  }

  return (
    <div className={styles.core}>
      <div className={styles.content}>
        <Text variant="title-medium" className={styles.title}>
          {t('profileTaxSituationTitle')}
        </Text>
        <Text className={styles.subtitle}>
          {t('profileTaxSituationDescription')}
        </Text>
      </div>

      <div className={styles['buttons-wrap-column']}>
        <Button onClick={onHandleClick} className={styles.buttons}>
          {t('profileTaxSituationViewProof')}
        </Button>

        <div className={styles['buttons-wrap-line']}>
          <Button
            variant="secondary"
            className={styles.buttons}
            onClick={handleDownloadFile}
          >
            <DownloadIcon className={styles['buttons-icon']} />
            {isPending
              ? t('profileTaxSituationDownloadingProof')
              : t('profileTaxSituationDownloadProof')}
          </Button>

          <Button
            variant="secondary"
            onClick={onHandleCopy}
            className={styles.buttons}
          >
            {isCopied ? (
              <CheckIcon className={styles['copy-check-icon']} />
            ) : (
              <CopyIcon />
            )}
            {isCopied
              ? t('profileTaxSituationCopiedLinkProof')
              : t('profileTaxSituationCopyLinkProof')}
          </Button>
        </div>
      </div>
    </div>
  )
}
