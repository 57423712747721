import { SelectBox, Text } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import styles from './search-method.module.css'

import type { SearchMethodProps } from './search-method.type'

export const SearchMethod = ({ method, onChangeMethod }: SearchMethodProps) => {
  const { t } = useTranslation('screenQuotationRequestList')

  return (
    <div className={styles.core}>
      <SelectBox
        title={t('searchMethodFileTitle')}
        value={method === 'file'}
        onChange={() => onChangeMethod('file')}
      >
        <Text>{t('searchMethodFileDescription')}</Text>
      </SelectBox>

      <SelectBox
        title={t('searchMethodManualTitle')}
        value={method === 'manual'}
        onChange={() => onChangeMethod('manual')}
      >
        <Text>{t('searchMethodManualDescription')}</Text>
      </SelectBox>
    </div>
  )
}
