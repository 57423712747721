import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Text,
  DownloadIcon,
  CopyIcon,
  CheckIcon,
  DropDown,
} from '@mercai/clever'

import { copyTextToClipboard, downloadFile, getFileExtension } from '@/helpers'
import { useFileSign } from '@/services'

import styles from './card-invoice.module.css'

import type { CardInvoiceProps } from './card-invoice.type'

export const CardInvoice = ({
  invoiceXMLUrl,
  invoicePDFUrl,
  orderReference,
}: CardInvoiceProps) => {
  const { t } = useTranslation('screenOrderList')

  const [isCopied, setIsCopied] = useState(false)
  const [link, setLink] = useState('')

  const { mutateAsync, isPending } = useFileSign()

  const getUrl = async (urlParam: string) => {
    if (!urlParam) return ''

    if (!urlParam.includes('http')) {
      const response = await mutateAsync({
        filePath: urlParam || '',
      })

      if (!response?.data?.signedUrl) return ''

      return response?.data?.signedUrl || ''
    }

    return ''
  }

  const onHandleDownload = async (file: 'pdf' | 'xml') => {
    const urls = {
      pdf: invoicePDFUrl || '',
      xml: invoiceXMLUrl || '',
    }

    let url = urls[file]

    if (!url) return null

    url = await getUrl(url)

    const extension = getFileExtension(url)
    downloadFile(url, `invoice_${orderReference}.${extension}`)
  }

  const onHandleCopy = async () => {
    if (!invoicePDFUrl && !invoiceXMLUrl) return null

    try {
      let url = invoicePDFUrl || invoiceXMLUrl || ''

      if (!url) return null

      url = await getUrl(url)

      const success = copyTextToClipboard(url)

      setIsCopied(success)

      setTimeout(() => {
        setIsCopied(false)
      }, 5000)
    } catch (err) {
      console.error(err)
    }
  }

  const getFileLink = async () => {
    const url = invoicePDFUrl || invoiceXMLUrl || ''

    if (!url) return ''

    return await getUrl(url)
  }

  useEffect(() => {
    getFileLink().then((url) => {
      setLink(url)
    })
  }, [invoicePDFUrl, invoiceXMLUrl])

  return (
    <div className={styles.core}>
      <div className={styles.content}>
        <Text variant="title-large" className={styles.title}>
          {t('orderShowCardInvoiceTitle')}
        </Text>
        <Text className={styles.subtitle}>
          {t('orderShowCardInvoiceDescription')}
        </Text>
      </div>

      <div className={styles['buttons-wrap-column']}>
        <Button href={link || ''} target="_blank" className={styles.buttons}>
          {t('orderShowCardInvoiceViewInvoice')}
        </Button>

        <div className={styles['buttons-wrap-line']}>
          <DropDown.Root>
            <DropDown.ButtonTrigger
              variant="secondary"
              className={styles.buttons}
            >
              <DownloadIcon className={styles['buttons-icon']} />
              {t('orderShowCardInvoiceDownloadInvoice')}
            </DropDown.ButtonTrigger>

            <DropDown.Content className={styles['dropdown-content']}>
              {invoicePDFUrl && (
                <DropDown.Item onClick={() => onHandleDownload('pdf')}>
                  <DownloadIcon className={styles['buttons-icon']} />
                  {isPending
                    ? t('orderShowCardInvoiceDownloadInvoiceLoading')
                    : t('orderShowCardInvoiceDownloadInvoicePDF')}
                </DropDown.Item>
              )}
              {invoiceXMLUrl && (
                <DropDown.Item onClick={() => onHandleDownload('xml')}>
                  <DownloadIcon className={styles['buttons-icon']} />
                  {isPending
                    ? t('orderShowCardInvoiceDownloadInvoiceLoading')
                    : t('orderShowCardInvoiceDownloadInvoiceXML')}
                </DropDown.Item>
              )}
            </DropDown.Content>
          </DropDown.Root>

          <Button
            variant="secondary"
            onClick={onHandleCopy}
            className={styles.buttons}
          >
            {isCopied ? (
              <CheckIcon className={styles['copy-check-icon']} />
            ) : (
              <CopyIcon />
            )}
            {isCopied
              ? t('orderShowCardInvoiceCopiedLinkInvoice')
              : t('orderShowCardInvoiceCopyLinkInvoice')}
          </Button>
        </div>
      </div>
    </div>
  )
}
