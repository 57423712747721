import { useMutation, useQuery } from '@tanstack/react-query'

import { convertObjectToCamelCase } from '@/helpers'
import type { WhoAmIProps } from '@/types'
import { api } from './api.service'

import { QUERIES_ADMIN } from './api.constants'
import { WHO_AM_I_URL } from './api.urls'

export const useWhoAmI = () => {
  const getWhoAmI = async (): Promise<{
    success: boolean
    message: string
    data?: WhoAmIProps
  }> => {
    try {
      const { data } = await api.get(WHO_AM_I_URL)

      return {
        success: true,
        message: 'Who am i fetched successfully!',
        data: convertObjectToCamelCase(data) as WhoAmIProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get who am i!',
        data: undefined,
      }
    }
  }

  const useMutationReturn = useMutation({
    mutationKey: [QUERIES_ADMIN.WHO_AM_I.module],
    mutationFn: () => getWhoAmI(),
  })

  return useMutationReturn
}

export const useGetWhoAmI = (token?: string) => {
  const getWhoAmI = async (): Promise<{
    success: boolean
    message: string
    data?: WhoAmIProps
  }> => {
    try {
      const { data } = await api.get(WHO_AM_I_URL)

      return {
        success: true,
        message: 'Who am i fetched successfully!',
        data: convertObjectToCamelCase(data) as WhoAmIProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get who am i!',
        data: undefined,
      }
    }
  }

  const useQueryReturn = useQuery({
    queryKey: [QUERIES_ADMIN.WHO_AM_I.module, token],
    queryFn: () => getWhoAmI(),
    enabled: !!token,
    refetchOnWindowFocus: false,
  })

  return useQueryReturn
}
