export const cardsForType: Record<string, string[]> = {
  all: [
    'sales_order_status',
    'tax_status',
    'invoice_status',
    'payment_status',
    'shipment_status',
    'moscow',
    'keyAccountManager',
    'created_at_interval',
  ],
  sale: [
    'sales_order_status',
    'tax_status',
    'invoice_status',
    'payment_status',
    'shipment_status',
    'moscow',
    'keyAccountManager',
    'created_at_interval',
  ],
  profile: [
    'sales_order_status',
    'tax_status',
    'moscow',
    'keyAccountManager',
    'created_at_interval',
  ],
  finance: [
    'sales_order_status',
    'invoice_status',
    'payment_status',
    'moscow',
    'keyAccountManager',
    'created_at_interval',
  ],
  logistic: [
    'sales_order_status',
    'shipment_status',
    'moscow',
    'keyAccountManager',
    'created_at_interval',
  ],
}
