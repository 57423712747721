import * as Yup from 'yup'

export const schemaOrder = (t: (key: string) => string) => ({
  status: Yup.string().required(t('requiredMessage')),
  dollarExchangeRate: Yup.string().required(t('requiredMessage')),
  keyAccountManagerId: Yup.string().required(t('requiredMessage')),
  financingCost: Yup.string().when(
    'purchaseOnCredit',
    (purchaseOnCredit, schema) => {
      if (purchaseOnCredit[0]) {
        return schema
          .required(t('requiredMessage'))
          .min(0, t('requiredMessage'))
      }

      return schema.nullable()
    },
  ),

  shipment: Yup.object().shape({
    firstMileCost: Yup.string()
      .required(t('requiredMessage'))
      .min(0, t('requiredMessage')),
    lastMileCost: Yup.string()
      .required(t('requiredMessage'))
      .min(0, t('requiredMessage')),
    customsCost: Yup.string()
      .required(t('requiredMessage'))
      .min(0, t('requiredMessage')),
    expectedDeliveryDays: Yup.string().required(t('requiredMessage')),
    price: Yup.string()
      .required(t('requiredMessage'))
      .min(0, t('requiredMessage')),
  }),
})
