import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterCustom, Routes, history } from './router'
import { ReactQueryProvider, AuthProvider } from './providers'
import './config/i18n'
import { setDefaultOptions } from 'date-fns'
import { es } from 'date-fns/locale'

import '../src/lib/globals.css'
import '../src/config/hotjar'

import * as Sentry from '@sentry/react'

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://2edd6ea9fb6cc45ee806bd59065cd4c5@o4506039367041024.ingest.us.sentry.io/4506973716807680',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],

    tracesSampleRate: 1.0,
    tracePropagationTargets: [/^https:\/\/admin\.mercai\.ai\//],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

setDefaultOptions({ locale: es })

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ReactQueryProvider>
      <RouterCustom history={history}>
        <AuthProvider>
          <Routes />
        </AuthProvider>
      </RouterCustom>
    </ReactQueryProvider>
  </React.StrictMode>,
)
