import { ChevronLeftIcon, CloseIcon, Drawer, Text } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { useCatalogProductDetail } from '@/services'
import { convertObjectToCamelCase } from '@/helpers'

import styles from './drawer-show.module.css'

import type { DrawerShowProps } from './drawer-show.type'

import { CatalogProductDetail } from '../detail'

import type { CatalogProductProps } from '@/types'

export const DrawerShow = ({
  isOpen,
  onHandleOpen,
  catalogProductUuid,
}: DrawerShowProps) => {
  const { t } = useTranslation('screenCatalogProductList')

  const { data: catalogProductData, isLoading } =
    useCatalogProductDetail(catalogProductUuid)

  return (
    <Drawer isOpen={isOpen} onHandleOpen={onHandleOpen}>
      <header className={styles.header}>
        <div className={styles['header-box-content']}>
          <Text className={styles['header-title']} variant="title-large">
            {t('drawerShowProductTitle')}
          </Text>
          <Text className={styles['header-subtitle']}>
            {t('drawerShowProductSubTitle')}
          </Text>
        </div>

        <button
          type="button"
          onClick={() => onHandleOpen(false)}
          className={styles['header-button']}
        >
          <CloseIcon />
        </button>
      </header>

      <main className={styles.main}>
        <div className={styles['main-box']}>
          <div className={styles['main-header']}>
            <button
              className={styles['main-header-back']}
              type="button"
              onClick={() => onHandleOpen(false)}
            >
              <ChevronLeftIcon />
            </button>

            <Text className={styles['main-title']} variant="text-large-bold">
              {t('drawerShowProductTitle')}
            </Text>
          </div>

          <div className={`${styles['main-content']}`}>
            <CatalogProductDetail
              isLoading={isLoading}
              catalogProduct={
                convertObjectToCamelCase(
                  catalogProductData?.data,
                ) as CatalogProductProps
              }
            />
          </div>
        </div>
      </main>
    </Drawer>
  )
}
