import { useEffect, useState } from 'react'

import {
  Alert,
  Button,
  Input,
  InputNumber,
  Label,
  Select,
  SelectSearch,
  Tooltip,
  QuestionFillIcon,
  DatePicker,
} from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { formatCurrency, onValidationSchema } from '@/helpers'

import { useSupplierList } from '@/services'
import { CatalogSearch } from '@/components/catalog-search'

import {
  shemaProductItemCatalogProduct,
  shemaProductItemNonCatalogProduct,
} from './order-item-form.constants'

import type { OrderItemFormProps } from './order-item-form.type'

import type {
  SaleOrderItemNonCatalogProductProps,
  CatalogProductProps,
  SupplierProps,
  SaleOrderItemProps,
} from '@/types'

import styles from './order-item-form.module.css'

export const OrderItemForm = ({
  onChangeValues,
  values,
  onChangeShipmentValues,
  shipmentValues,
  onClickBack,
  onHandleSubmit,
  isNewRegister,
}: OrderItemFormProps) => {
  const { t } = useTranslation('componentsOrderItemForm')
  const { t: tEnums } = useTranslation('enums')

  const [type, setType] = useState('catalog')
  const [initEdit, setInitEdit] = useState(false)

  const [errors, setErrors] = useState<Record<string, any>>({}) // eslint-disable-line @typescript-eslint/no-explicit-any
  const [isValid, setIsValid] = useState<boolean>(false)
  const [listenErrors, setListenErrors] = useState<boolean>(false)

  const [supplierSearch, setSupplierSearch] = useState<string>('')

  const { data: dataSearch, isLoading: isLoadingSearch } = useSupplierList({
    params: { search: supplierSearch },
  })

  useEffect(() => {
    const validate = async () => {
      const schema =
        type === 'catalog'
          ? shemaProductItemCatalogProduct
          : shemaProductItemNonCatalogProduct

      const response = await onValidationSchema(schema(t), {
        ...values,
        shipment: shipmentValues,
      })
      setIsValid(response.isValid)

      if (listenErrors) {
        setErrors(response.errors)
      }
    }

    validate()
  }, [listenErrors, values, shipmentValues, type])

  useEffect(() => {
    if (!initEdit) {
      if (!values?.catalogProduct?.uuid && !isNewRegister) {
        setType('rfq')
      } else {
        setType('catalog')
      }
    }

    return () => setInitEdit(false)
  }, [values?.catalogProduct?.uuid])

  const onHandleValues = (key: string, value?: string | number | null) => {
    if (!initEdit) setInitEdit(true)

    onChangeValues({ ...values, [key]: value })
  }

  const getValuesNumber = (key: keyof SaleOrderItemProps) => {
    if (values === undefined) return 0

    const value = values[key] as string | number

    return value !== undefined && !isNaN(+value) ? +value : 0
  }

  const onHandleValuesProduct = (key: string, value: string | number) => {
    onChangeValues({
      ...values,
      nonCatalogProduct: {
        ...values?.nonCatalogProduct,
        [key]: value,
      } as SaleOrderItemNonCatalogProductProps,
    })
  }

  const onSelectCatalogProduct = (
    catalogProductParam: Partial<CatalogProductProps>,
  ) => {
    if (!catalogProductParam) {
      onChangeValues({
        ...values,
        catalogProduct: undefined,
        catalogProductUuid: undefined,
      })

      return
    }

    onChangeValues({
      ...values,
      catalogProduct: catalogProductParam as CatalogProductProps,
      catalogProductUuid: catalogProductParam?.uuid,
    })
  }

  const onSelectSupplier = (supplier: Partial<SupplierProps>) => {
    onChangeShipmentValues({
      ...shipmentValues,
      supplier: supplier as SupplierProps,
      supplierUuid: supplier?.uuid,
    })
  }

  const onHandleSubmitLocal = () => {
    if (!isValid) {
      setListenErrors(true)
      return
    }

    setListenErrors(false)
    onHandleSubmit()
    onClickBack()
  }

  return (
    <>
      <div className={styles.core}>
        {!isNewRegister && type === 'rfq' && (
          <div>
            <Label>{t('formTypeLabel')}</Label>
            <Select
              placeholder={t('formTypePlaceholder')}
              options={[
                {
                  label: t('formTypeOptionCatalog'),
                  value: 'catalog',
                },
                {
                  label: t('formTypeOptionNonCatalog'),
                  value: 'rfq',
                },
              ]}
              value={type}
              onChange={setType}
            />
          </div>
        )}

        {type === 'rfq' && (
          <>
            <div>
              <Label>{t('formDescriptionProductLabel')}</Label>
              <Input
                placeholder={t('formDescriptionProductPlaceholder')}
                value={values?.nonCatalogProduct?.description}
                onChange={(newValue) =>
                  onHandleValuesProduct('description', newValue)
                }
                error={errors?.nonCatalogProduct?.description as string}
              />
            </div>

            <div>
              <Label>{t('formLinkProductLabel')}</Label>
              <Input
                placeholder={t('formLinkProductPlaceholder')}
                value={values?.nonCatalogProduct?.link}
                onChange={(newValue) => onHandleValuesProduct('link', newValue)}
                error={errors?.nonCatalogProduct?.link as string}
              />
            </div>
          </>
        )}

        {type === 'catalog' && (
          <CatalogSearch
            onSelectProduct={onSelectCatalogProduct}
            selectedProduct={values?.catalogProduct}
            error={errors?.catalogProduct?.uuid as string}
          />
        )}

        <div>
          <Label>{t('formPriceLabel')}</Label>
          <InputNumber
            placeholder={t('formPricePlaceholder')}
            showIcons
            value={getValuesNumber('price')}
            error={errors?.price}
            decimalPrecision={3}
            prefix="$ "
            onChange={(newValue) => onHandleValues('price', newValue)}
          />
        </div>

        <div>
          <Label>{t('formQuantityLabel')}</Label>
          <InputNumber
            placeholder={t('formQuantityPlaceholder')}
            value={getValuesNumber('quantity')}
            error={errors?.quantity}
            decimalPrecision={0}
            showIcons
            suffix=" Kg"
            onChange={(newValue) => onHandleValues('quantity', newValue)}
            stepSize={(values?.quantity || 0) >= 1000 ? 1000 : 100}
          />
        </div>

        <Alert title={t('formTotalPriceLabel')}>
          {t('formTotalPriceDescription')}{' '}
          {formatCurrency(
            getValuesNumber('price') * getValuesNumber('quantity'),
            'es-MX',
            'USD',
          )}
        </Alert>

        <div>
          <Label>{t('formPackageLabel')}</Label>
          <Select
            placeholder={t('formPackagePlaceholder')}
            options={[
              {
                label: t('formPackagePlaceholder'),
                value: '',
              },
              {
                label: tEnums('quotation_items_package_25kg_bags'),
                value: '25kg_bags',
              },
              {
                label: tEnums('quotation_items_package_super_sacks'),
                value: 'super_sacks',
              },
              {
                label: tEnums('quotation_items_package_in_bulk'),
                value: 'in_bulk',
              },
            ]}
            value={values?.package}
            error={errors?.package}
            onChange={(newValue) => onHandleValues('package', newValue)}
          />
        </div>

        <div className={styles['card-backoffice']}>
          <div>
            <Label>
              {t('formCostLabel')}
              <Tooltip
                text={t('formCostDescription')}
                widthText="15rem"
                orientation="left"
              >
                <QuestionFillIcon />
              </Tooltip>
            </Label>

            <InputNumber
              placeholder={t('formCostPlaceholder')}
              showIcons
              value={getValuesNumber('cost')}
              error={errors?.cost}
              decimalPrecision={3}
              prefix="$ "
              onChange={(newValue) => onHandleValues('cost', newValue)}
            />
          </div>

          <Alert title={t('formTotalCostLabel')}>
            {t('formTotalCostDescription')}{' '}
            {formatCurrency(
              getValuesNumber('cost') * getValuesNumber('quantity'),
              'es-MX',
              'USD',
            )}
          </Alert>

          <div>
            <SelectSearch<SupplierProps>
              dataSearch={dataSearch?.data?.results || []}
              isLoadingSearch={isLoadingSearch}
              onSearch={setSupplierSearch}
              onSelectItem={onSelectSupplier}
              selectedItem={shipmentValues?.supplier}
              keyLabel="name"
              keyValue="uuid"
              translations={{
                inputSearchLabel: t('formSupplierLabel'),
                inputSearchPlaceholder: t('formSupplierPlaceholder'),
                noResults: t('formSupplierNoResults'),
              }}
              error={
                (errors?.shipment?.supplier?.uuid ||
                  errors?.shipment?.supplier) as string
              }
            />
          </div>

          <div>
            <Label>{t('formSupplyPaymentDateLabel')}</Label>
            <DatePicker
              placeholder={t('formSupplyPaymentDatePlaceholder')}
              value={shipmentValues?.supplierPaidAt || ''}
              onChange={(newValue) =>
                onChangeShipmentValues({
                  ...shipmentValues,
                  supplierPaidAt: newValue || '',
                })
              }
              error={errors?.supplierPaidAt as string}
            />
          </div>

          <div>
            <Label>
              {t('formTraderComissionLabel')}
              <Tooltip
                text={t('formTraderComissionDescription')}
                widthText="15rem"
              >
                <QuestionFillIcon />
              </Tooltip>
              <span>{t('formOptionalLabel')}</span>
            </Label>
            <InputNumber
              placeholder={t('formTraderComissionPlaceholder')}
              value={getValuesNumber('traderCommission')}
              error={errors?.traderCommission}
              showIcons
              decimalPrecision={5}
              prefix="$ "
              onChange={(newValue) =>
                onHandleValues('traderCommission', newValue)
              }
            />
          </div>

          <Alert title={t('formTotalTraderComissionLabel')}>
            {t('formTotalTraderComissionDescription')}{' '}
            {formatCurrency(
              getValuesNumber('traderCommission') * getValuesNumber('quantity'),
              'es-MX',
              'USD',
            )}
          </Alert>
        </div>
      </div>
      <div className={styles.footer}>
        <Button variant="secondary" onClick={onClickBack}>
          {t('buttonBack')}
        </Button>

        <Button onClick={onHandleSubmitLocal}>{t('buttonSubmit')}</Button>
      </div>
    </>
  )
}
