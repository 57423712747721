/* eslint-disable */
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Accordion, ReceiptIcon, DownloadIcon, Text } from "@mercai/clever";

import { downloadFile } from "@/helpers";

import styles from "./order-logistic-documents.module.css";

import type { OrderLogisticDocumentsProps } from "./order-logistic-documents.type";

export const OrderLogisticDocuments = ({
  shipment,
}: OrderLogisticDocumentsProps) => {
  const [activeItems, setActiveItems] = useState<string[]>([]);

  const { t } = useTranslation("componentOrderCenterShow");

  const onClickDownload = (url: string) => {
    downloadFile(url);
  };

  return (
    <Accordion.Root
      activeItems={activeItems}
      onHandleActiveItems={setActiveItems}
      className={styles.core}
    >
      <Accordion.Item
        id="info-list"
        header={
          <div className={styles["box-title"]}>
            <ReceiptIcon />

            <Text variant="title-base" className={styles.title}>
              {t("orderLogisticDocumentsTitle")}
            </Text>
          </div>
        }
      >
        <div className={styles.content}>
          {!shipment?.shipmentInvoice &&
            !shipment?.deliveryList &&
            !shipment?.customsCertificate &&
            !shipment?.originCertificate &&
            !shipment?.shipmentReceipt && (
              <Text>{t("orderLogisticDocumentsEmpty")}</Text>
            )}

          {shipment?.shipmentInvoice && (
            <button
              type="button"
              className={styles.item}
              onClick={() => onClickDownload(shipment?.shipmentInvoice)}
            >
              {t("orderLogisticDocumentsShipmentInvoice")}
              <DownloadIcon />
            </button>
          )}

          {shipment?.deliveryList && (
            <button
              type="button"
              className={styles.item}
              onClick={() => onClickDownload(shipment?.deliveryList)}
            >
              {t("orderLogisticDocumentsDeliveryList")}
              <DownloadIcon />
            </button>
          )}

          {shipment?.customsCertificate && (
            <button
              type="button"
              className={styles.item}
              onClick={() => onClickDownload(shipment?.customsCertificate)}
            >
              {t("orderLogisticDocumentsCustomsCertificate")}
              <DownloadIcon />
            </button>
          )}

          {shipment?.originCertificate && (
            <button
              type="button"
              className={styles.item}
              onClick={() => onClickDownload(shipment?.originCertificate)}
            >
              {t("orderLogisticDocumentsOriginCertificate")}
              <DownloadIcon />
            </button>
          )}

          {shipment?.shipmentReceipt && (
            <button
              type="button"
              className={styles.item}
              onClick={() => onClickDownload(shipment?.shipmentReceipt)}
            >
              {t("orderLogisticDocumentsCustomsInvoice")}
              <DownloadIcon />
            </button>
          )}
        </div>
      </Accordion.Item>
    </Accordion.Root>
  );
};
