import * as Yup from 'yup'

export const schemaQuotation = (t: (key: string) => string) => ({
  status: Yup.string().required(t('requiredMessage')),
  rejectedReason: Yup.string().when('status', (status, schema) => {
    if (String(status) === 'rejected') {
      return schema.required(t('requiredMessage'))
    }

    return schema
  }),
  dollarExchangeRate: Yup.string().required(t('requiredMessage')),
  expiresAt: Yup.string().required(t('requiredMessage')),
  expectedDeliveryDays: Yup.string().required(t('requiredMessage')),
  firstMileFreightCost: Yup.string()
    .required(t('requiredMessage'))
    .min(0, t('requiredMessage')),
  lastMileFreightCost: Yup.string()
    .required(t('requiredMessage'))
    .min(0, t('requiredMessage')),
  customsFreightCost: Yup.string()
    .required(t('requiredMessage'))
    .min(0, t('requiredMessage')),
  financingCost: Yup.string().when(
    'purchaseOnCredit',
    (purchaseOnCredit, schema) => {
      if (purchaseOnCredit[0]) {
        return schema
          .required(t('requiredMessage'))
          .min(0, t('requiredMessage'))
      }

      return schema.nullable()
    },
  ),
  totalFreightPrice: Yup.string()
    .required(t('requiredMessage'))
    .min(0, t('requiredMessage')),
})
