import * as Yup from 'yup'

export const shemaProductItemNonCatalogProduct = (
  t: (key: string) => string,
) => {
  return {
    nonCatalogProduct: Yup.object().shape({
      description: Yup.string()
        .required(t('quotationFormProductDescriptionRequiredMessage'))
        .max(128, t('quotationFormProductDescriptionMaxMessage')),
      link: Yup.string().required(t('quotationFormProductLinkRequiredMessage')),
    }),
    price: Yup.number()
      .required(t('quotationFormProductPriceRequiredMessage'))
      .min(0, t('quotationFormProductPriceRequiredMessage')),
    cost: Yup.number()
      .required(t('quotationFormProductPriceRequiredMessage'))
      .min(0, t('quotationFormProductPriceRequiredMessage')),
    quantity: Yup.number()
      .required(t('quotationFormProductQuantityRequiredMessage'))
      .min(0, t('quotationFormProductQuantityRequiredMessage')),
    package: Yup.string().required(
      t('quotationFormProductPackageRequiredMessage'),
    ),
    supplier: Yup.object()
      .shape({
        uuid: Yup.string().required(t('quotationFormSupplierRequiredMessage')),
      })
      .required(t('quotationFormSupplierRequiredMessage')),
  }
}

export const shemaProductItemCatalogProduct = (t: (key: string) => string) => {
  return {
    catalogProduct: Yup.object().shape({
      uuid: Yup.string().required(
        t('quotationFormCatalogProductRequiredMessage'),
      ),
    }),
    price: Yup.number().required(t('quotationFormProductPriceRequiredMessage')),
    cost: Yup.number()
      .required(t('quotationFormProductPriceRequiredMessage'))
      .min(0, t('quotationFormProductPriceRequiredMessage')),
    quantity: Yup.number()
      .required(t('quotationFormProductQuantityRequiredMessage'))
      .min(0, t('quotationFormProductQuantityRequiredMessage')),
    package: Yup.string().required(
      t('quotationFormProductPackageRequiredMessage'),
    ),
    supplier: Yup.object()
      .shape({
        uuid: Yup.string().required(t('quotationFormSupplierRequiredMessage')),
      })
      .required(t('quotationFormSupplierRequiredMessage')),
  }
}
