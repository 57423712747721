import { useQuery, useMutation } from '@tanstack/react-query'
import { convertObjectToCamelCase, convertObjectToSnakeCase } from '@/helpers'

import { OrderTrackerListWithPagesProps } from '@/types'

import { QUERIES_ADMIN } from './api.constants'
import { api } from './api.service'
import { ORDER_TRACKER_URL, ORDER_TRACKER_REGENERATE_URL } from './api.urls'

export const useOrderTrackerList = ({
  params,
}: {
  params?: Record<string, string | number>
}) => {
  const getOrderTrackerList = async (): Promise<{
    success: boolean
    message: string
    data?: OrderTrackerListWithPagesProps
  }> => {
    try {
      const copyParams = { ...params }

      const offset = copyParams?.page
        ? (+(copyParams?.page || 0) - 1) * +copyParams.limit
        : 0

      delete copyParams.page

      const formattedParams = Object.keys(copyParams).reduce((acc, key) => {
        if (copyParams?.[key]) {
          return {
            ...acc,
            [key]: copyParams?.[key],
          }
        }

        return acc
      }, {})

      const { data } = await api.get(ORDER_TRACKER_URL, {
        params: {
          ...(convertObjectToSnakeCase(formattedParams) as Record<
            string,
            string
          >),
          offset,
        },
      })

      return {
        success: true,
        message: 'Order Trackers fetched successfully!',
        data: convertObjectToCamelCase(data) as OrderTrackerListWithPagesProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get order trackers!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.ORDER_TRACKER.module, params],
    queryFn: () => getOrderTrackerList(),
  })
}

export const useRegenerateOrderTracker = ({
  params,
}: {
  params?: Record<string, string | number>
}) => {
  const regenerateOrderTracker = async (): Promise<{
    success: boolean
    message: string
    data?: OrderTrackerListWithPagesProps
  }> => {
    try {
      const { data } = await api.post(ORDER_TRACKER_REGENERATE_URL, undefined, {
        params: convertObjectToSnakeCase(params),
      })

      return {
        success: true,
        message: 'Order Tracker regenerated successfully!',
        data: convertObjectToCamelCase(data) as OrderTrackerListWithPagesProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to regenerate order tracker!',
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.ORDER_TRACKER.module],
    mutationFn: () => regenerateOrderTracker(),
  })
}
