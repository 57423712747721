export const getMultiValue = (
  filter: Record<string, string>,
  key: string,
  value: string,
): boolean => {
  const values = (filter[key] as string)?.split(',') || []
  return values.includes(value)
}

export const onChangeMultiValue = (
  filter: Record<string, string>,
  onHandleFilter: (newValue: Record<string, string>) => void,
  key: string,
  value: string,
) => {
  let values = (filter[key] as string)?.split(',') || []
  values = values.filter((item) => !!item)

  let newValues = ''

  if (values.includes(value)) {
    newValues = values.filter((item) => item !== value).join(',')
  } else {
    newValues = [...values, value].join(',')
  }

  onHandleFilter({ ...filter, [key]: newValues })
}
