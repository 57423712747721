import { useEffect, useState } from 'react'
import { Input } from '../../core'
import { SearchIcon } from '../../../icons'

import { InputSearchProps } from './input-search.type'

export const InputSearch = ({
  onChange,
  value,
  className,
  translations,
}: InputSearchProps) => {
  const [localValue, setLocalValue] = useState(value)

  useEffect(() => {
    if (value !== localValue) setLocalValue(value)
  }, [value])

  useEffect(() => {
    const handler = setTimeout(() => {
      if (localValue === value) return
      onChange(localValue)
    }, 500)
    return () => clearTimeout(handler)
  }, [localValue, onChange])

  return (
    <Input
      className={className}
      iconLeft={<SearchIcon />}
      placeholder={translations?.inputPlaceholder}
      value={localValue}
      onChange={setLocalValue}
    />
  )
}
