import { useTranslation } from 'react-i18next'
import {
  Label,
  Text,
  Input,
  InputNumber,
  Tooltip,
  QuestionFillIcon,
} from '@mercai/clever'

import { LogisticShipmentProps, SaleOrderProps } from '@/types'
import type { LogisticFormTransportProps } from './costs.type'

import styles from './costs.module.css'

export const LogisticFormCosts = ({
  onChange,
  values = {} as SaleOrderProps,
}: LogisticFormTransportProps) => {
  const { t } = useTranslation('componentOrderCenterForm')

  const onChangeShipmentValues = (
    keyParam: keyof LogisticShipmentProps,
    value?: string | number,
  ) => {
    const shipment = { ...values?.shipments?.[0] }
    const updatedShipment = { ...shipment, [keyParam]: value }

    onChange({
      ...values,
      shipments: [updatedShipment, ...values?.shipments.slice(1)],
    })
  }

  return (
    <div className={styles.content}>
      <Text variant="text-large-bold">{t('logisticCostsTitle')}</Text>

      <div className={styles.field}>
        <Label>
          {t('logisticFirstMileCostLabel')}
          <Tooltip text={t('logisticCIADescription')} widthText="17.25rem">
            <QuestionFillIcon />
          </Tooltip>
        </Label>
        <InputNumber
          value={+(values?.shipments?.[0]?.firstMileCost || 0)}
          onChange={(newValue) =>
            onChangeShipmentValues('firstMileCost', newValue)
          }
          prefix="USD "
          showIcons
        />
      </div>

      <div className={styles.field}>
        <Label>
          {t('logisticLastMileCostLabel')}
          <Tooltip text={t('logisticCIADescription')} widthText="17.25rem">
            <QuestionFillIcon />
          </Tooltip>
        </Label>
        <InputNumber
          value={+(values?.shipments?.[0]?.lastMileCost || 0)}
          onChange={(newValue) =>
            onChangeShipmentValues('lastMileCost', newValue)
          }
          prefix="USD "
          showIcons
        />
      </div>

      <div className={styles.field}>
        <Label>
          {t('logisticCustomsCostLabel')}
          <Tooltip text={t('logisticCIADescription')} widthText="17.25rem">
            <QuestionFillIcon />
          </Tooltip>
        </Label>
        <InputNumber
          value={+(values?.shipments?.[0]?.customsCost || 0)}
          onChange={(newValue) =>
            onChangeShipmentValues('customsCost', newValue)
          }
          prefix="USD "
          showIcons
        />
      </div>

      <div className={styles.field}>
        <Label>
          {t('logisticExtraCostLabel')}
          <Tooltip text={t('logisticCIADescription')} widthText="17.25rem">
            <QuestionFillIcon />
          </Tooltip>
        </Label>
        <InputNumber
          value={+(values?.shipments?.[0]?.extraCost || 0)}
          onChange={(newValue) => onChangeShipmentValues('extraCost', newValue)}
          prefix="USD "
          showIcons
        />
      </div>

      <div className={styles.field}>
        <Label>{t('logisticExtraCostReasonLabel')}</Label>

        <Input
          value={values?.shipments?.[0]?.extraCostReason}
          onChange={(newValue) =>
            onChangeShipmentValues('extraCostReason', newValue)
          }
        />
      </div>
    </div>
  )
}
