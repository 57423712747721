import { TooltipProps, TooltipOrientationEnum } from './tooltip.type'

import { generateClasses } from './tooltip.constants'

import styles from './tooltip.module.css'

export const Tooltip = ({
  children,
  className,
  orientation = TooltipOrientationEnum.bottom,
  text,
  widthText,
}: TooltipProps) => {
  const generatedClasses = generateClasses(orientation)

  return (
    <div className={`${styles.core} ${className || ''}`}>
      <div
        className={generatedClasses}
        style={{ width: widthText || undefined }}
      >
        {text}
      </div>
      {children}
    </div>
  )
}
