import { useCallback, useEffect, useState, useMemo, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Tag,
  Button,
  Checkbox,
  DropDown,
  TableColumns,
  Text,
  Select,
  Skeleton,
  Pagination,
  SettingIcon,
  TableColumnsColumnProps,
  PlusIcon,
  InputSearch,
  RefreshIcon,
  Tooltip,
} from '@mercai/clever'

import { DEFAULT_LIMIT_PAGE } from '@/constants'
import { Header } from '@/components'
import {
  formatDate,
  useParamsState,
  useCookieState,
  trackEvent,
  trimString,
} from '@/helpers'
import { useKaiProcessorList } from '@/services'
import { ROUTES_ADMIN } from '@/router'
import { KaiProductProps, KaiProductOriginEnum } from '@/types'

import {
  CatalogProcessorDrawerNewForm,
  CatalogProcessorDrawerForm,
} from './components'

import styles from './list.module.css'

export const CatalogProcessorList = () => {
  const { t } = useTranslation('screenCatalogProcessorList')
  const { t: tEnums } = useTranslation('enums')

  const [totalPages, setTotalPages] = useState(1)

  const [columnsKeys, setColumnsKeys] = useCookieState<string[]>(
    '@mercai/catalog-processor-list-columns',
    [
      'reference',
      'user',
      'status',
      'origin',
      'previousPayload',
      'dataSheet',
      'completedAt',
      'createdAt',
    ],
  )

  const [filter, setFilter] = useParamsState({
    page: '1',
    limit: `${DEFAULT_LIMIT_PAGE}`,
    search: '',
    status: '',
    createdAtBefore: '',
    createdAtAfter: '',
  })

  const [queryParams, setQueryParams] = useParamsState({
    uuid: '',
    edit: '',
    new: '',
  })

  const onHandleColumnsShow = (key: string) => {
    let newColumnsKeys = columnsKeys

    if (columnsKeys.includes(key)) {
      newColumnsKeys = columnsKeys.filter((item) => item !== key)
    } else {
      newColumnsKeys = [...columnsKeys, key]
    }

    setColumnsKeys(newColumnsKeys)
  }

  const getColumnsShow = useCallback(
    (key: string) => columnsKeys.includes(key),
    [columnsKeys],
  )

  const handleFilter = (changedKey: string, newValue: string) => {
    setFilter({ ...filter, [changedKey]: newValue })
  }

  const { data, isLoading, isFetching, refetch } = useKaiProcessorList({
    params: filter,
  })

  useEffect(() => {
    if (data?.data?.count) {
      setTotalPages(Math.ceil(data?.data?.count / +filter.limit))
    }
  }, [data])

  useEffect(() => {
    trackEvent('adminCatalogProcessorListPageView')
  }, [])

  const onHandleNewRegister = () => {
    setQueryParams({ ...queryParams, edit: '', new: 'true', uuid: '' })
  }

  const onHandleEditRegister = (uuid: string) => {
    setQueryParams({ ...queryParams, new: '', edit: 'true', uuid })
  }

  const getStatus = (status: string = 'pending') => {
    const statuses: Record<string, ReactNode> = {
      pending: (
        <Tag model="light" variant="basic">
          {tEnums(`product_processor_status_${status}`)}
        </Tag>
      ),
      processing: (
        <Tag model="light" variant="primary">
          {tEnums(`product_processor_status_${status}`)}
        </Tag>
      ),
      processed: (
        <Tag model="light" variant="warning">
          {tEnums(`product_processor_status_${status}`)}
        </Tag>
      ),
      canceled: (
        <Tag model="light" variant="danger">
          {tEnums(`product_processor_status_${status}`)}
        </Tag>
      ),
      unprocessable: (
        <Tag model="light" variant="danger">
          {tEnums(`product_processor_status_${status}`)}
        </Tag>
      ),
      approved: (
        <Tag model="light" variant="success">
          {tEnums(`product_processor_status_${status}`)}
        </Tag>
      ),
      rejected: (
        <Tag model="light" variant="danger">
          {tEnums(`product_processor_status_${status}`)}
        </Tag>
      ),
    }

    return statuses[status]
  }

  const getOrigin = (origin: keyof typeof KaiProductOriginEnum = 'file') => {
    const origins: Record<KaiProductOriginEnum, ReactNode> = {
      file: (
        <Tag model="light" variant="basic">
          {t('tableOriginOptionFile')}
        </Tag>
      ),
      enrichment: (
        <Tag model="light" variant="primary">
          {t('tableOriginOptionCatalog')}
        </Tag>
      ),
      raw_information: (
        <Tag model="light" variant="secondary">
          {t('tableOriginOptionRawInformation')}
        </Tag>
      ),
    }

    return origins[origin]
  }

  const getContentProduct = useCallback(
    (title: string, index: number) => {
      return (
        <Tooltip
          text={title}
          widthText="18.75rem"
          orientation={index >= 8 ? 'top' : 'bottom'}
        >
          {trimString(title, 52, '...')}
        </Tooltip>
      )
    },
    [data],
  )

  const columns: TableColumnsColumnProps<KaiProductProps>[] = [
    {
      title: t('tableReference'),
      key: 'reference',
      dataKey: 'reference',
      width: '9rem',
      render: ({ record }) => (
        <Text
          className={styles.link}
          variant="link"
          href={ROUTES_ADMIN.CATALOG.PRODUCT.SHOW.fullPath(
            record.catalogProductUuid,
          )}
        >
          {record.reference}
        </Text>
      ),
    },
    {
      title: t('tableProductTitle'),
      key: 'previousPayload',
      dataKey: 'previousPayload',
      width: '20rem',
      render: ({ record, index }) =>
        getContentProduct(
          record?.previousPayload?.attributes?.title ||
            record?.generatedPayload?.attributes?.title ||
            '-',
          index,
        ),
    },
    {
      title: t('tableUser'),
      key: 'user',
      dataKey: 'user',
      width: '14rem',
    },
    {
      title: t('tableStatus'),
      key: 'status',
      dataKey: 'status',
      width: '9rem',
      render: ({ value }) => getStatus(value),
    },
    {
      title: t('tableOrigin'),
      key: 'origin',
      dataKey: 'origin',
      width: '9rem',
      render: ({ value }) =>
        getOrigin(value as keyof typeof KaiProductOriginEnum),
    },
    {
      title: t('tableFile'),
      key: 'dataSheet',
      dataKey: 'dataSheet',
      width: '9rem',
      render: ({ value }) =>
        value ? (
          <Text
            className={styles.link}
            variant="link"
            href={value}
            target="_blank"
          >
            {t('tableFileLink')}
          </Text>
        ) : (
          '-'
        ),
    },
    {
      title: t('tableCompletedAt'),
      key: 'completedAt',
      dataKey: 'completedAt',
      render: ({ value }) => (value ? formatDate(value) : '-'),
    },
    {
      title: t('tableCreatedAt'),
      key: 'createdAt',
      dataKey: 'createdAt',
      render: ({ value }) => (value ? formatDate(value) : '-'),
    },
  ]

  const memoizedColumns = useMemo(
    () => columns.filter((column) => getColumnsShow(column.key)).map((c) => c),
    [columns, getColumnsShow],
  )

  return (
    <div>
      <Header title={t('title')} description={t('description')} />

      <div className={styles.content}>
        <div>
          <div className={styles.filter}>
            <div className={styles['filter-group']}>
              <InputSearch
                value={filter.search as string}
                onChange={(value) => handleFilter('search', value)}
                className={styles['search-input']}
                translations={{
                  inputPlaceholder: t('searchFilterPlaceholder'),
                }}
              />
            </div>

            <div className={styles['filter-group']}>
              <Button onClick={onHandleNewRegister}>
                <PlusIcon />
                {t('tableNewRegister')}
              </Button>

              <DropDown.Root>
                <DropDown.ButtonTrigger
                  variant="secondary"
                  model="square"
                  hasArrow={false}
                >
                  <SettingIcon />
                </DropDown.ButtonTrigger>

                <DropDown.Content orientation="bottom-right">
                  {columns?.map((column) => (
                    <DropDown.Item key={column.key}>
                      <Checkbox
                        onChange={() => onHandleColumnsShow(column.key)}
                        value={getColumnsShow(column.key)}
                      >
                        {column.title}
                      </Checkbox>
                    </DropDown.Item>
                  ))}
                </DropDown.Content>
              </DropDown.Root>

              <Button
                variant="secondary"
                model="square"
                onClick={() => refetch()}
              >
                <RefreshIcon className={isFetching ? styles.animation : ''} />
              </Button>
            </div>
          </div>

          <TableColumns<KaiProductProps>
            isLoading={isLoading}
            columns={memoizedColumns}
            fixedColumns={[
              {
                title: t('tableActions'),
                key: 'uuid',
                dataKey: 'uuid',
                width: '8.25rem',
                render: ({ record }) => (
                  <div className={styles['action-buttons']}>
                    <Button
                      variant="secondary"
                      onClick={() => onHandleEditRegister(record.uuid)}
                    >
                      {t('tableActionsView')}
                    </Button>
                  </div>
                ),
                renderLoading: (
                  <div className={styles['action-buttons']}>
                    <Skeleton height="2rem" width="5.7rem" />
                  </div>
                ),
              },
            ]}
            data={data?.data?.results || []}
          />

          <div className={styles.pagination}>
            <div className={styles['pagination-show-registers']}>
              <Text>
                {t('tableShowRegisters', {
                  showNumber:
                    (data?.data?.count || 0) < +filter.limit
                      ? data?.data?.count
                      : +filter.limit,
                  totalNumber: data?.data?.count || 0,
                })}
              </Text>

              <Select
                value={filter.limit}
                onChange={(value) => handleFilter('limit', value)}
                options={[
                  { label: '10', value: '10' },
                  { label: '20', value: '20' },
                  { label: '50', value: '50' },
                  { label: '100', value: '100' },
                ]}
              />
            </div>

            <Pagination
              totalPages={totalPages}
              onChange={(page) => handleFilter('page', `${page}`)}
              currentPage={+filter.page as number}
            />
          </div>
        </div>
      </div>

      <CatalogProcessorDrawerNewForm
        isOpen={!!queryParams.new}
        onHandleOpen={() =>
          setQueryParams({ ...queryParams, new: '', uuid: '' })
        }
      />

      <CatalogProcessorDrawerForm
        isOpen={!!queryParams.edit}
        onHandleOpen={() =>
          setQueryParams({ ...queryParams, edit: '', uuid: '' })
        }
        registerUuid={queryParams.uuid}
      />
    </div>
  )
}
