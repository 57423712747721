import { useEffect, useState, useRef } from 'react'
import { Skeleton } from '@mercai/clever'

import { useCatalogProductList } from '@/services'

import { InputSearch, DropDownSearch } from './components'

import type { CatalogSearchProps } from './catalog-search.type'
import type { CatalogProductProps } from '@/types'

import styles from './catalog-search.module.css'

export const CatalogSearch = ({
  onSelectProduct,
  selectedProduct,
  error,
  maxHeightDropdown,
}: CatalogSearchProps) => {
  const [value, setValue] = useState('')
  const [isOpen, setIsOpen] = useState(false)

  const { data, isLoading } = useCatalogProductList({ search: value })

  const onChangeValue = (newValue: string) => {
    setValue(newValue)

    if (selectedProduct?.uuid) {
      onSelectProduct({})
    }
  }

  const onHandleSelectProduct = (catalogProductParam: CatalogProductProps) => {
    setIsOpen(false)
    onSelectProduct(catalogProductParam)
  }

  useEffect(() => {
    if (selectedProduct?.attributes?.title) {
      setValue(selectedProduct?.attributes?.title)
    }

    return () => {
      setValue('')
    }
  }, [selectedProduct])

  const rootRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const closeOpennedDropDown = (e: MouseEvent) => {
      if (
        rootRef.current &&
        isOpen &&
        !rootRef.current.contains(e.target as Node)
      ) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', closeOpennedDropDown)

    return () => document.removeEventListener('mousedown', closeOpennedDropDown)
  }, [isOpen, rootRef])

  return (
    <div className={styles.core} ref={rootRef}>
      {isLoading && !isOpen ? (
        <div className={styles['wrap-skeleton']}>
          <Skeleton width="70%" height="1.25rem" />
          <Skeleton width="100%" height="2.5rem" />
        </div>
      ) : (
        <InputSearch
          onChange={onChangeValue}
          value={value}
          onOpen={setIsOpen}
          error={error}
        />
      )}

      <DropDownSearch
        isOpen={isOpen}
        data={data?.data?.results}
        isLoading={isLoading}
        onSelectProduct={onHandleSelectProduct}
        selectedProduct={selectedProduct}
        maxHeight={maxHeightDropdown}
      />
    </div>
  )
}
