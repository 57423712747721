import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Text } from '@mercai/clever'

import { formatCurrency } from '@/helpers'

import { currencyOptions } from './currency-tab-values.constants'
import styles from './currency-tab-values.module.css'

import { CurrencyTabValuesProps } from './currency-tab-values.type'

export const CurrencyTabValues = ({
  usdValue,
  mxnValue,
  dollarExchangeRate,
  initialCurrency,
}: CurrencyTabValuesProps) => {
  const { t } = useTranslation('componentsCurrencyTabValues')

  const [currency, setCurrency] = useState('MXN')

  const options = currencyOptions(t)

  const totalByCurrency = formatCurrency(
    currency === 'MXN' ? mxnValue : usdValue,
    'es-MX',
    currency,
  )

  useEffect(() => {
    setCurrency(initialCurrency)
  }, [initialCurrency])

  return (
    <div className={styles.core}>
      <div className={styles.tabs}>
        {options.map((tab, index) => (
          <button
            key={index}
            className={`${styles.tab} ${
              currency === tab.currency ? styles.active : ''
            }`}
            onClick={() => setCurrency(tab.currency as typeof currency)}
          >
            <img src={tab.src} alt={tab.currency} />
            {tab.label}
          </button>
        ))}
      </div>

      <div className={styles.total}>
        <Text variant="title-base" className={styles.totalTitle}>
          {t('totalTitle')}
        </Text>

        <Text variant="text-large-bold">{totalByCurrency}</Text>

        <Text variant="text-small" className={styles['dollar-exchange-rate']}>
          {t('dollarExchangeRateLabel')}
          {formatCurrency(dollarExchangeRate, 'es-MX', 'MXN', 4)}
        </Text>
      </div>
    </div>
  )
}
