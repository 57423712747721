import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Text, DownloadIcon, CopyIcon, CheckIcon } from '@mercai/clever'

import {
  copyTextToClipboard,
  downloadFile,
  getFileNameAndExtension,
} from '@/helpers'
import { useFileSign } from '@/services'

import styles from './card-tax-situation.module.css'

import type { CardTaxSituationProps } from './card-tax-situation.type'

export const CardTaxSituation = ({ taxSituation }: CardTaxSituationProps) => {
  const { t } = useTranslation('screenOrderList')

  const [isCopied, setIsCopied] = useState(false)

  const { mutateAsync, isPending } = useFileSign()

  const handleDownloadFile = async () => {
    if (!taxSituation) return null
    const response = await mutateAsync({
      filePath: taxSituation || '',
    })

    if (!response?.data?.signedUrl) return

    const file = getFileNameAndExtension(taxSituation || '')

    downloadFile(taxSituation, `${file.fileName}.${file.extension}`)
  }

  const onHandleCopy = async () => {
    if (!taxSituation) return null

    const response = await mutateAsync({
      filePath: taxSituation || '',
    })

    if (!response?.data?.signedUrl) return

    try {
      const success = copyTextToClipboard(`${response?.data?.signedUrl}`)

      setIsCopied(success)

      setTimeout(() => {
        setIsCopied(false)
      }, 5000)
    } catch (err) {
      console.error(err)
    }
  }

  const onHandleClick = async () => {
    if (!taxSituation) return null

    const response = await mutateAsync({
      filePath: taxSituation || '',
    })

    if (!response?.data?.signedUrl) return

    window.open(response?.data?.signedUrl, '_blank')
  }

  return (
    <div className={styles.core}>
      <div className={styles.content}>
        <Text variant="title-medium" className={styles.title}>
          {t('profileShowCardTaxSituationTitle')}
        </Text>
        <Text className={styles.subtitle}>
          {t('profileShowCardTaxSituationDescription')}
        </Text>
      </div>

      <div className={styles['buttons-wrap-column']}>
        <Button onClick={onHandleClick} className={styles.buttons}>
          {t('profileShowCardTaxSituationViewProof')}
        </Button>

        <div className={styles['buttons-wrap-line']}>
          <Button
            variant="secondary"
            className={styles.buttons}
            onClick={handleDownloadFile}
          >
            <DownloadIcon className={styles['buttons-icon']} />
            {isPending
              ? t('profileShowCardTaxSituationDownloadingProof')
              : t('profileShowCardTaxSituationDownloadProof')}
          </Button>

          <Button
            variant="secondary"
            onClick={onHandleCopy}
            className={styles.buttons}
          >
            {isCopied ? (
              <CheckIcon className={styles['copy-check-icon']} />
            ) : (
              <CopyIcon />
            )}
            {isCopied
              ? t('profileShowCardTaxSituationCopiedLinkProof')
              : t('profileShowCardTaxSituationCopyLinkProof')}
          </Button>
        </div>
      </div>
    </div>
  )
}
