import { useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { Button, DrawerStepper } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import {
  useValidationErrors,
  onSuccessMessage,
  onErrorMessage,
} from '@/helpers'

import {
  useUpdateSupplier,
  QUERIES_ADMIN,
  useCreateSupplier,
  useSupplierDetail,
} from '@/services'

import { SupplierProps } from '@/types'

// import { schemaSupplier } from './drawer-form.constants'

import { SupplierForm } from './components'

import type { DrawerSupplierFormProps } from './drawer-form.type'

export const SupplierDrawerForm = ({
  isOpen,
  onHandleOpen,
  supplierUuid,
}: DrawerSupplierFormProps) => {
  const { t } = useTranslation('screenSupplierList')

  const isNewRegister = !supplierUuid

  const queryClient = useQueryClient()

  const [data, setData] = useState<Partial<SupplierProps>>({})

  const {
    isValid: isValidSupplier,
    errors: profileErrors,
    onHandleListenErrors: onHandleListenErrorsSupplier,
  } = useValidationErrors({}, data)

  const { data: dataSupplier } = useSupplierDetail(supplierUuid || '')

  const { mutateAsync: mutateAsyncUpdateSupplier } = useUpdateSupplier()
  const { mutateAsync: mutateAsyncCreateSupplier } = useCreateSupplier()

  const onSubmitCompleted = async () => {
    let response: {
      success: boolean
      message: string
      data?: SupplierProps
    } = {
      success: false,
      message: '',
      data: {} as SupplierProps,
    }

    if (isNewRegister) {
      response = await mutateAsyncCreateSupplier(data as SupplierProps)
    } else {
      response = await mutateAsyncUpdateSupplier(data as SupplierProps)
    }

    if (response.success) {
      if (isNewRegister) {
        onSuccessMessage(
          t('supplierFormSuccessCreateTitle'),
          t('supplierFormSuccessCreateMessage'),
        )
      } else {
        onSuccessMessage(
          t('supplierFormSuccessUpdateTitle'),
          t('supplierFormSuccessUpdateMessage'),
        )
      }

      queryClient.invalidateQueries({
        queryKey: [QUERIES_ADMIN.SUPPLIER.module],
      })

      setData({})
      onHandleOpen(false)
    } else {
      if (isNewRegister) {
        onErrorMessage(
          t('supplierFormErrorCreateTitle'),
          t('supplierFormErrorCreateMessage'),
        )
      } else {
        onErrorMessage(
          t('supplierFormErrorUpdateTitle'),
          t('supplierFormErrorUpdateMessage'),
        )
      }
    }
  }

  const onSubmitSupplierForm = () => {
    if (isValidSupplier) {
      onHandleListenErrorsSupplier(false)

      onSubmitCompleted()
    } else {
      onHandleListenErrorsSupplier(true)
    }
  }

  useEffect(() => {
    if (isOpen) {
      if (isNewRegister) {
        setData({})
      } else {
        if (dataSupplier?.data) {
          setData({
            ...dataSupplier.data,
          })
        }
      }
    }
  }, [isOpen, dataSupplier])

  return (
    <DrawerStepper
      translations={{
        optional: t('supplierFormOptional'),
      }}
      isOpen={isOpen}
      onHandleOpen={onHandleOpen}
      title={
        isNewRegister
          ? t('supplierFormTitleCreate')
          : t('supplierFormTitleUpdate')
      }
      description={
        isNewRegister
          ? t('supplierFormSubTitleCreate')
          : t('supplierFormSubTitleUpdate')
      }
      initialStep="root"
      steps={{
        root: {
          title: t('supplierFormGeneralFormTitle'),
          isOptional: false,
          backNavigate: 'root',
          component: (
            <SupplierForm
              errors={profileErrors as Record<string, string>}
              values={data || {}}
              onChangeValues={setData}
            />
          ),
          footer: () => (
            <>
              <Button variant="secondary" onClick={() => onHandleOpen(false)}>
                {t('supplierFormNavigateButtonBack')}
              </Button>

              <Button onClick={() => onSubmitSupplierForm()}>
                {t('supplierFormNavigateButtonSave')}
              </Button>
            </>
          ),
        },
      }}
    />
  )
}
