export const tokens = {
  colors: {
    primary100: '#E6E9FA',
    primary200: '#BEC8FA',
    primary300: '#96A6FA',
    primary400: '#6E85FA',
    primary500: '#3253FA',
    primary600: '#0024DB',
    primary700: '#1F3399',
    primary800: '#192B80',
    primary900: '#142266',
    primary1000: '#0F1A4D',
    primary1100: '#0A1133',
    gray100: '#FFFFFF',
    gray200: '#E1E2E6',
    gray300: '#A4AACC',
    gray400: '#8F95B2',
    gray500: '#7A7F99',
    gray600: '#666A80',
    gray700: '#525566',
    gray800: '#3D404D',
    gray900: '#292A33',
    gray1000: '#14151A',
    gray1100: '#000000',
    success100: '#F0FDF4',
    success200: '#DCFCE7',
    success300: '#BBF7D0',
    success400: '#86EFAC',
    success500: '#4ADE80',
    success600: '#22C55E',
    success700: '#16A34A',
    success800: '#15803D',
    success900: '#166534',
    success1000: '#14532D',
    success1100: '#0C331C',
    warning100: '#FEFCE8',
    warning200: '#FEF9C3',
    warning300: '#FEF08A',
    warning400: '#FDE047',
    warning500: '#FACC15',
    warning600: '#EAB308',
    warning700: '#CA8A04',
    warning800: '#A16207',
    warning900: '#854D0E',
    warning1000: '#713F12',
    warning1100: '#4D2B0C',
    danger100: '#FEF2F2',
    danger200: '#FEE2E2',
    danger300: '#FCA5A5',
    danger400: '#F87171',
    danger500: '#EF4444',
    danger600: '#DC2626',
    danger700: '#B91C1C',
    danger800: '#991B1B',
    danger900: '#7F1D1D',
    danger1000: '#4D1111',
    danger1100: '#330C0C',
    background: '#FAFAFC',
  },
  space: {
    1: '0.25rem', // 4px
    2: '0.5rem', // 8px
    3: '0.75rem', // 12px
    4: '1rem', // 16px
    5: '1.5rem', // 24px
    6: '2rem', // 32px
    7: '2.5rem', // 40px
    8: '3rem', // 48px
    9: '4rem', // 64px
  },
  fontSizes: {
    1: '0.75rem', // 12px
    2: '0.875rem', // 14px
    3: '1rem', // 16px
    4: '1.125rem', // 18px
    5: '1.25rem', // 20px
    6: '1.5rem', // 24px
    7: '2.5rem', // 40px
    8: '3rem', // 48px
  },
  fonts: {
    standard: 'Roboto, sans-serif',
  },
  fontWeights: {
    light: '300',
    normal: '400',
    medium: '600',
    bold: '700',
  },
  lineHeights: {
    small: '1.2',
    medium: '1.5',
    large: '1.8',
  },
  letterSpacings: {},
  sizes: {},
  borderWidths: {
    thin: '0.0625rem', // 1px
    medium: '0.125rem', // 2px
    thick: '0.1875rem', // 3px
  },
  radii: {
    xsmall: '0.25rem', // 4px
    small: '0.5rem', // 8px
    medium: '0.75rem', // 12px
    large: '1rem', // 16px
  },
  shadows: {},
  zIndexes: {
    1: '100',
    2: '200',
    3: '300',
    4: '400',
    5: '500',
  },
  transitions: {},
}
