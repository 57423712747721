import { useEffect, useState } from 'react'
import { ChevronLeftIcon, ProgressStep, Drawer, Text } from '@mercai/clever'
import { generateClassesOfMain } from './drawer-stepper-progress.constants'

import type {
  DrawerStepperProgressProps,
  DrawerStepperProgressFunctionOrComponentsProps,
} from './drawer-stepper-progress.type'

import styles from './drawer-stepper-progress.module.css'

export const DrawerStepperProgress = ({
  isOpen,
  onHandleOpen,
  steps,
  initialStep = 'root',
  title,
  description,
  translations,
  finalComponent,
}: DrawerStepperProgressProps) => {
  const [previousStep, setPreviousStep] = useState(initialStep)
  const [currentStep, setCurrentStep] = useState(initialStep)
  const [animate, setAnimate] = useState<'none' | 'next' | 'previous'>('none')

  useEffect(() => {
    if (animate !== 'none') {
      const timer = setTimeout(() => setAnimate('none'), 500)
      return () => clearTimeout(timer)
    }
  }, [animate])

  useEffect(() => {
    if (!isOpen) {
      setPreviousStep(initialStep)
      setCurrentStep(initialStep)
    }
  }, [isOpen])

  useEffect(() => {
    if (isOpen) {
      setPreviousStep(initialStep)
      setCurrentStep(initialStep)
    }
  }, [initialStep])

  const onNavigateToPreviousStep = () => {
    if (Object.keys(steps)?.[0] === currentStep) {
      onHandleOpen(false)
      return
    }

    const newCurrentStep = steps?.[currentStep]?.backNavigate

    setAnimate('previous')
    setPreviousStep(newCurrentStep)
    setCurrentStep(newCurrentStep)
  }

  const onNavigateToNextStep = (nextStep: string) => {
    setAnimate('next')
    setPreviousStep(currentStep)
    setCurrentStep(nextStep)
  }

  const renderFuncOrComp = (
    component: DrawerStepperProgressFunctionOrComponentsProps,
  ) => {
    if (typeof component === 'function') {
      return component({
        onNavigateToPreviousStep,
        onNavigateToNextStep,
      })
    } else {
      return component
    }
  }

  const classesOfMain = generateClassesOfMain(animate)

  return (
    <Drawer isOpen={isOpen} onHandleOpen={onHandleOpen}>
      <header className={styles.header}>
        <button
          type="button"
          onClick={onNavigateToPreviousStep}
          className={styles['header-button']}
        >
          <ChevronLeftIcon />
        </button>

        <div className={styles['header-box-content']}>
          <Text className={styles['header-title']} variant="text-large-bold">
            {title}
          </Text>
          {description && (
            <Text className={styles['header-subtitle']}>{description}</Text>
          )}
        </div>
      </header>

      {Object.keys(steps)?.length > 1 && (
        <div className={styles['progress-steps']}>
          <ProgressStep
            currentStep={Object.keys(steps)?.indexOf(currentStep) || 0}
            totalSteps={Object.keys(steps)?.length || 0}
          />
        </div>
      )}

      <main className={styles.main}>
        <div className={styles['main-box']}>
          {steps?.[previousStep]?.title && (
            <div className={styles['main-header']}>
              <Text className={styles['main-title']} variant="text-large-bold">
                {steps?.[previousStep]?.title}{' '}
                {steps?.[previousStep]?.isOptional && (
                  <span>{translations?.optional}</span>
                )}
              </Text>

              {steps?.[previousStep]?.description && (
                <Text className={styles['main-description']}>
                  {steps?.[previousStep]?.description}
                </Text>
              )}
            </div>
          )}

          <div className={`${styles['main-content']}`}>
            {renderFuncOrComp(steps?.[previousStep]?.component)}
          </div>

          <div className={styles['main-footer']}>
            {renderFuncOrComp(steps[currentStep]?.footer)}
          </div>
        </div>

        <div className={classesOfMain}>
          {steps?.[currentStep]?.title && (
            <div className={styles['main-header']}>
              <Text className={styles['main-title']} variant="text-large-bold">
                {steps?.[currentStep]?.title}{' '}
                {steps?.[currentStep]?.isOptional && (
                  <span>{translations.optional}</span>
                )}
              </Text>

              {steps?.[currentStep]?.description && (
                <Text className={styles['main-description']}>
                  {steps?.[currentStep]?.description}
                </Text>
              )}
            </div>
          )}

          <div className={`${styles['main-content']}`}>
            {renderFuncOrComp(steps?.[currentStep]?.component)}
          </div>

          <div className={styles['main-footer']}>
            {renderFuncOrComp(steps?.[currentStep]?.footer)}
          </div>
        </div>
      </main>

      {finalComponent}
    </Drawer>
  )
}
