import axios from 'axios'
import { getRefreshToken, setAuthToken } from '@/helpers'
import { history, ROUTES_AUTH } from '@/router'
import { AUTH_SIGN_IN_REFRESH_URL } from './api.urls'
import * as Sentry from '@sentry/react'

export const api = axios.create({
  timeout: 60000,
})

export const apiNoAuth = axios.create({
  timeout: 60000,
})

let isRefreshing = false
let refreshSubscribers: ((value: unknown) => void)[] = []

function subscribeToRefresh(cb: (value: unknown) => void): void {
  refreshSubscribers.push(cb)
}

function onRefreshed(result: string | Error) {
  refreshSubscribers.forEach((cb) => cb(result))
  refreshSubscribers = []
}

export const refreshToken = async () => {
  if (isRefreshing) {
    return new Promise((resolve, reject) => {
      subscribeToRefresh((result) => {
        if (result instanceof Error) {
          reject(result)
        } else {
          resolve(result)
        }
      })
    })
  }

  isRefreshing = true
  let result

  try {
    const refresh = getRefreshToken()

    if (!refresh) {
      throw new Error('No refresh token available')
    }

    const res = await apiNoAuth.post(AUTH_SIGN_IN_REFRESH_URL, { refresh })

    if (res.data.access) {
      result = res.data.access
      setAuthToken(result)
      return result
    } else {
      throw new Error('Failed to refresh access token')
    }
  } catch (error) {
    result = error
    throw error
  } finally {
    isRefreshing = false
    onRefreshed(result)
  }
}

api.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const originalRequest = error.config
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      try {
        const res = await refreshToken()

        api.defaults.headers.common.Authorization = `Bearer ${res}`
        originalRequest.headers.Authorization = `Bearer ${res}`
        setAuthToken(res)

        return api(originalRequest)
      } catch (e) {
        console.error({ e })

        if (error.response.status === 401 && originalRequest._retry) {
          history.replace(ROUTES_AUTH.SIGN_IN.fullPath())
        }

        return Promise.reject(e)
      }
    } else {
      Sentry.withScope((scope: any) => { // eslint-disable-line
        if (error?.response) {
          scope.setExtra('response', {
            data: JSON.stringify(error?.response?.data),
            status: error?.response?.status,
            headers: JSON.stringify(error?.response?.headers),
          })
        }

        scope.setExtra('config', {
          url: error?.config?.url,
          method: error?.config?.method,
          data: JSON.stringify(error?.config?.data),
          headers: JSON.stringify(error?.config?.headers),
        })
        Sentry.captureException(error)
      })

      return Promise.reject(error)
    }
  },
)
