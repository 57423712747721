import { useTranslation } from 'react-i18next'
import { Text, ProfileIcon, Skeleton } from '@mercai/clever'

import styles from './summary-profile.module.css'
import type { QuotationSummaryProfileProps } from './summary-profile.type'

const QuotationSummaryProfileDefault = ({
  name,
  companyName,
  email,
  phone,
}: QuotationSummaryProfileProps) => {
  const { t } = useTranslation('screenQuotationList')

  return (
    <div className={styles.core}>
      <div className={styles.title}>
        <ProfileIcon />
        <Text variant="title-base" className={styles.title}>
          {t('quotationShowTitleSummaryProfile')}
        </Text>
      </div>

      <div className={styles.content}>
        <Text>
          <strong>{t('quotationShowSummaryProfileName')}:</strong> {name || '-'}
        </Text>

        <Text>
          <strong>{t('quotationShowSummaryProfileCompanyName')}:</strong>{' '}
          {companyName || '-'}
        </Text>

        <Text>
          <strong>{t('quotationShowSummaryProfileEmail')}:</strong>{' '}
          {email || '-'}
        </Text>

        <Text>
          <strong>{t('quotationShowSummaryProfilePhone')}:</strong>{' '}
          {phone || '-'}
        </Text>
      </div>
    </div>
  )
}

const QuotationSummaryProfileLoading = () => {
  return (
    <div className={styles.core}>
      <div className={styles.title}>
        <Skeleton height="1.5rem" width="1.5rem" />
        <Skeleton height="1rem" width="8rem" />
      </div>

      <div className={styles.content}>
        <Skeleton height="1rem" width="90%" />
        <Skeleton height="1rem" width="80%" />
        <Skeleton height="1rem" width="100%" />
      </div>
    </div>
  )
}

export const QuotationSummaryProfile = (
  props: QuotationSummaryProfileProps,
) => {
  if (props.isLoading) {
    return <QuotationSummaryProfileLoading />
  }

  return <QuotationSummaryProfileDefault {...props} />
}
