import { Input, InputPhone, Label } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import type { ProfileFormProps } from './profile-form.type'

import styles from './profile-form.module.css'

export const ProfileForm = ({
  onChangeValues,
  values,
  errors,
}: ProfileFormProps) => {
  const { t } = useTranslation('screenQuotationList')

  const onHandleValues = (key: string, value: string | number) => {
    onChangeValues({ ...values, [key]: value })
  }

  return (
    <div className={styles.core}>
      <div>
        <Label>{t('quotationFormProfileFormEmailLabel')}</Label>
        <Input
          placeholder={t('quotationFormProfileFormEmailPlaceholder')}
          value={values?.email}
          onChange={(newValue) => onHandleValues('email', newValue)}
          error={errors?.email}
        />
      </div>

      <div>
        <Label>{t('quotationFormProfileFormBuyerNameLabel')}</Label>
        <Input
          placeholder={t('quotationFormProfileFormBuyerNamePlaceholder')}
          value={values?.name}
          onChange={(newValue) => onHandleValues('name', newValue)}
          error={errors?.name}
        />
      </div>

      <div>
        <Label>{t('quotationFormProfileFormCompanyNameLabel')}</Label>
        <Input
          placeholder={t('quotationFormProfileFormCompanyNamePlaceholder')}
          value={values?.companyName}
          onChange={(newValue) => onHandleValues('companyName', newValue)}
          error={errors?.companyName}
        />
      </div>

      <div>
        <Label>{t('quotationFormProfileFormPhoneLabel')}</Label>
        <InputPhone
          placeholder={t('quotationFormProfileFormPhonePlaceholder')}
          value={values?.phone}
          onChange={(newValue) => onHandleValues('phone', newValue)}
          error={errors?.phone}
        />
      </div>
    </div>
  )
}
