import { SVGProps } from 'react'

export const TargetIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M11 2V4.07C9.24011 4.29352 7.60439 5.09553 6.34996 6.34996C5.09553 7.60439 4.29352 9.24011 4.07 11H2V13H4.07C4.29352 14.7599 5.09553 16.3956 6.34996 17.65C7.60439 18.9045 9.24011 19.7065 11 19.93V22H13V19.93C14.7599 19.7065 16.3956 18.9045 17.65 17.65C18.9045 16.3956 19.7065 14.7599 19.93 13H22V11H19.93C19.7065 9.24011 18.9045 7.60439 17.65 6.34996C16.3956 5.09553 14.7599 4.29352 13 4.07V2M11 6.08V8H13V6.09C15.5 6.5 17.5 8.5 17.92 11H16V13H17.91C17.5 15.5 15.5 17.5 13 17.92V16H11V17.91C8.5 17.5 6.5 15.5 6.08 13H8V11H6.09C6.5 8.5 8.5 6.5 11 6.08ZM12 11C11.7348 11 11.4804 11.1054 11.2929 11.2929C11.1054 11.4804 11 11.7348 11 12C11 12.2652 11.1054 12.5196 11.2929 12.7071C11.4804 12.8946 11.7348 13 12 13C12.2652 13 12.5196 12.8946 12.7071 12.7071C12.8946 12.5196 13 12.2652 13 12C13 11.7348 12.8946 11.4804 12.7071 11.2929C12.5196 11.1054 12.2652 11 12 11Z" />
    </svg>
  )
}
