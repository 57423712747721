import { useQuery } from '@tanstack/react-query'

import { convertObjectToCamelCase } from '@/helpers'
import type { AccountLevelQuestionWithPagesProps } from '@/types'

import { QUERIES_ADMIN } from './api.constants'
import { api } from './api.service'
import { ACCOUNT_LEVEL_QUESTION_URL } from './api.urls'

export const useAccountLevelQuestionList = () => {
  const getAccountLevelQuestionList = async (): Promise<{
    success: boolean
    message: string
    data?: AccountLevelQuestionWithPagesProps
  }> => {
    try {
      const { data } = await api.get(ACCOUNT_LEVEL_QUESTION_URL)

      return {
        success: true,
        message: 'Account level question fetched successfully!',
        data: convertObjectToCamelCase(
          data,
        ) as AccountLevelQuestionWithPagesProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get account level question!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.ACCOUNT_LEVEL_QUESTION.module],
    queryFn: () => getAccountLevelQuestionList(),
  })
}
