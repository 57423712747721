import { useQuery } from '@tanstack/react-query'
import { convertObjectToCamelCase } from '@/helpers'

import { DollarQuotationProps } from '@/types'

import { QUERIES_ADMIN } from './api.constants'
import { api } from './api.service'
import { DOLLAR_QUOTATION_URL } from './api.urls'

export const useDollarQuotationLatest = () => {
  const getDollarQuotationLatest = async (): Promise<{
    success: boolean
    message: string
    data?: DollarQuotationProps
  }> => {
    try {
      const { data } = await api.get(DOLLAR_QUOTATION_URL)

      return {
        success: true,
        message: 'Dollar quotation fetched successfully!',
        data: convertObjectToCamelCase(data) as DollarQuotationProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get dollar quotation!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.DOLLAR_QUOTATION.module],
    queryFn: () => getDollarQuotationLatest(),
    refetchOnWindowFocus: false,
  })
}
