import styles from './metric.module.css'

export const selectedClassesMetrics: Record<'selected' | 'unselected', string> =
  {
    selected: `${styles['metrics-item']} ${styles.selected}`,
    unselected: `${styles['metrics-item']} ${styles.unselected}`,
  }

export const generateClassesMetric = (
  isSelected = false,
  className?: string,
) => {
  return `${selectedClassesMetrics[isSelected ? 'selected' : 'unselected']} ${className || ''}`
}
