import { Outlet } from 'react-router-dom'
import { Container } from '@mercai/clever'

import styles from './auth.module.css'

export const AuthLayout = () => {
  return (
    <div className={styles.layout}>
      <Container>
        <header className={styles.header}>
          <img
            src="/images/logo-opus.svg"
            alt="Mercai"
            width={112}
            height={32}
          />
        </header>

        <div className={styles['main-wrap']}>
          <main className={styles.main}>
            <Outlet />
          </main>
        </div>
        <footer className={styles.footer}>
          Copyright © {new Date().getFullYear()} • Mercai.
        </footer>
      </Container>
    </div>
  )
}
