import {
  generateOverlayClasses,
  generateDrawerClasses,
} from './drawer.constants'

import type { DrawerProps } from './drawer.type'

export const Drawer = ({
  children,
  isOpen,
  onHandleOpen,
  size = 'default',
}: DrawerProps) => {
  const generatedOverlayClasses = generateOverlayClasses(
    isOpen ? 'open' : 'close',
  )

  const generatedMainClasses = generateDrawerClasses(
    isOpen ? 'open' : 'close',
    size,
  )

  return (
    <section>
      <div
        className={generatedOverlayClasses}
        onClick={() => onHandleOpen(false)}
      />
      <main className={generatedMainClasses}>{isOpen ? children : ''}</main>
    </section>
  )
}
