import styles from './select-box.module.css'

export const selectBoxStates: Record<'active' | 'inactive' | 'error', string> =
  {
    error: styles['state-error'],
    active: styles['state-active'],
    inactive: '',
  }

export const generateClassesSelectBox = (
  isActive?: boolean,
  isError?: boolean,
  className?: string,
) => {
  const selectedClass = isError ? 'error' : isActive ? 'active' : 'inactive'

  return `${styles.core} ${selectBoxStates[selectedClass]} ${className || ''}`
}

export const selectBoxCircleStates: Record<'active' | 'inactive', string> = {
  active: styles['circle-state-active'],
  inactive: '',
}

export const generateClassesSelectBoxCircle = (isActive?: boolean) => {
  return `${styles.circle} ${
    selectBoxCircleStates[isActive ? 'active' : 'inactive']
  }`
}
