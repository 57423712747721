import { useMutation, useQuery } from '@tanstack/react-query'

import { DEFAULT_LIMIT_PAGE } from '@/constants'
import { convertObjectToCamelCase, convertObjectToSnakeCase } from '@/helpers'

import type {
  KaiProductProps,
  KaiProductAttributesProps,
  KaiProductWithPagesProps,
  KaiSmartSearchProps,
} from '@/types'

import { api } from './api.service'
import { QUERIES_ADMIN } from './api.constants'
import {
  KAI_PRODUCT_URL,
  KAI_PRODUCTS_URL,
  KAI_PRODUCTS_BY_TEXT_URL,
  KAI_PRODUCTS_BY_REFERENCE_URL,
  KAI_SMART_SEARCHS_URL,
  KAI_SMART_SEARCH_URL,
} from './api.urls'

export const useCreateKaiProcessorByReference = () => {
  const createKaiProcessor = async (payload: {
    reference: string
  }): Promise<{
    success: boolean
    message: string
    data?: KaiProductAttributesProps
  }> => {
    try {
      const { data } = await api.post(
        KAI_PRODUCTS_BY_REFERENCE_URL,
        convertObjectToSnakeCase(payload),
      )

      return {
        success: true,
        message: 'Processor created successfully!',
        data: convertObjectToCamelCase(data) as KaiProductAttributesProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to create processor!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.KAI_PROCESSOR.module, 'reference'],
    mutationFn: (payload: { reference: string }) => createKaiProcessor(payload),
  })
}

export const useCreateKaiProcessorByText = () => {
  const createKaiProcessor = async (payload: {
    productData: string
  }): Promise<{
    success: boolean
    message: string
    data?: KaiProductAttributesProps
  }> => {
    try {
      const { data } = await api.post(
        KAI_PRODUCTS_BY_TEXT_URL,
        convertObjectToSnakeCase(payload),
      )

      return {
        success: true,
        message: 'Processor created successfully!',
        data: convertObjectToCamelCase(data) as KaiProductAttributesProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to create processor!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.KAI_PROCESSOR.module, 'text'],
    mutationFn: (payload: { productData: string }) =>
      createKaiProcessor(payload),
  })
}

export const useKaiProcessorList = ({
  params = {},
}: {
  params: Record<string, string | number>
}) => {
  const getKaiProcessorList = async (): Promise<{
    success: boolean
    message: string
    data?: KaiProductWithPagesProps
  }> => {
    try {
      const copyParams = { ...params }

      const offset = copyParams?.page
        ? (+(copyParams?.page || 0) - 1) * DEFAULT_LIMIT_PAGE
        : 0

      delete copyParams.page

      const formattedParams = Object.keys(copyParams).reduce((acc, key) => {
        if (copyParams?.[key]) {
          if (key === 'category' && copyParams?.[key] === ['ALL'].join(',')) {
            return acc
          }

          return {
            ...acc,
            [key]: copyParams?.[key],
          }
        }

        return acc
      }, {})

      const { data } = await api.get(KAI_PRODUCTS_URL, {
        params: { ...formattedParams, offset },
      })

      return {
        success: true,
        message: 'Processor fetched successfully!',
        data: convertObjectToCamelCase(data) as KaiProductWithPagesProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get processor!',
        data: undefined,
      }
    }
  }

  const useQueryReturn = useQuery({
    queryKey: [QUERIES_ADMIN.KAI_PROCESSOR.module, params],
    queryFn: () => getKaiProcessorList(),
  })

  return useQueryReturn
}

export const useKaiProcessorDetail = (uuid?: string) => {
  const getKaiProcessorDetail = async (): Promise<{
    success: boolean
    message: string
    data?: KaiProductProps
  }> => {
    try {
      const { data } = await api.get(KAI_PRODUCT_URL(uuid || ''))

      return {
        success: true,
        message: 'Processor fetched successfully!',
        data: convertObjectToCamelCase(data) as KaiProductProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get processor!',
        data: undefined,
      }
    }
  }

  const useQueryReturn = useQuery({
    queryKey: [QUERIES_ADMIN.KAI_PROCESSOR.module, uuid],
    queryFn: () => getKaiProcessorDetail(),
    refetchOnWindowFocus: false,
    enabled: !!uuid,
  })

  return useQueryReturn
}

export const useUpdateKaiProcessor = (uuid?: string) => {
  const updateKaiProcessor = async (payload: {
    status: string
    reference?: string
    catalogProductUuid?: string
  }): Promise<{
    success: boolean
    message: string
    data?: KaiProductAttributesProps
  }> => {
    try {
      const { data } = await api.patch(
        KAI_PRODUCT_URL(uuid || ''),
        convertObjectToSnakeCase(payload),
      )

      return {
        success: true,
        message: 'Processor updated successfully!',
        data: convertObjectToCamelCase(data) as KaiProductAttributesProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to update processor!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.KAI_PROCESSOR.module, uuid],
    mutationFn: (payload: {
      status: string
      reference?: string
      catalogProductUuid?: string
    }) => updateKaiProcessor(payload),
  })
}

export const useCreateKaiSmartSearch = () => {
  const createKaiSmartSearch = async (payload: {
    rawInformation?: string
    file: File[]
  }): Promise<{
    success: boolean
    message: string
    data?: KaiSmartSearchProps
  }> => {
    try {
      const formData = new FormData()
      if (payload.rawInformation) {
        formData.append('raw_information', payload.rawInformation)
      }
      if (payload.file?.[0]) {
        formData.append('file', payload.file[0])
      }
      formData.append('origin', 'backoffice')

      const { data } = await api.post(KAI_SMART_SEARCHS_URL, formData)

      return {
        success: true,
        message: 'Smart search created successfully!',
        data: convertObjectToCamelCase(data) as KaiSmartSearchProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to create smart search!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.SEARCH_ENGINE.module],
    mutationFn: (payload: { rawInformation?: string; file: File[] }) =>
      createKaiSmartSearch(payload),
  })
}

export const useKaiSmartSearchDetail = (uuid?: string | null) => {
  const getKaiSmartSearchDetail = async (): Promise<{
    success: boolean
    message: string
    data?: KaiSmartSearchProps
  }> => {
    try {
      const { data } = await api.get(KAI_SMART_SEARCH_URL(uuid || ''))

      return {
        success: true,
        message: 'Smart search fetched successfully!',
        data: convertObjectToCamelCase(data) as KaiSmartSearchProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get smart search data!',
        data: undefined,
      }
    }
  }

  const useQueryReturn = useQuery({
    queryKey: [QUERIES_ADMIN.SEARCH_ENGINE.module, uuid],
    queryFn: () => getKaiSmartSearchDetail(),
    refetchOnWindowFocus: false,
    enabled: !!uuid,
    refetchInterval: uuid ? 2000 : undefined,
  })

  return useQueryReturn
}
