'use client'
import { useState } from 'react'
import { Text } from '../text'
import { EyeIcon, EyeSlashIcon } from '../../../icons'

import { InputProps, InputSizeEnum } from './input.type'

import { generateClasses } from './input.constants'

import styles from './input.module.css'

export const Input = ({
  className,
  disabled,
  onChange,
  size = InputSizeEnum.medium,
  value = '',
  iconLeft,
  iconRight,
  placeholder,
  id,
  type = 'text',
  error,
  isTextArea = false,
  onBlur,
  onClick,
  onKeyDown,
  onKeyDownTextArea,
  ref,
}: InputProps) => {
  const classes = generateClasses(size, !!error)

  const [showPassword, setShowPassword] = useState(false)

  const onHandleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <div className={className}>
      <div className={classes}>
        {!iconLeft || <div className={styles['left-icon']}>{iconLeft} </div>}
        {isTextArea ? (
          <textarea
            disabled={disabled}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              !disabled && onChange && onChange(e.target.value)
            }
            value={value}
            placeholder={placeholder}
            id={id}
            onBlur={onBlur}
            onClick={onClick}
            onKeyDown={onKeyDownTextArea}
          />
        ) : (
          <input
            disabled={disabled}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              !disabled && onChange && onChange(e.target.value)
            }
            value={value}
            placeholder={placeholder}
            id={id}
            type={showPassword && type === 'password' ? 'text' : type}
            onBlur={onBlur}
            onClick={onClick}
            onKeyDown={onKeyDown}
            ref={ref}
          />
        )}
        {type === 'password' && (
          <button
            type="button"
            className={styles['right-icon']}
            onClick={onHandleShowPassword}
          >
            {showPassword ? <EyeSlashIcon /> : <EyeIcon />}
          </button>
        )}
        {!iconRight || <div className={styles['right-icon']}>{iconRight}</div>}
      </div>
      {!error || <Text className={styles.error}>{error}</Text>}
    </div>
  )
}
