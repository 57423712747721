import { useEffect, useState } from 'react'
import { Drawer } from '@mercai/clever'

import { useFinanceProfileDetail } from '@/services'

import {
  FinanceProfileHeader,
  ProfileCreditForm,
  ProfileCreditShow,
} from './components'

import type { ProfileCreditProps } from './profile-credit.type'

export const ProfileCredit = ({
  isOpen,
  onHandleOpen,
  profileUuid,
  buyerProfileEmail,
}: ProfileCreditProps) => {
  const [isEdit, setIsEdit] = useState(false)

  const { data: financeProfileData, refetch } =
    useFinanceProfileDetail(profileUuid)

  const onSuccessUpdated = () => {
    refetch()
  }

  const getPendingForm = () => {
    if (isEdit) {
      return true
    }

    const documentsObject = {
      individual: {
        governmentIdFile: financeProfileData?.data?.governmentIdFile,
        taxSituation: financeProfileData?.data?.taxSituation,
        addressProofFile: financeProfileData?.data?.addressProofFile,
      },
      legal: {
        governmentIdFile: financeProfileData?.data?.governmentIdFile,
        taxSituation: financeProfileData?.data?.taxSituation,
        addressProofFile: financeProfileData?.data?.addressProofFile,
        constitutiveActFile: financeProfileData?.data?.constitutiveActFile,
      },
    }

    if (!financeProfileData?.data?.personType) {
      return false
    }

    const documents = documentsObject[financeProfileData?.data?.personType]

    if (Object.values(documents).every(Boolean)) {
      return true
    }

    return false
  }

  const isPendingForm = getPendingForm()

  useEffect(() => {
    if (!isOpen) {
      setIsEdit(false)
    }
  }, [isOpen])

  return (
    <Drawer isOpen={isOpen} onHandleOpen={() => onHandleOpen(false)}>
      <FinanceProfileHeader onHandleOpen={onHandleOpen} />

      {isPendingForm ? (
        <ProfileCreditShow
          onHandleOpen={onHandleOpen}
          financeProfileData={financeProfileData?.data}
          buyerProfileEmail={buyerProfileEmail}
          onHandleEdit={() => setIsEdit(!isEdit)}
        />
      ) : (
        <ProfileCreditForm
          onHandleOpen={onHandleOpen}
          profileUuid={profileUuid}
          financeProfileData={financeProfileData?.data}
          onSuccessUpdated={onSuccessUpdated}
          onHandleShow={() => setIsEdit(!isEdit)}
        />
      )}
    </Drawer>
  )
}
