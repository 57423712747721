import {
  Input,
  DatePicker,
  InputPhone,
  Label,
  Select,
  Radio,
} from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { ProfileCustomerStatusEnum, ProfileTypeEnum } from '@/types'

import type { ProfileFormProps } from './profile-form.type'

import styles from './profile-form.module.css'

export const ProfileForm = ({
  onChangeValues,
  values,
  errors,
  usersData,
}: ProfileFormProps) => {
  const { t } = useTranslation('screenProfileList')
  const { t: tEnums } = useTranslation('enums')

  const onHandleValues = (key: string, value: string | number | boolean) => {
    onChangeValues({ ...values, [key]: value })
  }

  return (
    <div className={styles.core}>
      <div>
        <Label>{t('profileFormEmailLabel')}</Label>
        <Input
          placeholder={t('profileFormEmailPlaceholder')}
          value={values?.email}
          onChange={(newValue) => onHandleValues('email', newValue)}
          error={errors?.email}
        />
      </div>

      <div>
        <Label>{t('profileFormBuyerNameLabel')}</Label>
        <Input
          placeholder={t('profileFormBuyerNamePlaceholder')}
          value={values?.name}
          onChange={(newValue) => onHandleValues('name', newValue)}
          error={errors?.name}
        />
      </div>

      <div>
        <Label>{t('profileFormCompanyNameLabel')}</Label>
        <Input
          placeholder={t('profileFormCompanyNamePlaceholder')}
          value={values?.companyName}
          onChange={(newValue) => onHandleValues('companyName', newValue)}
          error={errors?.companyName}
        />
      </div>

      <div>
        <Label>{t('profileFormPhoneLabel')}</Label>
        <InputPhone
          placeholder={t('profileFormPhonePlaceholder')}
          value={values?.phone}
          onChange={(newValue) => onHandleValues('phone', newValue)}
          error={errors?.phone}
        />
      </div>

      <div>
        <Label>
          {t('profileFormMoscowLabel')} <span>{t('optionalLabel')}</span>
        </Label>
        <Select
          value={values?.moscowClassification}
          onChange={(newValue) =>
            onHandleValues('moscowClassification', newValue)
          }
          placeholder={t('profileFormMoscowPlaceholder')}
          error={errors?.moscowClassification}
          options={[
            {
              label: tEnums('profile_moscow_must'),
              value: 'must',
            },
            {
              label: tEnums('profile_moscow_should'),
              value: 'should',
            },
            {
              label: tEnums('profile_moscow_could'),
              value: 'could',
            },
            {
              label: tEnums('profile_moscow_wont'),
              value: 'wont',
            },
          ]}
        />
      </div>

      <div>
        <Label>
          {t('profileFormKeyAccountManagerLabel')}{' '}
          <span>{t('optionalLabel')}</span>
        </Label>
        <Select
          value={values?.keyAccountManagerId}
          onChange={(newValue) =>
            onHandleValues('keyAccountManagerId', newValue)
          }
          placeholder={t('profileFormKeyAccountManagerPlaceholder')}
          error={errors?.keyAccountManagerId}
          options={
            usersData?.map((user) => ({
              label: `${user.firstName} ${user.lastName}`,
              value: `${user.id}`,
            })) || []
          }
        />
      </div>

      <div>
        <Label>
          {t('profileFormCustomerLevelLabel')} <span>{t('optionalLabel')}</span>
        </Label>
        <Select
          value={values?.customerLevelSlug as string}
          onChange={(newValue) => onHandleValues('customerLevelSlug', newValue)}
          placeholder={t('profileFormCustomerLevelPlaceholder')}
          error={errors?.customerLevelSlug}
          options={[
            {
              label: tEnums('profile_customer_uql'),
              value: 'UQL',
            },
            {
              label: tEnums('profile_customer_mql'),
              value: 'MQL',
            },
            {
              label: tEnums('profile_customer_sql'),
              value: 'SQL',
            },
            {
              label: tEnums('profile_customer_customer'),
              value: 'customer',
            },
            {
              label: tEnums('profile_customer_disqualified'),
              value: 'disqualified',
            },
          ]}
        />
      </div>

      <div>
        <Label>
          {t('profileFormCustomerStatusLabel')}{' '}
          <span>{t('optionalLabel')}</span>
        </Label>
        <Select
          value={values?.customerStatus}
          onChange={(newValue) => onHandleValues('customerStatus', newValue)}
          placeholder={t('profileFormCustomerStatusPlaceholder')}
          error={errors?.customerStatus}
          options={Object.values(ProfileCustomerStatusEnum).map((status) => ({
            label: tEnums(`profile_customer_status_${status}`),
            value: status,
          }))}
        />
      </div>

      <div>
        <Label>
          {t('profileFormTypeLabel')} <span>{t('optionalLabel')}</span>
        </Label>
        <Select
          value={values?.type}
          onChange={(newValue) => onHandleValues('type', newValue)}
          placeholder={t('profileFormTypePlaceholder')}
          error={errors?.type}
          options={Object.values(ProfileTypeEnum).map((type) => ({
            label: tEnums(`profile_type_${type}`),
            value: type,
          }))}
        />
      </div>

      {['churn', 'passive_churn'].includes(values?.customerStatus || '') && (
        <div>
          <Label>{t('profileFormChurnAtLabel')}</Label>

          <DatePicker
            value={values?.churnedAt}
            onChange={(newValue) => onHandleValues('churnedAt', newValue || '')}
            placeholder={t('profileFormChurnAtPlaceholder')}
            error={errors?.churnedAt}
          />
        </div>
      )}

      <div>
        <Label>{t('profileFormisFraudlentLabel')}</Label>

        <Radio
          value={!!values?.isFraudlent}
          onChange={() => onHandleValues('isFraudlent', true)}
        >
          {t('profileFormisFraudlentOptionsYes')}
        </Radio>

        <Radio
          value={!values?.isFraudlent}
          onChange={() => onHandleValues('isFraudlent', false)}
        >
          {t('profileFormisFraudlentOptionsNo')}
        </Radio>
      </div>
    </div>
  )
}
