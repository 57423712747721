import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Tag, MonetizationIcon } from '@mercai/clever'

import { CardDownload, InfoList } from '@/components'
import { formatNumber } from '@/helpers'

import styles from './order-finance.module.css'

import { OrderInvoiceStatusEnum, OrderPaymentStatusEnum } from '@/types'

import type { OrderFinanceProps } from './order-finance.type'

export const OrderFinance = ({
  isLoading,
  invoiceStatus,
  paymentMethod,
  paymentStatus,
  paymentForm,
  useCfdi,
  financingCost,
  invoicePdf,
  paymentProof,
}: OrderFinanceProps) => {
  const { t } = useTranslation('componentOrderCenterShow')
  const { t: tEnums } = useTranslation('enums')

  const statusInvoiceObject: Record<OrderInvoiceStatusEnum, ReactNode> = {
    pending: (
      <Tag model="light" variant="warning">
        {tEnums('order_invoice_status_pending')}
      </Tag>
    ),
    uploaded: (
      <Tag model="light" variant="success">
        {tEnums('order_invoice_status_uploaded')}
      </Tag>
    ),
    cancelled: (
      <Tag model="light" variant="danger">
        {tEnums('order_invoice_status_cancelled')}
      </Tag>
    ),
  }

  const statusPaymentObject: Record<OrderPaymentStatusEnum, ReactNode> = {
    pending: (
      <Tag model="light" variant="warning">
        {tEnums('order_payment_status_pending')}
      </Tag>
    ),
    paid: (
      <Tag model="light" variant="success">
        {tEnums('order_payment_status_paid')}
      </Tag>
    ),
    cancelled: (
      <Tag model="light" variant="danger">
        {tEnums('order_payment_status_cancelled')}
      </Tag>
    ),
    waiting_approval: (
      <Tag model="light" variant="warning">
        {tEnums('order_payment_status_waiting_approval')}
      </Tag>
    ),
    invalid: (
      <Tag model="light" variant="danger">
        {tEnums('order_payment_status_invalid')}
      </Tag>
    ),
  }

  return (
    <div className={styles.core}>
      <InfoList
        isLoading={isLoading}
        iconTitle={<MonetizationIcon />}
        title={t('orderFinanceTitle')}
        list={[
          {
            label: t('orderFinanceInvoiceStatus'),
            value: statusInvoiceObject[invoiceStatus || 'pending'],
          },
          {
            label: t('orderFinancePaymentStatus'),
            value: statusPaymentObject[paymentStatus || 'pending'],
          },
          {
            label: t('orderFinancePaymentMethod'),
            value: paymentMethod
              ? tEnums(`profile_payment_form_${paymentMethod}`)
              : '-',
          },
          {
            label: t('orderFinancePaymentType'),
            value: paymentForm
              ? tEnums(`profile_payment_method_${paymentForm}`)
              : '-',
          },
          {
            label: t('orderFinanceUseCFDI'),
            value: useCfdi ? tEnums(`profile_cfdi_${useCfdi}`) : '-',
          },
          {
            label: t('orderFinanceFinancingCost'),
            value:
              financingCost && +financingCost > 0
                ? `${formatNumber(+financingCost * 100, 'es-MX')}%`
                : '-',
          },
        ]}
      />

      {invoicePdf && (
        <CardDownload
          url={invoicePdf}
          translations={{
            title: t('orderFinanceInvoiceTitle'),
            viewButton: t('orderFinanceInvoiceViewButton'),
            copyButton: t('orderFinanceDownloadCopyButton'),
            copyButtonSuccess: t('orderFinanceDownloadCopyButtonSuccess'),
            downloadButton: t('orderFinanceDownloadDownloadButton'),
            loading: t('orderFinanceDownloadLoading'),
          }}
        />
      )}

      {paymentProof && (
        <CardDownload
          url={paymentProof}
          translations={{
            title: t('orderFinancePaymentProofTitle'),
            viewButton: t('orderFinancePaymentProofViewButton'),
            copyButton: t('orderFinanceDownloadCopyButton'),
            copyButtonSuccess: t('orderFinanceDownloadCopyButtonSuccess'),
            downloadButton: t('orderFinanceDownloadDownloadButton'),
            loading: t('orderFinanceDownloadLoading'),
          }}
        />
      )}
    </div>
  )
}
