import { ReactNode, KeyboardEvent } from 'react'

export enum InputMaskSizeEnum {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export interface InputMaskProps {
  id?: string
  className?: string
  disabled?: boolean
  size?: keyof typeof InputMaskSizeEnum
  onChange?: (value: string) => void
  value?: string
  mask: string
  alwaysShowMask?: boolean

  placeholder?: string
  iconLeft?: ReactNode
  iconRight?: ReactNode
  error?: string
  onBlur?: () => void
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void
}
