import styles from './select-order-item.module.css'

export const activesObject: Record<string, string> = {
  isActive: styles['is-active'],
  isInactive: styles['is-inactive'],
}

export const generateClasses = (isActive = false, className?: string) => {
  return `${styles.core} ${
    activesObject[isActive ? 'isActive' : 'isInactive']
  } ${className || ''}`
}
