import {
  Button,
  Card,
  Text,
  EditIcon,
  CheckList,
  Skeleton,
} from '@mercai/clever'
import { useTranslation } from 'react-i18next'
import { useAuth } from '@/providers'

import { formatDate, isEnabledCreditOrderTracker } from '@/helpers'
import { useFinanceProfileDetail } from '@/services'

import { TagMoscow, TagAccountLevel } from '../../../components'

import type { ProfileShowSidebarProps } from './sidebar.type'

import styles from './sidebar.module.css'

const ProfileShowSidebarDefault = ({
  categories,
  profile,
  onSelectCategory,
  onClickEdit,
  onSelectBenefit,
}: ProfileShowSidebarProps) => {
  const { t } = useTranslation('screenProfileShow')
  const { t: tEnums } = useTranslation('enums')

  const { whoAmI } = useAuth()

  const { data: financeProfileData } = useFinanceProfileDetail(
    profile?.uuid || '',
  )

  return (
    <Card className={styles.core}>
      <div className={styles.header}>
        <Text variant="title-large">{t('sidebarTitle')}</Text>

        <Button variant="secondary" model="square" onClick={onClickEdit}>
          <EditIcon />
        </Button>
      </div>

      <div className={styles['info-box']}>
        <div className={styles['info-box-row']}>
          <Text variant="title-base" className={styles['info-box-row-title']}>
            {t('sidebarInfoCustomerLevel')}:
          </Text>
          <TagAccountLevel
            accountLevelSlug={profile?.customerLevel?.slug || ''}
          />
        </div>

        <div className={styles['info-box-row']}>
          <Text variant="title-base" className={styles['info-box-row-title']}>
            {t('sidebarInfoCreatedAt')}:
          </Text>
          <Text>
            {profile?.createdAt ? formatDate(profile?.createdAt) : '-'}
          </Text>
        </div>

        <div className={styles['info-box-row']}>
          <Text variant="title-base" className={styles['info-box-row-title']}>
            {t('sidebarInfoName')}:
          </Text>
          <Text>{profile?.name || ' - '}</Text>
        </div>

        <div className={styles['info-box-row']}>
          <Text variant="title-base" className={styles['info-box-row-title']}>
            {t('sidebarInfoCompany')}:
          </Text>
          <Text>{profile?.companyName || '-'}</Text>
        </div>

        <div className={styles['info-box-row']}>
          <Text variant="title-base" className={styles['info-box-row-title']}>
            {t('sidebarInfoEmail')}:
          </Text>
          <Text>{profile?.email || '-'}</Text>
        </div>

        <div className={styles['info-box-row']}>
          <Text variant="title-base" className={styles['info-box-row-title']}>
            {t('sidebarInfoPhone')}:
          </Text>
          <Text>{profile?.phone || '-'}</Text>
        </div>

        <div className={styles['info-box-row']}>
          <Text variant="title-base" className={styles['info-box-row-title']}>
            {t('sidebarType')}:
          </Text>
          <Text>
            {profile?.type ? tEnums(`profile_type_${profile?.type}`) : '-'}
          </Text>
        </div>

        <div className={styles['info-box-row']}>
          <Text variant="title-base" className={styles['info-box-row-title']}>
            {t('sidebarInfoMoscow')}:
          </Text>
          <TagMoscow moscowClassification={profile?.moscowClassification} />
        </div>

        <div className={styles['info-box-row']}>
          <Text variant="title-base" className={styles['info-box-row-title']}>
            {t('sidebarInfoSpei')}:
          </Text>
          <Text>{financeProfileData?.data?.speiGatewayAccountId || '-'}</Text>
        </div>
      </div>

      {isEnabledCreditOrderTracker(whoAmI?.email || '') && (
        <div className={styles['info-box']}>
          <Text variant="title-large">{t('sidebarBenefitsTitle')}</Text>

          <CheckList
            itens={[{ label: t('sidebarBenefitsCredit'), value: 'credit' }]}
            onClick={onSelectBenefit}
          />
        </div>
      )}

      <div className={styles['attributes-box']}>
        <Text variant="title-large">{t('sidebarAttributesTitle')}</Text>

        <CheckList itens={categories} onClick={onSelectCategory} />
      </div>
    </Card>
  )
}

const ProfileSidebarLoading = () => {
  return (
    <Card className={styles.core}>
      <div className={styles.header}>
        <Skeleton height="1.8rem" width="6rem" />

        <Skeleton height="2.2rem" width="2.2rem" />
      </div>

      <div className={styles['info-box']}>
        <div className={styles['info-box-row']}>
          <Skeleton height="1rem" width="6rem" />
          <Skeleton height="1rem" width="8rem" />
        </div>

        <div className={styles['info-box-row']}>
          <Skeleton height="1rem" width="7rem" />
          <Skeleton height="1rem" width="9rem" />
        </div>

        <div className={styles['info-box-row']}>
          <Skeleton height="1rem" width="4rem" />
          <Skeleton height="1rem" width="9rem" />
        </div>

        <div className={styles['info-box-row']}>
          <Skeleton height="1rem" width="5rem" />
          <Skeleton height="1rem" width="6rem" />
        </div>

        <div className={styles['info-box-row']}>
          <Skeleton height="1rem" width="6rem" />
          <Skeleton height="1rem" width="6rem" />
        </div>

        <div className={styles['info-box-row']}>
          <Skeleton height="1rem" width="7rem" />
          <Skeleton height="1rem" width="5rem" />
        </div>

        <div className={styles['info-box-row']}>
          <Skeleton height="1rem" width="6rem" />
          <Skeleton height="1rem" width="6rem" />
        </div>
      </div>

      <div className={styles['info-box']}>
        <Skeleton height="1.8rem" width="6rem" />

        <div className={styles['attributes-box-loading']}>
          <Skeleton height="2rem" width="100%" />
        </div>
      </div>

      <div className={styles['attributes-box']}>
        <Skeleton height="1.8rem" width="6rem" />

        <div className={styles['attributes-box-loading']}>
          <Skeleton height="2rem" width="100%" />
          <Skeleton height="2rem" width="100%" />
          <Skeleton height="2rem" width="100%" />
          <Skeleton height="2rem" width="100%" />
          <Skeleton height="2rem" width="100%" />
        </div>
      </div>
    </Card>
  )
}

export const ProfileShowSidebar = (props: ProfileShowSidebarProps) => {
  if (props.isLoading) {
    return <ProfileSidebarLoading />
  }

  return <ProfileShowSidebarDefault {...props} />
}
