import { QuotationProductProps } from '@/types'

export const calculateQuotationValues = (
  products: QuotationProductProps[],
  totalFreight: number,
  discount: number,
  includeIva = true,
) => {
  const subTotal =
    products?.reduce((acc, product) => {
      if (product.selected) {
        const productPrice = product?.price ? +product?.price : 0
        const productQuantity = product?.quantity ? +product?.quantity : 0

        return acc + productPrice * productQuantity
      }

      return acc
    }, 0) || 0

  let iva = 0

  if (includeIva) {
    iva = (subTotal - discount) * 0.16 // add discount after
  }

  let total = subTotal - discount + iva + totalFreight
  total = total < 0 ? 0 : total

  return { discount, subTotal, iva, total }
}
