import styles from './checkbox.module.css'

import { CheckBoxSizeEnum } from './checkbox.type'

export const sizes: Record<keyof typeof CheckBoxSizeEnum, string> = {
  medium: `${styles['size-medium']}`,
  large: `${styles['size-large']}`,
}

export const generateClassesContainer = (
  size: keyof typeof CheckBoxSizeEnum,
  className?: string,
) => {
  return `${styles.container} ${sizes[size]} ${className ?? ''}`
}

export const checkedStates: Record<'checked' | 'unchecked', string> = {
  checked: styles.checked,
  unchecked: styles.unchecked,
}

export const generateClassesIcon = (
  checked?: boolean,
  hasChildren?: boolean,
) => {
  return `${checkedStates[checked ? 'checked' : 'unchecked']} ${
    hasChildren ? styles['has-children'] : ''
  }`
}
