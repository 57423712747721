import type { CardProps } from './card.type'

import { generateClasses } from './card.constants'

const Card = ({ children, className, variant = 'base' }: CardProps) => {
  const generatedClasses = generateClasses(variant, className)

  return <section className={generatedClasses}>{children}</section>
}

export default Card
