import styles from './drop-down-search.module.css'

export const statesDropDown: Record<'open' | 'close', string> = {
  open: styles['is-open'],
  close: styles['is-close'],
}

export const generateClassesOfDropDown = (isOpen: boolean) => {
  return `${styles.core} ${statesDropDown[isOpen ? 'open' : 'close']}`
}
