import { useTranslation } from 'react-i18next'

import { CalendarIcon } from '@mercai/clever'

import { formatDate, textToSnakeCase } from '@/helpers'

import { InfoList, InfoListListProps } from '@/components'

import type { OrderProfileStatusHistoryProps } from './order-profile-status-history.type'

export const OrderProfileStatusHistory = ({
  taxStatusHistory,
  isLoading,
}: OrderProfileStatusHistoryProps) => {
  const { t } = useTranslation('componentOrderCenterShow')
  const { t: tEnums } = useTranslation('enums')

  const mountStatus = () => {
    const statusList: InfoListListProps[] = []

    if (taxStatusHistory) {
      Object.keys(taxStatusHistory)?.forEach((status) => {
        statusList.push({
          label: tEnums(`profile_tax_status_${textToSnakeCase(status)}`),
          value: formatDate(taxStatusHistory[status]),
        })
      })
    }

    return statusList
  }

  const list = mountStatus()

  return (
    <div>
      <InfoList
        isLoading={isLoading}
        iconTitle={<CalendarIcon />}
        title={t('orderProfileStatusHistoryTitle')}
        list={list}
      />
    </div>
  )
}
