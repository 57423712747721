import styles from './carousel-products.module.css'

const ellipsesTable: Record<'active' | 'inactive', string> = {
  active: styles.active,
  inactive: styles.inactive,
}

export const generateClassesOfEllipsesTable = (isActive: boolean = false) => {
  return `${styles.indicator} ${ellipsesTable[isActive ? 'active' : 'inactive']}`
}

const productItem: Record<'active' | 'prev' | 'next' | 'inactive', string> = {
  active: styles.active,
  prev: styles.prev,
  next: styles.next,
  inactive: '',
}

export const generateClassesOfProductItem = (
  state: 'active' | 'prev' | 'next' | 'inactive' = 'inactive',
) => {
  return `${styles['product-item']} ${productItem[state]}`
}
