import { Text, ChevronRightIcon, Skeleton } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { formatDate } from '@/helpers'

import styles from './header.module.css'

import type { OrderHeaderProps } from './header.type'

const OrderHeaderDefault = ({
  reference,
  createdAt,
  updatedAt,
  onHandleOpen,
}: OrderHeaderProps) => {
  const { t } = useTranslation('componentOrderCenterShow')

  return (
    <div className={styles.core}>
      <button
        className={styles.button}
        type="button"
        onClick={() => onHandleOpen(false)}
      >
        <ChevronRightIcon />
      </button>

      <div className={styles.content}>
        <Text variant="text-large-bold" className={styles.title}>
          {t('headerTitle', {
            reference,
          })}
        </Text>

        <Text className={styles.subtitle}>
          {`${t('headerCreatedAt')} ${formatDate(createdAt || '')} •`}
          {` ${t('headerUpdatedAt')} ${formatDate(updatedAt || '')}`}
        </Text>
      </div>
    </div>
  )
}

const OrderHeaderLoading = () => {
  return (
    <div className={styles.core}>
      <Skeleton height="2rem" width="2rem" className={styles.button} />

      <div className={styles.content}>
        <Skeleton height="1.7rem" width="22rem" />
        <Skeleton height="1rem" width="20rem" />
      </div>
    </div>
  )
}

export const OrderHeader = (props: OrderHeaderProps) => {
  if (props.isLoading) {
    return <OrderHeaderLoading />
  }

  return <OrderHeaderDefault {...props} />
}
