import { Text } from '../text'

import {
  generateClassesSelectBox,
  generateClassesSelectBoxCircle,
} from './select-box.constants'

import styles from './select-box.module.css'

import type { SelectBoxProps } from './select-box.type'

const SelectBox = ({
  title,
  children,
  className,
  onChange,
  value,
  hasError = false,
}: SelectBoxProps) => {
  const onHandleClick = () => {
    onChange(!value)
  }

  const generatedClasses = generateClassesSelectBox(value, hasError, className)
  const generatedClassesCircle = generateClassesSelectBoxCircle(value)

  return (
    <button className={generatedClasses} type="button" onClick={onHandleClick}>
      <span className={generatedClassesCircle}></span>

      <Text variant="title-base" className={styles.title}>
        {title}
      </Text>
      {children}
    </button>
  )
}

export default SelectBox
