export const copyTextToClipboard = (text: string) => {
  const textArea = document.createElement('textarea')

  textArea.value = text

  textArea.style.position = 'fixed'
  textArea.style.top = '-9999px'
  textArea.style.left = '-9999px'

  document.body.appendChild(textArea)

  textArea.select()

  let success = false
  try {
    success = document.execCommand('copy')
  } catch (err) {
    console.error('Erro ao copiar texto', err)
  }
  document.body.removeChild(textArea)

  return success
}

export const deepCopy = <T>(obj: T): T => {
  return JSON.parse(JSON.stringify(obj))
}
