import { Skeleton, Text } from '../../../../core'
import { CheckIcon, SearchIcon } from '../../../../../icons'

import { generateClassesOfDropDown } from './drop-down-search.constants'

import styles from './drop-down-search.module.css'

import type { DropDownSearchProps } from './drop-down-search.type'

export const DropDownSearch = <T,>({
  data,
  isOpen,
  isLoading,
  selectedItem,
  onSelectItem,
  translations,
  keyLabel,
  keyValue,
  renderContentItem,
}: DropDownSearchProps<T>) => {
  const classes = generateClassesOfDropDown(isOpen)

  const ItemLoading = () => (
    <li className={styles.item}>
      <Skeleton width="1.5rem" height="1.5rem" />

      <div className={styles.content}>
        <Skeleton width="100%" height="1.25rem" />
      </div>
    </li>
  )

  const Item = (itemProps: T) => (
    <li className={styles['list-style']}>
      <button className={styles.item} onClick={() => onSelectItem(itemProps)}>
        <SearchIcon className={styles.icon} />

        <div className={styles.content}>
          {renderContentItem ? (
            renderContentItem(itemProps)
          ) : (
            <Text className={styles.title}>{`${itemProps[keyLabel]}`}</Text>
          )}
        </div>

        {selectedItem?.[keyValue] === itemProps[keyValue] && (
          <CheckIcon className={styles.icon} />
        )}
      </button>
    </li>
  )

  if (isLoading) {
    return (
      <div className={classes}>
        <ul className={styles.list}>
          <ItemLoading />
          <ItemLoading />
          <ItemLoading />
        </ul>
      </div>
    )
  }

  if (data && data?.length > 0) {
    return (
      <div className={classes}>
        {data?.map((item) => <Item key={`${item[keyValue]}`} {...item} />)}
      </div>
    )
  }

  return (
    <div className={classes}>
      <Text className={styles.item}>{translations?.noResults}</Text>
    </div>
  )
}
