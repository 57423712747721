import { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import {
  Button,
  DragDropFileList,
  DrawerStepper,
  Label,
  Select,
  Input,
} from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { CatalogSearch } from '@/components'
import { getFileNameAndExtension } from '@/helpers'
import {
  QUERIES_ADMIN,
  api,
  KAI_PRODUCTS_BY_FILE_URL,
  useCreateKaiProcessorByReference,
  useCreateKaiProcessorByText,
} from '@/services'

import type { CatalogProductProps } from '@/types'

import styles from './drawer-new-form.module.css'

import type { DrawerCatalogProcessorNewFormProps } from './drawer-new-form.type'

export const CatalogProcessorDrawerNewForm = ({
  isOpen,
  onHandleOpen,
}: DrawerCatalogProcessorNewFormProps) => {
  const { t } = useTranslation('screenCatalogProcessorList')

  const queryClient = useQueryClient()

  const [file, setFile] = useState<string | undefined>(undefined)
  const [type, setType] = useState<string>('catalog')
  const [product, setProduct] = useState<
    Partial<CatalogProductProps> | undefined
  >(undefined)
  const [text, setText] = useState<string>('')

  const {
    mutateAsync: mutateCreateKaiProcessorByReference,
    isPending: isPendingCatalog,
  } = useCreateKaiProcessorByReference()

  const {
    mutateAsync: mutateCreateKaiProcessorByText,
    isPending: isPendingText,
  } = useCreateKaiProcessorByText()

  const getFileValue = () => {
    if (!file) {
      return []
    }

    const { fileName, extension } = getFileNameAndExtension(file)

    return [
      {
        id: new Date().getTime(),
        name: `${fileName}.${extension}`,
        url: file as string,
        uploaded: true,
      },
    ]
  }

  const onHandleFileSuccess = (fileName?: string) => {
    queryClient.invalidateQueries({
      queryKey: [QUERIES_ADMIN.KAI_PROCESSOR.module],
    })

    onHandleOpen(false)

    setFile(fileName)
  }

  const onHandleSubmit = async () => {
    if (type === 'catalog') {
      if (!product?.reference) {
        return
      }

      const response = await mutateCreateKaiProcessorByReference({
        reference: product?.reference || '',
      })

      if (response.success) {
        queryClient.invalidateQueries({
          queryKey: [QUERIES_ADMIN.KAI_PROCESSOR.module],
        })

        onHandleOpen(false)
      }
    }

    if (type === 'text') {
      const response = await mutateCreateKaiProcessorByText({
        productData: text,
      })

      if (response.success) {
        queryClient.invalidateQueries({
          queryKey: [QUERIES_ADMIN.KAI_PROCESSOR.module],
        })

        onHandleOpen(false)
      }
    }
  }

  return (
    <DrawerStepper
      translations={{
        optional: t('drawerNewFormOptional'),
      }}
      isOpen={isOpen}
      onHandleOpen={onHandleOpen}
      title={t('drawerNewFormTitleCreate')}
      description={t('drawerNewFormSubTitleCreate')}
      initialStep="root"
      steps={{
        root: {
          title: t('drawerNewFormGeneralFormTitle'),
          isOptional: false,
          backNavigate: 'root',
          component: (
            <div className={styles.core}>
              <div>
                <Label>{t('drawerNewFormTypeLabel')}</Label>
                <Select
                  placeholder={t('drawerNewFormTypePlaceholder')}
                  options={[
                    {
                      label: t('drawerNewFormTypeOptionCatalog'),
                      value: 'catalog',
                    },
                    {
                      label: t('drawerNewFormTypeOptionFile'),
                      value: 'file',
                    },
                    {
                      label: t('drawerNewFormTypeOptionText'),
                      value: 'text',
                    },
                  ]}
                  value={type}
                  onChange={setType}
                />
              </div>

              {type === 'file' && (
                <div>
                  <Label>{t('drawerNewFormUploadFileLabel')}</Label>
                  <DragDropFileList
                    axiosInstance={api}
                    urlAdd={KAI_PRODUCTS_BY_FILE_URL}
                    configsRequest={{
                      fileField: 'file',
                      method: 'POST',
                    }}
                    initialData={getFileValue()}
                    successedUpload={(file) =>
                      onHandleFileSuccess(`${file?.filename}`)
                    }
                    translations={{
                      clickToUpload: t('drawerNewFormUploadFileButton'),
                      dragFile: t('drawerNewFormUploadFileDrag'),
                      sizeFile: t('drawerNewFormUploadFileSize'),
                    }}
                  />
                </div>
              )}

              {type === 'catalog' && (
                <CatalogSearch
                  onSelectProduct={(item) => setProduct(item)}
                  selectedProduct={product as CatalogProductProps}
                />
              )}

              {type === 'text' && (
                <div>
                  <Label>{t('drawerNewFormTextPayloadLabel')}</Label>
                  <Input
                    placeholder={t('drawerNewFormTextPayloadPlaceholder')}
                    isTextArea
                    value={text}
                    onChange={setText}
                  />
                </div>
              )}
            </div>
          ),
          footer: () => (
            <>
              <Button variant="secondary" onClick={() => onHandleOpen(false)}>
                {t('drawerNewFormNavigateButtonBack')}
              </Button>

              {(type === 'catalog' || type === 'text') && (
                <Button
                  onClick={onHandleSubmit}
                  disabled={isPendingCatalog || isPendingText}
                >
                  {t('drawerNewFormNavigateButtonSubmit')}
                </Button>
              )}
            </>
          ),
        },
      }}
    />
  )
}
