'use client'
import {
  createContext,
  useContext,
  Children,
  useRef,
  useState,
  useEffect,
} from 'react'
import { CheckIcon } from '../../../icons'

import {
  generateClassesOfCore,
  generateClassesOfItemIcon,
} from './timeline.constants'

import styles from './timeline.module.css'

import type {
  TimelineContextProps,
  TimelineRootProps,
  TimelineItemProps,
} from './timeline.type'

const TimelineContext = createContext<TimelineContextProps>({
  activeItems: [],
  currentItem: undefined,
})

export const useTimelineContext = () => useContext(TimelineContext)

const Root = ({
  activeItems,
  children,
  className,
  currentItem,
  variant = 'primary',
}: TimelineRootProps) => {
  const [styleLine, setStyleLine] = useState<Record<string, string>>({})

  const [windowWidth, setWindowWidth] = useState<number | undefined>(undefined)

  const refLine = useRef<HTMLElement>(null)

  const totalItems = Children.toArray(children).length
  const activeItemsLength = activeItems.length + (currentItem ? 1 : 0)

  const activePercentage =
    activeItemsLength >= totalItems
      ? 1
      : (activeItemsLength - 1) / (totalItems - 1)

  useEffect(() => {
    if (refLine.current) {
      if (windowWidth !== undefined && windowWidth < 767.98) {
        const height = (refLine?.current?.offsetHeight || 0) * activePercentage

        setStyleLine({
          height: `${height}px`,
        })
      } else {
        const width = (refLine?.current?.offsetWidth || 0) * activePercentage

        setStyleLine({
          width: `${width}px`,
        })
      }
    }
  }, [refLine, activePercentage, windowWidth])

  useEffect(() => {
    if (windowWidth === undefined) {
      setWindowWidth(window.innerWidth)
    }

    function handleResize() {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const generatedClasses = generateClassesOfCore(variant, className)

  return (
    <TimelineContext.Provider value={{ activeItems, currentItem }}>
      <ul className={generatedClasses}>
        <span className={styles['root-line']} ref={refLine}></span>
        <span className={styles['root-line-active']} style={styleLine}></span>
        {children}
      </ul>
    </TimelineContext.Provider>
  )
}

const Item = ({ className, children, id }: TimelineItemProps) => {
  const { activeItems, currentItem } = useTimelineContext()

  const isActive = activeItems?.includes(id)

  const generateClasse = generateClassesOfItemIcon(
    isActive,
    currentItem === id,
    className,
  )

  return (
    <li className={`${styles.item} ${className || ''}`}>
      <span className={generateClasse}>{isActive ? <CheckIcon /> : null}</span>
      <span className={styles['item-label']}>{children}</span>
    </li>
  )
}

const Timeline = {
  Root,
  Item,
}

export default Timeline
