import { useMutation, useQuery } from '@tanstack/react-query'
import { convertObjectToCamelCase, convertObjectToSnakeCase } from '@/helpers'

import { UserProps, UserListWithPagesProps } from '@/types'

import { QUERIES_ADMIN } from './api.constants'
import { api } from './api.service'
import { USER_URL, USERS_URL } from './api.urls'

export const useUserList = ({
  params,
}: {
  params: Record<string, string | number>
}) => {
  const getUserList = async (): Promise<{
    success: boolean
    message: string
    data?: UserListWithPagesProps
  }> => {
    try {
      const copyParams = { ...params }

      const offset = copyParams?.page
        ? (+(copyParams?.page || 0) - 1) * +copyParams.limit
        : 0

      delete copyParams.page

      const formattedParams = Object.keys(copyParams).reduce((acc, key) => {
        if (copyParams?.[key]) {
          return {
            ...acc,
            [key]: copyParams?.[key],
          }
        }

        return acc
      }, {})

      const { data } = await api.get(USERS_URL, {
        params: {
          ...(convertObjectToSnakeCase(formattedParams) as Record<
            string,
            string
          >),
          offset,
        },
      })

      return {
        success: true,
        message: 'Users fetched successfully!',
        data: convertObjectToCamelCase(data) as UserListWithPagesProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get users!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.USER.module, params],
    queryFn: () => getUserList(),
  })
}

export const useUpdateUser = (uuid: string) => {
  const updateUser = async (
    payload: Partial<UserProps>,
  ): Promise<{
    success: boolean
    message: string
    data?: UserProps
  }> => {
    try {
      const { data } = await api.patch(
        `${USER_URL(uuid)}`,
        convertObjectToSnakeCase(payload),
      )

      return {
        success: true,
        message: 'User updated successfully!',
        data: convertObjectToCamelCase(data) as UserProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to update user!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.USER.module],
    mutationFn: (payload: Partial<UserProps>) => updateUser(payload),
  })
}
