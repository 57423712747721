export const columnsForType = {
  all: [
    'reference',
    'buyerName',
    'companyName',
    'salesOrderStatus',
    'taxStatus',
    'paymentStatus',
    'invoiceStatus',
    'logisticStatus',
    'moscowClassification',
    'expectedDeliveryDate',
    'items',
    'transportPrice',
    'totalQuantity',
    'totalPrice',
    'keyAccountManager',
  ],
  sale: [
    'reference',
    'buyerName',
    'companyName',
    'salesOrderStatus',
    'taxStatus',
    'paymentStatus',
    'invoiceStatus',
    'logisticStatus',
    'moscowClassification',
    'expectedDeliveryDate',
    'items',
    'transportPrice',
    'totalQuantity',
    'totalPrice',
    'keyAccountManager',
  ],
  profile: [
    'reference',
    'buyerName',
    'companyName',
    'salesOrderStatus',
    'taxStatus',
    'createdAtDistanceToNow',
    'items',
    'totalPrice',
    'totalQuantity',
    'moscowClassification',
    'keyAccountManager',
  ],
  finance: [
    'reference',
    'buyerName',
    'companyName',
    'salesOrderStatus',
    'paymentStatus',
    'invoiceStatus',
    'taxStatus',
    'paidAt',
    'items',
    'totalPrice',
    'cogs',
    'keyAccountManager',
    'moscowClassification',
  ],
  logistic: [
    'reference',
    'buyer',
    'logisticStatus',
    'paymentStatus',
    'expectedDeliveryDate',
    'tableLogisticActions',
  ],
}
