import type { PaginationProps } from './pagination.type'

import { Button } from '../../core'
import { ChevronLeftIcon, ChevronRightIcon } from '../../../icons'

import styles from './pagination.module.css'

const Pagination = ({
  className,
  currentPage,
  onChange,
  totalPages,
}: PaginationProps) => {
  const getPagesToShow = () => {
    if (totalPages <= 5) {
      return Array.from({ length: totalPages }, (_, i) => i + 1)
    } else {
      if (currentPage <= 3) {
        return [1, 2, 3, 4, 5]
      } else if (currentPage >= totalPages - 2) {
        return [
          totalPages - 4,
          totalPages - 3,
          totalPages - 2,
          totalPages - 1,
          totalPages,
        ]
      } else {
        return [
          currentPage - 2,
          currentPage - 1,
          currentPage,
          currentPage + 1,
          currentPage + 2,
        ]
      }
    }
  }

  const onHandleLessClick = () => {
    if (currentPage > 1) {
      onChange(currentPage - 1)
    }
  }

  const onHandleMoreClick = () => {
    if (currentPage < totalPages) {
      onChange(currentPage + 1)
    }
  }

  const pagesToShow = getPagesToShow()

  return (
    <div className={`${styles.core} ${className || ''}`}>
      <Button
        variant="ghost"
        model="square"
        onClick={onHandleLessClick}
        disabled={currentPage === 1}
      >
        <ChevronLeftIcon />
      </Button>

      {pagesToShow.map((page) => (
        <Button
          variant="ghost"
          model="square"
          isActive={page === currentPage}
          key={page}
          onClick={() => onChange(page)}
        >
          {page}
        </Button>
      ))}

      <Button
        variant="ghost"
        model="square"
        onClick={onHandleMoreClick}
        disabled={currentPage === totalPages}
      >
        <ChevronRightIcon />
      </Button>
    </div>
  )
}

export default Pagination
