import { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { sweetAlert } from './sweetAlert'
import { tokens } from '@mercai/clever'

import * as Yup from 'yup'

export const buildNestedErrors = (
  errors: Yup.ValidationError[],
): Record<string, any> => { //eslint-disable-line
  return errors.reduce(
    (acc, error) => {
      if (!error.path) return acc

      const pathArray = error.path.split('.')
      pathArray.reduce((a, b, index) => {
        if (index + 1 === pathArray.length) {
          a[b] = error.message
          return null
        } else {
          if (!a[b]) a[b] = {}
          return a[b]
        }
      }, acc)

      return acc
    },
    {} as Record<string, any>, //eslint-disable-line
  )
}

export const onValidationSchema = async (
  shape: Record<string, Yup.AnySchema>,
  data: unknown,
) => {
  const response = {
    isValid: false,
    errors: {},
  }

  const schema = Yup.object().shape(shape)

  await schema
    .validate(data, { abortEarly: false })
    .then(() => {
      response.isValid = true
      response.errors = {}
    })
    .catch((err) => {
      if (err instanceof Yup.ValidationError) {
        response.isValid = false
        response.errors = buildNestedErrors(err.inner)
      }
    })

  return response
}

export const useValidationErrors = (
  shape: Record<string, Yup.AnySchema>,
  data: unknown,
) => {
  const [errors, setErrors] = useState<
    Record<string, string | Record<string, string>>
  >({})
  const [isValid, setIsValid] = useState<boolean>(false)
  const [listenErrors, setListenErrors] = useState<boolean>(false)

  const memoizedData = useMemo(() => data, [JSON.stringify(data)])
  const memoizedShape = useMemo(() => shape, [JSON.stringify(shape)])

  const previousErrors = useRef(errors)
  const previousIsValid = useRef(isValid)

  useEffect(() => {
    let isMounted = true

    const validate = async () => {
      const response = await onValidationSchema(memoizedShape, memoizedData)

      if (isMounted) {
        if (response.isValid !== previousIsValid.current) {
          setIsValid(response.isValid)
          previousIsValid.current = response.isValid
        }

        if (
          listenErrors &&
          JSON.stringify(response.errors) !==
            JSON.stringify(previousErrors.current)
        ) {
          setErrors(response.errors)
          previousErrors.current = response.errors
        }
      }
    }

    validate()

    return () => {
      isMounted = false
    }
  }, [listenErrors, memoizedShape, memoizedData])

  const onHandleListenErrors = useCallback((listen: boolean) => {
    setListenErrors(listen)

    if (!listen) {
      setErrors({})
    }
  }, [])

  return { isValid, errors, onHandleListenErrors }
}

export const useValidationErrorsWithValue = <T>(
  shape: Record<string, Yup.AnySchema>,
  initialData?: T,
) => {
  const [data, setData] = useState<T | undefined>(initialData)
  const [errors, setErrors] = useState<Record<string, string>>({})
  const [isValid, setIsValid] = useState<boolean>(false)
  const [listenErrors, setListenErrors] = useState<boolean>(false)

  useEffect(() => {
    const validate = async () => {
      const response = await onValidationSchema(shape, data)
      setIsValid(response.isValid)

      if (listenErrors) {
        setErrors(response.errors)
      }
    }

    validate()
  }, [data, listenErrors])

  const onHandleListenErrors = useCallback((listen: boolean) => {
    setListenErrors(listen)

    if (!listen) {
      setErrors({})
    }
  }, [])

  return { data, setData, isValid, errors, onHandleListenErrors }
}

export const onSuccessMessage = (label: string, message?: string) =>
  sweetAlert.fire({
    title: label,
    text: message,
    icon: 'success',
    iconColor: tokens.colors.success600,
  })

export const onErrorMessage = (label: string, message?: string) => {
  sweetAlert.fire({
    title: label,
    text: message,
    icon: 'error',
    iconColor: tokens.colors.danger600,
  })
}

export const onConfirmDangerMessage = async (
  label: string,
  message?: string,
  confirmLabel?: string,
  cancelLabel?: string,
): Promise<boolean> => {
  const result = await sweetAlert.fire({
    title: label,
    text: message,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: tokens.colors.danger600,
    cancelButtonColor: tokens.colors.gray200,
    iconColor: tokens.colors.danger600,
    confirmButtonText: confirmLabel || 'Yes',
    cancelButtonText: cancelLabel || 'No',
  })

  return result.isConfirmed
}

export const getErrors = (errors: Record<string, unknown>, key: string) => {
  const keys = key.split('.')
  let result = errors

  for (const k of keys) {
    if (result && k in result) {
      result = result[k] as Record<string, unknown>
    } else {
      return undefined
    }
  }

  return typeof result === 'string' ? result : ''
}
