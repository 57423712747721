import { CheckListItemStateEnum } from './check-list.type'

import styles from './check-list.module.css'

export const states: Record<keyof typeof CheckListItemStateEnum, string> = {
  default: styles['state-default'],
  checked: styles['state-checked'],
  selected: styles['state-selected'],
}

export const generateClasses = (state: keyof typeof CheckListItemStateEnum) => {
  return `${styles.item} ${states?.[state || 'default']}`
}
