import { useState } from 'react'
import { Accordion, Skeleton, Text } from '@mercai/clever'

import styles from './info-list.module.css'

import { InfoListProps } from './info-list.type'

export const InfoList = ({
  iconTitle,
  isLoading,
  list,
  title,
  actions,
}: InfoListProps) => {
  const [activeItems, setActiveItems] = useState<string[]>([])

  const size: Record<number, string> = {
    0: '25%',
    1: '45%',
    2: '35%',
    3: '60%',
    4: '15%',
    5: '20%',
  }

  return (
    <Accordion.Root
      activeItems={activeItems}
      onHandleActiveItems={setActiveItems}
      className={styles.core}
    >
      <Accordion.Item
        id="info-list"
        header={
          <div className={styles['box-title']}>
            {isLoading ? (
              <Skeleton height="1.75rem" width="1.75rem" />
            ) : (
              iconTitle
            )}

            {isLoading ? (
              <Skeleton height="1.2rem" width="60%" />
            ) : (
              <Text variant="title-base" className={styles.title}>
                {title}
              </Text>
            )}

            {!isLoading && <div className={styles.actions}>{actions}</div>}
          </div>
        }
      >
        <div className={styles.content}>
          {list?.map((item, index) => (
            <div className={styles.row} key={`item-${index}`}>
              {isLoading ? (
                <Skeleton width={size[index % 6]} height="1rem" />
              ) : (
                <Text className={styles.label}>
                  <strong>{item.label}</strong>
                </Text>
              )}

              {isLoading ? (
                <Skeleton width="50%" height="1rem" />
              ) : (
                <Text className={styles.value}>{item.value}</Text>
              )}
            </div>
          ))}
        </div>
      </Accordion.Item>
    </Accordion.Root>
  )
}
