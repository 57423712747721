import { useEffect, useState } from 'react'
import { ChevronLeftIcon, CloseIcon, Drawer, Text } from '@mercai/clever'
import { generateClassesOfMain } from './drawer-stepper.constants'

import type {
  DrawerStepperProps,
  DrawerStepperFunctionOrComponentsProps,
} from './drawer-stepper.type'

import styles from './drawer-stepper.module.css'

export const DrawerStepper = ({
  isOpen,
  onHandleOpen,
  steps,
  initialStep = 'root',
  title,
  description,
  translations,
  finalComponent,
}: DrawerStepperProps) => {
  const [previousStep, setPreviousStep] = useState(initialStep)
  const [currentStep, setCurrentStep] = useState(initialStep)
  const [animate, setAnimate] = useState<'none' | 'next' | 'previous'>('none')

  useEffect(() => {
    if (animate !== 'none') {
      const timer = setTimeout(() => setAnimate('none'), 500)
      return () => clearTimeout(timer)
    }
  }, [animate])

  useEffect(() => {
    if (!isOpen) {
      setPreviousStep(initialStep)
      setCurrentStep(initialStep)
    }
  }, [isOpen])

  useEffect(() => {
    if (isOpen) {
      setPreviousStep(initialStep)
      setCurrentStep(initialStep)
    }
  }, [initialStep])

  const onNavigateToPreviousStep = () => {
    const newCurrentStep = steps?.[currentStep]?.backNavigate

    setAnimate('previous')
    setPreviousStep(newCurrentStep)
    setCurrentStep(newCurrentStep)
  }

  const onNavigateToNextStep = (nextStep: string) => {
    setAnimate('next')
    setPreviousStep(currentStep)
    setCurrentStep(nextStep)
  }

  const renderFuncOrComp = (
    component: DrawerStepperFunctionOrComponentsProps,
  ) => {
    if (typeof component === 'function') {
      return component({
        onNavigateToPreviousStep,
        onNavigateToNextStep,
      })
    } else {
      return component
    }
  }

  const classesOfMain = generateClassesOfMain(animate)

  return (
    <Drawer isOpen={isOpen} onHandleOpen={onHandleOpen}>
      <header className={styles.header}>
        <div className={styles['header-box-content']}>
          <Text className={styles['header-title']} variant="title-large">
            {title}
          </Text>
          {description && (
            <Text className={styles['header-subtitle']}>{description}</Text>
          )}
        </div>

        <button
          type="button"
          onClick={() => onHandleOpen(false)}
          className={styles['header-button']}
        >
          <CloseIcon />
        </button>
      </header>

      <main className={styles.main}>
        <div className={styles['main-box']}>
          {steps?.[previousStep]?.title && (
            <div className={styles['main-header']}>
              {previousStep !== 'root' && (
                <button
                  className={styles['main-header-back']}
                  type="button"
                  onClick={onNavigateToPreviousStep}
                >
                  <ChevronLeftIcon />
                </button>
              )}

              <Text className={styles['main-title']} variant="text-large-bold">
                {steps?.[previousStep]?.title}{' '}
                {steps?.[previousStep]?.isOptional && (
                  <span>{translations?.optional}</span>
                )}
              </Text>
            </div>
          )}

          <div className={`${styles['main-content']}`}>
            {renderFuncOrComp(steps?.[previousStep]?.component)}
          </div>

          <div className={styles['main-footer']}>
            {renderFuncOrComp(steps[currentStep]?.footer)}
          </div>
        </div>

        <div className={classesOfMain}>
          {steps?.[currentStep]?.title && (
            <div className={styles['main-header']}>
              {currentStep !== 'root' && (
                <button
                  className={styles['main-header-back']}
                  type="button"
                  onClick={onNavigateToPreviousStep}
                >
                  <ChevronLeftIcon />
                </button>
              )}

              <Text className={styles['main-title']} variant="text-large-bold">
                {steps?.[currentStep]?.title}{' '}
                {steps?.[currentStep]?.isOptional && (
                  <span>{translations.optional}</span>
                )}
              </Text>
            </div>
          )}

          <div className={`${styles['main-content']}`}>
            {renderFuncOrComp(steps?.[currentStep]?.component)}
          </div>

          <div className={styles['main-footer']}>
            {renderFuncOrComp(steps?.[currentStep]?.footer)}
          </div>
        </div>
      </main>

      {finalComponent}
    </Drawer>
  )
}
