import {
  createContext,
  useContext,
  useLayoutEffect,
  useEffect,
  useState,
  useMemo,
} from 'react'

import { useNavigate, useSearchParams } from 'react-router-dom'

import {
  identifyEvent,
  setAuthToken,
  setRefreshToken,
  getAuthToken,
  removeAuthToken,
  removeRefreshToken,
  isSuperUser,
} from '@/helpers'
import { api, useAuthSignIn, useWhoAmI, useGetWhoAmI } from '@/services'

import { ROUTES_ADMIN, ROUTES_AUTH } from '@/router'

import type {
  AuthProviderProps,
  AuthContextDataProps,
  UserSignInProps,
} from './auth.type'

export const AuthContext = createContext({} as AuthContextDataProps)

const AuthProvider = ({ children }: AuthProviderProps) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const [initStarted, setInitStarted] = useState(false)
  const [token, setToken] = useState<string | undefined>(undefined)
  const { mutateAsync: mutateSignIn, isPending } = useAuthSignIn()
  const { mutateAsync: mutateWhoAmI, isPending: isPendingWhoAmI } = useWhoAmI()

  const isLoading = isPending || isPendingWhoAmI

  const { data: dataWhoAmI } = useGetWhoAmI(token)

  useEffect(() => {
    if (dataWhoAmI?.data?.email) {
      identifyEvent(
        dataWhoAmI?.data.email,
        dataWhoAmI?.data.email,
        dataWhoAmI?.data?.name,
        dataWhoAmI?.data?.accountLevel?.slug || 'empty',
      )
    }
  }, [dataWhoAmI])

  const onHandleNavigateWithNext = (link: string) => {
    const next = searchParams.get('next')

    if (next) {
      navigate(next)
    }

    navigate(link)
  }

  const signIn = async (user: UserSignInProps) => {
    const { email, password } = user

    const res = await mutateSignIn({
      email,
      password,
    })

    if (res?.success) {
      const resWhoAmI = await mutateWhoAmI()

      if (!resWhoAmI?.success || !resWhoAmI?.data?.isStaff) {
        return {
          success: false,
          message: 'You are not authorized to access this page!',
        }
      }

      setToken(res.data?.access)
      setRefreshToken(`${res.data?.refresh}`)

      onHandleNavigateWithNext(ROUTES_ADMIN.BUSINESS.RFQ.fullPath())

      return {
        success: true,
        message: res.message,
      }
    } else {
      return {
        success: false,
        message: res.message,
      }
    }
  }

  const signOut = () => {
    setToken(undefined)

    removeAuthToken()
    removeRefreshToken()

    api.defaults.headers.common.Authorization = ''

    onHandleNavigateWithNext(ROUTES_AUTH.SIGN_IN.fullPath())
  }

  useLayoutEffect(() => {
    if (initStarted && token !== undefined) {
      setAuthToken(`${token}`)
    }

    api.defaults.headers.common.Authorization = `Bearer ${token}`
  }, [token])

  useLayoutEffect(() => {
    const initToken = getAuthToken()

    setToken(initToken ? String(initToken) : undefined)

    api.defaults.headers.common.Authorization = `Bearer ${initToken}`

    setInitStarted(true)
  }, [])

  const valueProvider = useMemo(
    () => ({
      isSigned: Boolean(token || !initStarted),
      token,
      isLoading,
      signIn,
      signOut,
      onHandleNavigateWithNext,
      whoAmI: dataWhoAmI?.data,
      isSuperUser: isSuperUser(dataWhoAmI?.data?.email || ''),
    }),
    [token, isLoading, initStarted, dataWhoAmI?.data],
  )

  return (
    <AuthContext.Provider value={valueProvider}>
      {children}
    </AuthContext.Provider>
  )
}

const useAuth = (): AuthContextDataProps => {
  const context = useContext(AuthContext)

  return context
}

export { AuthProvider, useAuth }
