import { ArrowRightIcon, TrashIcon, Text } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { formatNumber } from '@/helpers'

import { generateClassesMetric } from './metric.constants'

import styles from './metric.module.css'

import { MetricProps, MetricsProps } from './metric.type'

export const Metric = ({
  value,
  total,
  label,
  decimalPlaces = 2,
  isActiveFilter,
  onClickFilter,
}: MetricProps) => {
  const { t } = useTranslation('screenOrderCenterPage')

  const getPercentage = () => {
    return formatNumber((value / (total || 1)) * 100, 'es-MX', decimalPlaces)
  }

  return (
    <li className={generateClassesMetric(isActiveFilter)}>
      <Text className={styles['metrics-item-texts']}>{label}</Text>

      <div className={styles['metrics-item-row']}>
        <Text variant="title-large">
          {formatNumber(value, 'es-MX', decimalPlaces)}
        </Text>

        {total && (
          <Text variant="title-small" className={styles['metrics-item-texts']}>
            ({getPercentage()}%)
          </Text>
        )}
      </div>

      {onClickFilter && (
        <button
          onClick={() => onClickFilter()}
          className={styles['metrics-item-button']}
        >
          <Text variant="text-small" className={styles['metrics-item-text']}>
            {isActiveFilter ? t('filterTabRemove') : t('filterTabApply')}
          </Text>

          {isActiveFilter ? <TrashIcon /> : <ArrowRightIcon />}
        </button>
      )}
    </li>
  )
}

export const Metrics = ({ metrics }: MetricsProps) => {
  return (
    <ul className={styles.metrics}>
      {metrics.map((metric) => (
        <Metric {...metric} key={metric.label} />
      ))}
    </ul>
  )
}
