import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, Text, Tag, WarningOutlineIcon } from '@mercai/clever'

import { formatLongDate } from '@/helpers'

import { ROUTES_ADMIN } from '@/router'

import type {
  TableColumnBuyerProps,
  TableColumnBuyerNameProps,
  TableColumnCompanyProps,
  TableColumnTaxStatusProps,
  TableColumnTagMoscowProps,
  TableColumnPaymentStatusProps,
  TableColumnInvoiceStatusProps,
  TableColumnLogisticStatusProps,
  TableColumnSalesOrderStatusProps,
  TableColumnExpectedDeliveryDateProps,
  TableColumnLogisticActionsProps,
} from './table-columns.type'

import styles from './table-columns.module.css'

export const TableColumnBuyer = ({
  buyerName,
  companyName,
  uuid,
}: TableColumnBuyerProps) => {
  const getInitials = () => {
    const name = companyName || buyerName

    const splittedName = name.split(' ')

    let initials = ''

    if (splittedName?.[0]) {
      initials = `${splittedName[0][0]}`
    }

    if (splittedName?.[splittedName?.length - 1]) {
      initials = `${initials}${splittedName[splittedName?.length - 1][0]}`
    }

    return initials
  }

  return (
    <Link
      target="_blank"
      to={ROUTES_ADMIN.PROFILE.SHOW.fullPath(uuid)}
      className={styles.buyer}
    >
      <Text variant="title-small" className={styles.initials}>
        {getInitials()}
      </Text>
      <div className={styles.content}>
        <Text variant="title-base">{buyerName || '-'}</Text>

        <Text variant="text-small">{companyName || ''}</Text>
      </div>
    </Link>
  )
}

export const TableColumnName = ({
  buyerName,
  uuid,
}: TableColumnBuyerNameProps) => {
  return (
    <div>
      <Link
        target="_blank"
        to={ROUTES_ADMIN.PROFILE.SHOW.fullPath(uuid)}
        className={styles.link}
      >
        {buyerName || '-'}
      </Link>
    </div>
  )
}

export const TableColumnCompany = ({
  companyName,
  uuid,
}: TableColumnCompanyProps) => {
  return (
    <div>
      <Link
        target="_blank"
        to={ROUTES_ADMIN.PROFILE.SHOW.fullPath(uuid)}
        className={styles.link}
      >
        {companyName || '-'}
      </Link>
    </div>
  )
}

export const TableTaxStatus = ({
  taxStatus = 'pending',
}: TableColumnTaxStatusProps) => {
  const { t } = useTranslation('enums')

  const object: Record<string, ReactNode> = {
    pending: (
      <Tag variant="warning" model="light">
        {t('profile_tax_status_pending')}
      </Tag>
    ),
    review: (
      <Tag variant="danger" model="light">
        {t('profile_tax_status_review')}
      </Tag>
    ),
    completed: (
      <Tag variant="success" model="light">
        {t('profile_tax_status_completed')}
      </Tag>
    ),
    invalid: (
      <Tag variant="basic" model="light">
        {t('profile_tax_status_invalid')}
      </Tag>
    ),
  }

  return <div>{object[taxStatus]}</div>
}

export const TableTagMoscow = ({
  moscowClassification,
}: TableColumnTagMoscowProps) => {
  const { t } = useTranslation('enums')

  const moscowObject: Record<string, ReactNode> = {
    must: (
      <Tag variant="success" model="light">
        {t('profile_moscow_must')}
      </Tag>
    ),
    should: (
      <Tag variant="primary" model="light">
        {t('profile_moscow_should')}
      </Tag>
    ),
    could: (
      <Tag variant="warning" model="light">
        {t('profile_moscow_could')}
      </Tag>
    ),
    wont: (
      <Tag variant="danger" model="light">
        {t('profile_moscow_wont')}
      </Tag>
    ),
    unknown: (
      <Tag variant="basic" model="light">
        {t('profile_moscow_unknown')}
      </Tag>
    ),
  }

  return moscowObject[moscowClassification || 'unknown']
}

export const TableColumnsPaymentStatus = ({
  paymentStatus,
}: TableColumnPaymentStatusProps) => {
  const { t } = useTranslation('enums')

  const object: Record<string, ReactNode> = {
    pending: (
      <Tag variant="warning" model="light">
        {t('order_payment_status_pending')}
      </Tag>
    ),
    waiting_approval: (
      <Tag variant="warning" model="light">
        {t('order_payment_status_waiting_approval')}
      </Tag>
    ),
    paid: (
      <Tag variant="success" model="light">
        {t('order_payment_status_paid')}
      </Tag>
    ),
    invalid: (
      <Tag variant="basic" model="light">
        {t('order_payment_status_invalid')}
      </Tag>
    ),
    order_payment_status_cancelled: (
      <Tag variant="danger" model="light">
        {t('order_payment_status_cancelled')}
      </Tag>
    ),
  }

  return <div>{object[paymentStatus]}</div>
}

export const TableColumnsInvoiceStatus = ({
  invoiceStatus,
}: TableColumnInvoiceStatusProps) => {
  const { t } = useTranslation('enums')

  const object: Record<string, ReactNode> = {
    pending: (
      <Tag variant="warning" model="light">
        {t('order_invoice_status_pending')}
      </Tag>
    ),
    uploaded: (
      <Tag variant="success" model="light">
        {t('order_invoice_status_uploaded')}
      </Tag>
    ),
    cancelled: (
      <Tag variant="danger" model="light">
        {t('order_invoice_status_cancelled')}
      </Tag>
    ),
  }

  return <div>{object[invoiceStatus]}</div>
}

export const TableColumnsLogisticStatus = ({
  logisticStatus,
}: TableColumnLogisticStatusProps) => {
  const { t } = useTranslation('enums')

  const object: Record<string, ReactNode> = {
    pending: (
      <Tag variant="warning" model="light">
        {t('logistic_shipment_status_pending')}
      </Tag>
    ),
    delivery_scheduled: (
      <Tag variant="success" model="light">
        {t('logistic_shipment_status_delivery_scheduled')}
      </Tag>
    ),
    loaded: (
      <Tag variant="success" model="light">
        {t('logistic_shipment_status_loaded')}
      </Tag>
    ),
    in_transit: (
      <Tag variant="primary" model="light">
        {t('logistic_shipment_status_in_transit')}
      </Tag>
    ),
    ready_to_deliver: (
      <Tag variant="warning" model="light">
        {t('logistic_shipment_status_ready_to_deliver')}
      </Tag>
    ),
    partially_delivered: (
      <Tag variant="warning" model="light">
        {t('logistic_shipment_status_partially_delivered')}
      </Tag>
    ),
    delivered: (
      <Tag variant="success" model="light">
        {t('logistic_shipment_status_delivered')}
      </Tag>
    ),
    cancelled: (
      <Tag variant="danger" model="light">
        {t('logistic_shipment_status_cancelled')}
      </Tag>
    ),
    refused: (
      <Tag variant="danger" model="light">
        {t('logistic_shipment_status_refused')}
      </Tag>
    ),
  }

  return <div>{object[logisticStatus]}</div>
}
export const TableColumnsSalesOrderStatus = ({
  salesOrderStatus,
}: TableColumnSalesOrderStatusProps) => {
  const { t } = useTranslation('enums')

  const object: Record<string, ReactNode> = {
    created: (
      <Tag variant="warning" model="light">
        {t('sale_order_status_created')}
      </Tag>
    ),
    processing: (
      <Tag variant="warning" model="light">
        {t('sale_order_status_processing')}
      </Tag>
    ),
    completed: (
      <Tag variant="success" model="light">
        {t('sale_order_status_completed')}
      </Tag>
    ),
    fraudulent: (
      <Tag variant="danger" model="light">
        {t('sale_order_status_fraudulent')}
      </Tag>
    ),
    cancelled: (
      <Tag variant="danger" model="light">
        {t('sale_order_status_cancelled')}
      </Tag>
    ),
  }

  return <div>{object[salesOrderStatus]}</div>
}

export const TableColumnExpectedDeliveryDate = ({
  expectedDeliveryAt,
  deliveryDate,
}: TableColumnExpectedDeliveryDateProps) => {
  const verifyLate = () => {
    const expectedDate = new Date(expectedDeliveryAt)

    let delivery = new Date(deliveryDate)

    if (!deliveryDate) {
      delivery = new Date()
    }

    return expectedDate < delivery
  }

  const isLate = verifyLate()

  return (
    <div className={styles['expected-delivery-date']}>
      <Text className={isLate ? styles['is-late'] : undefined}>
        {formatLongDate(expectedDeliveryAt)}
      </Text>

      {isLate && <WarningOutlineIcon />}
    </div>
  )
}

export const TableColumnLogisticActions = ({
  onClickEdit,
  onClickView,
}: TableColumnLogisticActionsProps) => {
  const { t } = useTranslation('screenOrderCenterPage')

  return (
    <div className={styles['logistic-actions']}>
      <Button variant="secondary" onClick={onClickEdit}>
        {t('tableLogisticActionsEdit')}
      </Button>

      <Button onClick={onClickView}>{t('tableLogisticActionsView')}</Button>
    </div>
  )
}
