import styles from './drawer-stepper-progress.module.css'

export const statesMain: Record<'next' | 'previous' | 'none', string> = {
  next: styles['main-box-animate-next'],
  previous: styles['main-box-animate-previous'],
  none: '',
}

export const generateClassesOfMain = (state: 'next' | 'previous' | 'none') => {
  return `${styles['main-box']} ${statesMain[state]}`
}
