import { useTranslation } from 'react-i18next'
import { useQueryClient } from '@tanstack/react-query'

import { DrawerStepperProgress } from '@mercai/clever'

import { QUERIES_ADMIN } from '@/services'

import { TransactionFormStep, TransactionOrderStep } from './components'

import type { TransactionDrawerFormProps } from './drawer-form.type'

export const TransactionDrawerForm = ({
  uuid,
  isOpen,
  onHandleOpen,
  onSetUuid,
}: TransactionDrawerFormProps) => {
  const { t } = useTranslation('screenOrderCenterPage')
  const queryClient = useQueryClient()

  const onSuccessSubmit = () => {
    queryClient.invalidateQueries({
      queryKey: [QUERIES_ADMIN.TRANSACTION.module],
    })
  }

  const isNewRegister = !uuid

  return (
    <DrawerStepperProgress
      translations={{
        optional: '',
      }}
      initialStep={'root'}
      isOpen={isOpen}
      onHandleOpen={onHandleOpen}
      title={
        isNewRegister
          ? t('formTransactionCreateDrawerTitle')
          : t('formTransactionUpdateDrawerTitle')
      }
      description={t('formTransactionUpdateDrawerDescription')}
      steps={{
        root: {
          title: t('formTransactionStepFormTitle'),
          isOptional: false,
          backNavigate: 'root',
          component: ({ onNavigateToPreviousStep, onNavigateToNextStep }) => (
            <TransactionFormStep
              uuid={uuid}
              onHandleBack={onNavigateToPreviousStep}
              onSuccessSubmit={(transaction) => {
                onSetUuid(transaction?.uuid || '')

                if (transaction?.payer?.uuid) {
                  onNavigateToNextStep('order')
                } else {
                  onNavigateToPreviousStep()
                }

                onSuccessSubmit()
              }}
            />
          ),
        },
        order: {
          title: t('formTransactionStepOrderTitle'),
          description: t('formTransactionStepOrderDescription'),
          isOptional: false,
          backNavigate: 'root',
          component: ({ onNavigateToPreviousStep }) => (
            <TransactionOrderStep
              uuid={uuid}
              onHandleBack={onNavigateToPreviousStep}
              onSuccessSubmit={() => {
                onHandleOpen()
                onSuccessSubmit()
              }}
            />
          ),
        },
      }}
    />
  )
}
