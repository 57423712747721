import { Button, Text, PlusIcon, Card } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import type { AddressListProps } from './address-list.type'

import styles from './adress-list.module.css'

export const AddressList = ({
  onClickNewAddress,
  addresses,
}: AddressListProps) => {
  const { t } = useTranslation('screenProfileList')

  return (
    <div className={styles.core}>
      <Text>{t('profileFormAddressListDescription')}</Text>

      {addresses?.map((address) => (
        <Card key={address.uuid} className={styles.items}>
          <Text variant="title-base">{`${t('profileFormAddressListDeliveryAt')} ${address.city}`}</Text>
          <Text>{`${address.country}, ${address.state}, ${address.city}, 
          ${address.neighborhood}, ${address.street} - ${address.number} 
          ${address.reference ? `(${address.reference})` : ''}`}</Text>
        </Card>
      ))}

      <Button onClick={onClickNewAddress} variant="light">
        <PlusIcon />
        {t('profileFormAddressListButton')}
      </Button>
    </div>
  )
}
