import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Text, DownloadIcon, CopyIcon, CheckIcon } from '@mercai/clever'

import { copyTextToClipboard, downloadFile, getFileExtension } from '@/helpers'
import { useFileSign } from '@/services'

import styles from './card-payment-proof.module.css'

import type { CardPaymentProofProps } from './card-payment-proof.type'

export const CardPaymentProof = ({
  paymentProof,
  orderReference,
}: CardPaymentProofProps) => {
  const { t } = useTranslation('screenOrderList')

  const [isCopied, setIsCopied] = useState(false)
  const [link, setLink] = useState('')

  const { mutateAsync, isPending } = useFileSign()

  const getUrl = async (urlParam: string) => {
    if (!urlParam) return ''

    if (!urlParam.includes('http')) {
      const response = await mutateAsync({
        filePath: urlParam || '',
      })

      if (!response?.data?.signedUrl) return ''

      return response?.data?.signedUrl || ''
    }

    return ''
  }

  const onHandleDownload = async () => {
    if (!paymentProof) return null

    let url = paymentProof

    if (!url) return null

    url = await getUrl(url)

    const extension = getFileExtension(url)
    downloadFile(url, `payment_proof_${orderReference}.${extension}`)
  }

  const onHandleCopy = async () => {
    if (!paymentProof) return null

    try {
      let url = paymentProof
      url = await getUrl(url)

      const success = copyTextToClipboard(url)

      setIsCopied(success)

      setTimeout(() => {
        setIsCopied(false)
      }, 5000)
    } catch (err) {
      console.error(err)
    }
  }

  const getFileLink = async () => {
    const url = paymentProof || ''

    if (!url) return ''

    return await getUrl(url)
  }

  useEffect(() => {
    getFileLink().then((url) => {
      setLink(url)
    })
  }, [paymentProof])

  return (
    <div className={styles.core}>
      <div className={styles.content}>
        <Text variant="title-large" className={styles.title}>
          {t('orderShowCardPaymentProofTitle')}
        </Text>
        <Text className={styles.subtitle}>
          {t('orderShowCardPaymentProofDescription')}
        </Text>
      </div>

      <div className={styles['buttons-wrap-column']}>
        <Button href={link} target="_blank" className={styles.buttons}>
          {t('orderShowCardPaymentProofViewProof')}
        </Button>

        <div className={styles['buttons-wrap-line']}>
          <Button
            variant="secondary"
            className={styles.buttons}
            onClick={onHandleDownload}
          >
            <DownloadIcon className={styles['buttons-icon']} />
            {isPending
              ? t('orderShowCardPaymentProofDownloadingProof')
              : t('orderShowCardPaymentProofDownloadProof')}
          </Button>

          <Button
            variant="secondary"
            onClick={onHandleCopy}
            className={styles.buttons}
          >
            {isCopied ? (
              <CheckIcon className={styles['copy-check-icon']} />
            ) : (
              <CopyIcon />
            )}
            {isCopied
              ? t('orderShowCardPaymentProofCopiedLinkProof')
              : t('orderShowCardPaymentProofCopyLinkProof')}
          </Button>
        </div>
      </div>
    </div>
  )
}
