import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { Tag } from '@mercai/clever'

import type { TagAccountLevelProps } from './tag-account-level.type'

export const TagAccountLevel = ({ accountLevelSlug }: TagAccountLevelProps) => {
  const { t } = useTranslation('enums')

  const customerLevelObject: Record<string, ReactNode> = {
    unknown: (
      <Tag variant="basic" model="light">
        {t('profile_customer_unknown')}
      </Tag>
    ),
    UQL: (
      <Tag variant="warning" model="light">
        {t('profile_customer_uql')}
      </Tag>
    ),
    MQL: (
      <Tag variant="primary" model="light">
        {t('profile_customer_mql')}
      </Tag>
    ),
    SQL: (
      <Tag variant="success" model="light">
        {t('profile_customer_sql')}
      </Tag>
    ),
    customer: (
      <Tag variant="success" model="fill">
        {t('profile_customer_customer')}
      </Tag>
    ),
    disqualified: (
      <Tag variant="danger" model="light">
        {t('profile_customer_disqualified')}
      </Tag>
    ),
  }

  return customerLevelObject[accountLevelSlug || 'unknown']
}
