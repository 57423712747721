import styles from './accordion.module.css'

export const statesIcon: Record<'open' | 'close', string> = {
  open: styles['icon-state-open'],
  close: '',
}

export const generateClassesOfIcon = (state: 'open' | 'close') => {
  return `${styles['icon-core']} ${statesIcon[state]}`
}
