import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const getKeysThatExistInInitialState = (
  initialState: Record<string, string>,
  params: Record<string, string>,
): Record<string, string> => {
  const newCurrentState: Record<string, string> = {}

  Object.keys(initialState).forEach((key) => {
    if (key in initialState) {
      newCurrentState[key] = params?.[key] || ''
    }
  })

  return newCurrentState
}

const getParamsFromUrl = (locationSearch: string) => {
  const searchParams = new URLSearchParams(locationSearch)

  const params: Record<string, string> = {}

  searchParams.forEach((value, key) => {
    params[key] = value
  })

  return params
}

export const useParamsState = (
  initialState: Record<string, string>,
): [Record<string, string>, (newState: Record<string, string>) => void] => {
  const navigate = useNavigate()
  const location = useLocation()

  const getInitialState = (): Record<string, string> => {
    const params = getParamsFromUrl(location.search)

    if (Object.keys(params)?.length === 0) {
      return { ...initialState }
    }

    const newState = getKeysThatExistInInitialState(initialState, params)

    return { ...initialState, ...params, ...newState } as Record<string, string>
  }

  const [state, setState] = useState<Record<string, string>>(getInitialState())

  const updateStateAndUrl = (newState: Record<string, string>) => {
    const urlParams = getParamsFromUrl(location.search)

    const currentState = { ...urlParams, ...newState } as Record<string, string>
    setState(currentState)

    const searchParams = new URLSearchParams()

    Object.entries(currentState).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== '') {
        searchParams.set(key, value)
      }
    })

    const newUrl = searchParams.toString()

    navigate(`?${newUrl}`, { replace: true })
  }

  return [state, updateStateAndUrl]
}
