import { Text, Skeleton, MonetizationIcon } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import type { OrderSummaryPaymentProps } from './summary-payment.type'

import styles from './summary-payment.module.css'

const OrderSummaryPaymentDefault = () => {
  const { t } = useTranslation('screenOrderList')

  return (
    <div className={styles.core}>
      <div className={styles.title}>
        <MonetizationIcon />
        <Text variant="title-base" className={styles.title}>
          {t('orderShowTitleSummaryPayment')}
        </Text>
      </div>

      <div className={styles.content}>
        <Text>{t('orderShowPaymentLine1')}</Text>
        <Text>{t('orderShowPaymentLine2')}</Text>
      </div>
    </div>
  )
}

const OrderSummaryPaymentLoading = () => {
  return (
    <div className={styles.core}>
      <div className={styles.title}>
        <Skeleton height="1.5rem" width="1.5rem" />
        <Skeleton height="1rem" width="8rem" />
      </div>

      <div className={styles.content}>
        <Skeleton height="1rem" width="100%" />
        <Skeleton height="1rem" width="100%" />
      </div>
    </div>
  )
}

export const OrderSummaryPayment = ({
  isLoading,
}: OrderSummaryPaymentProps) => {
  if (isLoading) {
    return <OrderSummaryPaymentLoading />
  }

  return <OrderSummaryPaymentDefault />
}
