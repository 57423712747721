import { Button, Checkbox, CloseIcon, Drawer, Text } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import styles from './drawer-filter.module.css'

import { DrawerFilterProps } from './drawer-filter.type'

export const CatalogProductDrawerFilter = ({
  isOpen,
  onHandleOpen,
  catalogCategories,
  filter,
  onHandleFilter,
  attributesFilters,
}: DrawerFilterProps) => {
  const { t } = useTranslation('screenCatalogProductList')

  const getBooleanOfMultiValue = (key: string, value: string): boolean => {
    const values = (filter[key] as string)?.split(',') || []
    return values.includes(value)
  }

  const onChangeBooleanOfMultiValue = (key: string, value: string) => {
    if (key === 'category' && value === 'ALL') {
      onHandleFilter({ ...filter, category: ['ALL'].join(',') })
      return
    }

    const values =
      (filter[key] as string)?.split(',').filter((item) => item !== 'ALL') || []

    let newValues = ''

    if (values.includes(value)) {
      newValues = values.filter((item) => item !== value).join(',')
    } else {
      newValues = [...values, value].join(',')
    }

    onHandleFilter({ ...filter, [key]: newValues })
  }

  return (
    <Drawer isOpen={isOpen} onHandleOpen={onHandleOpen}>
      <div className={styles.header}>
        <Text variant="title-large" className={styles['header-title']}>
          {t('drawerFilterTitle')}
        </Text>

        <button
          className={styles['header-button']}
          type="button"
          onClick={onHandleOpen}
        >
          <CloseIcon />
        </button>
      </div>

      <div className={styles.content}>
        <div className={styles['content-filter']}>
          <Text variant="title-base">{t('filterCategoryLabel')}</Text>

          <Checkbox
            key="ALL"
            value={getBooleanOfMultiValue('category', 'ALL')}
            onChange={() => onChangeBooleanOfMultiValue('category', 'ALL')}
          >
            {t('categoriesAllLabel')} (ALL)
          </Checkbox>

          {catalogCategories?.map((categoryItem) => (
            <Checkbox
              key={categoryItem.value}
              value={getBooleanOfMultiValue('category', categoryItem.value)}
              onChange={() =>
                onChangeBooleanOfMultiValue('category', categoryItem.value)
              }
            >
              {categoryItem.label} ({categoryItem.value})
            </Checkbox>
          ))}
        </div>

        {attributesFilters?.map((attribute) => (
          <div key={attribute.slug} className={styles['content-filter']}>
            <Text variant="title-base">{attribute.name}</Text>

            {attribute.options?.map((option) => (
              <Checkbox
                key={option.value}
                value={getBooleanOfMultiValue(attribute.slug, option.name)}
                onChange={() =>
                  onChangeBooleanOfMultiValue(attribute.slug, option.name)
                }
              >
                {option.name}
              </Checkbox>
            ))}
          </div>
        ))}
      </div>

      <div className={styles.footer}>
        <Button variant="secondary" onClick={onHandleOpen}>
          {t('drawerFilterButtonBack')}
        </Button>
      </div>
    </Drawer>
  )
}
