import { SVGProps } from 'react'

export const WarningOutlineIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M1 22L12 2L23 22H1ZM4.45 19.8947H19.55L12 6.21053L4.45 19.8947ZM12 18.8421C12.2833 18.8421 12.5208 18.7412 12.7125 18.5395C12.9042 18.3377 13 18.0877 13 17.7895C13 17.4912 12.9042 17.2412 12.7125 17.0395C12.5208 16.8377 12.2833 16.7368 12 16.7368C11.7167 16.7368 11.4792 16.8377 11.2875 17.0395C11.0958 17.2412 11 17.4912 11 17.7895C11 18.0877 11.0958 18.3377 11.2875 18.5395C11.4792 18.7412 11.7167 18.8421 12 18.8421ZM11 15.6842H13V10.4211H11V15.6842Z" />
    </svg>
  )
}
