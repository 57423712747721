import { useState } from 'react'
import {
  Button,
  Checkbox,
  CloseIcon,
  Drawer,
  InputMask,
  Text,
} from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { parseDateTimeToISO } from '@/helpers'

import styles from './drawer-filter.module.css'

import { DrawerFilterProps } from './drawer-filter.type'

export const QuotationRequestDrawerFilter = ({
  isOpen,
  onHandleOpen,
  filter,
  onHandleFilter,
}: DrawerFilterProps) => {
  const { t } = useTranslation('screenQuotationRequestList')
  const { t: tEnums } = useTranslation('enums')

  const [createdAtBefore, setCreatedAtBefore] = useState('')
  const [createdAtAfter, setCreatedAtAfter] = useState('')

  const getBooleanOfMultiValue = (key: string, value: string): boolean => {
    const values = (filter[key] as string)?.split(',') || []
    return values.includes(value)
  }

  const onChangeBooleanOfMultiValue = (key: string, value: string) => {
    let values = (filter[key] as string)?.split(',') || []
    values = values.filter((item) => !!item)

    let newValues = ''

    if (values.includes(value)) {
      newValues = values.filter((item) => item !== value).join(',')
    } else {
      newValues = [...values, value].join(',')
    }

    onHandleFilter({ ...filter, [key]: newValues })
  }

  const onApplyDate = (key: string) => {
    const value = key === 'createdAtBefore' ? createdAtBefore : createdAtAfter

    onHandleFilter({
      ...filter,
      [key]: value === '__/__/____' ? '' : parseDateTimeToISO(value),
    })
  }

  return (
    <Drawer isOpen={isOpen} onHandleOpen={onHandleOpen}>
      <div className={styles.header}>
        <Text variant="title-large" className={styles['header-title']}>
          {t('quotationRequestTableFilterTitle')}
        </Text>

        <button
          className={styles['header-button']}
          type="button"
          onClick={onHandleOpen}
        >
          <CloseIcon />
        </button>
      </div>

      <div className={styles.content}>
        <div className={styles['content-filter']}>
          <Text variant="title-base">
            {t('quotationRequestTableFilterStateLabel')}
          </Text>

          <Checkbox
            value={getBooleanOfMultiValue('status', 'pending')}
            onChange={() => onChangeBooleanOfMultiValue('status', 'pending')}
          >
            {tEnums('quotation_request_status_pending')}
          </Checkbox>

          <Checkbox
            value={getBooleanOfMultiValue('status', 'approved_for_quotation')}
            onChange={() =>
              onChangeBooleanOfMultiValue('status', 'approved_for_quotation')
            }
          >
            {tEnums('quotation_request_status_approved_for_quotation')}
          </Checkbox>

          <Checkbox
            value={getBooleanOfMultiValue('status', 'processed')}
            onChange={() => onChangeBooleanOfMultiValue('status', 'processed')}
          >
            {tEnums('quotation_request_status_processed')}
          </Checkbox>

          <Checkbox
            value={getBooleanOfMultiValue('status', 'completed')}
            onChange={() => onChangeBooleanOfMultiValue('status', 'completed')}
          >
            {tEnums('quotation_request_status_completed')}
          </Checkbox>

          <Checkbox
            value={getBooleanOfMultiValue('status', 'expired')}
            onChange={() => onChangeBooleanOfMultiValue('status', 'expired')}
          >
            {tEnums('quotation_request_status_expired')}
          </Checkbox>

          <Checkbox
            value={getBooleanOfMultiValue('status', 'invalid')}
            onChange={() => onChangeBooleanOfMultiValue('status', 'invalid')}
          >
            {tEnums('quotation_request_status_invalid')}
          </Checkbox>

          <Checkbox
            value={getBooleanOfMultiValue('status', 'disqualified')}
            onChange={() =>
              onChangeBooleanOfMultiValue('status', 'disqualified')
            }
          >
            {tEnums('quotation_request_status_disqualified')}
          </Checkbox>
        </div>

        <div className={styles['content-filter']}>
          <Text variant="title-base">
            {t('quotationRequestTableFilterOriginLabel')}
          </Text>

          <Checkbox
            value={getBooleanOfMultiValue('origin', 'quotation_request')}
            onChange={() =>
              onChangeBooleanOfMultiValue('origin', 'quotation_request')
            }
          >
            {tEnums('quotation_request_origin_quotation_request')}
          </Checkbox>

          <Checkbox
            value={getBooleanOfMultiValue('origin', 'open_quotation_request')}
            onChange={() =>
              onChangeBooleanOfMultiValue('origin', 'open_quotation_request')
            }
          >
            {tEnums('quotation_request_origin_open_quotation_request')}
          </Checkbox>

          <Checkbox
            value={getBooleanOfMultiValue('origin', 'open_catalog')}
            onChange={() =>
              onChangeBooleanOfMultiValue('origin', 'open_catalog')
            }
          >
            {tEnums('quotation_request_origin_open_catalog')}
          </Checkbox>

          <Checkbox
            value={getBooleanOfMultiValue('origin', 'assistant_guest')}
            onChange={() =>
              onChangeBooleanOfMultiValue('origin', 'assistant_guest')
            }
          >
            {tEnums('quotation_request_origin_assistant_guest')}
          </Checkbox>

          <Checkbox
            value={getBooleanOfMultiValue('origin', 'assistant_whatsapp')}
            onChange={() =>
              onChangeBooleanOfMultiValue('origin', 'assistant_whatsapp')
            }
          >
            {tEnums('quotation_request_origin_assistant_whatsapp')}
          </Checkbox>

          <Checkbox
            value={getBooleanOfMultiValue(
              'origin',
              'assistant_web_authenticated',
            )}
            onChange={() =>
              onChangeBooleanOfMultiValue(
                'origin',
                'assistant_web_authenticated',
              )
            }
          >
            {tEnums('quotation_request_origin_assistant_web_authenticated')}
          </Checkbox>

          <Checkbox
            value={getBooleanOfMultiValue(
              'origin',
              'backoffice_quotation_request',
            )}
            onChange={() =>
              onChangeBooleanOfMultiValue(
                'origin',
                'backoffice_quotation_request',
              )
            }
          >
            {tEnums('quotation_request_origin_backoffice_quotation_request')}
          </Checkbox>

          <Checkbox
            value={getBooleanOfMultiValue('origin', 'product_match')}
            onChange={() =>
              onChangeBooleanOfMultiValue('origin', 'product_match')
            }
          >
            {tEnums('quotation_request_origin_product_match')}
          </Checkbox>
        </div>

        <div className={styles['content-filter']}>
          <Text variant="title-base">
            {t('quotationRequestTableFilterMoscowLabel')}
          </Text>

          <Checkbox
            value={getBooleanOfMultiValue('moscow', 'must')}
            onChange={() => onChangeBooleanOfMultiValue('moscow', 'must')}
          >
            {t('quotationRequestTableFilterMoscowMW')}
          </Checkbox>

          <Checkbox
            value={getBooleanOfMultiValue('moscow', 'should')}
            onChange={() => onChangeBooleanOfMultiValue('moscow', 'should')}
          >
            {t('quotationRequestTableFilterMoscowSW')}
          </Checkbox>

          <Checkbox
            value={getBooleanOfMultiValue('moscow', 'could')}
            onChange={() => onChangeBooleanOfMultiValue('moscow', 'could')}
          >
            {t('quotationRequestTableFilterMoscowCW')}
          </Checkbox>

          <Checkbox
            value={getBooleanOfMultiValue('moscow', 'wont')}
            onChange={() => onChangeBooleanOfMultiValue('moscow', 'wont')}
          >
            {t('quotationRequestTableFilterMoscowWW')}
          </Checkbox>
        </div>

        <div className={styles['content-filter']}>
          <Text variant="title-base">
            {t('quotationRequestTableFilterCreatedAtLabel')}
          </Text>

          <div className={styles['content-filter-row']}>
            <InputMask
              value={createdAtAfter}
              onChange={setCreatedAtAfter}
              placeholder="dd/mm/aaaa"
              mask="99/99/9999"
              onBlur={() => onApplyDate('createdAtAfter')}
            />

            <Text>-</Text>

            <InputMask
              value={createdAtBefore}
              onChange={setCreatedAtBefore}
              placeholder="dd/mm/aaaa"
              mask="99/99/9999"
              onBlur={() => onApplyDate('createdAtBefore')}
            />
          </div>
        </div>
      </div>

      <div className={styles.footer}>
        <Button variant="secondary" onClick={onHandleOpen}>
          {t('quotationRequestTableFilterButtonBack')}
        </Button>
      </div>
    </Drawer>
  )
}
