import { Text, ChevronRightIcon, Skeleton } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import styles from './header.module.css'

import type { ProfileHeaderProps } from './header.type'

const ProfileHeaderDefault = ({ email, onHandleOpen }: ProfileHeaderProps) => {
  const { t } = useTranslation('componentsOrderForm')

  return (
    <div className={styles.core}>
      <button
        className={styles.button}
        type="button"
        onClick={() => onHandleOpen(false)}
      >
        <ChevronRightIcon />
      </button>

      <div className={styles.content}>
        <Text variant="title-large" className={styles.title}>
          {t('profileTaxSituationFormTitle')}
        </Text>

        <Text
          className={styles.subtitle}
        >{` ${t('profileTaxSituationFormEmail', { email })}`}</Text>
      </div>
    </div>
  )
}

const ProfileHeaderLoading = () => {
  return (
    <div className={styles.core}>
      <div className={styles.content}>
        <Skeleton height="1rem" width="20rem" />
      </div>

      <Skeleton height="2rem" width="2rem" />
    </div>
  )
}

export const ProfileHeader = (props: ProfileHeaderProps) => {
  if (props.isLoading) {
    return <ProfileHeaderLoading />
  }

  return <ProfileHeaderDefault {...props} />
}
