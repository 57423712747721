import { Text, CloseIcon, Skeleton } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { formatDate } from '@/helpers'

import styles from './header.module.css'

import type { QuotationHeaderProps } from './header.type'

const QuotationHeaderDefault = ({
  reference,
  createdAt,
  expiresAt,
  onHandleOpen,
}: QuotationHeaderProps) => {
  const { t } = useTranslation('screenQuotationList')

  return (
    <div className={styles.core}>
      <div className={styles.content}>
        <Text variant="title-large" className={styles.title}>
          {t('quotationShowTitle', {
            reference,
          })}
        </Text>

        <Text>
          {`${t('quotationShowSubTitleCreatedAt')} ${formatDate(createdAt || '')} •`}
          {` ${t('quotationShowSubTitleExpiresAt')} ${formatDate(expiresAt || '')}`}
        </Text>
      </div>

      <button
        className={styles.button}
        type="button"
        onClick={() => onHandleOpen(false)}
      >
        <CloseIcon />
      </button>
    </div>
  )
}

const QuotationHeaderLoading = () => {
  return (
    <div className={styles.core}>
      <div className={styles.content}>
        <Skeleton height="2rem" width="22rem" />
        <Skeleton height="1rem" width="20rem" />
      </div>

      <Skeleton height="2rem" width="2rem" />
    </div>
  )
}

export const QuotationHeader = (props: QuotationHeaderProps) => {
  if (props.isLoading) {
    return <QuotationHeaderLoading />
  }

  return <QuotationHeaderDefault {...props} />
}
