import { ChevronLeftIcon, ChevronRightIcon } from '@mercai/clever'
import { generateClassesOfItem } from './monthly-filter.constants'
import { MonthlyFilterProps } from './monthly-filter.type'
import styles from './monthly-filter.module.css'

import { addMonths, format, isSameMonth } from 'date-fns'

export const MonthlyFilter = ({
  onSelectedMonth,
  selectedMonth,
}: MonthlyFilterProps) => {
  const hasValue = Boolean(selectedMonth)
  const selectedMonthDate = selectedMonth ? new Date(selectedMonth) : new Date()

  const changeMonth = (offset: number) => {
    const newDate = addMonths(selectedMonthDate, offset)
    onSelectedMonth(newDate)
  }

  const renderMonthButton = (offset: number) => {
    const date = addMonths(selectedMonthDate, offset)

    const monthName = format(date, 'MMMM')
    const year = format(date, 'yyyy')

    const isSelected = hasValue && isSameMonth(selectedMonthDate, date)

    const classes = generateClassesOfItem(isSelected)

    return (
      <li key={`${monthName}-${year}`}>
        <button className={classes} onClick={() => onSelectedMonth(date)}>
          <span className={styles.month}>{monthName}</span>
          <span className={styles.year}>{year}</span>
        </button>
      </li>
    )
  }

  return (
    <ul className={styles.core}>
      <button className={styles.buttons} onClick={() => changeMonth(-1)}>
        <ChevronLeftIcon />
      </button>

      {renderMonthButton(-3)}
      {renderMonthButton(-2)}
      {renderMonthButton(-1)}
      {renderMonthButton(0)}
      {renderMonthButton(1)}

      <button className={styles.buttons} onClick={() => changeMonth(1)}>
        <ChevronRightIcon />
      </button>
    </ul>
  )
}
