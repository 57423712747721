import {
  SummaryValues as SummaryValuesDS,
  Skeleton,
  Text,
} from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { formatCurrency } from '@/helpers'

import styles from './summary-values.module.css'
import type { SummaryValuesProps } from './summary-values.type'

const OrderSummaryValuesDefault = ({
  totalFreight,
  iva,
  subTotal,
  total,
  discount,
  dollarQuotation,
}: SummaryValuesProps) => {
  const { t } = useTranslation('screenOrderList')

  return (
    <div className={styles.wrap}>
      <Text>
        {t('orderShowDollarQuotation')}:{' '}
        <strong>
          {formatCurrency(dollarQuotation || 0, 'es-MX', 'MXN', 4)}
        </strong>
      </Text>

      <SummaryValuesDS.Root>
        <SummaryValuesDS.Row>
          <SummaryValuesDS.Column type="bookend">
            {t('orderShowSummaryValuesAmounts')}
          </SummaryValuesDS.Column>
          <SummaryValuesDS.Column type="bookend" direction="right">
            {t('orderShowSummaryValuesMXNCurrency')}
          </SummaryValuesDS.Column>
          <SummaryValuesDS.Column type="bookend" direction="right">
            {t('orderShowSummaryValuesUSDCurrency')}
          </SummaryValuesDS.Column>
        </SummaryValuesDS.Row>

        <SummaryValuesDS.Row>
          <SummaryValuesDS.Column type="label">
            {t('orderShowSummaryValuesSubTotal')}
          </SummaryValuesDS.Column>
          <SummaryValuesDS.Column type="value" direction="right">
            {formatCurrency(subTotal * dollarQuotation)}
          </SummaryValuesDS.Column>
          <SummaryValuesDS.Column type="value" direction="right">
            {formatCurrency(subTotal)}
          </SummaryValuesDS.Column>
        </SummaryValuesDS.Row>

        {!discount || (
          <SummaryValuesDS.Row>
            <SummaryValuesDS.Column type="label">
              {t('orderShowSummaryValuesDiscount')}
            </SummaryValuesDS.Column>
            <SummaryValuesDS.Column type="value" direction="right">
              - {formatCurrency(discount * dollarQuotation)}
            </SummaryValuesDS.Column>
            <SummaryValuesDS.Column type="value" direction="right">
              - {formatCurrency(discount)}
            </SummaryValuesDS.Column>
          </SummaryValuesDS.Row>
        )}

        <SummaryValuesDS.Row>
          <SummaryValuesDS.Column type="label">
            {t('orderShowSummaryValuesIVA')}
          </SummaryValuesDS.Column>
          <SummaryValuesDS.Column type="value" direction="right">
            {formatCurrency(iva * dollarQuotation)}
          </SummaryValuesDS.Column>
          <SummaryValuesDS.Column type="value" direction="right">
            {formatCurrency(iva)}
          </SummaryValuesDS.Column>
        </SummaryValuesDS.Row>

        <SummaryValuesDS.Row>
          <SummaryValuesDS.Column type="label">
            {t('orderShowSummaryValuesDelivery')}
          </SummaryValuesDS.Column>
          <SummaryValuesDS.Column type="value" direction="right">
            {formatCurrency(totalFreight * dollarQuotation)}
          </SummaryValuesDS.Column>
          <SummaryValuesDS.Column type="value" direction="right">
            {formatCurrency(totalFreight)}
          </SummaryValuesDS.Column>
        </SummaryValuesDS.Row>

        <SummaryValuesDS.Row type="strip">
          <SummaryValuesDS.Column type="bookend">
            {t('orderShowSummaryValuesAmount')}
          </SummaryValuesDS.Column>
          <SummaryValuesDS.Column type="bookend" direction="right">
            {formatCurrency(total * dollarQuotation)}
          </SummaryValuesDS.Column>
          <SummaryValuesDS.Column type="bookend" direction="right">
            {formatCurrency(total)}
          </SummaryValuesDS.Column>
        </SummaryValuesDS.Row>
      </SummaryValuesDS.Root>
    </div>
  )
}

const OrderSummaryValuesLoading = () => {
  return (
    <SummaryValuesDS.Root>
      <SummaryValuesDS.Row>
        <SummaryValuesDS.Column type="bookend">
          <Skeleton width="5rem" height="1.5rem" />
        </SummaryValuesDS.Column>
        <SummaryValuesDS.Column type="bookend" direction="right">
          <Skeleton width="3rem" height="1.5rem" />
        </SummaryValuesDS.Column>
        <SummaryValuesDS.Column type="bookend" direction="right">
          <Skeleton width="3rem" height="1.5rem" />
        </SummaryValuesDS.Column>
      </SummaryValuesDS.Row>

      <SummaryValuesDS.Row>
        <SummaryValuesDS.Column type="label">
          <Skeleton width="7rem" height="1rem" />
        </SummaryValuesDS.Column>
        <SummaryValuesDS.Column type="value" direction="right">
          <Skeleton width="6rem" height="1rem" />
        </SummaryValuesDS.Column>
        <SummaryValuesDS.Column type="value" direction="right">
          <Skeleton width="6rem" height="1rem" />
        </SummaryValuesDS.Column>
      </SummaryValuesDS.Row>

      <SummaryValuesDS.Row>
        <SummaryValuesDS.Column type="label">
          <Skeleton width="7rem" height="1rem" />
        </SummaryValuesDS.Column>
        <SummaryValuesDS.Column type="value" direction="right">
          <Skeleton width="6rem" height="1rem" />
        </SummaryValuesDS.Column>
        <SummaryValuesDS.Column type="value" direction="right">
          <Skeleton width="6rem" height="1rem" />
        </SummaryValuesDS.Column>
      </SummaryValuesDS.Row>

      <SummaryValuesDS.Row>
        <SummaryValuesDS.Column type="label">
          <Skeleton width="7rem" height="1rem" />
        </SummaryValuesDS.Column>
        <SummaryValuesDS.Column type="value" direction="right">
          <Skeleton width="6rem" height="1rem" />
        </SummaryValuesDS.Column>
        <SummaryValuesDS.Column type="value" direction="right">
          <Skeleton width="6rem" height="1rem" />
        </SummaryValuesDS.Column>
      </SummaryValuesDS.Row>

      <SummaryValuesDS.Row type="strip">
        <SummaryValuesDS.Column type="bookend">
          <Skeleton width="5rem" height="1.6rem" />
        </SummaryValuesDS.Column>
        <SummaryValuesDS.Column type="bookend" direction="right">
          <Skeleton width="6rem" height="1.6rem" />
        </SummaryValuesDS.Column>
        <SummaryValuesDS.Column type="bookend" direction="right">
          <Skeleton width="7rem" height="1.6rem" />
        </SummaryValuesDS.Column>
      </SummaryValuesDS.Row>
    </SummaryValuesDS.Root>
  )
}

export const OrderSummaryValues = (props: SummaryValuesProps) => {
  if (props.isLoading) {
    return <OrderSummaryValuesLoading />
  }

  return <OrderSummaryValuesDefault {...props} />
}
