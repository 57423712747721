import { ChangeEvent } from 'react'

import { generateClassesRadio } from './radio.constants'

import type { RadioProps } from './radio.type'

import styles from './radio.module.css'

const Radio = ({ children, onChange, value, className }: RadioProps) => {
  const classesRadio = generateClassesRadio(value, !!children)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event?.target?.checked || false)
  }

  return (
    <label className={`${styles.container} ${className ?? ''}`}>
      <input
        type="radio"
        checked={value}
        onChange={handleChange}
        className={styles.hidden}
      />
      <span className={classesRadio}></span>
      {children}
    </label>
  )
}

export default Radio
