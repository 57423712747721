export const downloadFile = async (url: string, fileName?: string) => {
  try {
    const response = await fetch(url)
    if (response.ok) {
      const blob = await response.blob()
      const url = URL.createObjectURL(blob)

      const anchor = document.createElement('a')
      anchor.href = url
      anchor.download = fileName || 'image.png'

      document.body.appendChild(anchor)
      anchor.click()

      document.body.removeChild(anchor)
      URL.revokeObjectURL(url)
    } else {
      console.error('Download file failed!', response.statusText)
    }
  } catch (error) {
    console.error('Fail download file:', error)
  }
}

export const getFileExtension = (url: string) => {
  const regex = /\/[^/]+\.(?<extension>\w+)(\?|$)/
  const result = regex.exec(url)
  return result ? result?.groups?.extension : ''
}

export const getFileNameAndExtension = (url: string) => {
  const regex = /\/([^/]+)\.(?<extension>\w+)(\?|$)/
  const result = regex.exec(url)
  if (result && result[1] && result?.groups?.extension) {
    return { fileName: result[1], extension: result?.groups?.extension }
  }
  return { fileName: '', extension: '' }
}
