import { useTranslation } from 'react-i18next'
import {
  DatePicker,
  Label,
  Text,
  InfoIcon,
  Input,
  Select,
  TruckIcon,
  Tooltip,
} from '@mercai/clever'

import {
  LogisticShipmentProps,
  LogisticShipmentTransportOptionsProps,
  LogisticShipmentVehicleTypeEnum,
  SaleOrderProps,
} from '@/types'
import type { LogisticFormTransportProps } from './transport.type'

import styles from './transport.module.css'

export const LogisticFormTransport = ({
  onChange,
  values = {} as SaleOrderProps,
}: LogisticFormTransportProps) => {
  const { t } = useTranslation('componentOrderCenterForm')
  const { t: tEnums } = useTranslation('enums')

  const onChangeShipmentValues = (
    keyParam: keyof LogisticShipmentProps,
    value?: string,
  ) => {
    const shipment = { ...values?.shipments?.[0] }
    const updatedShipment = { ...shipment, [keyParam]: value }

    onChange({
      ...values,
      shipments: [updatedShipment],
    })
  }

  const onChangeTransportValues = (
    keyParam: keyof LogisticShipmentTransportOptionsProps,
    value?: string,
  ) => {
    const shipment = { ...values?.shipments?.[0] }
    const updatedTransportOptions = {
      ...shipment.transportOptions,
      [keyParam]: value,
    }

    const shipments = values?.shipments?.map((s) =>
      s.uuid === shipment.uuid
        ? { ...s, transportOptions: updatedTransportOptions }
        : s,
    )

    onChange({
      ...values,
      shipments: shipments as LogisticShipmentProps[],
    })
  }

  return (
    <>
      <div className={styles.title}>
        <TruckIcon />
        <Text variant="title-base">{t('logisticTitle')}</Text>
      </div>

      <div className={styles.content}>
        <div className={styles.field}>
          <Label>{t('logisticStatusLabel')}</Label>
          <Select
            options={[
              {
                label: tEnums('logistic_shipment_status_pending'),
                value: 'pending',
              },
              {
                label: tEnums('logistic_shipment_status_delivery_scheduled'),
                value: 'delivery_scheduled',
              },
              {
                label: tEnums('logistic_shipment_status_loaded'),
                value: 'loaded',
              },
              {
                label: tEnums('logistic_shipment_status_in_transit'),
                value: 'in_transit',
              },
              {
                label: tEnums('logistic_shipment_status_ready_to_deliver'),
                value: 'ready_to_deliver',
              },
              {
                label: tEnums('logistic_shipment_status_partially_delivered'),
                value: 'partially_delivered',
              },
              {
                label: tEnums('logistic_shipment_status_delivered'),
                value: 'delivered',
              },
              {
                label: tEnums('logistic_shipment_status_cancelled'),
                value: 'cancelled',
              },
              {
                label: tEnums('logistic_shipment_status_refused'),
                value: 'refused',
              },
            ]}
            value={values?.shipments?.[0]?.status}
            onChange={(newValue) => onChangeShipmentValues('status', newValue)}
          />
        </div>

        <div className={styles.field}>
          <Label>
            {t('logisticExpectedDeliveryDaysLabel')}

            <Tooltip
              text={t('logisticExpectedDeliveryDaysDescription')}
              widthText="16rem"
            >
              <InfoIcon className={styles['info-icon']} />
            </Tooltip>
          </Label>
          <Input
            value={`${values?.shipments?.[0]?.expectedDeliveryDays} ${t('logisticExpectedDeliveryDaysSuffix')}`}
            onChange={(newValue) =>
              onChangeShipmentValues('expectedDeliveryDays', newValue)
            }
            disabled
          />
        </div>

        <div className={styles.field}>
          <Label>
            {t('logisticExpectedDeliveryDateLabel')}

            <Tooltip
              text={t('logisticExpectedDeliveryDateDescription')}
              widthText="16rem"
            >
              <InfoIcon className={styles['info-icon']} />
            </Tooltip>
          </Label>
          <DatePicker
            value={values?.shipments?.[0]?.expectedDeliveryDate}
            onChange={(newValue) =>
              onChangeShipmentValues('expectedDeliveryDate', newValue)
            }
            disabled
          />
        </div>

        <div className={styles.field}>
          <Label>
            {t('logisticExpectedDeliveryAtLabel')}

            <Tooltip
              text={t('logisticExpectedDeliveryAtDescription')}
              widthText="16rem"
            >
              <InfoIcon className={styles['info-icon']} />
            </Tooltip>
          </Label>
          <DatePicker
            value={values?.shipments?.[0]?.expectedDeliveryAt}
            onChange={(newValue) =>
              onChangeShipmentValues('expectedDeliveryAt', newValue)
            }
          />
        </div>

        <div className={styles.field}>
          <Label>
            {t('logisticDeliveredAtLabel')}

            <Tooltip
              text={t('logisticDeliveredAtDescription')}
              widthText="16rem"
            >
              <InfoIcon className={styles['info-icon']} />
            </Tooltip>
          </Label>
          <DatePicker
            value={values?.shipments?.[0]?.deliveredAt}
            onChange={(newValue) =>
              onChangeShipmentValues('deliveredAt', newValue)
            }
          />
        </div>
      </div>

      <div className={styles.content}>
        <Text variant="text-large-bold">
          {values?.shipments?.[0]?.deliveryOption === 'pick_up'
            ? t('logisticTransportDataPickupTitle')
            : t('logisticTransportDataReceiveAtAddressTitle')}
        </Text>
        <div className={styles.field}>
          <Label>{t('logisticTransportConductorLabel')}</Label>
          <Input
            value={values?.shipments?.[0]?.transportOptions?.driver}
            onChange={(newValue) => onChangeTransportValues('driver', newValue)}
          />
        </div>
        <div className={styles.field}>
          <Label>{t('logisticTransportTransportCompanyLabel')}</Label>
          <Input
            value={values?.shipments?.[0]?.transportOptions?.transportCompany}
            onChange={(newValue) =>
              onChangeTransportValues('transportCompany', newValue)
            }
          />
        </div>
        <div className={styles.field}>
          <Label>{t('logisticTransportTruckTypeLabel')}</Label>
          <Select
            options={Object.values(LogisticShipmentVehicleTypeEnum).map(
              (value) => ({
                label: tEnums(`logistic_shipment_type_${value}`),
                value,
              }),
            )}
            value={values?.shipments?.[0]?.transportOptions?.vehicleType}
            onChange={(newValue) =>
              onChangeTransportValues('vehicleType', newValue)
            }
          />
        </div>
        <div className={styles.field}>
          <Label>{t('logisticTransportPlateLabel')}</Label>
          <Input
            value={values?.shipments?.[0]?.transportOptions?.vehicleIdentifier}
            onChange={(newValue) =>
              onChangeTransportValues('vehicleIdentifier', newValue)
            }
          />
        </div>

        {values?.shipments?.[0]?.deliveryOption === 'receive_at_address' && (
          <div className={styles.field}>
            <Label>{t('logisticTransportLinkLabel')}</Label>
            <Input
              value={values?.shipments?.[0]?.transportOptions?.trackingInfo}
              onChange={(newValue) =>
                onChangeTransportValues('trackingInfo', newValue)
              }
            />
          </div>
        )}
      </div>
    </>
  )
}
