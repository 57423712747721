export const TextComponents = {
  /**
   * 12px-regular-smalltxt
   * @type {{className: string, htmlTag: string}}
   */
  'text-xsmall': { className: 'text-xsmall', htmlTag: 'span' },

  /**
   * 12px-semibold-smalltitle
   * @type {{className: string, htmlTag: string}}
   */
  'title-xsmall': { className: 'title-xsmall', htmlTag: 'span' },

  /**
   * 14px-regular-bodytxt
   * @type {{className: string, htmlTag: string}}
   */
  'text-small': { className: 'text-small', htmlTag: 'span' },

  /**
   * 14px-semibold-smalltitle
   * @type {{className: string, htmlTag: string}}
   */
  'title-small': { className: 'title-small', htmlTag: 'span' },

  /**
   * 16px-regular-bodytxt
   * @type {{className: string, htmlTag: string}}
   */
  'text-base': { className: 'text-base', htmlTag: 'span' },

  /**
   * 16px-semibold-smalltitle
   * @type {{className: string, htmlTag: string}}
   */
  'title-base': { className: 'title-base', htmlTag: 'span' },

  /**
   * 18px-regular-bodytxt
   * @type {{className: string, htmlTag: string}}
   */
  'text-medium': { className: 'text-medium', htmlTag: 'span' },

  /**
   * 18px-semibold-smalltitle
   * @type {{className: string, htmlTag: string}}
   */
  'title-medium': { className: 'title-medium', htmlTag: 'h4' },

  /**
   * 20px-regular-bodytxt
   * @type {{className: string, htmlTag: string}}
   */
  'text-large': { className: 'text-large', htmlTag: 'h3' },

  /**
   * 20px-semibold-bodytxt
   * @type {{className: string, htmlTag: string}}
   */
  'text-large-bold': { className: 'text-large-bold', htmlTag: 'h3' },

  /**
   * 24px-semibold-title
   * @type {{className: string, htmlTag: string}}
   */
  'title-large': { className: 'title-large', htmlTag: 'h2' },

  /**
   * 40px-semibold-title
   * @type {{className: string, htmlTag: string}}
   */
  'title-xlarge': { className: 'title-xlarge', htmlTag: 'h1' },

  /**
   * 48px-semibold-title
   * @type {{className: string, htmlTag: string}}
   */
  'title-xxlarge': { className: 'title-xxlarge', htmlTag: 'h1' },

  /**
   * regular-link
   * @type {{className: string, htmlTag: string}}
   */
  link: { className: 'link', htmlTag: 'a' },
  /**
   * regular-link
   * @type {{className: string, htmlTag: string}}
   */
  button: { className: 'button', htmlTag: 'button' },
}
