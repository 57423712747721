import { Text, Skeleton, MonetizationIcon } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import styles from './summary-payment.module.css'

import type { QuotationSummaryPaymentProps } from './summary-payment.type'

const QuotationSummaryPaymentDefault = ({
  paymentLink,
}: QuotationSummaryPaymentProps) => {
  const { t } = useTranslation('screenQuotationList')

  return (
    <div className={styles.core}>
      <div className={styles.title}>
        <MonetizationIcon />
        <Text variant="title-base" className={styles.title}>
          {t('quotationShowTitleSummaryPayment')}
        </Text>
      </div>

      <div className={styles.content}>
        <Text>{t('quotationShowPaymentLine1')}</Text>
        <Text>{t('quotationShowPaymentLine2')}</Text>
        {paymentLink && (
          <Text
            variant="link"
            href={paymentLink}
            target="_blank"
            className={styles.link}
          >
            {t('quotationShowPaymentLink')}
          </Text>
        )}
      </div>
    </div>
  )
}

const QuotationSummaryPaymentLoading = () => {
  return (
    <div className={styles.core}>
      <div className={styles.title}>
        <Skeleton height="1.5rem" width="1.5rem" />
        <Skeleton height="1rem" width="8rem" />
      </div>

      <div className={styles.content}>
        <Skeleton height="1rem" width="100%" />
        <Skeleton height="1rem" width="100%" />
      </div>
    </div>
  )
}

export const QuotationSummaryPayment = ({
  isLoading,
  paymentLink,
}: QuotationSummaryPaymentProps) => {
  if (isLoading) {
    return <QuotationSummaryPaymentLoading />
  }

  return <QuotationSummaryPaymentDefault paymentLink={paymentLink} />
}
