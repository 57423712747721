import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  CloseIcon,
  FilterIcon,
  InputSearch,
  TableColumns,
  Tag,
  Text,
  Pagination,
  PlusIcon,
  TableColumnsColumnProps,
  Select,
} from '@mercai/clever'

import {
  TableColumnPayer,
  TableColumnPaidAt,
  TableColumnPaymentStatus,
  TransactionDrawerFilter,
  TableColumnActions,
  TransactionDrawerForm,
  TableColumnPrices,
  TableColumnPaymentMethod,
} from './components'

import { DEFAULT_LIMIT_PAGE } from '@/constants'

import { formatDate } from '@/helpers'

import { TransactionProps } from '@/types'

import { OrderCenterTableTransactionProps } from './table-transaction.type'

import styles from './table-transaction.module.css'

export const OrderCenterTableTransaction = ({
  data,
  isLoading,
  filter,
  onSetFilter,
  formattedFilter,
}: OrderCenterTableTransactionProps) => {
  const { t } = useTranslation('screenOrderCenterPage')

  const [isOpenDrawerFilter, setIsOpenDrawerFilter] = useState(false)
  const [registerUuidEdit, setRegisterUuidEdit] = useState('')
  const [isOpenDrawerForm, setIsOpenDrawerForm] = useState(false)

  const onHandleOpenDrawerEdit = (uuid?: string) => {
    setRegisterUuidEdit(uuid || '')
    setIsOpenDrawerForm(true)
  }

  const onHandleNewRegister = () => {
    setIsOpenDrawerForm(true)

    setRegisterUuidEdit('')
  }

  const [totalPages, setTotalPages] = useState(1)

  const getContentTotalFiltered = () => {
    const activeFilters = Object.keys(filter)?.filter(
      (key) => !!filter[key] && key !== 'page' && key !== 'limit',
    ).length

    if (activeFilters > 0) {
      return (
        <Tag variant="primary" model="light" size="small">
          {activeFilters}
        </Tag>
      )
    }

    return ''
  }

  const handleFilter = (changedKey: string, newValue: string | number) => {
    if (changedKey === 'page') {
      onSetFilter({ ...filter, [changedKey]: `${newValue}` })
    } else {
      onSetFilter({ ...filter, [changedKey]: `${newValue}`, page: '1' })
    }
  }

  const getFormattedFilter = (filterKey: string | number) => {
    const filterString = filter?.[filterKey]

    if (filterKey === 'keyAccountManager') {
      return formattedFilter?.keyAccountManager || ''
    }

    if (filterKey === 'createdAtBefore') {
      return `${t('filterCreatedAtBefore')} ${formatDate(filterString)}`
    }

    if (filterKey === 'createdAtAfter') {
      return `${t('filterCreatedAtAfter')} ${formatDate(filterString)}`
    }

    if (!filterString || typeof filterString === 'number') return ''

    if (filterString?.[0] === ',') {
      return filterString?.slice(1)?.replace(/,/g, ', ')
    }

    return filterString?.replace(/,/g, ', ')
  }

  const getContentFilter = () => {
    const filtersKeys = Object.keys(filter).filter(
      (item) => !['limit', 'page', 'ordering'].includes(item) && !!filter[item],
    )

    if (filtersKeys && filtersKeys.length === 0) {
      return null
    }

    return (
      <div className={styles['filtered-options']}>
        <Text>{t('filterApplied')}:</Text>

        {filtersKeys?.map((item) => (
          <Button
            onClick={() => handleFilter(item, '')}
            variant="light"
            size="small"
            key={item}
          >
            {getFormattedFilter(item)}

            <CloseIcon />
          </Button>
        ))}
      </div>
    )
  }

  useEffect(() => {
    if (data?.count) {
      setTotalPages(
        Math.ceil(data?.count / +(filter?.limit || DEFAULT_LIMIT_PAGE)),
      )
    }
  }, [data])

  const columns: TableColumnsColumnProps<TransactionProps>[] = [
    {
      title: t('tableTransactionReference'),
      key: 'reference',
      dataKey: 'reference',
      width: '9rem',
      render: ({ value }) => `#${value}`,
    },
    {
      title: t('tableTransactionPayer'),
      key: 'buyer',
      width: '20rem',
      render: ({ record }) => (
        <TableColumnPayer
          buyerName={record?.payer?.name}
          companyName={record?.payer?.companyName}
          uuid={record?.payer?.uuid}
        />
      ),
    },
    {
      title: t('tableTransactionPaidAt'),
      key: 'paidAt',
      dataKey: 'paidAt',
      width: '12rem',
      render: ({ value }) => <TableColumnPaidAt paidAt={value || ''} />,
    },
    {
      title: t('tableTransactionPaymentMethod'),
      key: 'paymentMethod',
      dataKey: 'paymentMethod',
      width: '12rem',
      render: ({ record }) => (
        <TableColumnPaymentMethod
          paymentMethod={record?.paymentMethod}
          destinationBank={record.destinationBank}
        />
      ),
    },
    {
      title: t('tableTransactionStatePayment'),
      key: 'paymentStatus',
      width: '12rem',
      render: ({ record }) => (
        <TableColumnPaymentStatus
          saleOrderReference={record?.order?.reference}
          status={record?.status}
        />
      ),
    },
    {
      title: t('tableTransactionAmount'),
      key: 'invoiceStatus',
      render: ({ record }) => (
        <TableColumnPrices
          currency={record?.currency || 'USD'}
          dollarExchangeRate={+(record?.dollarExchangeRate || 0)}
          dollarValue={+(record?.dollarValue || 0)}
          value={+(record?.value || 0)}
        />
      ),
    },
    {
      key: 'tableLogisticActions',
      dataKey: 'uuid',
      render: ({ record }) => (
        <TableColumnActions
          onClick={() => onHandleOpenDrawerEdit(record.uuid)}
        />
      ),
    },
  ]

  return (
    <div className={styles.core}>
      <Text variant="title-large">{t('tableTransactionTitle')}</Text>

      <div className={styles.filter}>
        <div className={styles['filter-group']}>
          <InputSearch
            value={filter.search as string}
            onChange={(value) => handleFilter('search', value)}
            className={styles['search-input']}
            translations={{
              inputPlaceholder: t('tableTransactionSearchPlaceholder'),
            }}
          />

          <Button
            variant="secondary"
            onClick={() => setIsOpenDrawerFilter(true)}
          >
            <FilterIcon />
            {t('tableTransactionFilterButton')}
            {getContentTotalFiltered()}
          </Button>
        </div>

        <div className={styles['filter-group']}>
          <Button onClick={onHandleNewRegister}>
            <PlusIcon />
            {t('tableTransactionButtonNew')}
          </Button>
        </div>
      </div>

      {getContentFilter()}

      <TableColumns<TransactionProps>
        classNames={{
          bodyColumn: styles['body-column'],
        }}
        isLoading={isLoading}
        columns={columns}
        data={data?.results || []}
      />

      <div className={styles.pagination}>
        <div className={styles['pagination-show-registers']}>
          <Text>
            {t('tableTransactionShowRegisters', {
              showNumber:
                (data?.count || 0) < +filter.limit
                  ? data?.count
                  : +filter.limit,
              totalNumber: data?.count || 0,
            })}
          </Text>

          <Select
            value={filter.limit}
            onChange={(value) => handleFilter('limit', value)}
            options={[
              { label: '10', value: '10' },
              { label: '20', value: '20' },
              { label: '50', value: '50' },
              { label: '100', value: '100' },
            ]}
          />
        </div>

        <Pagination
          totalPages={totalPages}
          onChange={(page) => handleFilter('page', page)}
          currentPage={+filter.page as number}
        />
      </div>

      <TransactionDrawerFilter
        isOpen={isOpenDrawerFilter}
        onHandleOpen={() => setIsOpenDrawerFilter((prev) => !prev)}
        filter={filter}
        onHandleFilter={onSetFilter}
      />

      <TransactionDrawerForm
        isOpen={isOpenDrawerForm}
        onHandleOpen={() => setIsOpenDrawerForm((prev) => !prev)}
        uuid={registerUuidEdit}
        onSetUuid={setRegisterUuidEdit}
      />
    </div>
  )
}
