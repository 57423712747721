import styles from './input-number.module.css'

import { InputNumberSizeEnum } from './input-number.type'

export const sizes: Record<InputNumberSizeEnum, string> = {
  small: styles['size-small'],
  medium: styles['size-medium'],
  large: styles['size-large'],
}

export const disabledClasses = {
  enabled: styles.enabled,
  disabled: styles.disabled,
}

export const generateClasses = (
  size: keyof typeof InputNumberSizeEnum,
  isCenter?: boolean,
  error?: boolean,
  disabled?: boolean,
) => {
  return `${styles.core} ${sizes[size]} ${isCenter ? styles.centered : ''} ${
    !error || styles['state-danger']
  } ${disabledClasses[disabled ? 'disabled' : 'enabled']}`
}
