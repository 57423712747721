import { useMutation } from '@tanstack/react-query'

import { AxiosError } from 'axios'
import { convertObjectToCamelCase } from '@/helpers'

import { api, apiNoAuth } from './api.service'

import { QUERIES_ADMIN } from './api.constants'

import { AUTH_SIGN_IN_URL } from './api.urls'

export const useAuthSignIn = () => {
  const authSignIn = async (payload: {
    email: string
    password: string
  }): Promise<{
    success: boolean
    message: string
    data?: {
      access: string
      refresh: string
    }
  }> => {
    try {
      const { data } = await apiNoAuth.post(AUTH_SIGN_IN_URL, payload)

      api.defaults.headers.common.Authorization = `Bearer ${data.access}`

      return {
        success: true,
        message: 'Sign in successfully!',
        data: convertObjectToCamelCase(data) as {
          access: string
          refresh: string
        },
      }
    } catch (err) {
      const axiosError = err as AxiosError<{ detail?: string }>

      return {
        success: false,
        message: axiosError?.response?.data.detail || 'Failed to sign in!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.AUTH.module],
    mutationFn: (payload: { email: string; password: string }) =>
      authSignIn(payload),
  })
}
