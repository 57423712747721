import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  DragDropFileList,
  FileListFileProps,
  Label,
  Text,
  MonetizationIcon,
  Select,
  DatePicker,
} from '@mercai/clever'

import { getFileNameAndExtension } from '@/helpers'
import {
  ORDERS_CENTER_INVOCE_URL,
  ORDERS_CENTER_PAYMENT_URL,
  api,
} from '@/services'

import styles from './finance-form.module.css'
import type { FinanceFormProps } from './finance-form.type'
import type {
  OrderInvoiceProps,
  OrderPaymentProps,
  SaleOrderProps,
} from '@/types'

export const FinanceForm = ({
  onChange,
  values = {} as SaleOrderProps,
  onSuccessSubmit,
}: FinanceFormProps) => {
  const { t } = useTranslation('componentOrderCenterForm')
  const { t: tEnums } = useTranslation('enums')

  const [dataFilesInvoicePDF, setDataFilesInvoicePDF] = useState<
    FileListFileProps[]
  >([])

  const [dataFilesInvoiceXml, setDataFilesInvoiceXml] = useState<
    FileListFileProps[]
  >([])
  const [dataFilesProof, setDataFilesProof] = useState<FileListFileProps[]>([])

  useEffect(() => {
    if (values?.payments?.[0]?.proof) {
      const file = getFileNameAndExtension(values?.payments?.[0]?.proof || '')

      setDataFilesProof([
        {
          id: file.fileName,
          name: `${file.fileName}.${file.extension}`,
          url: values?.payments?.[0]?.proof,
          uploaded: true,
        },
      ])
    } else {
      setDataFilesProof([])
    }

    if (values?.invoices?.[0]?.pdfFile) {
      const file = getFileNameAndExtension(values?.invoices?.[0]?.pdfFile || '')

      setDataFilesInvoicePDF([
        {
          id: file.fileName,
          name: `${file.fileName}.${file.extension}`,
          url: values?.invoices?.[0]?.pdfFile,
          uploaded: true,
        },
      ])
    } else {
      setDataFilesInvoicePDF([])
    }

    if (values?.invoices?.[0]?.xmlFile) {
      const file = getFileNameAndExtension(values?.invoices?.[0]?.xmlFile || '')

      setDataFilesInvoiceXml([
        {
          id: file.fileName,
          name: `${file.fileName}.${file.extension}`,
          url: values?.invoices?.[0]?.xmlFile,
          uploaded: true,
        },
      ])
    } else {
      setDataFilesInvoiceXml([])
    }
  }, [values?.invoices, values?.payments])

  const onChangeInvoiceValues = (
    keyParam: keyof OrderInvoiceProps,
    value: string,
  ) => {
    const invoice = { ...values?.invoices?.[0] }
    const updatedInvoice = { ...invoice, [keyParam]: value }

    onChange({
      ...values,
      invoices: [updatedInvoice, ...values?.invoices.slice(1)],
    })
  }

  const onChangePaymentValues = (
    keyParam: keyof OrderPaymentProps,
    value: string,
  ) => {
    const invoice = { ...values?.payments?.[0] }
    const updatedPayment = { ...invoice, [keyParam]: value }

    onChange({
      ...values,
      payments: [updatedPayment, ...values?.payments.slice(1)],
    })
  }

  return (
    <div className={styles.core}>
      <div className={styles.title}>
        <MonetizationIcon />
        <Text variant="title-base">{t('financeTitle')}</Text>
      </div>

      <div className={styles.content}>
        <div className={styles['content-title']}>
          <Text variant="title-base">{t('invoiceTitle')}</Text>
          <Text>{t('invoiceDescription')}</Text>
        </div>

        <div className={styles.field}>
          <Label>{t('invoiceStatusLabel')}</Label>
          <Select
            options={[
              {
                label: tEnums('order_invoice_status_pending'),
                value: 'pending',
              },
              {
                label: tEnums('order_invoice_status_uploaded'),
                value: 'uploaded',
              },
              {
                label: tEnums('order_invoice_status_cancelled'),
                value: 'cancelled',
              },
            ]}
            value={values?.invoices?.[0]?.status}
            onChange={(newValue) => onChangeInvoiceValues('status', newValue)}
          />
        </div>

        <div className={styles.field}>
          <Label>{t('invoiceDateLabel')}</Label>
          <DatePicker
            value={values?.invoices?.[0]?.invoicedAt}
            onChange={(newValue) =>
              onChangeInvoiceValues('invoicedAt', newValue || '')
            }
          />
        </div>

        <div className={styles.field}>
          <Label>{t('invoicePDFLabel')}</Label>
          <DragDropFileList
            translations={{
              clickToUpload: t('uploadClickToUpload'),
              dragFile: t('uploadDragFile'),
              sizeFile: t('uploadSizeFile'),
            }}
            urlAdd={ORDERS_CENTER_INVOCE_URL(values?.invoices?.[0]?.uuid || '')}
            configsRequest={{
              fileField: 'pdf_file',
              method: 'PATCH',
            }}
            axiosInstance={api}
            data={dataFilesInvoicePDF}
            onChangeData={setDataFilesInvoicePDF}
            successedUpload={onSuccessSubmit}
          />
        </div>

        <div className={styles.field}>
          <Label>{t('invoiceXMLLabel')}</Label>
          <DragDropFileList
            translations={{
              clickToUpload: t('uploadClickToUpload'),
              dragFile: t('uploadDragFile'),
              sizeFile: t('uploadSizeFile'),
            }}
            urlAdd={ORDERS_CENTER_INVOCE_URL(values?.invoices?.[0]?.uuid || '')}
            configsRequest={{
              fileField: 'xml_file',
              method: 'PATCH',
            }}
            axiosInstance={api}
            data={dataFilesInvoiceXml}
            onChangeData={setDataFilesInvoiceXml}
            successedUpload={onSuccessSubmit}
          />
        </div>
      </div>

      <div className={styles.content}>
        <div className={styles['content-title']}>
          <Text variant="title-base">{t('paymentTitle')}</Text>
          <Text>{t('paymentDescription')}</Text>
        </div>

        <div className={styles.field}>
          <Label>{t('paymentStatusLabel')}</Label>
          <Select
            options={[
              {
                label: tEnums('order_payment_status_pending'),
                value: 'pending',
              },
              {
                label: tEnums('order_payment_status_waiting_approval'),
                value: 'waiting_approval',
              },
              {
                label: tEnums('order_payment_status_paid'),
                value: 'paid',
              },
              {
                label: tEnums('order_payment_status_invalid'),
                value: 'invalid',
              },
              {
                label: tEnums('order_payment_status_cancelled'),
                value: 'cancelled',
              },
            ]}
            value={values?.payments?.[0]?.status}
            onChange={(newValue) => onChangePaymentValues('status', newValue)}
          />
        </div>

        <div className={styles.field}>
          <Label>{t('paymentDateLabel')}</Label>
          <DatePicker
            value={values?.payments?.[0]?.paidAt}
            onChange={(newValue) =>
              onChangePaymentValues('paidAt', newValue || '')
            }
          />
        </div>

        <div className={styles.field}>
          <Label>{t('paymentProofLabel')}</Label>
          <DragDropFileList
            translations={{
              clickToUpload: t('uploadClickToUpload'),
              dragFile: t('uploadDragFile'),
              sizeFile: t('uploadSizeFile'),
            }}
            urlAdd={ORDERS_CENTER_PAYMENT_URL(
              values?.payments?.[0]?.uuid || '',
            )}
            configsRequest={{
              fileField: 'proof',
              method: 'PATCH',
            }}
            axiosInstance={api}
            data={dataFilesProof}
            onChangeData={setDataFilesProof}
            successedUpload={onSuccessSubmit}
          />
        </div>
      </div>
    </div>
  )
}
