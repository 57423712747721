/* eslint-disable */
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  DatePicker,
  DragDropFileList,
  InputNumber,
  Label,
  Select,
  SelectSearch,
  Text,
} from "@mercai/clever";

import {
  useValidationErrors,
  getFileNameAndExtension,
  cleanObject,
  onSuccessMessage,
  onErrorMessage,
} from "@/helpers";

import {
  TRANSACTION_URL,
  api,
  useProfileList,
  useTransactionDetail,
  useTransactionCreate,
  useTransactionUpdate,
} from "@/services";

import { schema } from "./form.constants";

import {
  ProfileProps,
  TransactionProps,
  TransactionPaymentMethodEnum,
} from "@/types";
import type { TransactionFormStepProps } from "./form.type";

import styles from "./form.module.css";

export const TransactionFormStep = ({
  uuid,
  onHandleBack,
  onSuccessSubmit,
}: TransactionFormStepProps) => {
  const { t } = useTranslation("screenOrderCenterPage");
  const { t: tEnums } = useTranslation("enums");

  const isNewRecord = !uuid;

  const [values, setValues] = useState<TransactionProps>(
    {} as TransactionProps
  );
  const [editedForm, setEditedForm] = useState(false);
  const [profileSearch, setSupplierSearch] = useState<string>("");

  const { data: dataSearch, isLoading: isLoadingSearch } = useProfileList({
    params: { search: profileSearch },
  });

  const { data: transactionDetail } = useTransactionDetail(uuid);
  const { mutateAsync: mutateAsyncCreate } = useTransactionCreate();
  const { mutateAsync: mutateAsyncUpdate } = useTransactionUpdate(uuid);

  const { isValid, errors, onHandleListenErrors } = useValidationErrors(
    schema(t),
    values
  );

  const onChangeValues = (keyParam: keyof TransactionProps, value?: string) => {
    if (!editedForm) {
      setEditedForm(true);
    }

    setValues({
      ...values,
      [keyParam]: value,
    });
  };

  const onSelectProfile = (payer: Partial<ProfileProps>) => {
    if (!editedForm) {
      setEditedForm(true);
    }

    setValues({
      ...values,
      payer: payer as ProfileProps,
      payerUuid: payer?.uuid,
    });
  };

  const getProofFile = () => {
    const file = getFileNameAndExtension(values?.proof || "");

    if (values?.proof) {
      return [
        {
          id: file.fileName,
          name: `${file.fileName}.${file.extension}`,
          url: values?.proof,
          uploaded: true,
          preview: values?.proof,
        },
      ];
    }

    return [];
  };

  const onHandleSubmit = async () => {
    if (!isValid) {
      onHandleListenErrors(true);
      return;
    }

    if (!editedForm && !isNewRecord) {
      onSuccessSubmit?.(values);

      return;
    }

    const mutateAsync = isNewRecord ? mutateAsyncCreate : mutateAsyncUpdate;

    const payload: Partial<TransactionProps> = {
      payerUuid: values?.payerUuid,
      value: values?.value,
      currency: values?.currency,
      destinationBank: values?.destinationBank,
      paymentMethod: values?.paymentMethod,
      paidAt: values?.paidAt,
    };

    const result = await mutateAsync(cleanObject(payload));

    if (result.success) {
      onSuccessMessage(
        t("formTransactionSubmitSuccessTitle"),
        t("formTransactionSubmitSuccessMessage")
      );

      onSuccessSubmit?.(result?.data as TransactionProps);
    } else {
      onErrorMessage(
        t("formTransactionSubmitErrorTitle"),
        t("formTransactionSubmitErrorMessage")
      );
    }
  };

  useEffect(() => {
    if (transactionDetail?.data) {
      if (!editedForm) {
        setValues(transactionDetail.data);
      }
    } else {
      setValues({} as TransactionProps);
    }

    return () => {
      setEditedForm(false);
    };
  }, [transactionDetail]);

  return (
    <>
      <div className={styles.content}>
        <div className={styles.field}>
          <SelectSearch<ProfileProps>
            dataSearch={dataSearch?.data?.results || []}
            isLoadingSearch={isLoadingSearch}
            onSearch={setSupplierSearch}
            onSelectItem={onSelectProfile}
            selectedItem={values?.payer}
            keyLabel="name"
            keyValue="uuid"
            translations={{
              inputSearchLabel: t("formTransactionStepFormPayer"),
              inputSearchPlaceholder: t(
                "formTransactionStepFormPayerPlaceholder"
              ),
              noResults: t("formTransactionStepFormPayerNoResults"),
            }}
            error={errors?.payer as string}
            renderContentItem={(item) => (
              <div className={styles["select-item"]}>
                <Text variant="title-base">{item.companyName || t("formTransactionStepFormPayerNoHasCompany")}</Text>
                <Text variant="text-small">{item.name}</Text>
              </div>
            )}
          />
        </div>

        <div className={styles.field}>
          <Label>{t("formTransactionStepFormAmount")}</Label>

          <div className={styles["field-row"]}>
            <Select
              onChange={(newValue) => onChangeValues("currency", newValue)}
              value={values?.currency}
              options={[
                {
                  label: "MXN",
                  value: "MXN",
                },
                {
                  label: "USD",
                  value: "USD",
                },
              ]}
              className={styles["field-row-currency"]}
              error={errors?.currency as string}
            />

            <InputNumber
              value={values?.value}
              onChange={(newValue) =>
                onChangeValues("value", newValue as string)
              }
              showIcons
              className={styles["field-row-input"]}
              prefix={values?.currency === "MXN" ? "MXN$ " : "$ "}
              error={errors?.value as string}
            />
          </div>
        </div>

        <div className={styles.field}>
          <Label>{t("formTransactionStepFormBank")}</Label>

          <Select
            value={values?.destinationBank}
            onChange={(newValue) => onChangeValues("destinationBank", newValue)}
            options={[
              {
                label: "Santander",
                value: "Santander",
              },
              {
                label: "SVB",
                value: "SVB",
              },
              {
                label: "BBVA",
                value: "BBVA",
              },
              {
                label: "Conekta",
                value: "conekta",
              },
            ]}
            error={errors?.destinationBank as string}
          />
        </div>

        <div className={styles.field}>
          <Label>{t("formTransactionStepFormPaymentMethod")}</Label>

          <Select
            options={Object.keys(TransactionPaymentMethodEnum).map((key) => ({
              label: tEnums(`transaction_payment_method_${key}`),
              value: key,
            }))}
            value={values?.paymentMethod}
            onChange={(newValue) => onChangeValues("paymentMethod", newValue)}
            error={errors?.paymentMethod as string}
          />
        </div>

        <div className={styles.field}>
          <Label>{t("formTransactionStepFormPaymentDate")}</Label>

          <DatePicker
            value={values?.paidAt}
            onChange={(newValue) => onChangeValues("paidAt", newValue)}
            error={errors?.paidAt as string}
          />
        </div>

        {uuid && (
          <div className={styles.field}>
            <Label>{t("formTransactionStepFormProof")}</Label>
            <DragDropFileList
              axiosInstance={api}
              translations={{
                clickToUpload: t("formTransactionStepFormUploadClickToUpload"),
                dragFile: t("formTransactionStepFormUploadDragFile"),
                sizeFile: t("formTransactionStepFormUploadSizeFile"),
              }}
              urlAdd={TRANSACTION_URL(uuid || "")}
              configsRequest={{
                fileField: "proof",
                method: "PATCH",
              }}
              initialData={getProofFile()}
              successedUpload={(data) => onChangeValues("proof", data?.proof)}
            />
          </div>
        )}
      </div>

      <div className={styles.footer}>
        <Button onClick={onHandleBack} variant="secondary">
          {t("formTransactionStepFormBack")}
        </Button>
        <Button onClick={onHandleSubmit}>
          {t("formTransactionStepFormNext")}
        </Button>
      </div>
    </>
  );
};
