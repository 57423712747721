import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Skeleton, AlertIcon, CheckCircleFillIcon } from '@mercai/clever'

import { QuotationRequestStatusEnum } from '@/types'

import type { QuotationRequestShowStatusAlertProps } from './status-alert.type'

export const QuotationRequestShowStatusAlert = ({
  status,
  isLoading,
}: QuotationRequestShowStatusAlertProps) => {
  const { t } = useTranslation('screenQuotationRequestShow')

  const statusObjects: Record<
    QuotationRequestStatusEnum,
    {
      alertVariant: 'primary' | 'danger' | 'success' | 'warning'
      title: string
      description: string
      icon: ReactNode
    }
  > = {
    approved_for_quotation: {
      alertVariant: 'warning',
      title: t('statusApprovedForQuotationTitle'),
      description: t('statusApprovedForQuotationDescription'),
      icon: <AlertIcon />,
    },
    completed: {
      alertVariant: 'success',
      title: t('statusCompletedTitle'),
      description: t('statusCompletedDescription'),
      icon: <CheckCircleFillIcon />,
    },
    disqualified: {
      alertVariant: 'danger',
      title: t('statusDisqualifiedTitle'),
      description: t('statusDisqualifiedDescription'),
      icon: <AlertIcon />,
    },
    expired: {
      alertVariant: 'danger',
      title: t('statusExpiredTitle'),
      description: t('statusExpiredDescription'),
      icon: <AlertIcon />,
    },
    pending: {
      alertVariant: 'primary',
      title: t('statusPendingTitle'),
      description: t('statusPendingDescription'),
      icon: <AlertIcon />,
    },
    invalid: {
      alertVariant: 'danger',
      title: t('statusInvalidTitle'),
      description: t('statusInvalidDescription'),
      icon: <AlertIcon />,
    },
    processed: {
      alertVariant: 'primary',
      title: t('statusProcessedTitle'),
      description: t('statusProcessedDescription'),
      icon: <CheckCircleFillIcon />,
    },
  }

  const statusObject =
    statusObjects?.[status || QuotationRequestStatusEnum.pending]

  if (isLoading) {
    return (
      <div>
        <Skeleton height="4rem" width="100%" />
      </div>
    )
  }

  return (
    <div>
      <Alert
        leftIcon={statusObject?.icon}
        variant={statusObject?.alertVariant}
        title={statusObject?.title}
      >
        {statusObject?.description}
      </Alert>
    </div>
  )
}
