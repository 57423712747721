import type { ProgressProps } from './progress.type'

import styles from './progress.module.css'

export const Progress = ({ percentage }: ProgressProps) => {
  return (
    <div className={styles.wrap}>
      <div className={styles.background} />
      <div
        className={styles.progress}
        style={{ width: `${percentage > 100 ? 100 : percentage}%` }}
      ></div>
    </div>
  )
}
