import {
  Input,
  InputPhone,
  Label,
  Tooltip,
  QuestionFillIcon,
  Radio,
} from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import type { SupplierFormProps } from './supplier-form.type'

import styles from './supplier-form.module.css'

export const SupplierForm = ({
  onChangeValues,
  values,
  errors,
}: SupplierFormProps) => {
  const { t } = useTranslation('screenSupplierList')

  const onHandleValues = (key: string, value: string | number | boolean) => {
    onChangeValues({ ...values, [key]: value })
  }

  return (
    <div className={styles.core}>
      <div>
        <Label>{t('supplierFormProfileFormNameLabel')}</Label>
        <Input
          placeholder={t('supplierFormProfileFormNamePlaceholder')}
          value={values?.name}
          onChange={(newValue) => onHandleValues('name', newValue)}
          error={errors?.name}
        />
      </div>

      <div>
        <Label>
          {t('supplierFormProfileFormEmailLabel')}{' '}
          <span>{t('optionalLabel')}</span>
        </Label>
        <Input
          placeholder={t('supplierFormProfileFormEmailPlaceholder')}
          value={values?.email}
          onChange={(newValue) => onHandleValues('email', newValue)}
          error={errors?.email}
        />
      </div>

      <div>
        <Label>
          {t('supplierFormProfileFormPhoneLabel')}
          <span>{t('optionalLabel')}</span>
        </Label>
        <InputPhone
          placeholder={t('supplierFormProfileFormPhonePlaceholder')}
          value={values?.phone}
          onChange={(newValue) => onHandleValues('phone', newValue)}
          error={errors?.phone}
        />
      </div>

      <div>
        <div>
          <Label>
            {t('supplierFormProfileFormForeignLabel')}
            <Tooltip
              text={t('supplierFormProfileFormForeignDescription')}
              widthText="16rem"
            >
              <QuestionFillIcon />
            </Tooltip>
          </Label>
        </div>

        <Radio
          value={!!values?.isForeign}
          onChange={() => onHandleValues('isForeign', true)}
        >
          {t('supplierFormProfileFormForeignOptionsYes')}
        </Radio>

        <Radio
          value={!values?.isForeign}
          onChange={() => onHandleValues('isForeign', false)}
        >
          {t('supplierFormProfileFormForeignOptionsNo')}
        </Radio>
      </div>
    </div>
  )
}
