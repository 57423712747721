'use client'
import 'react-international-phone/style.css'
import { PhoneInput } from 'react-international-phone'

import { Text } from '../text'

import styles from './input-phone.module.css'

import { InputPhoneProps } from './input-phone.type'

import { generateClasses } from './input-phone.constants'

export const InputPhone = ({
  className,
  disabled,
  onChange,
  size = 'medium',
  value,
  placeholder,
  defaultCountry = 'mx',
  error,
  onBlur,
}: InputPhoneProps) => {
  const classes = generateClasses(size, !!error)

  const getValue = () => {
    if (!value) {
      return ''
    }

    if (value?.includes('+')) {
      return value
    }

    return `+${value}`
  }

  const onChangeValue = (value: string) => {
    if (value === '+52') {
      return
    }

    onChange && onChange(value)
  }

  return (
    <div className={className}>
      <PhoneInput
        className={classes}
        defaultCountry={defaultCountry}
        disabled={disabled}
        onChange={onChangeValue}
        value={getValue()}
        placeholder={placeholder}
        inputStyle={{
          border: 'none',
          backgroundColor: 'transparent',
        }}
        onBlur={onBlur}
        countrySelectorStyleProps={{
          buttonStyle: {
            borderTop: 'none',
            borderBottom: 'none',
            borderLeft: 'none',
            backgroundColor: 'transparent',
            paddingLeft: '0.5rem',
            paddingRight: '0.25rem',
          },
        }}
      />

      {!error || <Text className={styles.error}>{error}</Text>}
    </div>
  )
}
