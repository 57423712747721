export enum CreditOrderStatusEnum {
  pending = 'pending',
  active = 'active',
  denied = 'denied',
  paid = 'paid',
  payment_overdue = 'payment_overdue',
  cancelled = 'cancelled',
}

export interface CreditOrderProps {
  debtorUuid: string
  amount: string
  paymentTerm: '15' | '30' | '45' | '60'
  orderUuid: string
  status: keyof typeof CreditOrderStatusEnum
  interest: string
  expectedPaymentDate: string
  paidAt: string
}
