import { Button, Text, PlusIcon, SelectBox } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import type { AddressListProps } from './address-list.type'

import styles from './adress-list.module.css'

export const AddressList = ({
  onClickNewAddress,
  addresses,
  onChangeAddressSelected,
  addressSelected,
}: AddressListProps) => {
  const { t } = useTranslation('screenQuotationList')

  return (
    <div className={styles.core}>
      <Text>{t('quotationFormAddressListDescription')}</Text>

      {addresses?.map((address) => (
        <SelectBox
          title={`${t('quotationFormAddressListDeliveryAt')} ${address?.city || address?.postalCode}`}
          onChange={() => onChangeAddressSelected(address.uuid)}
          value={addressSelected === address.uuid}
          key={address.uuid}
        >
          {`
            ${address.country ? `${address.country}` : ''} 
            ${address.state ? `, ${address.state}` : ''}
            ${address.city ? `, ${address.city}` : ''}
            ${address.neighborhood ? `, ${address.neighborhood}` : ''}
            ${address.street ? `, ${address.street}` : ''}
            ${address.number && address.number !== 0 ? `, ${address.number}` : ''}
            ${address.postalCode ? `, ${address.postalCode}` : ''}
            ${address.reference ? ` (${address.reference})` : ''}
          `}
        </SelectBox>
      ))}

      <Button onClick={onClickNewAddress} variant="light">
        <PlusIcon />
        {t('quotationFormAddressListButton')}
      </Button>
    </div>
  )
}
