'use client'
import { Text } from '../text'

import { InputDayProps } from './input-day.type'

import { generateButtonClasses } from './input-day.constants'

import styles from './input-day.module.css'

export const InputDay = ({
  className,
  onChange,
  value,
  id,
  error,
}: InputDayProps) => {
  const days = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
  ]

  return (
    <div className={className} id={id}>
      <div className={styles.core}>
        {days?.map((day) => (
          <button
            key={day}
            onClick={() => onChange && onChange(day)}
            className={generateButtonClasses(day === value)}
          >
            <Text>{day}</Text>
          </button>
        ))}
      </div>
      {!error || <Text className={styles.error}>{error}</Text>}
    </div>
  )
}
