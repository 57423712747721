'use client'
import { useEffect, useState, useContext, useRef, createContext } from 'react'
import type {
  DropDownContentProps,
  DropDownItemProps,
  DropDownRootProps,
  DropDownButtonTriggerProps,
  DropDownTriggerProps,
  DropDownContextProps,
} from './drop-down.type'

import { Button } from '../button'
import { ChevronDownIcon } from '../../../icons'

import { generateClassesOfContent } from './drop-down.constants'

import styles from './drop-down.module.css'

const DropDownContext = createContext<DropDownContextProps>({
  isActive: false,
  onHandleIsActive: () => null,
})

export const useDropDownContext = () => useContext(DropDownContext)

const Root = ({ children }: DropDownRootProps) => {
  const [isActive, setIsActive] = useState(false)

  const rootRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const closeOpennedDropDown = (e: MouseEvent) => {
      if (
        rootRef.current &&
        isActive &&
        !rootRef.current.contains(e.target as Node)
      ) {
        setIsActive(false)
      }
    }

    document.addEventListener('mousedown', closeOpennedDropDown)

    return () => document.removeEventListener('mousedown', closeOpennedDropDown)
  }, [isActive, rootRef])

  return (
    <DropDownContext.Provider
      value={{ isActive, onHandleIsActive: setIsActive }}
    >
      <div className={styles.root} ref={rootRef}>
        {children}
      </div>
    </DropDownContext.Provider>
  )
}

const ButtonTrigger = ({
  children,
  hasArrow = true,
  ...rest
}: DropDownButtonTriggerProps) => {
  const { onHandleIsActive, isActive } = useDropDownContext()

  return (
    <Button onClick={() => onHandleIsActive(!isActive)} {...rest}>
      {children}

      {hasArrow && <ChevronDownIcon className={styles['arrow-down-icon']} />}
    </Button>
  )
}

const Trigger = ({ children, className, ...rest }: DropDownTriggerProps) => {
  const { onHandleIsActive, isActive } = useDropDownContext()

  return (
    <button
      onClick={() => onHandleIsActive(!isActive)}
      className={`${styles.trigger} ${className || ''}`}
      {...rest}
    >
      {children}
    </button>
  )
}

const Content = ({
  children,
  orientation = 'bottom',
  className,
}: DropDownContentProps) => {
  const { isActive } = useDropDownContext()

  const generatedClasses = generateClassesOfContent(
    orientation,
    isActive,
    className,
  )

  return <div className={generatedClasses}>{children}</div>
}

const Item = ({ children, onClick }: DropDownItemProps) => {
  return (
    <button className={styles.item} onClick={onClick}>
      {children}
    </button>
  )
}

export const DropDown = {
  Root,
  ButtonTrigger,
  Trigger,
  Content,
  Item,
}
