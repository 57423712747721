import { createElement } from 'react'
import { Link } from 'react-router-dom'

import { TextComponents } from './text.constants'

import styles from './text.module.css'

import type { TextProps } from './text.type'

const Text = ({
  children,
  className,
  variant = 'text-base',
  ...rest
}: TextProps) => {
  const tagName = TextComponents[variant].htmlTag

  const classNameVariant = styles[variant]
  const classNames = `${classNameVariant} ${styles['text-core']} ${
    className ?? ''
  }`

  if (variant === 'link') {
    return (
      <Link {...rest} to={`${rest.href}`} className={classNames}>
        {children}
      </Link>
    )
  }

  return createElement(
    tagName,
    {
      className: classNames,
      ...rest,
    },
    children,
  )
}

export default Text
