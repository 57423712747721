import {
  Button,
  InputMask,
  InputNumber,
  Label,
  Select,
  Radio,
  Tooltip,
  QuestionFillIcon,
} from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { useValidationErrors } from '@/helpers'

import { schemaQuotation } from './quotation-form.constants'

import type { QuotationFormProps } from './quotation-form.type'

import styles from './quotation-form.module.css'

export const QuotationForm = ({
  onChangeValues,
  values,
  onNavigateToPreviousStep,
  onHandleSubmit,
  onHandleSubmitCompleted,
  isNewRegister,
  disabled,
}: QuotationFormProps) => {
  const { t } = useTranslation('screenQuotationList')
  const { t: tEnums } = useTranslation('enums')

  const { isValid, errors, onHandleListenErrors } = useValidationErrors(
    schemaQuotation(t),
    values,
  )

  const onHandleValues = (key: string, value: string | number | boolean) => {
    onChangeValues({ ...values, [key]: value })
  }

  const onHandleSubmitLocal = () => {
    if (!isValid) {
      onHandleListenErrors(true)
      return
    }

    onHandleListenErrors(false)
    onHandleSubmit()
  }

  return (
    <>
      <div className={styles.core}>
        <div>
          <Label>{t('quotationFormStatusLabel')}</Label>
          <Select
            placeholder={t('quotationFormStatusPlaceholder')}
            value={values?.status}
            error={errors?.status as string}
            onChange={(newValue) => onHandleValues('status', newValue)}
            options={[
              {
                label: tEnums('quotation_status_pending'),
                value: 'pending',
              },
              {
                label: tEnums('quotation_status_active'),
                value: 'active',
              },
              {
                label: tEnums('quotation_status_accepted'),
                value: 'accepted',
              },
              {
                label: tEnums('quotation_status_rejected'),
                value: 'rejected',
              },
              {
                label: tEnums('quotation_status_cancelled'),
                value: 'cancelled',
              },
              {
                label: tEnums('quotation_status_expired'),
                value: 'expired',
              },
            ]}
          />
        </div>
        {values?.status === 'rejected' && (
          <div>
            <Label>{t('quotationFormRejectedReasonLabel')}</Label>

            <Select
              placeholder={t('quotationFormRejectedReasonPlaceholder')}
              value={values?.rejectedReason}
              error={errors?.rejectedReason as string}
              onChange={(newValue) =>
                onHandleValues('rejectedReason', newValue)
              }
              options={[
                {
                  label: 'Tiempo de entrega demasiado largo.',
                  value: 'Tiempo de entrega demasiado largo.',
                },
                {
                  label: 'No es el producto que necesito.',
                  value: 'No es el producto que necesito.',
                },
                {
                  label: 'Tengo una oferta mejor.',
                  value: 'Tengo una oferta mejor.',
                },
                {
                  label: 'No estoy seguro si es el producto que necesito.',
                  value: 'No estoy seguro si es el producto que necesito.',
                },
                {
                  label: 'Otro',
                  value: 'Otro',
                },
              ]}
            />
          </div>
        )}
        <div>
          <Label>{t('quotationFormExpiresAtLabel')}</Label>
          <InputMask
            placeholder={t('quotationFormExpiresAtPlaceholder')}
            value={values?.expiresAt}
            onChange={(newValue) => onHandleValues('expiresAt', newValue)}
            error={errors?.expiresAt as string}
            mask={'99/99/9999 99:99'}
          />
        </div>
        <div>
          <Label>{t('quotationFormDollarQuotationLabel')} </Label>
          <InputNumber
            placeholder={t('quotationFormDollarQuotationPlaceholder')}
            value={values?.dollarExchangeRate || 0}
            error={errors?.dollarExchangeRate as string}
            disabled
            decimalPrecision={4}
            prefix={'MXN$ '}
            stepSize={1}
            showIcons
            onChange={(newValue) =>
              onHandleValues('dollarExchangeRate', newValue)
            }
          />
        </div>
        <div>
          <Label>
            {t('quotationFormDiscountLabel')}{' '}
            <span>{t('quotationFormOptionalLabel')}</span>
          </Label>
          <InputNumber
            placeholder={t('quotationFormDiscountPlaceholder')}
            value={values?.discount ? +values.discount : 0}
            error={errors?.discount as string}
            prefix={'$ '}
            stepSize={100}
            showIcons
            decimalPrecision={2}
            onChange={(newValue) => onHandleValues('discount', newValue)}
          />
        </div>
        <div>
          <Label>{t('quotationFormTotalFreightPriceLabel')}</Label>
          <InputNumber
            placeholder={t('quotationFormTotalFreightPricePlaceholder')}
            value={values?.totalFreightPrice || 0}
            error={errors?.totalFreightPrice as string}
            prefix={'$ '}
            min={0}
            stepSize={100}
            showIcons
            decimalPrecision={2}
            onChange={(newValue) =>
              onHandleValues('totalFreightPrice', newValue)
            }
          />
        </div>

        <div>
          <Label>{t('quotationFormExpectedDeliveryDaysLabel')} </Label>
          <InputNumber
            placeholder={t('quotationFormExpectedDeliveryDaysPlaceholder')}
            value={values?.expectedDeliveryDays || 0}
            error={errors?.expectedDeliveryDays as string}
            decimalPrecision={0}
            min={0}
            suffix={t('quotationFormExpectedDeliveryDaysSuffix')}
            stepSize={1}
            showIcons
            onChange={(newValue) =>
              onHandleValues('expectedDeliveryDays', newValue)
            }
          />
        </div>

        <div>
          <Label>
            {t('quotationFormIncludeIvaLabel')}
            <Tooltip
              text={t('quotationFormIncludeIvaDescription')}
              widthText="15rem"
            >
              <QuestionFillIcon />
            </Tooltip>
          </Label>

          <Radio
            value={!!values?.includeIva}
            onChange={() => onHandleValues('includeIva', true)}
          >
            {t('quotationFormIncludeIvaOptionsYes')}
          </Radio>

          <Radio
            value={!values?.includeIva}
            onChange={() => onHandleValues('includeIva', false)}
          >
            {t('quotationFormIncludeIvaOptionsNo')}
          </Radio>
        </div>

        <div className={styles['card-backoffice']}>
          <div>
            <Label>
              {t('quotationFormFirstMileFreightCostLabel')}
              <Tooltip
                text={t('quotationFormCIADescription')}
                widthText="16rem"
              >
                <QuestionFillIcon />
              </Tooltip>
            </Label>
            <InputNumber
              placeholder={t('quotationFormFirstMileFreightCostPlaceholder')}
              value={values?.firstMileFreightCost || 0}
              error={errors?.firstMileFreightCost as string}
              prefix={'$ '}
              stepSize={100}
              min={0}
              showIcons
              decimalPrecision={2}
              onChange={(newValue) =>
                onHandleValues('firstMileFreightCost', newValue)
              }
            />
          </div>
          <div>
            <Label>
              {t('quotationFormLastMileFreightCostLabel')}
              <Tooltip
                text={t('quotationFormCIADescription')}
                widthText="16rem"
              >
                <QuestionFillIcon />
              </Tooltip>
            </Label>
            <InputNumber
              placeholder={t('quotationFormLastMileFreightCostPlaceholder')}
              value={values?.lastMileFreightCost || 0}
              error={errors?.lastMileFreightCost as string}
              prefix={'$ '}
              stepSize={100}
              min={0}
              showIcons
              decimalPrecision={2}
              onChange={(newValue) =>
                onHandleValues('lastMileFreightCost', newValue)
              }
            />
          </div>
          <div>
            <Label>
              {t('quotationFormCustomsFreightCostLabel')}
              <Tooltip
                text={t('quotationFormCIADescription')}
                widthText="16rem"
              >
                <QuestionFillIcon />
              </Tooltip>
            </Label>
            <InputNumber
              placeholder={t('quotationFormCustomsFreightCostPlaceholder')}
              value={values?.customsFreightCost || 0}
              error={errors?.customsFreightCost as string}
              prefix={'$ '}
              stepSize={100}
              min={0}
              showIcons
              decimalPrecision={2}
              onChange={(newValue) =>
                onHandleValues('customsFreightCost', newValue)
              }
            />
          </div>

          <div>
            <div>
              <Label>
                {t('quotationFormPurchaseOnCreditLabel')}
                <Tooltip
                  text={t('quotationFormPurchaseOnCreditDescription')}
                  widthText="16rem"
                >
                  <QuestionFillIcon />
                </Tooltip>
              </Label>
            </div>

            <Radio
              value={!!values?.purchaseOnCredit}
              onChange={() => onHandleValues('purchaseOnCredit', true)}
            >
              {t('quotationFormPurchaseOnCreditOptionsYes')}
            </Radio>

            <Radio
              value={!values?.purchaseOnCredit}
              onChange={() => onHandleValues('purchaseOnCredit', false)}
            >
              {t('quotationFormPurchaseOnCreditOptionsNo')}
            </Radio>
          </div>

          {values?.purchaseOnCredit && (
            <div>
              <Label>
                {t('quotationFormFinancingCostLabel')}
                <Tooltip
                  text={t('quotationFormCIADescription')}
                  widthText="16rem"
                >
                  <QuestionFillIcon />
                </Tooltip>
              </Label>
              <InputNumber
                placeholder={t('quotationFormFinancingCostPlaceholder')}
                value={values?.financingCost ? values?.financingCost * 100 : 0}
                error={errors?.financingCost as string}
                suffix={'%'}
                stepSize={1}
                min={0}
                showIcons
                decimalPrecision={2}
                onChange={(newValue) =>
                  onHandleValues(
                    'financingCost',
                    (+(newValue || 1) / 100).toFixed(4),
                  )
                }
              />
            </div>
          )}
        </div>
      </div>
      <div className={styles.footer}>
        <Button variant="secondary" onClick={onNavigateToPreviousStep}>
          {t('navigateButtonBack')}
        </Button>

        <Button onClick={onHandleSubmitLocal}>{t('navigateButtonNext')}</Button>

        {!isNewRegister &&
          (disabled ? (
            <Tooltip
              widthText="200px"
              text={t('quotationFormDisabledButtonFinishTooltip')}
              orientation="top"
            >
              <Button disabled>{t('navigateButtonFinish')}</Button>
            </Tooltip>
          ) : (
            <Button
              variant="light"
              onClick={onHandleSubmitCompleted}
              disabled={disabled}
            >
              {t('navigateButtonFinish')}
            </Button>
          ))}
      </div>
    </>
  )
}
