import { useState } from 'react'
import { Button, Text, DownloadIcon, CopyIcon, CheckIcon } from '@mercai/clever'

import {
  copyTextToClipboard,
  downloadFile,
  getFileNameAndExtension,
} from '@/helpers'
import { useFileSign } from '@/services'

import styles from './card-donwload.module.css'

import type { CardDownloadProps } from './card-download.type'

export const CardDownload = ({
  url: urlReceived,
  translations,
}: CardDownloadProps) => {
  const [isCopied, setIsCopied] = useState(false)

  const { mutateAsync, isPending } = useFileSign()

  const getUrl = async () => {
    if (!urlReceived) return ''

    if (!urlReceived.includes('http')) {
      const response = await mutateAsync({
        filePath: urlReceived || '',
      })

      if (!response?.data?.signedUrl) return ''

      return response?.data?.signedUrl || ''
    }

    return urlReceived
  }

  const handleDownloadFile = async () => {
    const url = await getUrl()

    if (!url) return null

    const file = getFileNameAndExtension(url || '')

    downloadFile(url, `${file.fileName}.${file.extension}`)
  }

  const onHandleCopy = async () => {
    const url = await getUrl()

    if (!url) return null

    try {
      const success = copyTextToClipboard(`${url}`)

      setIsCopied(success)

      setTimeout(() => {
        setIsCopied(false)
      }, 5000)
    } catch (err) {
      console.error(err)
    }
  }

  const onHandleClick = async () => {
    const url = await getUrl()

    if (!url) return null

    window.open(url, '_blank')
  }

  return (
    <div className={styles.core}>
      <div className={styles.content}>
        <Text variant="title-base" className={styles.title}>
          {translations.title}
        </Text>
      </div>

      <div className={styles['buttons-wrap-column']}>
        <Button onClick={onHandleClick} className={styles.buttons}>
          {translations.viewButton}
        </Button>

        <div className={styles['buttons-wrap-line']}>
          <Button
            variant="secondary"
            className={styles.buttons}
            onClick={handleDownloadFile}
          >
            <DownloadIcon className={styles['buttons-icon']} />
            {isPending ? translations.loading : translations.downloadButton}
          </Button>

          <Button
            variant="secondary"
            onClick={onHandleCopy}
            className={styles.buttons}
          >
            {isCopied ? (
              <CheckIcon className={styles['copy-check-icon']} />
            ) : (
              <CopyIcon />
            )}
            {isCopied
              ? translations.copyButtonSuccess
              : translations.copyButton}
          </Button>
        </div>
      </div>
    </div>
  )
}
