import { useTranslation } from 'react-i18next'
import { Button, Card, Text, Skeleton, Checkbox } from '@mercai/clever'

import {
  QuotationRequestItemListItem,
  QuotationRequestItemListItemLoading,
} from '../item-list-item'

import type { QuotationRequestListItemsProps } from './list-items.type'

import styles from './list-items.module.css'

export const QuotationRequestListItems = ({
  items,
  isLoading,
  onSelectItems,
  selectedItems,
  onClickQuote,
}: QuotationRequestListItemsProps) => {
  const { t } = useTranslation('screenQuotationRequestShow')

  const onHandleSelectItem = (uuid: string) => {
    if (selectedItems?.includes(uuid)) {
      onSelectItems?.(selectedItems?.filter((item) => item !== uuid))
    } else {
      onSelectItems?.([...(selectedItems || []), uuid])
    }
  }

  const onHandleSelectAll = () => {
    if (selectedItems?.length === items?.length) {
      onSelectItems?.([])
    } else {
      onSelectItems?.(items?.map((item) => item?.uuid || '') || [])
    }
  }

  const getSelectedAll = () => {
    return selectedItems?.length === items?.length
  }

  const showList = items && items?.length > 0

  if (isLoading) {
    return (
      <div className={styles.core}>
        <Skeleton height="1.25rem" width="12rem" />

        <div className={styles.list}>
          {[0, 1, 2, 3, 4, 5]?.map((item) => (
            <QuotationRequestItemListItemLoading key={item} />
          ))}
        </div>
      </div>
    )
  }

  return (
    <div className={styles.core}>
      <div className={styles['title-wrap']}>
        <Text variant="text-large-bold">{t('listItemsTitle')}</Text>

        <Button
          disabled={(selectedItems?.length || 0) < 1 && showList}
          onClick={onClickQuote}
        >
          {t('listItemsButtonQuote')}
        </Button>
      </div>

      {showList ? (
        <>
          <Card className={styles['selected-all']}>
            <Checkbox
              onChange={onHandleSelectAll}
              value={getSelectedAll()}
              size="large"
            >
              {t('listItemsSelectAll')}
            </Checkbox>

            <Text>
              {t('listItemsSelected', { number: selectedItems?.length || 0 })}
            </Text>
          </Card>

          <div className={styles.list}>
            {items?.map((item) => (
              <QuotationRequestItemListItem
                onSelect={() => onHandleSelectItem(item?.uuid || '')}
                selected={selectedItems?.includes(item?.uuid || '')}
                key={item.uuid}
                {...item}
              />
            ))}
          </div>
        </>
      ) : (
        <div className={styles['empty-box']}>
          <img
            src="/images/quotation-request-empty-quotations.png"
            alt="empty quotations"
          />

          <div className={styles['empty-box-content']}>
            <Text variant="title-base">{t('listItemsEmptyTitle')}</Text>
            <Text>{t('listItemsEmptyDescription')}</Text>
          </div>
        </div>
      )}
    </div>
  )
}
