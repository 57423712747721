import { useState } from 'react'

import { Skeleton, Select, Text } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { formatCurrency } from '@/helpers'

import styles from './summary-values.module.css'
import type { SummaryValuesProps } from './summary-values.type'

const OrderSummaryValuesDefault = ({
  totalFreight = 0,
  iva = 0,
  subTotal = 0,
  total = 0,
  discount = 0,
  dollarQuotation = 0,
}: SummaryValuesProps) => {
  const { t } = useTranslation('componentOrderCenterShow')
  const [currency, setCurrency] = useState('USD')

  return (
    <div className={styles.wrap}>
      <Text variant="text-large-bold">{t('summaryValuesAmounts')}</Text>

      <div>
        <div className={styles['line-row']}>
          <Text>{t('summaryValuesShowValues')}</Text>

          <Select
            className={styles.select}
            size="small"
            options={[
              { value: 'USD', label: 'USD' },
              { value: 'MXN', label: 'MXN' },
            ]}
            value={currency}
            onChange={setCurrency}
          />
        </div>

        <div className={styles['line-row']}>
          <Text>{t('summaryValuesDollarQuotation')}</Text>
          <Text variant="title-base">
            {formatCurrency(dollarQuotation || 0, 'es-MX', 'MXN', 4)}
          </Text>
        </div>
      </div>

      <div className={styles.summary}>
        <div className={styles['summary-row']}>
          <Text> {t('summaryValuesSubTotal')}</Text>

          <Text variant="title-base">
            {formatCurrency(
              currency === 'MXN' ? subTotal * dollarQuotation : subTotal,
            )}
          </Text>
        </div>

        <div className={styles['summary-row']}>
          <Text>{t('summaryValuesDiscount')}</Text>
          <Text variant="title-base">
            {formatCurrency(
              currency === 'MXN' ? discount * dollarQuotation : discount,
            )}
          </Text>
        </div>

        <div className={styles['summary-row']}>
          <Text>{t('summaryValuesIVA')}</Text>
          <Text variant="title-base">
            {formatCurrency(currency === 'MXN' ? iva * dollarQuotation : iva)}
          </Text>
        </div>

        <div className={styles['summary-row']}>
          <Text>{t('summaryValuesDelivery')}</Text>
          <Text variant="title-base">
            {formatCurrency(
              currency === 'MXN'
                ? totalFreight * dollarQuotation
                : totalFreight,
            )}
          </Text>
        </div>

        <div className={styles['summary-row-total']}>
          <Text variant="title-large">{t('summaryValuesAmount')}</Text>
          <Text variant="title-large">
            {formatCurrency(
              currency === 'MXN' ? total * dollarQuotation : total,
            )}
          </Text>
        </div>
      </div>
    </div>
  )
}

const OrderSummaryValuesLoading = () => {
  return (
    <div className={styles.wrap}>
      <Text variant="text-large-bold">
        <Skeleton width="10rem" height="1.5rem" />
      </Text>

      <div>
        <div className={styles['line-row']}>
          <Text>
            <Skeleton width="8rem" height="1rem" />
          </Text>

          <Skeleton width="5rem" height="2rem" />
        </div>

        <div className={styles['line-row']}>
          <Text>
            <Skeleton width="10rem" height="1rem" />
          </Text>
          <Text variant="title-base">
            <Skeleton width="6rem" height="1rem" />
          </Text>
        </div>
      </div>

      <div className={styles.summary}>
        {['subTotal', 'discount', 'iva', 'delivery', 'amount'].map(
          (item, index) => (
            <div key={item} className={styles['summary-row']}>
              <Text>
                <Skeleton width="7rem" height="1rem" />
              </Text>
              <Text variant="title-base">
                <Skeleton width={index === 4 ? '8rem' : '6rem'} height="1rem" />
              </Text>
            </div>
          ),
        )}
      </div>
    </div>
  )
}

export const OrderSummaryValues = (props: SummaryValuesProps) => {
  if (props.isLoading) {
    return <OrderSummaryValuesLoading />
  }

  return <OrderSummaryValuesDefault {...props} />
}
