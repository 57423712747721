import styles from './monthly-filter.module.css'

const monthlyItem: Record<'active' | 'inactive', string> = {
  active: styles.active,
  inactive: '',
}

export const generateClassesOfItem = (isActive: boolean = false) => {
  return `${styles.item} ${monthlyItem[isActive ? 'active' : 'inactive']}`
}
