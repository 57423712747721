import DatePickerLib from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './date-picker.css'

import { es } from 'date-fns/locale'
import { Text } from '../text'
import { DatePickerProps, DatePickerSizeEnum } from './date-picker.type'
import { generateClasses } from './date-picker.constants'
import styles from './date-picker.module.css'

export const DatePicker = ({
  className,
  disabled,
  onChange = () => null,
  size = DatePickerSizeEnum.medium,
  value = '',
  placeholder,
  id,
  error,
  onBlur,
  hasTime = false,
}: DatePickerProps) => {
  const classes = generateClasses(size, !!error, className)

  const parsedValue = value ? new Date(value) : null

  const isValidDate =
    parsedValue instanceof Date && !Number.isNaN(parsedValue.getTime())

  const handleChange = (date: Date | null) => {
    if (!date) {
      onChange('')
      return
    }

    const timestamp = date.getTime()
    const offset = date.getTimezoneOffset() * 60000

    const adjustedDate = new Date(timestamp - offset)

    if (hasTime) {
      onChange(adjustedDate.toISOString())
    } else {
      onChange(adjustedDate.toISOString().split('T')[0])
    }
  }

  const getValue = () => {
    if (!isValidDate) {
      return null
    }

    const timestamp = parsedValue.getTime()
    const offset = parsedValue.getTimezoneOffset() * 60000
    const adjustedDate = new Date(timestamp + offset)

    return adjustedDate
  }

  return (
    <>
      <div className={classes}>
        <DatePickerLib
          locale={es}
          selected={getValue()}
          onChange={handleChange}
          disabled={disabled}
          placeholderText={placeholder}
          id={id}
          onBlur={onBlur}
          dateFormat={hasTime ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy'}
          autoComplete="off"
          showTimeSelect={hasTime}
          timeFormat="HH:mm"
          timeIntervals={15}
          timeCaption="Time"
        />
      </div>
      {error && <Text className={styles.error}>{error}</Text>}
    </>
  )
}
