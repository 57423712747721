import {
  SummaryValuesRootProps,
  SummaryValuesRowProps,
  SummaryValuesColumnProps,
  SummaryValuesColumnDirectionEnums,
  SummaryValuesRowTypeEnums,
} from './summary-values.type'

import {
  generateClassesRowTypes,
  generateClassesColumnTypes,
} from './summary-values.constants'

import styles from './summary-values.module.css'

const Root = ({ className, children }: SummaryValuesRootProps) => {
  return <ul className={`${styles.core} ${className}`}>{children}</ul>
}

const Row = ({
  className,
  children,
  type = SummaryValuesRowTypeEnums.normal,
}: SummaryValuesRowProps) => {
  const rowClasses = generateClassesRowTypes(type, className)

  return <li className={rowClasses}>{children}</li>
}

const Column = ({
  className,
  children,
  type,
  direction = SummaryValuesColumnDirectionEnums.left,
}: SummaryValuesColumnProps) => {
  const columnClasses = generateClassesColumnTypes(type, direction, className)

  return <span className={columnClasses}>{children}</span>
}

const SummaryValues = {
  Root,
  Row,
  Column,
}

export default SummaryValues
