import { Text, Skeleton, CalendarIcon } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { formatDateWithTime } from '@/helpers'

import styles from './timeline-status.module.css'

import type { OrderTimelineStatusProps } from './timeline-status.type'

const OrderTimelineStatusDefault = ({
  statusHistory,
}: OrderTimelineStatusProps) => {
  const { t } = useTranslation('screenOrderList')
  const { t: tEnums } = useTranslation('enums')

  return (
    <div className={styles.core}>
      <div className={styles.title}>
        <CalendarIcon />
        <Text variant="title-base" className={styles.title}>
          {t('orderShowTitleTimelineStatus')}
        </Text>
      </div>

      {statusHistory ? (
        <div className={styles.content}>
          {statusHistory?.pendingBuyerData && (
            <Text>
              {tEnums('order_status_pending_buyer_data')}:{' '}
              {formatDateWithTime(statusHistory?.pendingBuyerData)}{' '}
            </Text>
          )}

          {statusHistory?.pendingInvoice && (
            <Text>
              {tEnums('order_status_pending_invoice')}:{' '}
              {formatDateWithTime(statusHistory?.pendingInvoice)}{' '}
            </Text>
          )}

          {statusHistory?.pendingPayment && (
            <Text>
              {tEnums('order_status_pending_payment')}:{' '}
              {formatDateWithTime(statusHistory?.pendingPayment)}{' '}
            </Text>
          )}

          {statusHistory?.waitingPaymentApproval && (
            <Text>
              {tEnums('order_status_waiting_payment_approval')}:{' '}
              {formatDateWithTime(statusHistory?.waitingPaymentApproval)}{' '}
            </Text>
          )}

          {statusHistory?.paid && (
            <Text>
              {tEnums('order_status_paid')}:{' '}
              {formatDateWithTime(statusHistory?.paid)}{' '}
            </Text>
          )}

          {statusHistory?.partialDelivered && (
            <Text>
              {tEnums('order_status_partial_delivered')}:{' '}
              {formatDateWithTime(statusHistory?.partialDelivered)}{' '}
            </Text>
          )}

          {statusHistory?.delivered && (
            <Text>
              {tEnums('order_status_delivered')}:{' '}
              {formatDateWithTime(statusHistory?.delivered)}{' '}
            </Text>
          )}
        </div>
      ) : (
        <div>
          <Text>{t('orderShowTimelineEmpty')}</Text>
        </div>
      )}
    </div>
  )
}

const OrderTimelineStatusLoading = () => {
  return (
    <div className={styles.core}>
      <div className={styles.title}>
        <Skeleton height="1.5rem" width="1.5rem" />
        <Skeleton height="1rem" width="8rem" />
      </div>

      <div className={styles.content}>
        <Skeleton height="1rem" width="100%" />
        <Skeleton height="1rem" width="100%" />
        <Skeleton height="1rem" width="100%" />
        <Skeleton height="1rem" width="100%" />
      </div>
    </div>
  )
}

export const OrderTimelineStatus = ({
  isLoading,
  ...rest
}: OrderTimelineStatusProps) => {
  if (isLoading) {
    return <OrderTimelineStatusLoading />
  }

  return <OrderTimelineStatusDefault {...rest} />
}
