import { Button, Card, Skeleton, Text, SparksIcon } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import {} from '@/helpers'

import { ROUTES_ADMIN } from '@/router'

import styles from './product-list-item.module.css'

import type { ProductListItemProps } from './product-list-item.type'

export const ProductListItem = ({
  reference,
  attributes,
  category,
  onEditItem,
  onShowItem,
  onEnhanceItem,
  isLoadingEnhancement,
  enhancementItem,
  uuid,
  onUpdateImage,
  isLoadingUpdateImage,
}: ProductListItemProps) => {
  const { t } = useTranslation('componentProductListItem')
  const { t: tEnums } = useTranslation('enums')

  const getMainImage = () => {
    const findedThumb = attributes?.mainImageThumbnails?.find((item) =>
      item.includes('640-640'),
    )

    if (findedThumb) {
      return findedThumb
    }

    if (!attributes?.mainImage) {
      return '/images/product-placeholder.webp'
    }

    return attributes?.mainImage
  }

  return (
    <Card className={styles.core}>
      {!!enhancementItem && (
        <span className={styles.ribbon}>
          <SparksIcon />
          <Text variant="text-small">
            {t('enhancementStatusLabel')}:{' '}
            {tEnums(`product_processor_status_${enhancementItem.status}`)}
          </Text>
        </span>
      )}

      <img src={getMainImage()} alt="placeholder" className={styles.img} />

      <div className={styles.content}>
        <Text variant="title-base">
          {String(attributes?.title).toLocaleUpperCase()}
        </Text>

        <div className={styles.attributes}>
          {reference && (
            <div className={styles.row}>
              <Text>
                <strong>{t('referenceLabel')}</strong> {reference}
              </Text>
            </div>
          )}

          {attributes?.fluidity && (
            <div className={styles.row}>
              <Text>
                <strong>{t('fluidityLabel')}</strong> {attributes?.fluidity}{' '}
                g/10m
              </Text>
            </div>
          )}

          {attributes?.density && (
            <div className={styles.row}>
              <Text>
                <strong>{t('densityLabel')}</strong> {attributes?.density} g/cm³
              </Text>
            </div>
          )}

          {Array.isArray(attributes?.process) && (
            <div className={styles.row}>
              <Text>
                <strong>{t('processLabel')}</strong>{' '}
                {Array.isArray(attributes?.process)
                  ? attributes?.process?.map((item) => item?.value).join(', ')
                  : '-'}
              </Text>
            </div>
          )}

          <div className={styles.row}>
            <Text>
              <strong>{t('categoryLabel')}</strong> {category?.name}
            </Text>
          </div>

          {attributes?.brand && (
            <div className={styles.row}>
              <Text>
                <strong>{t('brandLabel')}</strong>{' '}
                {attributes?.brand?.value || '-'}
              </Text>
            </div>
          )}

          {attributes?.certifications && (
            <div className={styles.certifications}>
              {attributes?.certifications?.map((item) => (
                <div key={item?.value} className={styles.certification}>
                  <img
                    src={item.imageUrl}
                    alt={item.value}
                    height={24}
                    width={24}
                  />
                </div>
              ))}
            </div>
          )}

          {attributes?.quantityPerPackage && (
            <div className={styles.row}>
              <Text>
                <strong>{t('quantityPerPackageLabel')}</strong>{' '}
                {attributes?.quantityPerPackage || '-'}
              </Text>
            </div>
          )}

          {attributes?.size && (
            <div className={styles.row}>
              <Text>
                <strong>{t('sizeLabel')}</strong> {attributes?.size || '-'}
              </Text>
            </div>
          )}
        </div>
      </div>

      <div className={styles.buttons}>
        <Button variant="light" onClick={() => onEditItem(uuid)}>
          {t('buttonEditProduct')}
        </Button>
        <Button variant="secondary" onClick={() => onShowItem(uuid)}>
          {t('buttonViewProduct')}
        </Button>
        {onUpdateImage && (
          <Button
            variant="secondary"
            onClick={() => onUpdateImage(reference || '')}
            disabled={isLoadingUpdateImage}
          >
            {t('buttonUpdadeImageProduct')}
          </Button>
        )}

        {onEnhanceItem && (
          <div>
            {enhancementItem ? (
              <Button
                variant="secondary"
                href={`${ROUTES_ADMIN.CATALOG.PROCESSOR.fullPath()}?uuid=${enhancementItem.uuid}&edit=true`}
                target="_blank"
              >
                {t('buttonEnhanceProductLink')}
              </Button>
            ) : (
              <Button
                variant="secondary"
                onClick={() => onEnhanceItem(reference || '')}
                disabled={isLoadingEnhancement || !!enhancementItem}
              >
                {t('buttonEnhanceProduct')}
              </Button>
            )}
          </div>
        )}
      </div>
    </Card>
  )
}

export const ProductListItemLoading = () => {
  return (
    <Card className={styles.core}>
      <Skeleton className={styles.img} height="14.25rem" width="14.25rem" />
      <div className={styles.content}>
        <Skeleton className={styles.img} height="1.2rem" width="15rem" />

        <div className={styles.attributes}>
          <div className={styles.row}>
            <Skeleton className={styles.img} height="1.2rem" width="5rem" />
            <Skeleton className={styles.img} height="1.2rem" width="8rem" />
          </div>

          <div className={styles.row}>
            <Skeleton className={styles.img} height="1.2rem" width="4.5rem" />
            <Skeleton className={styles.img} height="1.2rem" width="8rem" />
          </div>

          <div className={styles.row}>
            <Skeleton className={styles.img} height="1.2rem" width="4rem" />
            <Skeleton className={styles.img} height="1.2rem" width="10rem" />
          </div>
          <div className={styles.row}>
            <Skeleton className={styles.img} height="1.2rem" width="4rem" />
            <Skeleton className={styles.img} height="1.2rem" width="9rem" />
          </div>
          <div className={styles.row}>
            <Skeleton className={styles.img} height="1.2rem" width="4rem" />
            <Skeleton className={styles.img} height="1.2rem" width="5rem" />
          </div>

          <div className={styles.certifications}>
            <div className={styles.certification}>
              <Skeleton className={styles.img} height="1.5rem" width="1.5rem" />
            </div>
            <div className={styles.certification}>
              <Skeleton className={styles.img} height="1.5rem" width="1.5rem" />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.buttons}>
        <Skeleton className={styles.img} height="2.15rem" width="12rem" />
        <Skeleton className={styles.img} height="2.15rem" width="12rem" />
      </div>
    </Card>
  )
}
