export interface SummaryValuesRootProps {
  className?: string
  children: React.ReactNode
}

export enum SummaryValuesRowTypeEnums {
  normal = 'normal',
  strip = 'strip',
}

export interface SummaryValuesRowProps {
  className?: string
  children: React.ReactNode
  type?: keyof typeof SummaryValuesRowTypeEnums
}

export enum SummaryValuesColumnTypeEnums {
  label = 'label',
  value = 'value',
  bookend = 'bookend',
}

export enum SummaryValuesColumnDirectionEnums {
  left = 'left',
  right = 'right',
}

export interface SummaryValuesColumnProps {
  className?: string
  children: React.ReactNode
  type: keyof typeof SummaryValuesColumnTypeEnums
  direction?: keyof typeof SummaryValuesColumnDirectionEnums
}
