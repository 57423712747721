import styles from './summary-values.module.css'

import {
  SummaryValuesRowTypeEnums,
  SummaryValuesColumnTypeEnums,
  SummaryValuesColumnDirectionEnums,
} from './summary-values.type'

export const rowTypes: Record<SummaryValuesRowTypeEnums, string> = {
  normal: styles['row-core'],
  strip: `${styles['row-core']} ${styles['row-strip']}`,
}

export const generateClassesRowTypes = (
  rowType: keyof typeof SummaryValuesRowTypeEnums,
  className?: string,
) => {
  return `${rowTypes[rowType]} ${className ?? ''}`
}

export const columnTypes: Record<SummaryValuesColumnTypeEnums, string> = {
  label: styles['column-label'],
  value: styles['column-value'],
  bookend: styles['column-bookend'],
}

export const columnDirections: Record<
  SummaryValuesColumnDirectionEnums,
  string
> = {
  left: '',
  right: styles['column-direction-right'],
}

export const generateClassesColumnTypes = (
  columnType: keyof typeof SummaryValuesColumnTypeEnums,
  columnDirection: keyof typeof SummaryValuesColumnDirectionEnums,
  className?: string,
) => {
  return `${columnTypes[columnType]} ${columnDirections[columnDirection]} ${
    className ?? ''
  }`
}
