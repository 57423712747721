import { ReactNode } from 'react'

export enum SelectSizeEnum {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export interface SelectOptionProps {
  value: string
  label: string
}

export interface SelectProps {
  id?: string
  className?: string
  disabled?: boolean
  size?: keyof typeof SelectSizeEnum
  onChange?: (value: string) => void
  value?: string
  placeholder?: string
  iconLeft?: ReactNode
  iconRight?: ReactNode
  options: SelectOptionProps[]
  error?: string
}
