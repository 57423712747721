import styles from './progress-step.module.css'

import type { ProgressStepProps } from './progress-step.type'

import { generateClasses } from './progress-step.constants'

export const ProgressStep = ({
  currentStep,
  totalSteps,
  className,
}: ProgressStepProps) => {
  const emptyArray = new Array(totalSteps).fill(null)

  return (
    <div className={`${styles.core} ${className || ''}`}>
      {emptyArray.map((_, index) => (
        <div key={index} className={generateClasses(index <= currentStep)} />
      ))}
    </div>
  )
}
