import { useRef, useState, ChangeEvent, DragEvent } from 'react'
import { Text } from '../text'

import { generateClasses } from './drag-drop-file.constants'
import styles from './drag-drop-file.module.css'

import type { DragDropFileProps } from './drag-drop-file.type'

export const DragDropFile = ({
  className,
  onDrop,
  translations,
  error,
  isMultiple = true,
}: DragDropFileProps) => {
  const [dragActive, setDragActive] = useState<boolean>(false)

  const inputRef = useRef<HTMLInputElement>(null)

  const generatedClasses = generateClasses(
    dragActive ? 'active' : error ? 'error' : 'inactive',
    className,
  )

  const handleDrag = (e: DragEvent<HTMLFormElement | HTMLDivElement>): void => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  const handleFiles = (files: FileList): void => {
    onDrop(Array.from(files))
  }

  const handleDrop = (e: DragEvent<HTMLDivElement>): void => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFiles(e.dataTransfer.files)
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault()
    if (e.target.files && e.target.files[0]) {
      handleFiles(e.target.files)
      e.target.value = ''
    }
  }

  const onButtonClick = (): void => {
    inputRef.current?.click()
  }

  return (
    <>
      <div
        onDragEnter={handleDrag}
        className={generatedClasses}
        onSubmit={(e) => e.preventDefault()}
        onClick={onButtonClick}
      >
        <input
          ref={inputRef}
          type="file"
          multiple={isMultiple}
          onChange={handleChange}
        />
        <label
          htmlFor="input-file-upload"
          className={dragActive ? 'drag-active' : ''}
        >
          <div>
            <Text>{translations?.dragFile}</Text>
            <Text className={styles.link}>{translations?.clickToUpload}</Text>
            <Text>{translations?.sizeFile}</Text>
          </div>
        </label>
        {dragActive && (
          <div
            className={styles['drag-file-element']}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          ></div>
        )}
      </div>
      {!!error && <Text className={styles['error-message']}>{error}</Text>}
    </>
  )
}
