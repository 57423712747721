'use client'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { ChevronLeftIcon, ChevronRightIcon, Skeleton } from '@mercai/clever'

import { CarouselCategoriesProps } from './carousel-categories.type'

import { generateClasses } from './carousel-categories.constants'

import styles from './carousel-categories.module.css'

export const CarouselCategoriesDefault = ({
  items,
  selectedCategory,
  onCategoryChange,
}: CarouselCategoriesProps) => {
  const scrollRef = useRef<HTMLDivElement>(null)

  const { t } = useTranslation('componentCarouselCategories')

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: -200, behavior: 'smooth' })
    }
  }

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: 200, behavior: 'smooth' })
    }
  }

  return (
    <div className={styles.wrap}>
      <div className={styles.core} ref={scrollRef}>
        <button
          type="button"
          key="ALL"
          className={generateClasses(selectedCategory(['ALL'].join(',')))}
          title={t('itemAllLabel')}
          onClick={() => onCategoryChange(['ALL'].join(','))}
        >
          <span className={styles['category-item-icon']}>ALL</span>
          <span className={styles['category-item-label']}>
            {t('itemAllLabel')}
          </span>
        </button>

        {items?.map((item) => (
          <button
            type="button"
            key={item.value}
            className={generateClasses(selectedCategory(item.value))}
            title={item.label}
            onClick={() => onCategoryChange(item.value)}
          >
            <span className={styles['category-item-icon']}>{item.value}</span>
            <span className={styles['category-item-label']}>{item.label}</span>
          </button>
        ))}
      </div>

      <div className={styles.buttons}>
        <button onClick={scrollLeft}>
          <ChevronLeftIcon />
        </button>
        <button onClick={scrollRight}>
          <ChevronRightIcon />
        </button>
      </div>
    </div>
  )
}

export const CarouselCategoriesLoading = () => {
  return (
    <div className={styles.wrap}>
      <div className={styles.core}>
        {[0, 1, 2, 3, 4, 5, 6, 7]?.map((item) => (
          <div key={item} className={generateClasses(false)}>
            <span className={styles['category-item-icon']}>
              <Skeleton height="1rem" width="1rem" />
            </span>
            <span className={styles['category-item-label']}>
              <Skeleton height="1rem" width="5rem" />
            </span>
          </div>
        ))}
      </div>

      <div className={styles.buttons}>
        <button>
          <ChevronLeftIcon />
        </button>
        <button>
          <ChevronRightIcon />
        </button>
      </div>
    </div>
  )
}

export const CarouselCategories = (props: CarouselCategoriesProps) => {
  if (props.isLoading) {
    return <CarouselCategoriesLoading />
  }

  return <CarouselCategoriesDefault {...props} />
}
