import { useContext, createContext, useEffect, useRef, useState } from 'react'

import { ChevronDownIcon } from '../../../icons'

import type {
  AccordionRootProps,
  AccordionContextProps,
  AccortionItemProps,
  AccordionContentProps,
  AccordionHeaderProps,
} from './accordion.type'

import { generateClassesOfIcon } from './accordion.constants'

import styles from './accordion.module.css'

const AccordionContext = createContext<AccordionContextProps>({
  activeItems: [],
  onHandleActiveItems: () => null,
})

export const useAccordionContext = () => useContext(AccordionContext)

const Root = ({
  activeItems,
  children,
  onHandleActiveItems,
  className,
  showArrow = true,
}: AccordionRootProps) => {
  return (
    <AccordionContext.Provider
      value={{ activeItems, onHandleActiveItems, showArrow }}
    >
      <ul className={className}>{children}</ul>
    </AccordionContext.Provider>
  )
}

const Header = ({
  children,
  id,
  isActive,
  className,
}: AccordionHeaderProps) => {
  const { activeItems, onHandleActiveItems, showArrow } = useAccordionContext()

  const onHandleOpen = () => {
    if (isActive) {
      onHandleActiveItems(activeItems.filter((item) => item !== id))
    } else {
      onHandleActiveItems([...activeItems, id])
    }
  }

  const generatedIconClasses = generateClassesOfIcon(
    isActive ? 'open' : 'close',
  )

  return (
    <button
      className={`${styles['header-core']} ${className || ''}`}
      onClick={onHandleOpen}
      type="button"
    >
      {children}
      {showArrow && <ChevronDownIcon className={generatedIconClasses} />}
    </button>
  )
}

const Content = ({ children, isActive, className }: AccordionContentProps) => {
  const contentRef = useRef<HTMLDivElement>(null)
  const [contentHeight, setContentHeight] = useState<number | 'auto'>('auto')

  useEffect(() => {
    if (
      contentRef.current &&
      contentRef.current.scrollHeight !== contentHeight
    ) {
      setContentHeight(contentRef.current.scrollHeight)
    }
  }, [children, contentRef?.current?.scrollHeight])

  return (
    <div
      ref={contentRef}
      className={`${styles['content-core']} ${className || ''}`}
      style={{ maxHeight: isActive ? `${contentHeight}px` : '0px' }}
    >
      {children}
    </div>
  )
}

const Item = ({
  id,
  children,
  className,
  header,
  footer,
  contentClassName,
  headerClassName,
}: AccortionItemProps) => {
  const context = useAccordionContext()

  const isActive = context?.activeItems?.includes(id)

  return (
    <li className={className}>
      <Header isActive={isActive} id={id} className={headerClassName}>
        {header}
      </Header>

      <Content isActive={isActive} className={contentClassName}>
        {children}
      </Content>

      {footer}
    </li>
  )
}

const Accordion = {
  Root,
  Item,
}

export default Accordion
