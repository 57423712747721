'use client'
import { memo, useState } from 'react'
import type { CircleProgressBarProps } from './circle-progress-bar.type'

import { tokens } from '../../../tokens'

import styles from './circle-progress-bar.module.css'

function CircleProgress({
  progress = 0,
  strokeWidth = 2,
  transitionDuration = 0.5,
  transitionTimingFunction = 'ease',
  strokeBackground = tokens.colors.gray200,
  gradient = [
    { stop: 0.0, color: tokens.colors.primary600 },
    { stop: 1, color: tokens.colors.primary600 },
  ],
  style,
  className,
}: CircleProgressBarProps) {
  progress = Math.round(progress * 100) / 100
  const width = 16
  const center = width / 2
  const height = width
  const [unique] = useState(() => Math.random().toString())
  const rotate = 90 + 180
  const r = center - strokeWidth / 2
  const circumference = Math.PI * r * 2
  const offset = (circumference * (100 - progress)) / 100

  return (
    <div className={`${className || ''} ${styles.progress}`} style={style}>
      <svg viewBox={`0 0 ${width} ${height}`} className={styles.svg}>
        <defs>
          <linearGradient
            id={'gradient' + unique}
            x1="0%"
            y1="0%"
            x2="0%"
            y2="100%"
          >
            {gradient.map(({ stop, color }) => (
              <stop key={stop} offset={stop * 100} stopColor={color} />
            ))}
          </linearGradient>
        </defs>

        <circle
          transform={`rotate(${rotate} ${center} ${center})`}
          id="path"
          cx={center}
          cy={center}
          r={r}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={circumference}
          fill="none"
          stroke={strokeBackground}
          strokeLinecap="round"
        ></circle>
        <circle
          style={{
            transition: `stroke-dashoffset ${transitionDuration}s ${transitionTimingFunction}`,
          }}
          transform={`rotate(${rotate} ${center} ${center})`}
          id="path"
          cx={center}
          cy={center}
          r={r}
          strokeWidth={strokeWidth}
          strokeDasharray={`${circumference}`}
          strokeDashoffset={offset}
          fill="none"
          stroke={`url(#gradient${unique})`}
          strokeLinecap="round"
        ></circle>
      </svg>
    </div>
  )
}

export const CircleProgressBar = memo(CircleProgress)
