export enum TooltipOrientationEnum {
  'left' = 'left',
  'right' = 'right',
  'bottom' = 'bottom',
  'top' = 'top',
}

export interface TooltipProps {
  children: React.ReactNode
  className?: string
  orientation?: keyof typeof TooltipOrientationEnum
  text: string
  widthText?: string
}
