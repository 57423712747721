import { Alert, AlertVariantEnum, AlertIcon, Skeleton } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { OrderSummaryStatusProps } from './summary-status.type'

export const OrderSummaryStatus = ({
  status,
  isLoading,
}: OrderSummaryStatusProps) => {
  const { t } = useTranslation('screenOrderList')

  if (isLoading) {
    return <Skeleton height="7.5rem" />
  }

  const statusObjects = {
    pending_buyer_data: {
      variant: 'warning',
      title: t('orderShowSummaryStatusPendingBuyerDataTitle'),
      description: t('orderShowSummaryStatusPendingBuyerDataDescription'),
    },
    pending_invoice: {
      variant: 'warning',
      title: t('orderShowSummaryStatusPendingInvoiceTitle'),
      description: t('orderShowSummaryStatusPendingInvoiceDescription'),
    },
    pending_payment: {
      variant: 'warning',
      title: t('orderShowSummaryStatusPendingPaymentTitle'),
      description: t('orderShowSummaryStatusPendingPaymentDescription'),
    },
    waiting_payment_approval: {
      variant: 'warning',
      title: t('orderShowSummaryStatusWaitingPaymentApprovalTitle'),
      description: t('orderShowSummaryStatusWaitingPaymentApprovalDescription'),
    },
    paid: {
      variant: 'primary',
      title: t('orderShowSummaryStatusPaidTitle'),
      description: t('orderShowSummaryStatusPaidDescription'),
    },
    partial_delivered: {
      variant: 'primary',
      title: t('orderShowSummaryStatusPartialDeliveredTitle'),
      description: t('orderShowSummaryStatusPartialDeliveredDescription'),
    },
    delivered: {
      variant: 'success',
      title: t('orderShowSummaryStatusDeliveredTitle'),
      description: t('orderShowSummaryStatusDeliveredDescription'),
    },
    canceled: {
      variant: 'danger',
      title: t('orderShowSummaryStatusCanceledTitle'),
      description: t('orderShowSummaryStatusCanceledDescription'),
    },
    fraudulent: {
      variant: 'danger',
      title: t('orderShowSummaryStatusFraudulentTitle'),
      description: t('orderShowSummaryStatusFraudulentDescription'),
    },
  }

  const statusObject = statusObjects[status]

  return (
    <Alert
      leftIcon={<AlertIcon />}
      variant={statusObject.variant as AlertVariantEnum}
      title={statusObject.title}
    >
      {statusObject.description}
    </Alert>
  )
}
