import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

import {
  Alert,
  Button,
  Checkbox,
  DragDropFileList,
  Input,
  InputNumber,
  Label,
  Select,
  Radio,
  PlusIcon,
  TrashIcon,
  Skeleton,
} from '@mercai/clever'

import { getFileNameAndExtension, useValidationErrors } from '@/helpers'

import { api, CATALOG_PRODUCT_PUBLIC_UPLOAD_URL } from '@/services'

import styles from './auto-generate-form.module.css'

import { AutoGenerateFormProps } from './auto-generate-form.type'

import { CatalogCategoryAttributeProps } from '@/types'

export const AutoGenerateForm = ({
  schema,
  footerClassName,
  onSubmit: onOutsideSubmit,
  isLoading,
  initialValues,
  isResetForm,
  footer,
  errors: externalErrors,
}: AutoGenerateFormProps) => {
  const { t } = useTranslation('componentAutoGenerateForm')

  const [data, setData] = useState<Record<string, unknown>>(initialValues || {})
  const [initEditData, setInitEditData] = useState(false)

  useEffect(() => {
    if (
      initialValues &&
      JSON.stringify(data) !== JSON.stringify(initialValues) &&
      !initEditData
    ) {
      setData({ ...(initialValues as Record<string, unknown>) } || {})
    }
  }, [initialValues])

  useEffect(() => {
    if (isResetForm) {
      setInitEditData(false)
      setData({})
    }
  }, [isResetForm])

  const capitalizeFirstLetter = (str: string) => {
    if (!str) return str
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  const yupSchema = schema?.reduce(
    (acc, propertyItem) => {
      const propertyKey = propertyItem?.slug

      if (!propertyItem.isRequired) {
        return acc
      }

      if (propertyItem.dataType === 'str') {
        acc[propertyKey] = Yup.string().required(
          `${capitalizeFirstLetter(propertyKey)} field is required`,
        )
      }

      if (
        propertyItem?.dataType === 'decimal.Decimal' ||
        propertyItem?.dataType === 'int'
      ) {
        acc[propertyKey] = Yup.number().required(
          `${capitalizeFirstLetter(propertyKey)} field is required`,
        )
      }

      if (propertyItem?.dataType === 'bool') {
        acc[propertyKey] = Yup.boolean().required(
          `${capitalizeFirstLetter(propertyKey)} field is required`,
        )
      }

      if (propertyItem?.many) {
        acc[propertyKey] = Yup.array()
          .min(1, `${capitalizeFirstLetter(propertyKey)} field is required`)
          .required(`${capitalizeFirstLetter(propertyKey)} field is required`)
      }

      return acc
    },
    {} as Record<string, Yup.AnySchema>,
  )

  const { errors, isValid, onHandleListenErrors } = useValidationErrors(
    yupSchema,
    data,
  )

  const generateLabel = (label: string, isOptional: boolean) => {
    return (
      <Label htmlFor={label}>
        {capitalizeFirstLetter(label)} {isOptional && <span>(optional)</span>}
      </Label>
    )
  }

  const generateInput = (
    property: CatalogCategoryAttributeProps,
    onChangeValue: (newValue: unknown) => void,
    value?: unknown,
  ) => {
    if (property.many && property.widget !== 'upload') {
      const arrayData = (value || []) as string[]

      const handleRemoveItem = (index: string) => {
        const newData = arrayData as []
        newData.splice(Number(index), 1)

        onChangeValue(newData)
      }

      const handleAddItem = () => {
        if (Array.isArray(arrayData)) {
          if (property.widget === 'range' && arrayData.length >= 2) {
            return
          }

          const newData = (arrayData || []) as string[]

          newData?.push('')

          onChangeValue(newData)
          return
        }

        const newData = [arrayData] as string[]

        newData?.push('')

        onChangeValue(newData)
      }

      const onChangeValueItem = (index: number, newValue: string) => {
        const newData = arrayData as string[]
        newData[index] = newValue

        onChangeValue(newData)
      }

      return (
        <div className={styles['input-array']}>
          {Array.isArray(arrayData) &&
            arrayData?.map((item: string, index: number) => (
              <div className={styles['input-array-row']} key={index}>
                {generateInput(
                  { ...property, many: false },
                  (newValue) => onChangeValueItem(index, `${newValue}`),
                  item as string,
                )}
                <Button
                  variant="danger"
                  model="square"
                  onClick={() => handleRemoveItem(`${index}`)}
                >
                  <TrashIcon />
                </Button>
              </div>
            ))}

          {errors?.[property?.slug] && (
            <Alert variant="danger">{errors?.[property?.slug] as string}</Alert>
          )}
          {!(property.widget === 'range' && arrayData.length >= 2) && (
            <Button variant="secondary" model="square" onClick={handleAddItem}>
              <PlusIcon />
            </Button>
          )}
        </div>
      )
    }

    if (property.widget === 'select') {
      return (
        <Select
          className={styles['input-width-100']}
          options={
            property?.options?.map(({ name }) => ({
              label: name,
              value: name,
            })) || []
          }
          value={value as string}
          onChange={onChangeValue}
          error={
            (errors?.[property?.slug] ||
              externalErrors?.[property?.slug]) as string
          }
        />
      )
    }

    if (property.widget === 'input' || property.widget === 'textbox') {
      return (
        <Input
          className={styles['input-width-100']}
          value={value as string}
          onChange={onChangeValue}
          error={errors?.[property?.slug] as string}
          isTextArea={property.widget === 'textbox'}
        />
      )
    }

    if (property.widget === 'input_number' || property.widget === 'range') {
      return (
        <InputNumber
          decimalPrecision={property.dataType === 'decimal.Decimal' ? 2 : 0}
          stepSize={1}
          showIcons
          className={styles['input-width-100']}
          value={value as number}
          onChange={onChangeValue}
          error={errors?.[property?.slug] as string}
        />
      )
    }

    if (property.widget === 'checkbox') {
      return (
        <Checkbox
          className={styles['input-width-100']}
          value={value as boolean}
          onChange={onChangeValue}
        />
      )
    }

    if (property.widget === 'radio') {
      return (
        <Radio
          className={styles['input-width-100']}
          value={value as boolean}
          onChange={onChangeValue}
        />
      )
    }

    if (property.widget === 'upload') {
      const getFileValue = () => {
        if (!value) {
          return []
        }

        if (property.many) {
          return (value as string[]).map((url) => {
            const file = getFileNameAndExtension(`${url}` || '')

            return {
              id: new Date().getTime(),
              name: `${file.fileName}.${file.extension}`,
              url: value as string,
              uploaded: true,
            }
          })
        }

        const file = getFileNameAndExtension(`${value}` || '')

        return [
          {
            id: new Date().getTime(),
            name: `${file.fileName}.${file.extension}`,
            url: value as string,
            uploaded: true,
          },
        ]
      }

      const onChangeFileValue = (url: string) => {
        if (property.many) {
          const newValue = [...((value || []) as string[]), url]
          onChangeValue(newValue)
        } else {
          onChangeValue(url)
        }
      }

      return (
        <DragDropFileList
          axiosInstance={api}
          urlAdd={CATALOG_PRODUCT_PUBLIC_UPLOAD_URL(property.slug)}
          configsRequest={{
            fileField: 'file',
            method: 'POST',
          }}
          initialData={getFileValue()}
          successedUpload={(value) =>
            onChangeFileValue(value?.url ? `${value?.url}` : '')
          }
          translations={{
            clickToUpload: t('uploadButton'),
            dragFile: t('uploadDrag'),
            sizeFile: t('uploadSize'),
          }}
          error={errors?.[property?.slug] as string}
        />
      )
    }
  }

  const generateForm = (propertyItem: CatalogCategoryAttributeProps) => {
    const onChangeValue = (newValue: unknown) => {
      if (!initEditData) {
        setInitEditData(true)
      }

      setData({
        ...data,
        [propertyItem?.slug]: newValue,
      })
    }

    const labelComponent = generateLabel(
      propertyItem?.name,
      !propertyItem?.isRequired,
    )

    const inputComponent = generateInput(
      propertyItem,
      onChangeValue,
      data?.[propertyItem?.slug],
    )

    return (
      <div key={propertyItem?.slug}>
        {labelComponent}
        {inputComponent}
      </div>
    )
  }

  const generateLoadingItem = () => {
    return (
      <div className={styles['loading-wrap']}>
        <Skeleton height="1.125rem" width="10rem" />
        <Skeleton height="2.75rem" width="100%" />
      </div>
    )
  }

  const generateLoading = () => {
    return (
      <>
        {generateLoadingItem()}
        {generateLoadingItem()}
        {generateLoadingItem()}
        {generateLoadingItem()}
        {generateLoadingItem()}
        {generateLoadingItem()}
        {generateLoadingItem()}
        {generateLoadingItem()}
        {generateLoadingItem()}
      </>
    )
  }

  const onSubmit = () => {
    if (isValid) {
      const newValues = Object.keys(data)?.reduce((accumulator, currentKey) => {
        const findSchema = schema?.find((item) => item.slug === currentKey)

        if (!findSchema) return accumulator

        const { dataType, many } = findSchema

        if (many) {
          let arrayData: string[] = data?.[currentKey] as string[]

          if (!Array.isArray(arrayData)) {
            if (arrayData !== undefined && arrayData !== null) {
              arrayData = [arrayData]
            } else {
              arrayData = []
            }
          }

          if (dataType === 'bool') {
            accumulator[currentKey] = arrayData?.map((item) => item === 'true')
          }

          if (dataType === 'int' || dataType === 'decimal.Decimal') {
            accumulator[currentKey] = arrayData?.map((item) =>
              item ? Number(item) : undefined,
            )
          }

          if (dataType === 'str') {
            accumulator[currentKey] = arrayData?.map((item) =>
              item ? `${item}` : undefined,
            )
          }

          return accumulator
        }

        if (dataType === 'bool') {
          accumulator[currentKey] = data?.[currentKey] === 'true'
        }

        if (dataType === 'int' || dataType === 'decimal.Decimal') {
          accumulator[currentKey] = Number(data?.[currentKey])
        }

        if (dataType === 'str') {
          accumulator[currentKey] = data?.[currentKey]
            ? `${data?.[currentKey]}`
            : undefined
        }

        return accumulator
      }, data)

      onOutsideSubmit && onOutsideSubmit(newValues)
      onHandleListenErrors(false)
    } else {
      onHandleListenErrors(true)
    }
  }

  return (
    <div className={styles.form}>
      {isLoading ? generateLoading() : null}

      {schema?.map((propertyItem) => generateForm(propertyItem))}

      {footer ? (
        footer({ onSubmit })
      ) : (
        <div className={footerClassName}>
          <Button onClick={onSubmit}>Submit</Button>
        </div>
      )}
    </div>
  )
}
