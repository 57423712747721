/**
 * - pt-BR: BRL (Brasileiro)
 * - es-MX: MXN (Mexicano)
 * - en-US: USD (Americano)
 */

export function formatNumber(
  value: number | string,
  locale = 'es-MX',
  minimumFractionDigits = 2,
  maximumFractionDigits = 2,
): string {
  const options: Intl.NumberFormatOptions = {
    style: 'decimal',
    minimumFractionDigits,
    maximumFractionDigits,
  }

  const formattedValue = new Intl.NumberFormat(locale, options).format(+value)

  return formattedValue
}
