import { useState } from 'react'
import { Button, DownloadIcon, Text, Skeleton } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { trimString, downloadFile, getFileNameAndExtension } from '@/helpers'
import { useFileSign } from '@/services'

import { CatalogProductDetailProps } from './detail.type'

import styles from './detail.module.css'

export const CatalogProductDetailDefault = ({
  catalogProduct,
}: CatalogProductDetailProps) => {
  const [showDetails, setShowDetails] = useState(false)

  const { t } = useTranslation('screenCatalogProductList')

  const { mutateAsync, isPending } = useFileSign()

  const onHandleDownloadDataSheet = async () => {
    if (catalogProduct?.attributes?.dataSheet) {
      let url = catalogProduct?.attributes?.dataSheet

      if (!url.includes('http')) {
        const response = await mutateAsync({
          filePath: url || '',
        })

        if (!response?.data?.signedUrl) return

        url = response?.data?.signedUrl
      }

      const { fileName, extension } = getFileNameAndExtension(
        catalogProduct?.attributes?.dataSheet || '',
      )

      downloadFile(url, `${fileName}.${extension}`)
    }
  }

  const getDescription = () => {
    if (
      catalogProduct?.attributes?.description &&
      catalogProduct?.attributes?.description?.length > 270
    ) {
      return (
        <Text>
          {showDetails
            ? catalogProduct?.attributes?.description
            : trimString(
                catalogProduct?.attributes?.description || '',
                270,
                '... ',
              )}
          <Text
            onClick={() => setShowDetails(!showDetails)}
            className={styles['description-handle-open-button']}
          >
            {showDetails
              ? t('detailDescriptionReadLess')
              : t('detailDescriptionReadMore')}
          </Text>
        </Text>
      )
    }

    return <Text>{catalogProduct?.attributes?.description}</Text>
  }

  return (
    <div className={styles.core}>
      <div className={styles.images}>
        <img
          src={
            catalogProduct?.attributes?.mainImage ||
            '/images/product-placeholder.webp'
          }
          alt="product image"
          className={styles['main-image']}
        />

        <div className={styles.gallery}>
          {catalogProduct?.attributes?.images?.map((image, index) => (
            <img
              src={image}
              alt="product image"
              key={index}
              className={styles['gallery-image']}
            />
          ))}
        </div>
      </div>

      <div className={styles.content}>
        <div className={styles['content-header']}>
          <Text variant="text-large-bold">
            {catalogProduct?.attributes?.title}
          </Text>

          <div className={styles['content-header-sold']}>
            <Text>{t('detailSoldBy')}: </Text>
            <Text variant="title-base">Mercai</Text>
          </div>
        </div>

        <div className={styles['content-buttons']}>
          <div className={styles['content-buttons-row']}>
            <Button
              variant="secondary"
              onClick={onHandleDownloadDataSheet}
              disabled={!catalogProduct?.attributes?.dataSheet}
            >
              <DownloadIcon />{' '}
              {isPending
                ? t('detailButtonDataSheetDownloading')
                : t('detailButtonDataSheet')}
            </Button>
          </div>
        </div>

        <div className={styles['content-attributes']}>
          <Text>{getDescription()}</Text>

          <div className={styles['content-attributes-row']}>
            <Text variant="title-base">{t('detailCategoryLabel')}: </Text>
            <Text>
              {catalogProduct?.category?.name} -{' '}
              {catalogProduct?.category?.shortName}
            </Text>
          </div>

          {catalogProduct?.attributes?.process && (
            <div className={styles['content-attributes-row']}>
              <Text variant="title-base">{t('detailProcessLabel')}: </Text>
              <Text>
                {Array.isArray(catalogProduct?.attributes?.process)
                  ? catalogProduct?.attributes?.process
                      ?.map((item) => item?.value)
                      .join(', ')
                  : '-'}
              </Text>
            </div>
          )}

          {catalogProduct?.attributes?.color && (
            <div className={styles['content-attributes-row']}>
              <Text variant="title-base">{t('detailColorLabel')}: </Text>
              <Text>
                {catalogProduct?.attributes?.color
                  ? catalogProduct?.attributes?.color?.value
                  : '-'}
              </Text>
            </div>
          )}

          <div className={styles['content-attributes-row']}>
            <Text variant="title-base">{t('detailBrandLabel')}: </Text>
            <Text>
              {catalogProduct?.attributes?.brand
                ? catalogProduct?.attributes?.brand?.value
                : '-'}
            </Text>
          </div>

          <div className={styles['content-attributes-row']}>
            <Text variant="title-base">{t('detailSKULabel')}: </Text>
            <Text>{catalogProduct?.reference || '-'}</Text>
          </div>

          {catalogProduct?.attributes?.size && (
            <div className={styles['content-attributes-row']}>
              <Text variant="title-base">{t('detailSizeLabel')}: </Text>
              <Text>
                {catalogProduct?.attributes?.size
                  ? catalogProduct?.attributes?.size
                  : '-'}
              </Text>
            </div>
          )}

          {catalogProduct?.attributes?.quantityPerPackage && (
            <div className={styles['content-attributes-row']}>
              <Text variant="title-base">
                {t('detailQuantityPerPackageLabel')}:{' '}
              </Text>
              <Text>
                {catalogProduct?.attributes?.quantityPerPackage
                  ? catalogProduct?.attributes?.quantityPerPackage
                  : '-'}
              </Text>
            </div>
          )}

          {catalogProduct?.attributes?.certifications && (
            <div className={styles['content-attributes-row']}>
              <Text variant="title-base">
                {t('detailCertificationLabel')}:{' '}
              </Text>
              <Text>
                {catalogProduct?.attributes?.certifications
                  ?.map((item) => item?.value)
                  .join(', ') || '-'}
              </Text>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export const CatalogProductDetailLoading = () => {
  return (
    <div className={styles.core}>
      <div className={styles.images}>
        <Skeleton
          className={styles['main-image']}
          height="20rem"
          width="100%"
        />

        <div className={styles.gallery}>
          {[...Array(3)].map((_, index) => (
            <Skeleton
              key={index}
              className={styles['gallery-image']}
              height="5rem"
              width="100%"
            />
          ))}
        </div>
      </div>

      <div className={styles.content}>
        <div className={styles['content-header']}>
          <Skeleton height="2rem" width="70%" />
          <Skeleton height="1.5rem" width="30%" />
        </div>

        <div className={styles['content-buttons']}>
          <Skeleton
            className={styles['content-button-add-list']}
            height="2.5rem"
            width="100%"
          />
          <div className={styles['content-buttons-row']}>
            <Skeleton height="2.5rem" width="48%" />
            <Skeleton height="2.5rem" width="48%" />
          </div>
        </div>

        <div className={styles['content-attributes']}>
          <Skeleton height="1rem" width="100%" />
          <Skeleton height="1rem" width="100%" />
          <Skeleton height="1rem" width="100%" />
          <Skeleton height="1rem" width="100%" />

          <div className={styles['content-attributes-row']}>
            <Skeleton height="1rem" width="3rem" />
            <Skeleton height="1rem" width="5rem" />
          </div>

          <div className={styles['content-attributes-row']}>
            <Skeleton height="1rem" width="3rem" />
            <Skeleton height="1rem" width="5rem" />
          </div>

          <div className={styles['content-attributes-row']}>
            <Skeleton height="1rem" width="3rem" />
            <Skeleton height="1rem" width="5rem" />
          </div>

          <div className={styles['content-attributes-row']}>
            <Skeleton height="1rem" width="3rem" />
            <Skeleton height="1rem" width="5rem" />
          </div>
        </div>
      </div>
    </div>
  )
}

export const CatalogProductDetail = (props: CatalogProductDetailProps) => {
  if (props.isLoading) return <CatalogProductDetailLoading />

  return <CatalogProductDetailDefault {...props} />
}
