export enum FinanceProfileCreditGatewayEnum {
  internal = 'internal',
  xepelin = 'xepelin',
}

export enum FinanceProfileGovernmentIdTypeEnum {
  ine = 'ine',
}

export enum FinanceProfileCreditBureauEnum {
  black_trust = 'black_trust',
}

export enum FinanceProfilePersonTypeEnum {
  individual = 'individual',
  legal = 'legal',
}

export enum FinanceProfileCreditStatusEnum {
  requested = 'requested',
  review = 'review',
  approved = 'approved',
  rejected = 'rejected',
}

export interface FinanceProfileCreditInUseOrderProps {
  uuid: string
  status: keyof typeof FinanceProfileCreditStatusEnum
  amount: number
  reference: string
  createdAt: string
  updatedAt: string
}

export interface FinanceProfileProps {
  profileUuid: string
  personType: keyof typeof FinanceProfilePersonTypeEnum
  governmentIdFile: string
  governmentIdType: keyof typeof FinanceProfileGovernmentIdTypeEnum
  addressProofFile: string
  constitutiveActFile: string
  creditBureau: keyof typeof FinanceProfileCreditBureauEnum
  creditBureauUrl: string
  creditStatus: keyof typeof FinanceProfileCreditStatusEnum
  creditApprovedAt: string
  creditGateway: keyof typeof FinanceProfileCreditGatewayEnum
  totalCreditAmount: number
  statusHistory: Record<string, string>
  taxSituation: string
  totalCreditInUse: number
  createdAt: string
  updatedAt: string
  creditInUseOrders: FinanceProfileCreditInUseOrderProps[]

  speiGateway: string
  speiGatewayAccountId: string
  speiGatewayUserId: string
}
