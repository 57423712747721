import { useMemo } from 'react'
import { filesize } from 'filesize'

import { DragDropFile, FileList, FileListFileProps } from '@mercai/clever'

import { DragDropFileListProps } from './drag-drop-file-list.type'

export const DragDropFileList = ({
  data,
  onDrop,
  onRemove,
  translations,
  className,
  error,
}: DragDropFileListProps) => {
  const dataToFileList: FileListFileProps[] = useMemo(
    () =>
      data.map((file, i) => ({
        file,
        id: i,
        name: file.name,
        readableSize: filesize(file.size),
        preview: URL.createObjectURL(file),
        progress: 0,
        uploaded: true,
        error: false,
        url: undefined,
      })),
    [data],
  )

  const handleOnRemove = (index: number | string) => {
    if (onRemove) {
      onRemove(data.filter((_, i) => i !== index))
    }
  }

  return (
    <>
      <DragDropFile
        onDrop={onDrop}
        className={className}
        error={error}
        translations={translations}
        isMultiple={false}
      />
      <FileList
        data={dataToFileList}
        onClickRemove={onRemove ? handleOnRemove : undefined}
      />
    </>
  )
}
