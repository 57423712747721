import { ChangeEvent } from 'react'

import {
  generateClassesIcon,
  generateClassesContainer,
} from './checkbox.constants'
import { CheckIcon } from '../../../icons'

import type { CheckboxProps } from './checkbox.type'

import styles from './checkbox.module.css'

const Checkbox = ({
  children,
  onChange,
  value,
  className,
  size = 'medium',
}: CheckboxProps) => {
  const classesCheckBoxContainer = generateClassesContainer(size, className)
  const classesCheckboxIcon = generateClassesIcon(value, !!children)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event?.target?.checked || false)
  }

  return (
    <label className={classesCheckBoxContainer}>
      <input
        type="checkbox"
        checked={value}
        onChange={handleChange}
        className={styles.hidden}
      />
      <span className={classesCheckboxIcon}>{value && <CheckIcon />}</span>
      <div className={styles.children}>{children}</div>
    </label>
  )
}

export default Checkbox
