import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  CloseIcon,
  FilterIcon,
  Tag,
  Text,
  Select,
  SearchIcon,
  PlusIcon,
} from '@mercai/clever'

import { trackEvent, useParamsState } from '@/helpers'

import {
  useCatalogAttributeOption,
  useCatalogProductList,
  useCatalogCategoryList,
  useKaiProcessorList,
  useKaiSmartSearchDetail,
} from '@/services'

import {
  DrawerForm,
  DrawerShow,
  CatalogProductTable,
  InputSearch,
  CatalogProductDrawerFilter,
} from './components'

import { CarouselCategories, Header } from '@/components'

import { DrawerSmartSearch } from './components/drawer-smart-search'
import { AlertSmartSearch } from './components/alert-smart-search/alert-smart-search'

import styles from './list.module.css'

export const ProductList = () => {
  const { t } = useTranslation('screenCatalogProductList')

  const [queryParams, setQueryParams] = useParamsState({
    uuid: '',
    form: '',
    show: '',
  })

  const [isOpenDrawerFilter, setIsOpenDrawerFilter] = useState(false)

  const [isOpenDrawerSmartSearch, setIsOpenDrawerSmartSearch] = useState(false)
  const [smartSearchUUID, setSmartSearchUUID] = useState<
    string | undefined | null
  >()

  const [filter, setFilter] = useParamsState({
    search: '',
    ordering: '-created_at',
    page: '1',
    category: ['ALL'].join(','),
    reference: '',
  })

  useEffect(() => {
    trackEvent('adminProductsListPageView')
  }, [])

  const onHandleFilter = (key: string, newValue: string) => {
    setFilter({
      ...filter,
      [key]: newValue,
    })
  }

  const { data: catalogAttributeOptionList } = useCatalogAttributeOption(
    'process,certifications,brand',
  )

  const { data: catalogCategoryList, isLoading: isLoadingCatalogCategoryList } =
    useCatalogCategoryList()

  const catalogCategoryFormatted =
    catalogCategoryList?.data?.results?.map((item) => ({
      label: item.name,
      value: item.shortName,
    })) || []

  const { data, isLoading } = useCatalogProductList(filter)

  const {
    data: kaiProcessorList,
    isLoading: isLoadingKaiProcessorList,
    refetch: onRefetchEnhance,
  } = useKaiProcessorList({
    params: {
      reference:
        data?.data?.results?.map((item) => item.reference).join(',') || '',
    },
  })

  const onSelectEditCatalogProduct = (uuid: string) => {
    setQueryParams({ ...queryParams, show: '', form: 'true', uuid })
  }

  const onSelectShowCatalogProduct = (uuid: string) => {
    setQueryParams({ ...queryParams, form: '', show: 'true', uuid })
  }

  const getBooleanOfMultiValue = (key: string, value: string): boolean => {
    const values = (filter[key] as string)?.split(',') || []
    return values.includes(value)
  }

  const onChangeBooleanOfMultiValue = (key: string, value: string) => {
    if (key === 'category' && value === 'ALL') {
      setFilter({ ...filter, category: ['ALL'].join(',') })
      return
    }

    const values =
      (filter[key] as string)?.split(',').filter((item) => item !== 'ALL') || []

    let newValues = ''

    if (values.includes(value)) {
      newValues = values.filter((item) => item !== value).join(',')
    } else {
      newValues = [...values, value].join(',')
    }

    setFilter({ ...filter, [key]: newValues })
  }

  const onHandleDrawerFilter = () => {
    setIsOpenDrawerFilter((prev) => !prev)
  }

  const getFormattedFilter = (filterString?: string | number) => {
    if (!filterString || typeof filterString === 'number') return ''

    if (filterString?.[0] === ',') {
      return filterString?.slice(1)?.replace(/,/g, ', ')
    }

    return filterString?.replace(/,/g, ', ')
  }

  const onHandleNewProduct = () => {
    setQueryParams({ ...queryParams, form: 'true', show: '', uuid: '' })
  }

  const onHandleToogleDrawerSmartSearch = () => {
    setIsOpenDrawerSmartSearch((prev) => !prev)
  }

  const onHandleSubmitDrawerSmartSearch = (uuid: string) => {
    setSmartSearchUUID(uuid)
    setIsOpenDrawerSmartSearch(false)
  }

  const { data: smartSearchDetail } = useKaiSmartSearchDetail(smartSearchUUID)

  useEffect(() => {
    if (
      (smartSearchDetail?.data?.status === 'processed' ||
        smartSearchDetail?.data?.status === 'unprocessable') &&
      smartSearchUUID
    ) {
      if (smartSearchDetail?.data?.searchResults?.length === 0) {
        setSmartSearchUUID(null)
        setFilter({
          ...filter,
          reference: '',
        })
        return
      }
      setSmartSearchUUID(undefined)
      setFilter({
        ...filter,
        reference: smartSearchDetail?.data?.searchResults.join(',') || '',
      })
    }
  }, [smartSearchDetail])

  return (
    <div>
      <Header title={t('title')} description={t('description')} />

      <div className={styles.grid}>
        <div className={styles.content}>
          <CarouselCategories
            items={catalogCategoryFormatted}
            selectedCategory={(shortName: string) =>
              getBooleanOfMultiValue('category', shortName)
            }
            onCategoryChange={(value) =>
              onChangeBooleanOfMultiValue('category', [value]?.join(','))
            }
            isLoading={isLoadingCatalogCategoryList}
          />

          <div className={styles['filter-box']}>
            <div className={styles['filter-row']}>
              <div className={styles['filter-group']}>
                <InputSearch
                  className={styles['input-search']}
                  value={filter.search as string}
                  onChange={(value) => onHandleFilter('search', value)}
                />

                <Button variant="secondary" onClick={onHandleDrawerFilter}>
                  <FilterIcon />
                  {t('filterButton')}
                </Button>

                <Button
                  onClick={onHandleToogleDrawerSmartSearch}
                  variant="secondary"
                >
                  <SearchIcon />
                  {t('smartSearchButton')}
                </Button>

                <Button onClick={onHandleNewProduct}>
                  <PlusIcon />
                  {t('newProductButton')}
                </Button>
              </div>

              <Select
                className={styles['select-order']}
                placeholder={t('orderingPlaceholder')}
                onChange={(value) => onHandleFilter('ordering', value)}
                value={filter.ordering as string}
                options={[
                  { label: t('orderOlderFirst'), value: 'created_at' },
                  { label: t('orderNewerFirst'), value: '-created_at' },
                ]}
              />
            </div>

            {((filter?.category &&
              (filter?.category !== 'ALL' || filter?.reference)) ||
              filter?.search ||
              filter?.brand ||
              filter?.process ||
              filter?.certifications) && (
              <div className={styles['filtered-options']}>
                <Text>{t('filterApplied')}:</Text>

                {filter?.category && filter?.category !== 'ALL' && (
                  <Tag size="medium" variant="secondary">
                    {getFormattedFilter(filter?.category)}
                    <button onClick={() => onHandleFilter('category', 'ALL')}>
                      <CloseIcon />
                    </button>
                  </Tag>
                )}

                {filter?.search && (
                  <Tag size="medium" variant="secondary">
                    {getFormattedFilter(filter?.search)}
                    <button onClick={() => onHandleFilter('search', '')}>
                      <CloseIcon />
                    </button>
                  </Tag>
                )}

                {filter?.brand && (
                  <Tag size="medium" variant="secondary">
                    {getFormattedFilter(filter?.brand)}
                    <button onClick={() => onHandleFilter('brand', '')}>
                      <CloseIcon />
                    </button>
                  </Tag>
                )}

                {filter?.process && (
                  <Tag size="medium" variant="secondary">
                    {getFormattedFilter(filter?.process)}
                    <button onClick={() => onHandleFilter('process', '')}>
                      <CloseIcon />
                    </button>
                  </Tag>
                )}

                {filter?.certifications && (
                  <Tag size="medium" variant="secondary">
                    {getFormattedFilter(filter?.certifications)}
                    <button
                      onClick={() => onHandleFilter('certifications', '')}
                    >
                      <CloseIcon />
                    </button>
                  </Tag>
                )}
                {filter?.reference && (
                  <Tag size="medium" variant="secondary">
                    {t('smartSearchButton')}
                    <button onClick={() => onHandleFilter('reference', '')}>
                      <CloseIcon />
                    </button>
                  </Tag>
                )}
              </div>
            )}
          </div>

          <AlertSmartSearch
            smartSearchUUID={smartSearchUUID}
            filterReference={filter.reference as string}
          />

          <CatalogProductTable
            data={data?.data?.results || []}
            countData={data?.data?.count || 0}
            isLoading={
              isLoading || isLoadingKaiProcessorList || !!smartSearchUUID
            }
            filter={filter}
            onHandleFilter={onHandleFilter}
            onEditItem={onSelectEditCatalogProduct}
            onShowItem={onSelectShowCatalogProduct}
            kaiProcessorList={kaiProcessorList?.data?.results || []}
            onRefetchEnhance={onRefetchEnhance}
          />
        </div>

        <CatalogProductDrawerFilter
          isOpen={isOpenDrawerFilter}
          onHandleOpen={onHandleDrawerFilter}
          catalogCategories={catalogCategoryFormatted || []}
          filter={filter}
          onHandleFilter={setFilter}
          attributesFilters={catalogAttributeOptionList?.data?.results || []}
        />

        <DrawerForm
          isOpen={!!queryParams.form}
          onHandleOpen={() =>
            setQueryParams({
              show: '',
              form: '',
              uuid: '',
            })
          }
          catalogProductUuid={queryParams.uuid}
        />

        <DrawerShow
          isOpen={!!queryParams.show}
          onHandleOpen={() =>
            setQueryParams({
              show: '',
              form: '',
              uuid: '',
            })
          }
          catalogProductUuid={queryParams.uuid}
        />

        <DrawerSmartSearch
          isOpen={isOpenDrawerSmartSearch}
          onHandleOpen={onHandleToogleDrawerSmartSearch}
          onHandleSubmit={onHandleSubmitDrawerSmartSearch}
        />
      </div>
    </div>
  )
}
