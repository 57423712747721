import styles from './date-picker.module.css'

import { DatePickerSizeEnum } from './date-picker.type'

export const sizes: Record<DatePickerSizeEnum, string> = {
  small: styles['size-small'],
  medium: styles['size-medium'],
  large: styles['size-large'],
}

export const generateClasses = (
  size: keyof typeof DatePickerSizeEnum,
  error?: boolean,
  className?: string,
) => {
  return `${styles.core} ${sizes[size]} ${!error || styles['state-danger']} ${className}`
}
