import { ChevronRightIcon, CheckCircleFillIcon } from '../../../icons'
import { Text } from '../text'

import { CheckListProps } from './check-list.type'

import styles from './check-list.module.css'

import { generateClasses } from './check-list.constants'

export const CheckList = ({ className, itens, onClick }: CheckListProps) => {
  return (
    <div className={`${styles.core} ${className || ''}`}>
      {itens?.map((item) => (
        <button
          key={item.value}
          className={generateClasses(item.state || 'default')}
          type="button"
          onClick={() => onClick(item.value)}
        >
          <Text>{item.label}</Text>

          {item.state === 'checked' ? (
            <CheckCircleFillIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </button>
      ))}
    </div>
  )
}
