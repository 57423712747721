import { useState } from 'react'

import { useTranslation } from 'react-i18next'

import { Alert, Button, Card, Input, Label } from '@mercai/clever'

import { useAuth } from '@/providers'

import styles from './sign-in.module.css'

export const AuthSignIn = () => {
  const { t } = useTranslation('screenSignIn')

  const { signIn, isLoading } = useAuth()

  const [errorMessage, setErrorMessage] = useState('')

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const onHandleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setErrorMessage('')

    const result = await signIn({
      email: String(email).toLowerCase(),
      password,
    })

    if (!result?.success) {
      setErrorMessage(result?.message || t('credentialErrorMessage'))
    }
  }

  return (
    <Card className={styles.box}>
      <form onSubmit={onHandleSubmit}>
        <h1 className={styles.title}>{t('title')}</h1>

        <h2 className={styles.description}>{t('description')}</h2>

        <Label htmlFor="email">{t('emailLabel')}</Label>
        <Input
          value={email}
          onChange={setEmail}
          id="email"
          className={styles['space-input']}
          type="email"
        />

        <Label htmlFor="password">{t('passwordLabel')}</Label>
        <Input
          value={password}
          onChange={setPassword}
          id="password"
          className={styles['space-input']}
          type="password"
        />

        {errorMessage === '' || (
          <Alert className={styles.alert} variant="danger">
            {errorMessage}
          </Alert>
        )}

        <Button type="submit" className={styles['button-submit']}>
          {isLoading ? t('loading') : t('buttonSubmit')}
        </Button>
      </form>
    </Card>
  )
}
