import { SVGProps } from 'react'

export const BoxIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M11.2535 19.425V12.575L5.25348 9.1V15.95L11.2535 19.425ZM13.2535 19.425L19.2535 15.95V9.1L13.2535 12.575V19.425ZM11.2535 21.725L4.25348 17.7C3.93681 17.5167 3.69098 17.275 3.51598 16.975C3.34098 16.675 3.25348 16.3417 3.25348 15.975V8.025C3.25348 7.65833 3.34098 7.325 3.51598 7.025C3.69098 6.725 3.93681 6.48333 4.25348 6.3L11.2535 2.275C11.5701 2.09167 11.9035 2 12.2535 2C12.6035 2 12.9368 2.09167 13.2535 2.275L20.2535 6.3C20.5701 6.48333 20.816 6.725 20.991 7.025C21.166 7.325 21.2535 7.65833 21.2535 8.025V15.975C21.2535 16.3417 21.166 16.675 20.991 16.975C20.816 17.275 20.5701 17.5167 20.2535 17.7L13.2535 21.725C12.9368 21.9083 12.6035 22 12.2535 22C11.9035 22 11.5701 21.9083 11.2535 21.725ZM16.2535 8.525L18.1785 7.425L12.2535 4L10.3035 5.125L16.2535 8.525ZM12.2535 10.85L14.2035 9.725L8.27848 6.3L6.32848 7.425L12.2535 10.85Z" />
    </svg>
  )
}
