export enum DatePickerSizeEnum {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export interface DatePickerProps {
  id?: string
  className?: string
  disabled?: boolean
  size?: keyof typeof DatePickerSizeEnum
  onChange?: (value?: string) => void
  value?: string

  placeholder?: string
  error?: string
  onBlur?: () => void

  hasTime?: boolean
}
