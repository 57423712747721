import { useState } from 'react'
import { Checkbox, InputMask, Select, Text } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { parseDateTimeToISO } from '@/helpers'
import { useUserList } from '@/services'

import { getMultiValue, onChangeMultiValue } from './cards.helpers'
import type { DrawerFilterCardProps } from './cards.type'

import {
  LogisticShipmentStatusEnum,
  OrderPaymentStatusEnum,
  OrderInvoiceStatusEnum,
  ProfileMoscowsClassificationEnum,
  ProfileTaxStatusEnum,
  SaleOrderStatusEnum,
} from '@/types'

import styles from './cards.module.css'

export const DrawerFilterTaxStatusCard = ({
  filter,
  onChangeFilter,
}: DrawerFilterCardProps) => {
  const { t } = useTranslation('screenOrderCenterPage')
  const { t: tEnums } = useTranslation('enums')

  return (
    <div className={styles['content-filter']}>
      <Text variant="title-base">{t('drawerFilterTaxStatusTitle')}</Text>
      {Object.keys(ProfileTaxStatusEnum)?.map((status) => (
        <Checkbox
          key={status}
          value={getMultiValue(filter, 'tax_status', status)}
          onChange={() =>
            onChangeMultiValue(filter, onChangeFilter, 'tax_status', status)
          }
        >
          {tEnums(`profile_tax_status_${status}`)}
        </Checkbox>
      ))}
    </div>
  )
}

export const DrawerFilterInvoiceStatusCard = ({
  filter,
  onChangeFilter,
}: DrawerFilterCardProps) => {
  const { t } = useTranslation('screenOrderCenterPage')
  const { t: tEnums } = useTranslation('enums')

  return (
    <div className={styles['content-filter']}>
      <Text variant="title-base">{t('drawerFilterInvoiceStatusTitle')}</Text>

      {Object.keys(OrderInvoiceStatusEnum)?.map((status) => (
        <Checkbox
          key={status}
          value={getMultiValue(filter, 'invoice_status', status)}
          onChange={() =>
            onChangeMultiValue(filter, onChangeFilter, 'invoice_status', status)
          }
        >
          {tEnums(`order_invoice_status_${status}`)}
        </Checkbox>
      ))}
    </div>
  )
}

export const DrawerFilterPaymentStatusCard = ({
  filter,
  onChangeFilter,
}: DrawerFilterCardProps) => {
  const { t } = useTranslation('screenOrderCenterPage')
  const { t: tEnums } = useTranslation('enums')

  return (
    <div className={styles['content-filter']}>
      <Text variant="title-base">{t('drawerFilterPaymentStatusTitle')}</Text>

      {Object.keys(OrderPaymentStatusEnum)?.map((status) => (
        <Checkbox
          key={status}
          value={getMultiValue(filter, 'payment_status', status)}
          onChange={() =>
            onChangeMultiValue(filter, onChangeFilter, 'payment_status', status)
          }
        >
          {tEnums(`order_payment_status_${status}`)}
        </Checkbox>
      ))}
    </div>
  )
}

export const DrawerFilterMoscowCard = ({
  filter,
  onChangeFilter,
}: DrawerFilterCardProps) => {
  const { t } = useTranslation('screenOrderCenterPage')
  const { t: tEnums } = useTranslation('enums')

  return (
    <div className={styles['content-filter']}>
      <Text variant="title-base">{t('drawerFilterTaxStatusMoscowLabel')}</Text>

      {Object.keys(ProfileMoscowsClassificationEnum)?.map((status) => (
        <Checkbox
          key={status}
          value={getMultiValue(filter, 'moscow', status)}
          onChange={() =>
            onChangeMultiValue(filter, onChangeFilter, 'moscow', status)
          }
        >
          {tEnums(`profile_moscow_${status}`)}
        </Checkbox>
      ))}
    </div>
  )
}

export const DrawerFilterCreatedAtIntervalCard = ({
  filter,
  onChangeFilter,
}: DrawerFilterCardProps) => {
  const { t } = useTranslation('screenOrderCenterPage')

  const [createdAtBefore, setCreatedAtBefore] = useState('')
  const [createdAtAfter, setCreatedAtAfter] = useState('')

  const onApplyDate = (key: string) => {
    const value = key === 'createdAtBefore' ? createdAtBefore : createdAtAfter

    onChangeFilter({
      ...filter,
      [key]: value === '__/__/____' ? '' : parseDateTimeToISO(value),
    })
  }

  return (
    <div className={styles['content-filter']}>
      <Text variant="title-base">{t('drawerFilterCreatedAtTitle')}</Text>

      <div className={styles['content-filter-row']}>
        <InputMask
          value={createdAtAfter}
          onChange={setCreatedAtAfter}
          placeholder="dd/mm/aaaa"
          mask="99/99/9999"
          onBlur={() => onApplyDate('createdAtAfter')}
        />

        <Text>-</Text>

        <InputMask
          value={createdAtBefore}
          onChange={setCreatedAtBefore}
          placeholder="dd/mm/aaaa"
          mask="99/99/9999"
          onBlur={() => onApplyDate('createdAtBefore')}
        />
      </div>
    </div>
  )
}

export const DrawerFilterKamCard = ({
  filter,
  onChangeFilter,
}: DrawerFilterCardProps) => {
  const { t } = useTranslation('screenOrderCenterPage')

  const { data: dataUsers } = useUserList({
    params: {
      limit: 100,
      page: 1,
      isStaff: 'true',
    },
  })

  const dataUsersList = dataUsers?.data?.results?.filter(
    (user) => user?.firstName,
  )

  return (
    <div className={styles['content-filter']}>
      <Text variant="title-base">{t('drawerFilterKAM')}</Text>
      <Select
        value={filter.keyAccountManager || ''}
        options={[
          { label: t('drawerFilterKAMNoFilled'), value: '' },
          ...(dataUsersList?.map((user) => ({
            label: `${user?.firstName} ${user?.lastName}`,
            value: `${user?.id}`,
          })) || []),
        ]}
        onChange={(newValue) =>
          onChangeFilter({ ...filter, keyAccountManager: newValue })
        }
      />{' '}
    </div>
  )
}

export const DrawerFilterLogisticStatusCard = ({
  filter,
  onChangeFilter,
}: DrawerFilterCardProps) => {
  const { t } = useTranslation('screenOrderCenterPage')
  const { t: tEnums } = useTranslation('enums')

  return (
    <div className={styles['content-filter']}>
      <Text variant="title-base">{t('drawerFilterLogisticStatusTitle')}</Text>

      {Object.keys(LogisticShipmentStatusEnum)?.map((status) => (
        <Checkbox
          key={status}
          value={getMultiValue(filter, 'shipment_status', status)}
          onChange={() =>
            onChangeMultiValue(
              filter,
              onChangeFilter,
              'shipment_status',
              status,
            )
          }
        >
          {tEnums(`logistic_shipment_status_${status}`)}
        </Checkbox>
      ))}
    </div>
  )
}

export const DrawerFilterSalesOrderStatusCard = ({
  filter,
  onChangeFilter,
}: DrawerFilterCardProps) => {
  const { t } = useTranslation('screenOrderCenterPage')
  const { t: tEnums } = useTranslation('enums')

  return (
    <div className={styles['content-filter']}>
      <Text variant="title-base">{t('drawerFilterSalesOrderStatusTitle')}</Text>

      {Object.keys(SaleOrderStatusEnum)?.map((status) => (
        <Checkbox
          key={status}
          value={getMultiValue(filter, 'status', status)}
          onChange={() =>
            onChangeMultiValue(filter, onChangeFilter, 'status', status)
          }
        >
          {tEnums(`sale_order_status_${status}`)}
        </Checkbox>
      ))}
    </div>
  )
}
