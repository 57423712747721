import styles from './tab.module.css'

export const selectedClassesItems: Record<'selected' | 'unselected', string> = {
  selected: `${styles.item} ${styles.selected}`,
  unselected: `${styles.item} ${styles.unselected}`,
}

export const generateClassesItems = (
  isSelected = false,
  className?: string,
) => {
  return `${selectedClassesItems[isSelected ? 'selected' : 'unselected']} ${className || ''}`
}
