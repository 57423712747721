import { useTranslation } from 'react-i18next'
import DraxlrEmbed from 'react-draxlr-embed'

import { Header } from '@/components'

import styles from './dashboard.module.css'

export const AnalyticsDashboard = () => {
  const { t } = useTranslation('screenDashboard')

  return (
    <div className={styles.core}>
      <Header title={t('title')} description={t('description')} />

      <div className={styles.content}>
        <div className={styles.wrapper}>
          <DraxlrEmbed
            id="672a8dcdfe25d1ac61aed007"
            apiKey="FBiM2Ti5S7p6LXXtWsow2hRqkPrSFU"
            type="dashboardGroup"
            options={{
              configuration: {
                hideHeader: false,
                showExport: false,
                backgroundColor: '#ffffff',
              },
              filters: {},
            }}
            iframeStyle={{
              width: '100%',
              height: '67.5rem',
              overflow: 'hidden',
            }}
            onBeforeLoad={() => console.log('Loading...')}
            onAfterLoad={() => console.log('Load complete')}
            onError={(error) => console.error('Error:', error)}
          />
        </div>
      </div>
    </div>
  )
}
