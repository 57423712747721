import { Text } from '../text'

import { generateClasses } from './alert.constants'

import styles from './alert.module.css'

import { AlertProps, AlertVariantEnum } from './alert.type'

const Alert = ({
  children,
  className,
  variant = AlertVariantEnum.primary,
  leftIcon,
  title,
}: AlertProps) => {
  const alertClasses = generateClasses(variant, className)
  return (
    <section className={alertClasses}>
      {leftIcon ? <div className={styles['left-icon']}>{leftIcon}</div> : null}
      <div className={styles.content}>
        {title ? (
          <Text variant="title-base" className={styles.title}>
            {title}
          </Text>
        ) : null}
        <Text className={styles.content}>{children}</Text>
      </div>
    </section>
  )
}

export default Alert
