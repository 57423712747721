import { Input, Label } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import type { AddressFormProps } from './address-form.type'

import styles from './address-form.module.css'

export const AddressForm = ({
  onChangeValues,
  values,
  errors,
}: AddressFormProps) => {
  const { t } = useTranslation('screenQuotationRequestList')

  const onHandleValues = (key: string, value: string | number) => {
    onChangeValues({ ...values, [key]: value })
  }

  return (
    <div className={styles.core}>
      <div>
        <Label>{t('addressFormPostalCode')}</Label>
        <Input
          value={values?.postalCode}
          onChange={(newValue) => onHandleValues('postalCode', newValue)}
          error={errors?.postalCode}
        />
      </div>

      <div>
        <Label>{t('addressFormState')}</Label>
        <Input
          value={values?.state}
          onChange={(newValue) => onHandleValues('state', newValue)}
          error={errors?.state}
        />
      </div>

      <div>
        <Label>{t('addressFormCity')}</Label>
        <Input
          value={values?.city}
          onChange={(newValue) => onHandleValues('city', newValue)}
          error={errors?.city}
        />
      </div>

      <div>
        <Label>{t('addressFormNeighborhood')}</Label>
        <Input
          value={values?.neighborhood}
          onChange={(newValue) => onHandleValues('neighborhood', newValue)}
          error={errors?.neighborhood}
        />
      </div>

      <div className={styles.row}>
        <div>
          <Label>{t('addressFormStreet')}</Label>
          <Input
            value={values?.street}
            onChange={(newValue) => onHandleValues('street', newValue)}
            error={errors?.street}
          />
        </div>

        <div>
          <Label>{t('addressFormNumber')}</Label>
          <Input
            value={values?.number ? String(values?.number) : ''}
            onChange={(newValue) => onHandleValues('number', newValue)}
            error={errors?.number}
          />
        </div>
      </div>

      <div>
        <Label>
          {t('addressFormReference')}{' '}
          <span>{t('productFormFieldOptional')}</span>
        </Label>
        <Input
          isTextArea
          value={values?.reference}
          onChange={(newValue) => onHandleValues('reference', newValue)}
          error={errors?.reference}
        />
      </div>
    </div>
  )
}
