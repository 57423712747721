import { useMutation, useQuery } from '@tanstack/react-query'
import { convertObjectToCamelCase, convertObjectToSnakeCase } from '@/helpers'

import { AddressProps } from '@/types'

import { QUERIES_ADMIN } from './api.constants'
import { api } from './api.service'
import { ADDRESSES_URL, ADD_ADDRESS_URL } from './api.urls'

export const useAddressList = (profileUuid: string) => {
  const getAddressList = async (): Promise<{
    success: boolean
    message: string
    data?: { addresses: AddressProps[] }
  }> => {
    try {
      const { data } = await api.get(ADDRESSES_URL(profileUuid))

      return {
        success: true,
        message: 'Address requests fetched successfully!',
        data: convertObjectToCamelCase(data) as { addresses: AddressProps[] },
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get adresses!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.ADDRESS.module, profileUuid],
    queryFn: () => getAddressList(),
    enabled: !!profileUuid,
  })
}

export const useCreateAddress = (profileUuid: string) => {
  const createAddress = async (
    payload: Partial<AddressProps>,
  ): Promise<{
    success: boolean
    message: string
    data?: AddressProps
  }> => {
    try {
      const { data } = await api.post(
        ADD_ADDRESS_URL(profileUuid),
        convertObjectToSnakeCase(payload),
      )

      return {
        success: true,
        message: 'Address created successfully!',
        data: convertObjectToCamelCase(data) as AddressProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to create address!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.ADDRESS.module],
    mutationFn: (payload: Partial<AddressProps>) => createAddress(payload),
  })
}
