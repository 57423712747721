import { Button, Drawer } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { appOrderLink } from '@/constants'
import { calculateOrderValues } from '@/helpers'
import { useOrderDetail } from '@/services'

import {
  OrderHeader,
  OrderSummaryStatus,
  CardInvoice,
  CardPaymentProof,
  OrderSummaryDelivery,
  OrderSummaryProducts,
  OrderSummaryValues,
  OrderSummaryProfile,
  OrderSummaryPayment,
  OrderTimelineStatus,
} from './components'

import styles from './drawer-show.module.css'

import type { DrawerShowProps } from './drawer-show.type'

export const OrderDrawerShow = ({
  isOpen,
  onHandleOpen,
  orderUuid,
}: DrawerShowProps) => {
  const { t } = useTranslation('screenOrderList')

  const { data: order, isLoading } = useOrderDetail(orderUuid)

  const { discount, iva, subTotal, total } = calculateOrderValues(
    order?.data?.items || [],
    order?.data?.deliveryOption === 'pick_up'
      ? 0
      : +(order?.data?.totalFreightPrice || 0),
    +(order?.data?.discount || 0),
  )

  return (
    <Drawer isOpen={isOpen} onHandleOpen={() => onHandleOpen(false)}>
      <div className={styles['header-wrap']}>
        <OrderHeader
          reference={order?.data?.reference || ''}
          createdAt={order?.data?.createdAt || ''}
          updatedAt={order?.data?.updatedAt || ''}
          onHandleOpen={onHandleOpen}
          isLoading={isLoading}
        />
      </div>

      <div className={styles.content}>
        <OrderSummaryStatus
          status={order?.data?.status || 'pending_buyer_data'}
          isLoading={isLoading}
        />

        {(order?.data?.invoicePdf || order?.data?.invoiceXml) && (
          <CardInvoice
            invoicePDFUrl={order?.data?.invoicePdf || ''}
            invoiceXMLUrl={order?.data?.invoiceXml || ''}
            orderReference={order?.data?.reference || ''}
          />
        )}

        {order?.data?.paymentProof && (
          <CardPaymentProof
            paymentProof={order?.data?.paymentProof || ''}
            orderReference={order?.data?.reference || ''}
          />
        )}

        <OrderSummaryProfile
          companyName={order?.data?.buyerProfile?.companyName || ''}
          email={order?.data?.buyerProfile?.email || ''}
          name={order?.data?.buyerProfile?.name || ''}
          phone={order?.data?.buyerProfile?.phone || ''}
          isLoading={isLoading}
        />

        <OrderSummaryProducts
          dollarQuotation={order?.data?.dollarExchangeRate || 0}
          items={order?.data?.items || []}
          isLoading={isLoading}
          expectedDeliveryDays={order?.data?.expectedDeliveryDays || 0}
        />

        <OrderSummaryPayment isLoading={isLoading} />

        <OrderSummaryDelivery
          buyerAddress={''} // review this
          deliveryOption={order?.data?.deliveryOption || ''}
          dollarQuotation={order?.data?.dollarExchangeRate || 0}
          totalFreightPrice={order?.data?.totalFreightPrice || 0}
          firstMileFreightCost={order?.data?.firstMileFreightCost || 0}
          lastMileFreightCost={order?.data?.lastMileFreightCost || 0}
          customsFreightCost={order?.data?.customsFreightCost || 0}
          financingCost={order?.data?.financingCost || 0}
          expectedDeliveryDays={order?.data?.expectedDeliveryDays || 0}
          expectedDeliveryAt={order?.data?.expectedDeliveryAt || ''}
          deliveredAt={order?.data?.deliveredAt || ''}
          isLoading={isLoading}
        />

        <OrderTimelineStatus
          isLoading={isLoading}
          statusHistory={order?.data?.statusHistory}
        />

        <div className={styles['summary-values-wrap']}>
          <OrderSummaryValues
            isLoading={isLoading}
            discount={discount}
            dollarQuotation={+(order?.data?.dollarExchangeRate || 0)}
            iva={iva}
            subTotal={subTotal}
            total={total}
            totalFreight={
              order?.data?.deliveryOption === 'pick_up'
                ? 0
                : +(order?.data?.totalFreightPrice || 0)
            }
          />
        </div>
      </div>

      <div className={styles.footer}>
        <Button variant="secondary" onClick={() => onHandleOpen(false)}>
          {t('orderShowButtonClose')}
        </Button>

        <Button
          variant="light"
          href={appOrderLink(order?.data?.uuid || '')}
          target="_blank"
        >
          {t('orderShowButtonAppLink')}
        </Button>
      </div>
    </Drawer>
  )
}
