import styles from './select.module.css'

import { SelectSizeEnum } from './select.type'

export const sizes: Record<SelectSizeEnum, string> = {
  small: styles['size-small'],
  medium: styles['size-medium'],
  large: styles['size-large'],
}

export const generateClasses = (
  isEmpty: boolean,
  size: keyof typeof SelectSizeEnum,
  error?: boolean,
) => {
  return `${styles.core} ${sizes[size]} ${!error || styles['state-danger']} ${isEmpty ? styles.placeholder : ''}`
}
