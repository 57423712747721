import { useEffect, useState } from 'react'
import { ChevronLeftIcon, ChevronRightIcon, Text } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { formatCurrency, formatNumber } from '@/helpers'

import {
  generateClassesOfEllipsesTable,
  generateClassesOfProductItem,
} from './carousel-products.constants'

import { CarouselProductsProps } from './carousel-products.type'

import styles from './carousel-products.module.css'

export const CarouselProducts = ({
  items,
  dollarExchangeRate,
}: CarouselProductsProps) => {
  const { t } = useTranslation('screenOrderCenterPage')

  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    setCurrentIndex(0)
  }, [items])

  const onClickPrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? items.length - 1 : prevIndex - 1,
    )
  }

  const onClickNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === items.length - 1 ? 0 : prevIndex + 1,
    )
  }

  const getItemState = (index: number) => {
    if (index === currentIndex) return 'active'
    if (index === (currentIndex - 1 + items.length) % items.length)
      return 'prev'
    if (index === (currentIndex + 1) % items.length) return 'next'
    return 'inactive'
  }

  return (
    <div className={styles.core}>
      <div className={styles.header}>
        <Text variant="text-large-bold">{t('carouselProductsTitle')}</Text>

        <div className={styles['indicators-container']}>
          {items?.length > 1 && (
            <button
              onClick={onClickPrev}
              className={styles['button-indicators']}
            >
              <ChevronLeftIcon />
            </button>
          )}
          <div className={styles['indicators-wrap']}>
            {items?.map((item, index) => (
              <button
                key={item.orderItemUuid}
                className={generateClassesOfEllipsesTable(
                  index === currentIndex,
                )}
                onClick={() => setCurrentIndex(index)}
              ></button>
            ))}
          </div>

          {items?.length > 1 && (
            <button
              onClick={onClickNext}
              className={styles['button-indicators']}
            >
              <ChevronRightIcon />
            </button>
          )}
        </div>
      </div>

      <div className={styles['products-container']}>
        {items?.map((item, index) => (
          <div
            key={item?.uuid}
            className={generateClassesOfProductItem(getItemState(index))}
          >
            <img
              src={item?.catalogProduct?.attributes?.mainImage}
              alt={item?.catalogProduct?.attributes?.title}
              className={styles['product-image']}
            />

            <div className={styles['product-info']}>
              <Text>{item?.catalogProduct?.attributes?.title}</Text>

              <div className={styles['product-info-row']}>
                <Text variant="title-base">
                  {t('carouselProductsQuantity')}
                </Text>

                <Text>{formatNumber(item?.quantity, 'es-MX')}</Text>
              </div>

              <div className={styles['product-info-row']}>
                <Text variant="title-base">
                  {t('carouselProductsPriceUnity')}
                </Text>

                <Text>
                  {formatCurrency(
                    +(item?.price || 0) * dollarExchangeRate,
                    'es-MX',
                    'MXN',
                  )}{' '}
                  ({formatCurrency(item?.price, 'es-MX', 'USD')})
                </Text>
              </div>

              <div className={styles['product-info-row']}>
                <Text variant="title-base">
                  {t('carouselProductsReference')}
                </Text>

                <Text>{item?.catalogProduct?.reference}</Text>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
