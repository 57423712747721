import { Text, TruckIcon, Skeleton } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { mercaiAddressCD, linkMapsLocationMercai } from '@/constants'
import { formatCurrency, formatNumber, formatDate } from '@/helpers'

import type { SummaryDeliveryProps } from './summary-delivery.type'

import styles from './summary-delivery.module.css'

const OrderSummaryDeliveryDefault = ({
  buyerAddress,
  totalFreightPrice,
  customsFreightCost,
  financingCost,
  firstMileFreightCost,
  lastMileFreightCost,
  dollarQuotation,
  deliveryOption,
  expectedDeliveryDays,
  deliveredAt,
  expectedDeliveryAt,
}: SummaryDeliveryProps) => {
  const { t } = useTranslation('screenOrderList')

  const mxnAmountPrice = formatCurrency(
    totalFreightPrice * dollarQuotation,
    'es-MX',
    'MXN',
  )
  const usdAmountPrice = formatCurrency(totalFreightPrice, 'es-MX', 'USD')

  return (
    <div className={styles.core}>
      <div className={styles.title}>
        <TruckIcon />
        <Text variant="title-base" className={styles.title}>
          {t('orderShowTitleSummaryDelivery')}
        </Text>
      </div>

      <div className={styles.content}>
        {deliveryOption === 'pick_up' ? (
          <>
            <Text variant="text-base">
              {t(`orderShowSummaryDeliveryPickUpTitle`)}
            </Text>

            <Text variant="text-base" className={styles.description}>
              {t('orderShowSummaryDeliveryPickUpDescription', {
                address: mercaiAddressCD,
              })}

              <Text
                variant="link"
                href={linkMapsLocationMercai}
                target="_blank"
              >
                {t('orderShowSummaryDeliveryPickUpDescriptionMaps')}
              </Text>
            </Text>
          </>
        ) : (
          <>
            <Text variant="text-base">
              {t(`orderShowSummaryDeliveryDeliveryTitle`)}
            </Text>

            <Text variant="text-base">{buyerAddress}</Text>
          </>
        )}

        <div className={styles['content-row']}>
          <Text variant="title-base">
            {t('orderShowSummaryDeliveryFreightPrice')}:{' '}
          </Text>

          {totalFreightPrice ? (
            <Text variant="text-base">
              {mxnAmountPrice} ({usdAmountPrice})
            </Text>
          ) : (
            <Text variant="text-base">{t('orderShowSummaryDeliveryFree')}</Text>
          )}
        </div>

        <div className={styles['content-row']}>
          <Text variant="title-base">
            {t('orderShowSummaryDeliveryFirstMileFreightCost')}:{' '}
          </Text>

          {firstMileFreightCost ? (
            <Text variant="text-base">
              {formatCurrency(
                firstMileFreightCost * dollarQuotation,
                'es-MX',
                'MXN',
              )}{' '}
              ({formatCurrency(firstMileFreightCost, 'es-MX', 'USD')})
            </Text>
          ) : (
            <Text variant="text-base">
              {t('orderShowSummaryDeliveryUninformedData')}
            </Text>
          )}
        </div>

        <div className={styles['content-row']}>
          <Text variant="title-base">
            {t('orderShowSummaryDeliveryLastMileFreightCost')}:{' '}
          </Text>

          {lastMileFreightCost ? (
            <Text variant="text-base">
              {formatCurrency(
                lastMileFreightCost * dollarQuotation,
                'es-MX',
                'MXN',
              )}{' '}
              ({formatCurrency(lastMileFreightCost, 'es-MX', 'USD')})
            </Text>
          ) : (
            <Text variant="text-base">
              {t('orderShowSummaryDeliveryUninformedData')}
            </Text>
          )}
        </div>

        <div className={styles['content-row']}>
          <Text variant="title-base">
            {t('orderShowSummaryDeliveryCustomsFreightCost')}:{' '}
          </Text>

          {customsFreightCost ? (
            <Text variant="text-base">
              {formatCurrency(
                customsFreightCost * dollarQuotation,
                'es-MX',
                'MXN',
              )}{' '}
              ({formatCurrency(customsFreightCost, 'es-MX', 'USD')})
            </Text>
          ) : (
            <Text variant="text-base">
              {t('orderShowSummaryDeliveryUninformedData')}
            </Text>
          )}
        </div>

        <div className={styles['content-row']}>
          <Text variant="title-base">
            {t('orderShowSummaryDeliveryFinancingCost')}:{' '}
          </Text>

          {financingCost ? (
            <Text variant="text-base">
              {formatNumber(financingCost * 100, 'es-MX', 2)}%
            </Text>
          ) : (
            <Text variant="text-base">
              {t('orderShowSummaryDeliveryUninformedData')}
            </Text>
          )}
        </div>

        <div className={styles['content-row']}>
          <Text variant="title-base">
            {t('orderShowSummaryDeliveryExpectedDeliveryDays')}:{' '}
          </Text>
          <Text variant="text-base">
            {expectedDeliveryDays
              ? `${expectedDeliveryDays} ${t('orderShowSummaryDeliveryDaysAfterPayment')}`
              : t('orderShowSummaryDeliveryDateNotDefined')}
          </Text>
        </div>

        <div className={styles['content-row']}>
          <Text variant="title-base">
            {t('orderShowSummaryDeliveryExpectedDeliveryAt')}:{' '}
          </Text>
          <Text variant="text-base">
            {expectedDeliveryAt
              ? formatDate(expectedDeliveryAt)
              : t('orderShowSummaryDeliveryDateNotDefined')}
          </Text>
        </div>

        <div className={styles['content-row']}>
          <Text variant="title-base">
            {t('orderShowSummaryDeliveryDeliveredAt')}:{' '}
          </Text>
          <Text variant="text-base">
            {deliveredAt
              ? formatDate(deliveredAt)
              : t('orderShowSummaryDeliveryDateNotDefined')}
          </Text>
        </div>
      </div>
    </div>
  )
}

const OrderSummaryDeliveryLoading = () => {
  return (
    <div className={styles.core}>
      <div className={styles.title}>
        <Skeleton height="1.5rem" width="1.5rem" />
        <Skeleton height="1rem" width="8rem" />
      </div>

      <div className={styles.content}>
        <Skeleton height="1rem" width="100%" />
        <Skeleton height="1rem" width="60%" />
      </div>
    </div>
  )
}

export const OrderSummaryDelivery = (props: SummaryDeliveryProps) => {
  if (props.isLoading) {
    return <OrderSummaryDeliveryLoading />
  }

  return <OrderSummaryDeliveryDefault {...props} />
}
