import InputMaskLib from 'react-input-mask'
import { Text } from '../text'

import { InputMaskProps, InputMaskSizeEnum } from './input-mask.type'

import { generateClasses } from './input-mask.constants'

import styles from './input-mask.module.css'

export const InputMask = ({
  className,
  disabled,
  onChange,
  size = InputMaskSizeEnum.medium,
  value = '',
  iconLeft,
  iconRight,
  placeholder,
  id,
  mask,
  alwaysShowMask,
  error,
  onBlur,
  onKeyDown,
}: InputMaskProps) => {
  const classes = generateClasses(size, !!error)

  return (
    <div className={className}>
      <div className={classes}>
        {!iconLeft || <div className={styles['left-icon']}>{iconLeft} </div>}
        <InputMaskLib
          disabled={disabled}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            !disabled && onChange && onChange(e.target.value)
          }
          value={value}
          placeholder={placeholder}
          id={id}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          mask={mask}
          alwaysShowMask={alwaysShowMask}
        />
        {!iconRight || <div className={styles['right-icon']}>{iconRight}</div>}
      </div>
      {!error || <Text className={styles.error}>{error}</Text>}
    </div>
  )
}
