import { useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { Button, Drawer, Label, Select } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { onSuccessMessage, onErrorMessage } from '@/helpers'

import { useUpdateUser, QUERIES_ADMIN } from '@/services'

import { ProfileHeader, CardTaxSituation } from './components'

import styles from './drawer-form-profile.module.css'

import type { DrawerFormProfileProps } from './drawer-form-profile.type'

export const ProfileDrawerForm = ({
  isOpen,
  onHandleOpen,
  email,
  taxStatus,
  taxSituation,
  id,
}: DrawerFormProfileProps) => {
  const { t } = useTranslation('screenOrderList')
  const { t: tEnums } = useTranslation('enums')

  const queryClient = useQueryClient()

  const errors: Record<string, string> = {}

  const [values, setValues] = useState<{
    taxStatus?: string
  }>({})

  const onHandleValues = (key: string, value: string) => {
    setValues((prev) => ({ ...prev, [key]: value }))
  }

  useEffect(() => {
    if (taxStatus) {
      setValues({
        taxStatus,
      })
    }
  }, [taxStatus])

  const { mutateAsync, isPending: isLoadingUpdate } = useUpdateUser(id)

  const onHandleSubmit = async () => {
    const response = await mutateAsync({
      taxStatus: values?.taxStatus,
    })

    if (response.success) {
      onSuccessMessage(
        t('profileFormSuccessTitle'),
        t('profileFormSuccessUpdateMessage'),
      )

      onHandleOpen(false)

      queryClient.invalidateQueries({
        queryKey: [QUERIES_ADMIN.ORDER.module],
      })
    } else {
      onErrorMessage(
        t('profileFormErrorTitle'),
        t('profileFormErrorUpdateMessage'),
      )
    }
  }

  return (
    <Drawer isOpen={isOpen} onHandleOpen={() => onHandleOpen(false)}>
      <div className={styles['header-wrap']}>
        <ProfileHeader email={email} onHandleOpen={onHandleOpen} />
      </div>

      <div className={styles.content}>
        {taxSituation && <CardTaxSituation taxSituation={taxSituation} />}

        <div>
          <Label>{t('profileFormTaxStatusLabel')}</Label>
          <Select
            placeholder={t('profileFormTaxStatusPlaceholder')}
            value={values?.taxStatus}
            error={errors?.taxStatus as string}
            onChange={(newValue) => onHandleValues('taxStatus', newValue)}
            options={[
              {
                label: tEnums('profile_tax_status_pending'),
                value: 'pending',
              },
              {
                label: tEnums('profile_tax_status_review'),
                value: 'review',
              },
              {
                label: tEnums('profile_tax_status_completed'),
                value: 'completed',
              },
              {
                label: tEnums('profile_tax_status_invalid'),
                value: 'invalid',
              },
            ]}
          />
        </div>
      </div>

      <div className={styles.footer}>
        <Button variant="secondary" onClick={() => onHandleOpen(false)}>
          {t('profileFormButtonClose')}
        </Button>
        <Button onClick={onHandleSubmit}>
          {isLoadingUpdate
            ? t('profileFormButtonSubmitLoading')
            : t('profileFormButtonSubmit')}
        </Button>
      </div>
    </Drawer>
  )
}
