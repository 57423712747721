import { useEffect, ReactNode } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button } from '@mercai/clever'

import { ROUTES_ADMIN } from '@/router'

import { Header } from '@/components'
import { trackEvent } from '@/helpers'

import { QuotationRequestList } from '../quotation-request'
import { QuotationList } from '../quotation'
// import { OrderList } from '../order'

import styles from './list.module.css'

export const BusinessList = () => {
  const { t } = useTranslation('screenBusinessList')

  const location = useLocation()
  const page = location.pathname.split('/').pop()

  const objectsRoute: Record<string, ReactNode> = {
    'quotation-request': <QuotationRequestList />,
    quotation: <QuotationList />,
    // order: <OrderList />,
  }

  useEffect(() => {
    trackEvent('adminBusinessListPageView', { page })
  }, [page])

  return (
    <div>
      <Header title={t('title')} description={t('description')} />

      <div className={styles.content}>
        <div className={styles.buttons}>
          <Button
            variant="secondary"
            model="pill"
            isActive={page === 'quotation-request'}
            href={ROUTES_ADMIN.BUSINESS.fullPath('quotation-request')}
          >
            {t('buttonRFQs')}
          </Button>
          <Button
            variant="secondary"
            model="pill"
            isActive={page === 'quotation'}
            href={ROUTES_ADMIN.BUSINESS.fullPath('quotation')}
          >
            {t('buttonQuotations')}
          </Button>
          {/* <Button
            variant="secondary"
            model="pill"
            isActive={page === 'order'}
            href={ROUTES_ADMIN.BUSINESS.fullPath('order')}
          >
            {t('buttonOrders')}
          </Button> */}
        </div>

        {objectsRoute?.[page as string]}
      </div>
    </div>
  )
}
