import { useTranslation } from 'react-i18next'

import { AlertIcon, Text } from '@mercai/clever'

import { InfoList } from '@/components'
import { formatDate } from '@/helpers'
import { ROUTES_ADMIN } from '@/router'

import styles from './order-quotation.module.css'

import type { OrderQuotationProps } from './order-quotation.type'

export const OrderQuotation = ({
  createdAt,
  creatorEmail,
  reference,
  uuid,
  isLoading,
}: OrderQuotationProps) => {
  const { t } = useTranslation('componentOrderCenterShow')

  return (
    <div>
      <InfoList
        isLoading={isLoading}
        iconTitle={<AlertIcon />}
        title={t('orderQuotationTitle')}
        list={[
          {
            label: t('orderQuotationQuotation'),
            value: reference ? (
              <Text
                variant="link"
                href={ROUTES_ADMIN.BUSINESS.QUOTATION.SHOW.fullPath(uuid || '')}
                target="_blank"
                className={styles.link}
              >{`#${reference}`}</Text>
            ) : (
              '-'
            ),
          },
          {
            label: t('orderQuotationCreator'),
            value: creatorEmail,
          },
          {
            label: t('orderQuotationCreatedAt'),
            value: createdAt ? formatDate(createdAt) : '-',
          },
        ]}
      />
    </div>
  )
}
