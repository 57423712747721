import { useState, useEffect } from 'react'
import { filesize } from 'filesize'
import axiosNoAuth, { AxiosProgressEvent } from 'axios'

import type {
  DragDropFileListProps,
  DragDropFileFileProps,
} from './drag-drop-file-list.type'

import { FileList, DragDropFile } from '../../core'

export const DragDropFileList = ({
  initialData,
  data: dataOutside,
  onChangeData,
  translations,
  urlAdd,
  urlRemove,
  successedUpload,
  configsRequest,
  error,
  axiosInstance,
}: DragDropFileListProps) => {
  const [uploadedFilesLocal, setUploadedFilesLocal] = useState<
    DragDropFileFileProps[]
  >(dataOutside || [])

  const axios = axiosInstance || axiosNoAuth

  const uploadedFiles = dataOutside || uploadedFilesLocal
  const setUploadedFiles = onChangeData || setUploadedFilesLocal

  const updateFile = (
    id: number | string,
    data: Partial<DragDropFileFileProps>,
  ): void => {
    setUploadedFiles((state) =>
      state?.map((file) => (file.id === id ? { ...file, ...data } : file)),
    )
  }

  const processUpload = (uploadedFile: DragDropFileFileProps): void => {
    const formData = new FormData()

    if (uploadedFile.file) {
      formData.append(
        configsRequest?.fileField || 'file',
        uploadedFile?.file,
        uploadedFile.name,
      )

      formData.append('filename', uploadedFile.name)
    }

    const config = {
      onUploadProgress: ({ loaded, total }: AxiosProgressEvent) => {
        const progress = parseInt(
          String(Math.round((loaded * 100) / (total || 1))),
        )

        updateFile(uploadedFile.id, {
          progress,
        })
      },
    }

    const request =
      configsRequest?.method === 'POST'
        ? axios.post(urlAdd, formData, config)
        : configsRequest?.method === 'PUT'
          ? axios.put(urlAdd, formData, config)
          : axios.patch(urlAdd, formData, config)

    request
      .then((response) => {
        updateFile(uploadedFile.id, {
          uploaded: true,
          id: response.data.id,
          url: response.data.url,
          progress: 100,
        })

        if (successedUpload) {
          successedUpload(response.data)
        }
      })
      .catch(() => {
        updateFile(uploadedFile.id, {
          error: true,
        })
      })
  }

  const handleUpload = (files: File[]): void => {
    const listFiles = files.map((file) => ({
      file,
      id: Math.random().toString(),
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: undefined,
    }))

    setUploadedFiles((prevFiles) => [...prevFiles, ...listFiles])

    for (let i = 0; i < listFiles.length; i++) {
      processUpload(listFiles[i])
    }
  }

  const onHandleRemove = () => {
    console.error('no implemented')
  }

  useEffect(() => {
    if (initialData) {
      setUploadedFiles(initialData)
    }
  }, [initialData])

  useEffect(() => {
    return () => {
      uploadedFiles?.forEach((file) => {
        if (file.preview) {
          URL.revokeObjectURL(file.preview)
        }
      })
    }
  }, [uploadedFiles])

  return (
    <>
      <DragDropFile
        onDrop={handleUpload}
        translations={translations}
        error={error}
      />

      <FileList
        data={uploadedFiles}
        onClickRemove={urlRemove ? onHandleRemove : undefined}
      />
    </>
  )
}
