import { useMutation } from '@tanstack/react-query'
import { convertObjectToCamelCase, convertObjectToSnakeCase } from '@/helpers'

import { FileSignProps } from '@/types'

import { QUERIES_ADMIN } from './api.constants'
import { api } from './api.service'
import { BASE_FILE_SIGN_URL } from './api.urls'

export const useFileSign = () => {
  const fileSign = async (payload: {
    filePath: string
  }): Promise<{
    success: boolean
    message: string
    data?: FileSignProps
  }> => {
    try {
      const { data } = await api.post(
        BASE_FILE_SIGN_URL,
        convertObjectToSnakeCase(payload),
      )

      return {
        success: true,
        message: 'Sign file successfully!',
        data: convertObjectToCamelCase(data) as FileSignProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to sign file!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.FILE.module],
    mutationFn: (payload: { filePath: string }) => fileSign(payload),
  })
}
