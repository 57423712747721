import { useTranslation } from 'react-i18next'
import { ChartIcon } from '@mercai/clever'

import { InfoList } from '@/components'

import { formatCurrency } from '@/helpers'

import styles from './order-logistic-cost.module.css'

import type { OrderLogisticCostProps } from './order-logistic-cost.type'

export const OrderLogisticCost = ({
  isLoading,
  shipment,
  totalQuantityShipment,
}: OrderLogisticCostProps) => {
  const { t } = useTranslation('componentOrderCenterShow')

  const getCostPerKg = () => {
    const totalCost =
      (Number(shipment?.customsCost) || 0) +
      (Number(shipment?.firstMileCost) || 0) +
      (Number(shipment?.lastMileCost) || 0)

    return formatCurrency(
      Number(totalCost) / (totalQuantityShipment || 1),
      'es-MX',
      'USD',
    )
  }

  return (
    <div className={styles.core}>
      <InfoList
        isLoading={isLoading}
        iconTitle={<ChartIcon />}
        title={t('orderLogisticCostShipmentTitle')}
        list={[
          {
            label: t('orderLogisticCostShipmentFirstMileCost'),
            value: shipment?.firstMileCost
              ? formatCurrency(shipment?.firstMileCost, 'es-MX', 'USD')
              : '-',
          },
          {
            label: t('orderLogisticCostShipmentLastMileCost'),
            value: shipment?.lastMileCost
              ? formatCurrency(shipment?.lastMileCost, 'es-MX', 'USD')
              : '-',
          },
          {
            label: t('orderLogisticCostShipmentCustomsCost'),
            value: shipment?.customsCost
              ? formatCurrency(shipment?.customsCost, 'es-MX', 'USD')
              : '-',
          },
          {
            label: t('orderLogisticCostShipmentExtraCost'),
            value: shipment?.extraCost
              ? formatCurrency(shipment?.extraCost, 'es-MX', 'USD')
              : '-',
          },
          {
            label: t('orderLogisticCostShipmentExtraCostReason'),
            value: shipment?.extraCostReason || '-',
          },
          {
            label: t('orderLogisticCostShipmentCostPerKg'),
            value: getCostPerKg(),
          },
        ]}
      />
    </div>
  )
}
