import { useEffect, useState } from 'react'

import { TableColumnsProps } from './table-columns.type'

import { Button } from '../../core/button'
import { Skeleton } from '../../core/skeleton'
import { ChevronDownIcon } from '../../../icons/chevron-down-icon'

import {
  generateClassesExpandableIcon,
  generateClassesExpandableRow,
} from './table-columns.constant'

import styles from './table-columns.module.css'

export const TableColumns = <T,>({
  columns,
  fixedColumns,
  data,
  isLoading,
  classNames,
  expandableComponent,
}: TableColumnsProps<T>) => {
  const localData = isLoading ? ([0, 1, 2, 3, 4, 5, 6, 7, 8, 9] as T[]) : data
  const [expandedRows, setExpandedRows] = useState<number[]>([])

  const toggleRow = (index: number) => {
    setExpandedRows((prev) => {
      if (prev.includes(index)) {
        return prev.filter((i) => i !== index)
      }
      return [...prev, index]
    })
  }

  useEffect(() => {
    setExpandedRows([])
  }, [data])

  function getValueByPath<U>(
    object: U,
    path: string | number,
  ): string | undefined {
    const keys = `${path}`.split('.')
    let result: any = object // eslint-disable-line
    for (const key of keys) {
      if (result == null) {
        return '-'
      }
      result = result[key]
    }
    return result
  }

  return (
    <div className={styles['table-wrap']}>
      <div className={styles['table-overflow']}>
        <div className={styles.table}>
          <ul className={styles.header}>
            {expandableComponent && (
              <li className={`${styles['header-column-expandable-icon']}`} />
            )}

            {columns?.map((column, index) => {
              const style = column?.width
                ? { minWidth: column.width, maxWidth: column.width }
                : undefined

              return (
                <li
                  className={styles['header-column']}
                  key={`${column.dataKey as string}_${column.title}_${index}`}
                  style={style}
                >
                  {column.title}
                </li>
              )
            })}
          </ul>

          <ul className={styles.body}>
            {Array.isArray(localData) &&
              localData?.map((record, index) => (
                <li key={index} className={styles['body-row-group']}>
                  <div className={styles['body-row']}>
                    {expandableComponent && (
                      <div
                        className={`${styles['body-column-expandable-icon']} ${classNames?.bodyColumn || ''}`}
                      >
                        <Button
                          variant="ghost"
                          model="square"
                          onClick={() => toggleRow(index)}
                          className={generateClassesExpandableIcon(
                            expandedRows.includes(index),
                          )}
                        >
                          <ChevronDownIcon />
                        </Button>
                      </div>
                    )}

                    {columns?.map((column) => {
                      const value =
                        getValueByPath(record, column?.dataKey as string) || '-'
                      const style = column?.width
                        ? { minWidth: column.width, maxWidth: column.width }
                        : undefined

                      if (isLoading) {
                        return (
                          <div
                            className={`${styles['body-column']} ${classNames?.bodyColumn || ''}`}
                            key={`loading_${column.key as string}_${column.title}_${index}`}
                            style={style}
                          >
                            {column.renderLoading || (
                              <Skeleton height="1rem" width="100%" />
                            )}
                          </div>
                        )
                      }

                      return (
                        <div
                          className={`${styles['body-column']} ${classNames?.bodyColumn || ''}`}
                          key={column.key as string}
                          style={style}
                        >
                          {column.render
                            ? column.render({
                                value: value === '-' ? undefined : value,
                                record,
                                index,
                              })
                            : value}
                        </div>
                      )
                    })}
                  </div>

                  {expandableComponent && (
                    <div
                      className={generateClassesExpandableRow(
                        expandedRows.includes(index),
                      )}
                    >
                      {expandableComponent({
                        record,
                        index,
                      })}
                    </div>
                  )}
                </li>
              ))}
          </ul>
        </div>
      </div>

      {fixedColumns && fixedColumns?.length > 0 && (
        <div className={styles['table-fixed']}>
          <ul className={styles.header}>
            {fixedColumns?.map((column) => {
              const style = column?.width
                ? { minWidth: column.width, maxWidth: column.width }
                : undefined

              return (
                <li
                  className={styles['header-column']}
                  key={column.key as string}
                  style={style}
                >
                  {column.title}
                </li>
              )
            })}
          </ul>

          <ul className={styles.body}>
            {Array.isArray(localData) &&
              localData?.map((record, index) => (
                <li className={styles['body-row']} key={index}>
                  {fixedColumns?.map((column) => {
                    const value =
                      getValueByPath(record, column?.dataKey as string) || '-'
                    const style = column?.width
                      ? { minWidth: column.width, maxWidth: column.width }
                      : undefined

                    if (isLoading) {
                      return (
                        <div
                          className={`${styles['body-column']} ${classNames?.bodyColumn || ''}`}
                          key={column.key as string}
                          style={style}
                        >
                          {column.renderLoading || (
                            <Skeleton height="1rem" width="100%" />
                          )}
                        </div>
                      )
                    }

                    return (
                      <div
                        className={`${styles['body-column']} ${classNames?.bodyColumn || ''}`}
                        key={column.key as string}
                        style={style}
                      >
                        {column.render
                          ? column.render({ value, record, index })
                          : value}
                      </div>
                    )
                  })}
                </li>
              ))}
          </ul>
        </div>
      )}
    </div>
  )
}
