import styles from './input-phone.module.css'

import { InputPhoneSizeEnum } from './input-phone.type'

export const sizes: Record<InputPhoneSizeEnum, string> = {
  small: styles['size-small'],
  medium: styles['size-medium'],
  large: styles['size-large'],
}

export const generateClasses = (
  size: keyof typeof InputPhoneSizeEnum,
  error?: boolean,
) => {
  return `${styles.core} ${sizes[size]} ${!error || styles['state-danger']}`
}
