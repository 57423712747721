import * as Yup from 'yup'

export const shemaProductItemMetal = (t: (key: string) => string) => {
  return {
    catalogProduct: Yup.object().shape({}).nullable(),
    product: Yup.object()
      .shape({
        title: Yup.string().required(t('productFormFieldRequiredMessage')),
        category: Yup.string().required(t('productFormFieldRequiredMessage')),
      })
      .required(t('productFormFieldRequiredMessage')),
    quantity: Yup.number()
      .min(0, t('productFormFieldRequiredMessage'))
      .required(t('productFormFieldRequiredMessage')),
    purchaseFrequency: Yup.string().required(
      t('productFormFieldRequiredMessage'),
    ),
  }
}

export const shemaProductItemPlastic = (t: (key: string) => string) => {
  return {
    catalogProduct: Yup.object().shape({}).nullable(),
    product: Yup.object()
      .shape({
        title: Yup.string().required(t('productFormFieldRequiredMessage')),
        category: Yup.string().required(t('productFormFieldRequiredMessage')),
        fluidity: Yup.string()
          .required(t('productFormFieldRequiredMessage'))
          .matches(
            /^(?:\d+(?:\.\d+)?-\d+(?:\.\d+)?|\d+(?:\.\d+)?)$/,
            t('productFormFieldInvalidFluidityMessage'),
          ),
      })
      .required(t('productFormFieldRequiredMessage')),
    quantity: Yup.number()
      .min(0, t('productFormFieldRequiredMessage'))
      .required(t('productFormFieldRequiredMessage')),
    purchaseFrequency: Yup.string().required(
      t('productFormFieldRequiredMessage'),
    ),
  }
}

export const shemaProductItemCatalogProduct = (t: (key: string) => string) => {
  return {
    catalogProduct: Yup.object()
      .shape({
        uuid: Yup.string().required(t('productFormFieldRequiredMessage')),
      })
      .required(t('productFormFieldRequiredMessage')),
    product: Yup.object().shape({}).nullable(),
    quantity: Yup.number()
      .min(0, t('productFormFieldRequiredMessage'))
      .required(t('productFormFieldRequiredMessage')),
    purchaseFrequency: Yup.string().required(
      t('productFormFieldRequiredMessage'),
    ),
  }
}
