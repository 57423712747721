import styles from './drawer.module.css'

export const statesOverlay: Record<'open' | 'close', string> = {
  open: styles['overlay-state-open'],
  close: styles['overlay-state-close'],
}

export const generateOverlayClasses = (
  state: 'open' | 'close',
  className?: string,
) => {
  return `${statesOverlay[state]} ${className || ''}`
}

export const statesDrawer: Record<'open' | 'close', string> = {
  open: styles['drawer-state-open'],
  close: '',
}

export const sizesDrawer: Record<'default' | 'double', string> = {
  default: styles['drawer-size-default'],
  double: styles['drawer-size-double'],
}

export const generateDrawerClasses = (
  state: 'open' | 'close',
  size: 'default' | 'double',
  className?: string,
) => {
  return `${styles.drawer} ${statesDrawer[state]} ${sizesDrawer[size]} ${className || ''}`
}
