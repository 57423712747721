import { useState } from 'react'
import {
  Accordion,
  BoxIcon,
  Tag,
  Text,
  Tooltip,
  ChevronDownIcon,
  Skeleton,
} from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { formatCurrency, formatNumber, trimString } from '@/helpers'

import styles from './summary-products.module.css'

import { QuotationSummaryProductsProps } from './summary-products.type'

const QuotationSummaryProductsDefault = ({
  products,
  dollarQuotation,
}: QuotationSummaryProductsProps) => {
  const { t } = useTranslation('screenQuotationList')
  const { t: tEnums } = useTranslation('enums')
  const [activeItems, setActiveItems] = useState<string[]>([])

  const onHandleOpenItem = (id: string) => {
    if (activeItems.includes(id)) {
      setActiveItems(activeItems.filter((item) => item !== id))
    } else {
      setActiveItems([...activeItems, id])
    }
  }

  const verifyItemIsActive = (id: string) => activeItems.includes(id)

  return (
    <div className={styles.core}>
      <div className={styles.title}>
        <BoxIcon />
        <Text variant="title-base" className={styles.title}>
          {t('quotationShowTitleSummaryProducts')}
        </Text>
      </div>

      <Accordion.Root
        activeItems={activeItems}
        onHandleActiveItems={setActiveItems}
        showArrow={false}
      >
        {products?.map((item) => (
          <Accordion.Item
            key={item.uuid}
            id={item.uuid}
            className={styles['table-item']}
            header={
              <div>
                <div className={styles['table-item-row']}>
                  <Text variant="title-base">
                    {t('quotationShowSummaryProductDescription')}:
                  </Text>
                  <Text variant="text-base">
                    <Tooltip
                      text={
                        item.catalogProduct?.attributes?.title ||
                        item?.nonCatalogProduct?.description
                      }
                      widthText="20rem"
                    >
                      {trimString(
                        item.catalogProduct?.attributes?.title ||
                          item?.nonCatalogProduct?.description,
                        28,
                        '...',
                      )}
                    </Tooltip>
                  </Text>
                </div>

                <div className={styles['table-item-row']}>
                  <Text variant="title-base">
                    {t('quotationShowSummaryProductSelected')}:
                  </Text>
                  <Text variant="text-base">
                    {item?.selected
                      ? t('quotationShowSummaryProductSelectedYes')
                      : t('quotationShowSummaryProductSelectedNo')}
                  </Text>
                </div>

                <div className={styles['table-item-row']}>
                  <Text variant="title-base">
                    {t('quotationShowSummaryProductQuantity')}:
                  </Text>
                  <Text variant="text-base">{formatNumber(item.quantity)}</Text>

                  <Tag
                    size="small"
                    variant="secondary"
                    className={styles['table-item-packaging']}
                  >
                    {tEnums(`order_itens_package_${item.package}`)}
                  </Tag>
                </div>

                <div className={styles['table-item-row']}>
                  <Text variant="title-base">
                    {t('quotationShowSummaryProductUnitPrice')}:
                  </Text>
                  <Text variant="text-base">
                    {formatCurrency(
                      +item.price * +dollarQuotation,
                      'es-MX',
                      'MXN',
                      3,
                    )}{' '}
                    ({formatCurrency(item.price, 'es-MX', 'USD', 3)})
                  </Text>
                </div>
                <div className={styles['table-item-row']}>
                  <Text variant="title-base">
                    {t('quotationShowSummaryProductTotalPrice')}:
                  </Text>
                  <Text variant="text-base">
                    {formatCurrency(
                      +item.quantity * +item.price * +dollarQuotation,
                      'es-MX',
                      'MXN',
                    )}{' '}
                    (
                    {formatCurrency(
                      +item.quantity * +item.price,
                      'es-MX',
                      'USD',
                    )}
                    )
                  </Text>
                </div>
              </div>
            }
            footer={
              <Text
                className={styles['table-item-button']}
                variant="button"
                onClick={() => onHandleOpenItem(item.uuid)}
              >
                {verifyItemIsActive(item.uuid)
                  ? t('quotationShowSummaryProductViewLess')
                  : t('quotationShowSummaryProductViewMore')}
                <ChevronDownIcon
                  className={
                    verifyItemIsActive(item.uuid)
                      ? ''
                      : styles['table-item-button-icon-revert']
                  }
                />
              </Text>
            }
          >
            <div className={styles['table-item-wrap']}>
              <div className={styles['table-item-row']}>
                <Text variant="title-base">
                  {t('quotationShowSummaryProductUnitCost')}:
                </Text>
                {item.cost ? (
                  <Text variant="text-base">
                    {formatCurrency(
                      +item.cost * +dollarQuotation,
                      'es-MX',
                      'MXN',
                      3,
                    )}{' '}
                    ({formatCurrency(item.cost, 'es-MX', 'USD', 3)})
                  </Text>
                ) : (
                  <Text variant="text-base">
                    {t('quotationShowSummaryProductNoData')}
                  </Text>
                )}
              </div>

              <div className={styles['table-item-row']}>
                <Text variant="title-base">
                  {t('quotationShowSummaryProductTotalCost')}:
                </Text>
                {item.cost ? (
                  <Text variant="text-base">
                    {formatCurrency(
                      +item.quantity * +item.cost * +dollarQuotation,
                      'es-MX',
                      'MXN',
                    )}{' '}
                    (
                    {formatCurrency(
                      +item.quantity * +item.cost,
                      'es-MX',
                      'USD',
                    )}
                    )
                  </Text>
                ) : (
                  <Text variant="text-base">
                    {t('quotationShowSummaryProductNoData')}
                  </Text>
                )}
              </div>

              <div className={styles['table-item-row']}>
                <Text variant="title-base">
                  {t('quotationShowSummaryTraderComission')}:
                </Text>
                <Text variant="text-base">
                  {formatCurrency(
                    +item.traderCommission * +dollarQuotation,
                    'es-MX',
                    'MXN',
                  )}{' '}
                  ({formatCurrency(+item.traderCommission, 'es-MX', 'USD')})
                </Text>
              </div>
              <div className={styles['table-item-row']}>
                <Text variant="title-base">
                  {t('quotationShowSummaryProductSupplier')}:
                </Text>
                <Text variant="text-base">
                  {item.supplier?.name ||
                    t('quotationShowSummaryProductNoData')}
                </Text>
              </div>
            </div>
          </Accordion.Item>
        ))}
      </Accordion.Root>
    </div>
  )
}

const QuotationSummaryProductsLoading = () => {
  return (
    <div className={styles.core}>
      <div className={styles.title}>
        <Skeleton height="1.5rem" width="1.5rem" />
        <Skeleton height="1rem" width="8rem" />
      </div>

      <div>
        <div className={styles['table-item-row']}>
          <Skeleton width="10rem" height="1rem" />
          <Skeleton width="12rem" height="1rem" />
        </div>
        <div className={styles['table-item-row']}>
          <Skeleton width="8rem" height="1rem" />
          <Skeleton width="13rem" height="1rem" />
        </div>
        <div className={styles['table-item-row']}>
          <Skeleton width="6rem" height="1rem" />
          <Skeleton width="14rem" height="1rem" />
        </div>
        <div className={styles['table-item-row']}>
          <Skeleton width="10rem" height="1rem" />
          <Skeleton width="8rem" height="1rem" />
        </div>
        <div className={styles['table-item-row']}>
          <Skeleton width="12rem" height="1rem" />
          <Skeleton width="10rem" height="1rem" />
        </div>

        <div className={styles['table-item-button']}>
          <Skeleton width="7rem" height="1rem" />
        </div>
      </div>
    </div>
  )
}

export const QuotationSummaryProducts = (
  props: QuotationSummaryProductsProps,
) => {
  if (props.isLoading) {
    return <QuotationSummaryProductsLoading />
  }

  return <QuotationSummaryProductsDefault {...props} />
}
