import { OrderTrackerProps } from '@/types'

const convertStringToNumber = (value: string | number) => {
  if (isNaN(Number(value))) {
    return 0
  }

  return Number(value)
}

const calculateGmv = (record: OrderTrackerProps) => {
  const { grossProfitData } = record || {}
  const { orderGmv } = grossProfitData || {}

  return convertStringToNumber(orderGmv)
}

export const calculateCogs = (record: OrderTrackerProps) => {
  const { grossProfitData } = record || {}
  const { orderTotalProductCost } = grossProfitData || {}

  return convertStringToNumber(orderTotalProductCost)
}

export const calculateFinancingCost = (record: OrderTrackerProps) => {
  const { grossProfitData } = record || {}
  const { orderFinancingCost } = grossProfitData || {}

  const financingCost = convertStringToNumber(orderFinancingCost)
  const gmv = calculateGmv(record)

  return gmv * financingCost
}

export const calculateGrossProfit = (record: OrderTrackerProps) => {
  const gmv = calculateGmv(record)
  const totalCost = calculateCogs(record)

  return gmv - totalCost
}

export const calculateGMVPercentage = (record: OrderTrackerProps) => {
  const gmv = calculateGmv(record)
  const grossProfit = calculateGrossProfit(record)

  return (grossProfit / gmv) * 100
}

export const calculateCM1 = (record: OrderTrackerProps) => {
  const { grossProfitData } = record || {}
  const { orderFirstMileFreightCost, orderCustomsFreightCost } =
    grossProfitData || {}

  const firstMileFreightCost = convertStringToNumber(orderFirstMileFreightCost)
  const customsFreightCost = convertStringToNumber(orderCustomsFreightCost)
  const grossProfit = calculateGrossProfit(record)

  return grossProfit - firstMileFreightCost - customsFreightCost
}

export const calculateCM1Percentage = (record: OrderTrackerProps) => {
  const cm1 = calculateCM1(record)
  const gmv = calculateGmv(record)

  if (gmv === 0) {
    return 0
  }

  return (cm1 / gmv) * 100
}

export const calculateCM2 = (record: OrderTrackerProps) => {
  const { grossProfitData } = record || {}
  const { orderLastMileFreightCost } = grossProfitData || {}

  const lastMileFreightCost = convertStringToNumber(orderLastMileFreightCost)
  const cm1 = calculateCM1(record)

  return cm1 - lastMileFreightCost
}

export const calculateCM2Percentage = (record: OrderTrackerProps) => {
  const cm2 = calculateCM2(record)
  const gmv = calculateGmv(record)

  if (gmv === 0) {
    return 0
  }

  return (cm2 / gmv) * 100
}

export const calculateCM3 = (record: OrderTrackerProps) => {
  const { grossProfitData } = record || {}
  const { orderTotalTraderCommission } = grossProfitData || {}

  const financingCost = calculateFinancingCost(record)
  const traderCommission = convertStringToNumber(orderTotalTraderCommission)

  const cm2 = calculateCM2(record)

  return cm2 - financingCost - traderCommission
}

export const calculateCM3Percentage = (record: OrderTrackerProps) => {
  const cm3 = calculateCM3(record)
  const gmv = calculateGmv(record)

  if (gmv === 0) {
    return 0
  }

  return (cm3 / gmv) * 100
}

const categories = {
  polymers: [
    'PLA',
    'ABS',
    'SBC',
    'TPU',
    'TPE',
    'EVA',
    'PA',
    'PC',
    'PVC',
    'PE',
    'PP',
    'MPE',
    'PS',
    'MB',
    'OTR',
    'PBT',
    'PET',
    'TPV',
    'COM',
    'ING',
  ],
  steel: [
    'ACE',
    'ALE',
    'ALN',
    'AGL',
    'CNL',
    'CDO',
    'CLV',
    'EST',
    'LMN',
    'MAL',
    'PRF',
    'PLC',
    'ROL',
    'TUB',
    'VAR',
  ],
  'polymers-engineering': [
    'PLA',
    'ABS',
    'SBC',
    'TPU',
    'TPE',
    'EVA',
    'PA',
    'PC',
    'PVC',
  ],
  'polymers-pp': ['PP'],
  'polymers-pe': ['PE', 'MPE'],
  'mild-steel': [
    'ALE',
    'ALN',
    'AGL',
    'CNL',
    'CDO',
    'CLV',
    'EST',
    'LMN',
    'MAL',
    'PRF',
    'PLC',
    'ROL',
    'TUB',
    'VAR',
  ],
  'stainless-steel': ['ACE'],
}

export const calculateGMVByCategory = (
  record: OrderTrackerProps,
  category:
    | 'polymers'
    | 'polymers-engineering'
    | 'polymers-pp'
    | 'polymers-pe'
    | 'steel'
    | 'mild-steel'
    | 'stainless-steel',
) => {
  const { itemsData } = record || {}

  const itemsOfCategory = itemsData?.filter((item) => {
    return categories?.[category]?.includes(
      item.productData.productCategory.shortName,
    )
  })

  return itemsOfCategory?.reduce((acc, item) => {
    const { productData } = item || {}
    const { productPrice, productQuantity } = productData || {}

    return acc + +productPrice * +productQuantity
  }, 0)
}

export const calculateCOGSByCategory = (
  record: OrderTrackerProps,
  category:
    | 'polymers'
    | 'polymers-engineering'
    | 'polymers-pp'
    | 'polymers-pe'
    | 'steel'
    | 'mild-steel'
    | 'stainless-steel',
) => {
  const { itemsData } = record || {}

  const itemsOfCategory = itemsData?.filter((item) => {
    return categories?.[category]?.includes(
      item.productData.productCategory.shortName,
    )
  })

  return itemsOfCategory?.reduce((acc, item) => {
    const { productData } = item || {}
    const { productCost, productQuantity } = productData || {}

    const productCostNumber = convertStringToNumber(productCost)
    const productQuantityNumber = convertStringToNumber(productQuantity)

    return acc + productCostNumber * productQuantityNumber
  }, 0)
}
