import { Text } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { formatLongDate, formatCurrency } from '@/helpers'

import { CarouselProducts } from './components'

import { OrderCenterExpandedColumnProps } from './expanded-column.type'

import styles from './expanded-column.module.css'

export const OrderCenterExpandedColumn = ({
  record,
}: OrderCenterExpandedColumnProps) => {
  const { t } = useTranslation('screenOrderCenterPage')

  return (
    <div className={styles.core}>
      <div className={styles.content}>
        <div className={styles.row}>
          <Text variant="title-base">
            {t('expandedColumnExpectedDeliveryDays')}
          </Text>
          <Text>
            {record.shipments?.[0]?.expectedDeliveryDays}{' '}
            {t('expandedColumnExpectedDeliveryDaysValue')}
          </Text>
        </div>

        <div className={styles.row}>
          <Text variant="title-base">{t('expandedColumnDeliveryAt')}</Text>
          <Text>
            {record.shipments?.[0]?.deliveredAt
              ? formatLongDate(record.shipments?.[0]?.deliveredAt)
              : '-'}
          </Text>
        </div>

        <div className={styles.row}>
          <Text variant="title-base">{t('expandedColumnOrigin')}</Text>
          <Text>{record?.shipments?.[0]?.originAddress || '-'}</Text>
        </div>

        <div className={styles.row}>
          <Text variant="title-base">{t('expandedColumnDestination')}</Text>
          <Text>{record?.shipments?.[0]?.destinationAddress || '-'}</Text>
        </div>

        <div className={styles.row}>
          <Text variant="title-base">{t('expandedColumnTransportPrice')}</Text>
          <Text>
            {formatCurrency(record?.totalFreightPrice, 'es-MX', 'USD')}
          </Text>
        </div>

        <div className={styles.row}>
          <Text variant="title-base">{t('expandedColumnAmount')}</Text>
          <Text>{formatCurrency(record?.totalOrderPrice, 'es-MX', 'USD')}</Text>
        </div>

        <div className={styles.row}>
          <Text variant="title-base">{t('expandedColumnKAM')}</Text>
          <Text>
            {typeof record?.keyAccountManager === 'string'
              ? record?.keyAccountManager
              : record?.keyAccountManager?.name}
          </Text>
        </div>
      </div>

      <div className={styles.content}>
        <CarouselProducts
          items={record?.items}
          dollarExchangeRate={+(record?.payments?.[0]?.dollarExchangeRate || 0)}
        />
      </div>
    </div>
  )
}
