import styles from './progress-step.module.css'

export const statesActive: Record<'active' | 'inactive', string> = {
  active: styles['is-active'],
  inactive: styles['is-inactive'],
}

export const generateClasses = (state: boolean, className?: string) => {
  return `${styles.step} ${statesActive[state ? 'active' : 'inactive']} ${className || ''}`
}
