import { useEffect, useState, useRef } from 'react'

import { InputSearch, DropDownSearch } from './components'
import { Skeleton } from '../../core'

import type { SelectSearchProps } from './select-search.type'

import styles from './select-search.module.css'

export const SelectSearch = <T,>({
  onSelectItem,
  selectedItem,
  keyLabel = 'label' as keyof T,
  keyValue = 'value' as keyof T,
  error,
  dataSearch,
  isLoadingSearch,
  onSearch,
  translations,
  renderContentItem,
}: SelectSearchProps<T>) => {
  const [value, setValue] = useState('')
  const [isOpen, setIsOpen] = useState(false)

  const onChangeValue = (newValue: string) => {
    setValue(newValue)
    onSearch(newValue)

    if (selectedItem?.[keyValue]) {
      onSelectItem({})
    }
  }

  const onHandleSelectItem = (itemParam: T) => {
    setIsOpen(false)
    onSelectItem(itemParam)
  }

  useEffect(() => {
    if (selectedItem?.[keyLabel]) {
      setValue(`${selectedItem?.[keyLabel]}`)
    }

    return () => {
      setValue('')
    }
  }, [selectedItem])

  const rootRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const closeOpennedDropDown = (e: MouseEvent) => {
      if (
        rootRef.current &&
        isOpen &&
        !rootRef.current.contains(e.target as Node)
      ) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', closeOpennedDropDown)

    return () => document.removeEventListener('mousedown', closeOpennedDropDown)
  }, [isOpen, rootRef])

  return (
    <div className={styles.core} ref={rootRef}>
      {isLoadingSearch && !isOpen ? (
        <div className={styles['wrap-skeleton']}>
          <Skeleton width="70%" height="1.25rem" />
          <Skeleton width="100%" height="2.5rem" />
        </div>
      ) : (
        <InputSearch
          onChange={onChangeValue}
          value={value}
          onOpen={setIsOpen}
          error={error}
          translations={translations}
        />
      )}

      <DropDownSearch
        isOpen={isOpen}
        data={dataSearch}
        isLoading={isLoadingSearch}
        onSelectItem={onHandleSelectItem}
        selectedItem={selectedItem}
        keyLabel={keyLabel}
        keyValue={keyValue}
        translations={translations}
        renderContentItem={renderContentItem}
      />
    </div>
  )
}
