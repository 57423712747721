import { Button, CloseIcon, Drawer, Text } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import {
  DrawerFilterStatusCard,
  DrawerFilterPaymentMethodCard,
  DrawerFilterCreatedAtIntervalCard,
} from './components'

import styles from './drawer-filter.module.css'

import { DrawerFilterProps } from './drawer-filter.type'

export const TransactionDrawerFilter = ({
  isOpen,
  onHandleOpen,
  filter,
  onHandleFilter,
}: DrawerFilterProps) => {
  const { t } = useTranslation('screenOrderCenterPage')

  const commonProps = {
    filter,
    onChangeFilter: onHandleFilter,
  }

  return (
    <Drawer isOpen={isOpen} onHandleOpen={onHandleOpen}>
      <div className={styles.header}>
        <Text variant="title-large" className={styles['header-title']}>
          {t('drawerFilterTransactionTitle')}
        </Text>

        <button
          className={styles['header-button']}
          type="button"
          onClick={onHandleOpen}
        >
          <CloseIcon />
        </button>
      </div>

      <div className={styles.content}>
        <DrawerFilterStatusCard {...commonProps} />
        <DrawerFilterPaymentMethodCard {...commonProps} />
        <DrawerFilterCreatedAtIntervalCard {...commonProps} />
      </div>

      <div className={styles.footer}>
        <Button variant="secondary" onClick={onHandleOpen}>
          {t('drawerFilterTaxStatusButtonBack')}
        </Button>
      </div>
    </Drawer>
  )
}
