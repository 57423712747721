import { useEffect, useState } from 'react'

import { Button, Drawer, Label, Select } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { onSuccessMessage, onErrorMessage } from '@/helpers'

import { useUpdateProfile, useOrderCenterSaleOrderDetail } from '@/services'

import { ProfileHeader, CardTaxSituation } from './components'

import styles from './profile-tax-situation.module.css'

import type { ProfileTaxSituationProps } from './profile-tax-situation.type'
import { ProfileTaxStatusEnum } from '@/types'

export const ProfileTaxSituationForm = ({
  isOpen,
  onHandleOpen,
  saleOrderUuid,
  onSuccessUpdated,
}: ProfileTaxSituationProps) => {
  const { t } = useTranslation('componentsOrderForm')
  const { t: tEnums } = useTranslation('enums')

  const { data: saleOrder } = useOrderCenterSaleOrderDetail(saleOrderUuid)

  const errors: Record<string, string> = {}

  const [values, setValues] = useState<{
    taxStatus?: string
  }>({})

  const onHandleValues = (key: string, value: string) => {
    setValues((prev) => ({ ...prev, [key]: value }))
  }

  useEffect(() => {
    if (saleOrder?.data?.buyer?.taxStatus) {
      setValues({
        taxStatus: saleOrder?.data?.buyer?.taxStatus,
      })
    }
  }, [saleOrder?.data?.buyer?.taxStatus])

  const { mutateAsync, isPending: isLoadingUpdate } = useUpdateProfile()

  const onHandleSubmit = async () => {
    const response = await mutateAsync({
      uuid: saleOrder?.data?.buyer?.uuid,
      taxStatus: values?.taxStatus as ProfileTaxStatusEnum,
    })

    if (response.success) {
      onSuccessMessage(
        t('profileTaxSituationSuccessTitle'),
        t('profileTaxSituationSuccessUpdateMessage'),
      )

      onHandleOpen(false)

      if (onSuccessUpdated) {
        onSuccessUpdated()
      }
    } else {
      onErrorMessage(
        t('profileTaxSituationErrorTitle'),
        t('profileTaxSituationErrorUpdateMessage'),
      )
    }
  }

  return (
    <Drawer isOpen={isOpen} onHandleOpen={() => onHandleOpen(false)}>
      <ProfileHeader
        email={saleOrder?.data?.buyer?.email || ''}
        onHandleOpen={onHandleOpen}
      />

      <div className={styles.content}>
        {saleOrder?.data?.buyer?.taxSituation && (
          <CardTaxSituation
            taxSituation={saleOrder?.data?.buyer?.taxSituation}
          />
        )}

        <div>
          <Label>{t('profileTaxSituationTaxStatusLabel')}</Label>
          <Select
            placeholder={t('profileTaxSituationTaxStatusPlaceholder')}
            value={values?.taxStatus}
            error={errors?.taxStatus as string}
            onChange={(newValue) => onHandleValues('taxStatus', newValue)}
            options={[
              {
                label: tEnums('profile_tax_status_pending'),
                value: 'pending',
              },
              {
                label: tEnums('profile_tax_status_review'),
                value: 'review',
              },
              {
                label: tEnums('profile_tax_status_completed'),
                value: 'completed',
              },
              {
                label: tEnums('profile_tax_status_invalid'),
                value: 'invalid',
              },
            ]}
          />
        </div>
      </div>

      <div className={styles.footer}>
        <Button variant="secondary" onClick={() => onHandleOpen(false)}>
          {t('profileTaxSituationButtonClose')}
        </Button>
        <Button onClick={onHandleSubmit}>
          {isLoadingUpdate
            ? t('profileTaxSituationButtonSubmitLoading')
            : t('profileTaxSituationButtonSubmit')}
        </Button>
      </div>
    </Drawer>
  )
}
