import { useEffect, useState } from 'react'
import { Card, Tab, Text } from '@mercai/clever'
import { useTranslation } from 'react-i18next'
import { format, subDays, differenceInDays } from 'date-fns'

import { useOrderCenterLogisticClassification } from '@/services'

import { Metrics, PeriodDate } from './components'

import { OrderCenterMetricsProps } from './metrics.type'

import styles from './metrics.module.css'

export const OrderCenterMetrics = ({
  type,
  filter,
  onFilterChange,
}: OrderCenterMetricsProps) => {
  const { t } = useTranslation('screenOrderCenterPage')

  const [selectedTab, setSelectedTab] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (filter?.createdAtAfter && filter?.createdAtBefore && !selectedTab) {
      const differenceDates = differenceInDays(
        new Date(filter?.createdAtBefore),
        new Date(filter?.createdAtAfter),
      )

      const days: Record<number, string> = {
        7: 'last7Days',
        14: 'last14Days',
        30: 'last30Days',
      }

      setSelectedTab(days?.[differenceDates] || 'period')
    }
  }, [filter?.createdAtAfter, filter?.createdAtBefore])

  const { data } = useOrderCenterLogisticClassification({
    orderCreatedAtAfter: filter?.createdAtAfter,
    orderCreatedAtBefore: filter?.createdAtBefore,
  })

  const onChangeSelectedTab = (tab: string) => {
    setSelectedTab(tab)

    if (['last7Days', 'last14Days', 'last30Days'].includes(tab)) {
      let selectedDate = new Date()
      const dateNow = new Date()

      const days: Record<string, number> = {
        last30Days: 30,
        last14Days: 14,
        last7Days: 7,
      }

      selectedDate = subDays(dateNow, days[tab])

      const selectedDateISO = format(selectedDate, "yyyy-MM-dd'T'HH:mm:ss'Z'")
      const dateNowISO = format(dateNow, "yyyy-MM-dd'T'HH:mm:ss'Z'")

      onFilterChange({
        ...filter,
        createdAtAfter: selectedDateISO,
        createdAtBefore: dateNowISO,
      })
    } else {
      onFilterChange({
        ...filter,
        createdAtAfter: '',
        createdAtBefore: '',
      })
    }
  }

  const onChangePeriod = (startDate?: string, endDate?: string) => {
    onFilterChange({
      ...filter,
      createdAtAfter: startDate || '',
      createdAtBefore: endDate || '',
    })
  }

  const onChangeStatusFilter = (status: string) => {
    onFilterChange({
      ...filter,
      classification_group:
        filter?.classification_group === status ? '' : status,
    })
  }

  if (type === 'logistic') {
    return (
      <div className={styles.core}>
        <Text variant="title-large">{t('metricsTitle')}</Text>

        <Card className={styles.card}>
          <div className={styles['tab-container']}>
            <Tab
              value={selectedTab || 'all'}
              onChange={onChangeSelectedTab}
              items={[
                {
                  label: t('metricsTabAll'),
                  value: 'all',
                },
                {
                  label: t('metricsTabLast7Days'),
                  value: 'last7Days',
                },
                {
                  label: t('metricsTabLast14Days'),
                  value: 'last14Days',
                },
                {
                  label: t('metricsTabLast30Days'),
                  value: 'last30Days',
                },
                {
                  label: t('metricsTabPeriod'),
                  value: 'period',
                },
              ]}
            />

            {selectedTab === 'period' && (
              <PeriodDate
                onPeriodApply={onChangePeriod}
                initialEndDate={filter?.createdAtBefore}
                initialStartDate={filter?.createdAtAfter}
              />
            )}

            <Text className={styles['total-metric']}>
              {data?.data?.total} {t('metricsTotalMetric')}
            </Text>
          </div>

          <Metrics
            metrics={[
              {
                label: t('metricsPendingMetric'),
                value: data?.data?.pending ?? 0,
                total: data?.data?.total ?? 0,
                decimalPlaces: 0,
                isActiveFilter: filter.classification_group === 'pending',
                onClickFilter: () => onChangeStatusFilter('pending'),
              },
              {
                label: t('metricsOverdueMetric'),
                value: data?.data?.overdue ?? 0,
                total: data?.data?.total ?? 0,
                decimalPlaces: 0,
                isActiveFilter: filter.classification_group === 'overdue',
                onClickFilter: () => onChangeStatusFilter('overdue'),
              },
              {
                label: t('metricsInTransitMetric'),
                value: data?.data?.inTransit ?? 0,
                total: data?.data?.total ?? 0,
                decimalPlaces: 0,
                isActiveFilter: filter.classification_group === 'in_transit',
                onClickFilter: () => onChangeStatusFilter('in_transit'),
              },
              {
                label: t('metricsDeliveredOnTimeMetric'),
                value: data?.data?.deliveredOnTime ?? 0,
                total: data?.data?.total ?? 0,
                decimalPlaces: 0,
                isActiveFilter:
                  filter.classification_group === 'delivered_on_time',
                onClickFilter: () => onChangeStatusFilter('delivered_on_time'),
              },
              {
                label: t('metricsDeliveredLateMetric'),
                value: data?.data?.deliveredLate ?? 0,
                total: data?.data?.total ?? 0,
                decimalPlaces: 0,
                isActiveFilter:
                  filter.classification_group === 'delivered_late',
                onClickFilter: () => onChangeStatusFilter('delivered_late'),
              },
              {
                label: t('metricsCanceledMetric'),
                value: data?.data?.cancelled ?? 0,
                total: data?.data?.total ?? 0,
                decimalPlaces: 0,
                isActiveFilter: filter.classification_group === 'cancelled',
                onClickFilter: () => onChangeStatusFilter('cancelled'),
              },
              {
                label: t('metricsAvgDeliveryTimeMetric'),
                value: data?.data?.avgDeliveryDays ?? 0,
              },
            ]}
          />
        </Card>
      </div>
    )
  }

  return null
}
