import { LabelProps } from './label.type'

import styles from './label.module.css'

const Label = ({ children, className, ...props }: LabelProps) => {
  return (
    <label className={`${styles.core} ${className || ''}`} {...props}>
      {children}
    </label>
  )
}

export default Label
