import styles from './drag-drop-file.module.css'

export const states: Record<'active' | 'inactive' | 'error', string> = {
  active: styles['state-active'],
  inactive: '',
  error: styles['state-error'],
}

export const generateClasses = (
  state: 'active' | 'inactive' | 'error',
  className?: string,
) => {
  return `${styles.core} ${states[state]} ${className || ''}`
}
