import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Tab } from '@mercai/clever'

import styles from './logistic-form.module.css'
import type { LogisticFormProps } from './logistic-form.type'
import type { SaleOrderProps } from '@/types'

import {
  LogisticFormCosts,
  LogisticFormDocuments,
  LogisticFormTransport,
} from './components'

export const LogisticForm = ({
  onChange,
  values = {} as SaleOrderProps,
  onRefetch,
}: LogisticFormProps) => {
  const { t } = useTranslation('componentOrderCenterForm')

  const [tab, setTab] = useState('transport')

  const tabsContent: Record<string, React.ReactNode> = {
    transport: <LogisticFormTransport onChange={onChange} values={values} />,
    costs: <LogisticFormCosts onChange={onChange} values={values} />,
    documents: (
      <LogisticFormDocuments
        onChange={onChange}
        values={values}
        onSuccessUpload={onRefetch}
      />
    ),
  }

  return (
    <div className={styles.core}>
      <Tab
        items={[
          {
            label: t('logisticTabTransport'),
            value: 'transport',
          },
          {
            label: t('logisticTabCosts'),
            value: 'costs',
          },
          {
            label: t('logisticTabDocuments'),
            value: 'documents',
          },
        ]}
        onChange={(value) => setTab(value)}
        value={tab}
      />

      {tabsContent[tab]}
    </div>
  )
}
