import { useQuery } from '@tanstack/react-query'

import { convertObjectToCamelCase } from '@/helpers'

import { api } from './api.service'

import type { CatalogAttributeOptionWithPagesProps } from '@/types'

import { CATALOG_ATTRIBUTE_OPTION_URL } from './api.urls'

export const useCatalogAttributeOption = (slug: string) => {
  const getCatalogAttributeOption = async (): Promise<{
    success: boolean
    message: string
    data?: CatalogAttributeOptionWithPagesProps
  }> => {
    try {
      const { data } = await api.get(CATALOG_ATTRIBUTE_OPTION_URL, {
        params: {
          slug,
        },
      })

      return {
        success: true,
        message: 'Catalog attribute options fetched successfully!',
        data: convertObjectToCamelCase(
          data,
        ) as CatalogAttributeOptionWithPagesProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get catalog attribute options!',
        data: undefined,
      }
    }
  }

  const useQueryReturn = useQuery({
    queryKey: ['catalog-category-attribute-option', slug],
    queryFn: () => getCatalogAttributeOption(),
  })

  return {
    ...useQueryReturn,
  }
}
