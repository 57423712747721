import styles from './radio.module.css'

export const radioStates: Record<'active' | 'inactive', string> = {
  active: styles.active,
  inactive: styles.inactive,
}

export const generateClassesRadio = (
  radio?: boolean,
  hasChildren?: boolean,
) => {
  return `${radioStates[radio ? 'active' : 'inactive']} ${
    hasChildren ? styles['has-children'] : ''
  }`
}
