import { useState } from 'react'
import {
  Accordion,
  BoxIcon,
  Tag,
  Text,
  Tooltip,
  ChevronDownIcon,
  Skeleton,
} from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import {
  formatCurrency,
  formatNumber,
  trimString,
  getOrderItemDescription,
} from '@/helpers'

import styles from './order-items.module.css'

import { OrderItemsProps } from './order-items.type'

const OrderItemsDefault = ({
  items,
  supplierName,
  dollarQuotation = '0',
}: OrderItemsProps) => {
  const { t } = useTranslation('componentOrderCenterShow')
  const { t: tEnums } = useTranslation('enums')
  const [activeItemsRoot, setActiveItemsRoot] = useState<string[]>([])
  const [activeItems, setActiveItems] = useState<string[]>([])

  const onHandleOpenItem = (id: string) => {
    if (activeItems.includes(id)) {
      setActiveItems(activeItems.filter((item) => item !== id))
    } else {
      setActiveItems([...activeItems, id])
    }
  }

  const verifyItemIsActive = (id: string) => activeItems.includes(id)

  return (
    <Accordion.Root
      className={styles.core}
      activeItems={activeItemsRoot}
      onHandleActiveItems={setActiveItemsRoot}
    >
      <Accordion.Item
        id="order-items"
        header={
          <div className={styles.title}>
            <BoxIcon />
            <Text variant="title-base" className={styles.title}>
              {t('orderItemsTitle')}
            </Text>
          </div>
        }
      >
        <Accordion.Root
          activeItems={activeItems}
          onHandleActiveItems={setActiveItems}
          showArrow={false}
        >
          {items?.map((item) => (
            <Accordion.Item
              key={item.uuid}
              id={item.uuid}
              className={styles['table-item']}
              header={
                <div>
                  <div className={styles['table-item-row']}>
                    <Text variant="title-base">
                      {t('orderItemsDescription')}:
                    </Text>
                    <Text variant="text-base">
                      <Tooltip
                        text={getOrderItemDescription(item)}
                        widthText="20rem"
                      >
                        {trimString(getOrderItemDescription(item), 28, '...')}
                      </Tooltip>
                    </Text>
                  </div>

                  <div className={styles['table-item-row']}>
                    <Text variant="title-base">{t('orderItemsQuantity')}:</Text>
                    <Text variant="text-base">
                      {formatNumber(item.quantity)}
                    </Text>

                    <Tag
                      size="small"
                      variant="secondary"
                      className={styles['table-item-packaging']}
                    >
                      {tEnums(`order_itens_package_${item.package}`)}
                    </Tag>
                  </div>

                  <div className={styles['table-item-row']}>
                    <Text variant="title-base">
                      {t('orderItemsUnitPrice')}:
                    </Text>
                    <Text variant="text-base">
                      {formatCurrency(
                        +item.price * +dollarQuotation,
                        'es-MX',
                        'MXN',
                        3,
                      )}{' '}
                      ({formatCurrency(item.price, 'es-MX', 'USD', 3)})
                    </Text>
                  </div>
                  <div className={styles['table-item-row']}>
                    <Text variant="title-base">
                      {t('orderItemsTotalPrice')}:
                    </Text>
                    <Text variant="text-base">
                      {formatCurrency(
                        +item.quantity * +item.price * +dollarQuotation,
                        'es-MX',
                        'MXN',
                      )}{' '}
                      (
                      {formatCurrency(
                        +item.quantity * +item.price,
                        'es-MX',
                        'USD',
                      )}
                      )
                    </Text>
                  </div>
                </div>
              }
              footer={
                <Text
                  className={styles['table-item-button']}
                  variant="button"
                  onClick={() => onHandleOpenItem(item.uuid)}
                >
                  {verifyItemIsActive(item.uuid)
                    ? t('orderItemsViewLess')
                    : t('orderItemsViewMore')}
                  <ChevronDownIcon
                    className={
                      verifyItemIsActive(item.uuid)
                        ? ''
                        : styles['table-item-button-icon-revert']
                    }
                  />
                </Text>
              }
            >
              <div className={styles['table-item-wrap']}>
                <div className={styles['table-item-row']}>
                  <Text variant="title-base">{t('orderItemsUnitCost')}:</Text>
                  {item?.cost ? (
                    <Text variant="text-base">
                      {formatCurrency(
                        +item.cost * +dollarQuotation,
                        'es-MX',
                        'MXN',
                        3,
                      )}{' '}
                      ({formatCurrency(item.cost, 'es-MX', 'USD', 3)})
                    </Text>
                  ) : (
                    <Text variant="text-base">{t('orderItemsNoData')}</Text>
                  )}
                </div>

                <div className={styles['table-item-row']}>
                  <Text variant="title-base">{t('orderItemsTotalCost')}:</Text>

                  {item?.cost ? (
                    <Text variant="text-base">
                      {formatCurrency(
                        +item.quantity * +item.cost * +dollarQuotation,
                        'es-MX',
                        'MXN',
                      )}{' '}
                      (
                      {formatCurrency(
                        +item.quantity * +item.cost,
                        'es-MX',
                        'USD',
                      )}
                      )
                    </Text>
                  ) : (
                    <Text variant="text-base">{t('orderItemsNoData')}</Text>
                  )}
                </div>

                <div className={styles['table-item-row']}>
                  <Text variant="title-base">
                    {t('orderShowSummaryTraderComission')}:
                  </Text>
                  <Text variant="text-base">
                    {formatCurrency(
                      +(item.traderCommission || 0) * +dollarQuotation,
                      'es-MX',
                      'MXN',
                    )}{' '}
                    (
                    {formatCurrency(
                      +(item.traderCommission || 0),
                      'es-MX',
                      'USD',
                    )}
                    )
                  </Text>
                </div>
                <div className={styles['table-item-row']}>
                  <Text variant="title-base">{t('orderItemsSupplier')}:</Text>

                  <Text variant="text-base">
                    {supplierName || t('orderItemsNoData')}
                  </Text>
                </div>
              </div>
            </Accordion.Item>
          ))}
        </Accordion.Root>
      </Accordion.Item>
    </Accordion.Root>
  )
}

const OrderItemsLoading = () => {
  return (
    <div className={styles.core}>
      <div className={styles.title}>
        <Skeleton height="1.5rem" width="1.5rem" />
        <Skeleton height="1rem" width="8rem" />
      </div>

      <div>
        <div className={styles['table-item-row']}>
          <Skeleton width="10rem" height="1rem" />
          <Skeleton width="12rem" height="1rem" />
        </div>
        <div className={styles['table-item-row']}>
          <Skeleton width="8rem" height="1rem" />
          <Skeleton width="13rem" height="1rem" />
        </div>
        <div className={styles['table-item-row']}>
          <Skeleton width="6rem" height="1rem" />
          <Skeleton width="14rem" height="1rem" />
        </div>
        <div className={styles['table-item-row']}>
          <Skeleton width="10rem" height="1rem" />
          <Skeleton width="8rem" height="1rem" />
        </div>
        <div className={styles['table-item-row']}>
          <Skeleton width="12rem" height="1rem" />
          <Skeleton width="10rem" height="1rem" />
        </div>

        <div className={styles['table-item-button']}>
          <Skeleton width="7rem" height="1rem" />
        </div>
      </div>
    </div>
  )
}

export const OrderItems = (props: OrderItemsProps) => {
  if (props.isLoading) {
    return <OrderItemsLoading />
  }

  return <OrderItemsDefault {...props} />
}
