import styles from './carousel-categories.module.css'

export const variants: Record<string, string> = {
  isSelected: styles['category-item-selected'],
  isUnselected: '',
}

export const generateClasses = (isSelected: boolean) => {
  return `${styles['category-item']} ${
    isSelected ? variants.isSelected : variants.isUnselected
  }`
}
