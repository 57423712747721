import { Card, Text, ProfileIcon, Skeleton } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import type { QuotationRequestBuyerDataProps } from './buyer-data.type'

import styles from './buyer-data.module.css'

export const QuotationRequestBuyerData = ({
  companyName,
  email,
  name,
  phone,
  isLoading,
}: QuotationRequestBuyerDataProps) => {
  const { t } = useTranslation('screenQuotationRequestShow')

  if (isLoading) {
    return (
      <Card>
        <div className={styles.title}>
          <Skeleton height="1.5rem" width="1.5rem" />

          <Skeleton height="1.5rem" width="10rem" />
        </div>

        <div className={styles.content}>
          <div className={styles['content-row']}>
            <Skeleton height="1rem" width="8rem" />
            <Skeleton height="1rem" width="8rem" />
          </div>

          <div className={styles['content-row']}>
            <Skeleton height="1rem" width="8rem" />
            <Skeleton height="1rem" width="8rem" />
          </div>

          <div className={styles['content-row']}>
            <Skeleton height="1rem" width="8rem" />
            <Skeleton height="1rem" width="8rem" />
          </div>

          <div className={styles['content-row']}>
            <Skeleton height="1rem" width="8rem" />
            <Skeleton height="1rem" width="8rem" />
          </div>
        </div>
      </Card>
    )
  }

  return (
    <Card>
      <div className={styles.title}>
        <ProfileIcon />
        <Text variant="title-large">{t('buyerDataTitle')}</Text>
      </div>

      <div className={styles.content}>
        <div className={styles['content-row']}>
          <Text variant="title-base">{t('buyerDataNameLabel')}: </Text>
          <Text>{name || '-'}</Text>
        </div>

        <div className={styles['content-row']}>
          <Text variant="title-base">{t('buyerDataCompanyLabel')}: </Text>
          <Text>{companyName || '-'}</Text>
        </div>

        <div className={styles['content-row']}>
          <Text variant="title-base">{t('buyerDataEmailLabel')}: </Text>
          <Text>{email || '-'}</Text>
        </div>

        <div className={styles['content-row']}>
          <Text variant="title-base">{t('buyerDataPhoneLabel')}: </Text>
          <Text>{phone || '-'}</Text>
        </div>
      </div>
    </Card>
  )
}
