import styles from './alert.module.css'

import { AlertVariantEnum } from './alert.type'

export const variants: Record<AlertVariantEnum, string> = {
  primary: styles['variant-primary'],
  warning: styles['variant-warning'],
  danger: styles['variant-danger'],
  success: styles['variant-success'],
}

export const generateClasses = (
  variant: keyof typeof AlertVariantEnum,
  className?: string,
) => {
  return `${styles.core} ${variants[variant]} ${className || ''}`
}
