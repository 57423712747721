import { Button, Text, DownloadIcon, Tag, TagVariantEnum } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { ROUTES_ADMIN } from '@/router'
import { formatCurrency } from '@/helpers'

import styles from './show.module.css'

import type { ProfileCreditShowProps } from './show.type'

import { FinanceProfileCreditStatusEnum } from '@/types'

export const ProfileCreditShow = ({
  onHandleOpen,
  financeProfileData,
  buyerProfileEmail,
  onHandleEdit,
}: ProfileCreditShowProps) => {
  const { t } = useTranslation('componentsFinanceProfileForm')
  const { t: tEnums } = useTranslation('enums')

  const statusObjects: Record<
    FinanceProfileCreditStatusEnum,
    { label: string; variant: keyof typeof TagVariantEnum }
  > = {
    requested: {
      label: tEnums('profile_credit_status_requested'),
      variant: 'primary',
    },
    review: {
      label: tEnums('profile_credit_status_review'),
      variant: 'warning',
    },
    approved: {
      label: tEnums('profile_credit_status_approved'),
      variant: 'success',
    },
    rejected: {
      label: tEnums('profile_credit_status_rejected'),
      variant: 'danger',
    },
  }

  const statusObject =
    statusObjects[financeProfileData?.creditStatus || 'requested']

  return (
    <>
      <div className={styles.content}>
        <div className={styles['content-group']}>
          <Text variant="text-large-bold" className={styles['content-title']}>
            {t('profileShowGeneralInfoTitle')}
          </Text>

          <div className={styles.row}>
            <Text>{t('profileShowTypeCredit')}</Text>
            <Text>
              {tEnums(
                `profile_credit_gateway_${financeProfileData?.creditGateway}`,
              )}
            </Text>
          </div>

          <div className={styles.row}>
            <Text>{t('profileShowPersonTypeLabel')}</Text>

            <Text>
              {tEnums(
                `profile_credit_person_type_${financeProfileData?.personType}`,
              )}
            </Text>
          </div>

          <div className={styles.row}>
            <Text>{t('profileShowStatusLabel')}</Text>
            <Tag model="light" variant={statusObject.variant}>
              {statusObject.label}
            </Tag>
          </div>
        </div>

        <div className={styles['content-group']}>
          <Text variant="text-large-bold" className={styles['content-title']}>
            {t('profileShowDocumentsTitle')}
          </Text>

          <ul className={styles.files}>
            {financeProfileData?.governmentIdFile && (
              <li>
                <a href={financeProfileData?.governmentIdFile} target="_blank">
                  <Text>{t('profileShowGovernmentId')}</Text>

                  <DownloadIcon />
                </a>
              </li>
            )}

            {financeProfileData?.taxSituation && (
              <li>
                <a href={financeProfileData?.taxSituation} target="_blank">
                  <Text>{t('profileShowTaxSituation')}</Text>

                  <DownloadIcon />
                </a>
              </li>
            )}

            {financeProfileData?.addressProofFile && (
              <li>
                <a href={financeProfileData?.addressProofFile} target="_blank">
                  <Text>{t('profileShowAddressProof')}</Text>

                  <DownloadIcon />
                </a>
              </li>
            )}

            {financeProfileData?.constitutiveActFile && (
              <li>
                <a
                  href={financeProfileData?.constitutiveActFile}
                  target="_blank"
                >
                  <Text>{t('profileShowCreditAct')}</Text>

                  <DownloadIcon />
                </a>
              </li>
            )}
          </ul>
        </div>

        <div className={styles['content-group']}>
          <Text variant="text-large-bold" className={styles['content-title']}>
            {t('profileShowCreditUseTitle')}
          </Text>

          <ul className={styles['content-group']}>
            <li className={styles.row}>
              <Text variant="title-base" className={styles['text-gray']}>
                {t('profileShowCreditApproved')}
              </Text>
              <Text>
                {formatCurrency(
                  financeProfileData?.totalCreditAmount || 0,
                  'es-MX',
                  'USD',
                )}
              </Text>
            </li>

            <li className={styles.row}>
              <Text variant="title-base" className={styles['text-gray']}>
                {t('profileShowCreditUsed')}
              </Text>
              <Text>
                {formatCurrency(
                  financeProfileData?.totalCreditInUse || 0,
                  'es-MX',
                  'USD',
                )}
              </Text>
            </li>

            <li className={styles.row}>
              <Text variant="title-base" className={styles['text-gray']}>
                {t('profileShowCreditAvailable')}
              </Text>
              <Text>
                {formatCurrency(
                  (financeProfileData?.totalCreditAmount || 0) -
                    (financeProfileData?.totalCreditInUse || 0),
                  'es-MX',
                  'USD',
                )}
              </Text>
            </li>
          </ul>
        </div>

        <div className={styles['content-group']}>
          <Text variant="text-large-bold" className={styles['content-title']}>
            {t('profileShowOrdersTitle')}
          </Text>

          {financeProfileData?.creditInUseOrders &&
          financeProfileData?.creditInUseOrders?.length > 0 ? (
            <div className={styles['content-group']}>
              {financeProfileData?.creditInUseOrders?.map((order) => (
                <div className={styles.row}>
                  <Text>{order?.reference}</Text>

                  <Text>
                    {formatCurrency(order?.amount || 0, 'es-MX', 'USD')}
                  </Text>

                  <Button
                    variant="secondary"
                    size="small"
                    href={ROUTES_ADMIN.ANALYTICS.ORDER_TRACKER.FILTER.fullPath(
                      order?.reference.replace('SO-', 'O-'),
                    )}
                    target="_blank"
                  >
                    {t('profileShowOrderShow')}
                  </Button>
                </div>
              ))}
            </div>
          ) : (
            <div className={styles['content-group']}>
              <Text>{t('profileShowOrdersEmpty')}</Text>

              <Button
                variant="secondary"
                size="small"
                href={ROUTES_ADMIN.ANALYTICS.ORDER_TRACKER.FILTER.fullPath(
                  buyerProfileEmail,
                )}
                className={styles['empty-button']}
              >
                {t('profileShowOrdersProfileNavigate')}
              </Button>
            </div>
          )}
        </div>
      </div>

      <div className={styles.footer}>
        <Button variant="secondary" onClick={() => onHandleOpen(false)}>
          {t('profileCreditButtonClose')}
        </Button>

        <Button onClick={onHandleEdit} variant="light">
          {t('profileCreditButtonEdit')}
        </Button>
      </div>
    </>
  )
}
