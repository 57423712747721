import styles from './table-rows.module.css'

const statesTableControl: Record<'without-border' | 'with-border', string> = {
  'without-border': '',
  'with-border': styles['table-control-with-border'],
}

const statesLockTableControl: Record<'lock' | 'unlock', string> = {
  lock: styles['table-control-locked'],
  unlock: styles['table-control-unlocked'],
}

export const generateClassesOfTableControl = (
  isShowBorder: boolean,
  isLocked: boolean,
) => {
  return `${styles['table-control']} ${statesTableControl[isShowBorder ? 'with-border' : 'without-border']}
    ${statesLockTableControl[isLocked ? 'lock' : 'unlock']}
  `
}

const statesSidebarColumn: Record<'sidebar' | 'body', string> = {
  sidebar: styles['sidebar-column'],
  body: styles['body-column'],
}

const statesHighlightColumn: Record<'highlight' | 'normal', string> = {
  highlight: styles['highlight-column'],
  normal: '',
}

export const generateClassesOfHighlightColumn = (
  isSidebar: boolean,
  isHighlight: boolean,
) => {
  return `${statesSidebarColumn[isSidebar ? 'sidebar' : 'body']} ${statesHighlightColumn[isHighlight ? 'highlight' : 'normal']}`
}
