import * as Yup from 'yup'

export const shemaAddressItem = (t: (key: string) => string) => ({
  postalCode: Yup.string().required(t('addressFormFieldRequiredMessage')),
  state: Yup.string().required(t('addressFormFieldRequiredMessage')),
  city: Yup.string().required(t('addressFormFieldRequiredMessage')),
  neighborhood: Yup.string().required(t('addressFormFieldRequiredMessage')),
  street: Yup.string().required(t('addressFormFieldRequiredMessage')),
  number: Yup.string().required(t('addressFormFieldRequiredMessage')),
})

export const schemaProductList = (t: (key: string) => string) => ({
  items: Yup.array()
    .min(1, t('productListRequiredMessage'))
    .required(t('productListRequiredMessage')),
})

export const schemaProfileItem = (t: (key: string) => string) => ({
  email: Yup.string().required(t('profileFormFieldRequiredMessage')),
  name: Yup.string().required(t('profileFormFieldRequiredMessage')),
  companyName: Yup.string().required(t('profileFormFieldRequiredMessage')),
  phone: Yup.string().required(t('profileFormFieldRequiredMessage')),
})
