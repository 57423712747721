import { useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import {
  Button,
  DragDropFileList,
  Drawer,
  Label,
  Select,
  FileListFileProps,
} from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import {
  getFileNameAndExtension,
  onSuccessMessage,
  onErrorMessage,
} from '@/helpers'
import {
  useOrderDetail,
  ORDER_PAYMENT_PROOF_URL,
  ORDER_INVOICE_PDF_URL,
  ORDER_INVOICE_XML_URL,
  useOrderUpdate,
  QUERIES_ADMIN,
} from '@/services'

import { OrderHeader } from './components'

import styles from './drawer-form.module.css'

import type { DrawerFormProps } from './drawer-form.type'

import { OrderDetailProps } from '@/types'

export const OrderDrawerForm = ({
  isOpen,
  onHandleOpen,
  orderUuid,
}: DrawerFormProps) => {
  const { t } = useTranslation('screenOrderList')
  const { t: tEnums } = useTranslation('enums')

  const queryClient = useQueryClient()

  const errors: Record<string, string> = {}

  const [values, setValues] = useState<Partial<OrderDetailProps>>({})
  const [dataFilesInvoicePDF, setDataFilesInvoicePDF] = useState<
    FileListFileProps[]
  >([])
  const [dataFilesInvoiceXML, setDataFilesInvoiceXML] = useState<
    FileListFileProps[]
  >([])
  const [dataFilesPaymentProof, setDataFilesPaymentProof] = useState<
    FileListFileProps[]
  >([])

  const { data: order, isLoading } = useOrderDetail(orderUuid)

  const onHandleValues = (key: string, value: string) => {
    setValues((prev) => ({ ...prev, [key]: value }))
  }

  useEffect(() => {
    if (order !== undefined && order?.data !== undefined) {
      setValues(order.data)
    }
  }, [order])

  useEffect(() => {
    const file = getFileNameAndExtension(values?.paymentProof || '')

    if (values?.paymentProof) {
      setDataFilesPaymentProof([
        {
          id: file.fileName,
          name: `${file.fileName}.${file.extension}`,
          url: values?.paymentProof,
          uploaded: true,
        },
      ])
    } else {
      setDataFilesPaymentProof([])
    }

    if (values?.invoicePdf) {
      const file = getFileNameAndExtension(values?.invoicePdf || '')

      setDataFilesInvoicePDF([
        {
          id: file.fileName,
          name: `${file.fileName}.${file.extension}`,
          url: values?.invoicePdf,
          uploaded: true,
        },
      ])
    } else {
      setDataFilesInvoicePDF([])
    }

    if (values?.invoiceXml) {
      const file = getFileNameAndExtension(values?.invoiceXml || '')

      setDataFilesInvoiceXML([
        {
          id: file.fileName,
          name: `${file.fileName}.${file.extension}`,
          url: values?.invoiceXml,
          uploaded: true,
        },
      ])
    } else {
      setDataFilesInvoiceXML([])
    }
  }, [values?.paymentProof, values?.invoicePdf, values?.invoiceXml])

  const { mutateAsync, isPending: isLoadingUpdate } = useOrderUpdate(orderUuid)

  const onHandleSubmit = async () => {
    const response = await mutateAsync({
      status: values?.status,
      paymentLink: values?.paymentLink,
    })

    if (response.success) {
      onSuccessMessage(
        t('orderFormSuccessTitle'),
        t('orderFormSuccessUpdateMessage'),
      )

      onHandleOpen(false)

      queryClient.invalidateQueries({
        queryKey: [QUERIES_ADMIN.ORDER.module],
      })
      queryClient.invalidateQueries({
        queryKey: [QUERIES_ADMIN.ORDER.module, orderUuid],
      })
    } else {
      onErrorMessage(t('orderFormErrorTitle'), t('orderFormErrorUpdateMessage'))
    }
  }

  return (
    <Drawer isOpen={isOpen} onHandleOpen={() => onHandleOpen(false)}>
      <div className={styles['header-wrap']}>
        <OrderHeader
          reference={order?.data?.reference || ''}
          createdAt={order?.data?.createdAt || ''}
          updatedAt={order?.data?.updatedAt || ''}
          onHandleOpen={onHandleOpen}
          isLoading={isLoading}
        />
      </div>

      <div className={styles.content}>
        <div>
          <Label>{t('orderFormStatusLabel')}</Label>
          <Select
            placeholder={t('orderFormStatusPlaceholder')}
            value={values?.status}
            error={errors?.status as string}
            onChange={(newValue) => onHandleValues('status', newValue)}
            options={[
              {
                label: tEnums('order_status_pending_buyer_data'),
                value: 'pending_buyer_data',
              },
              {
                label: tEnums('order_status_pending_invoice'),
                value: 'pending_invoice',
              },
              {
                label: tEnums('order_status_pending_payment'),
                value: 'pending_payment',
              },
              {
                label: tEnums('order_status_waiting_payment_approval'),
                value: 'waiting_payment_approval',
              },
              {
                label: tEnums('order_status_paid'),
                value: 'paid',
              },
              {
                label: tEnums('order_status_partial_delivered'),
                value: 'partial_delivered',
              },
              {
                label: tEnums('order_status_delivered'),
                value: 'delivered',
              },
              {
                label: tEnums('order_status_canceled'),
                value: 'canceled',
              },
              {
                label: tEnums('order_status_fraudulent'),
                value: 'fraudulent',
              },
            ]}
          />
        </div>

        <div>
          <Label>{t('orderFormInvoicePDFLabel')}</Label>
          <DragDropFileList
            translations={{
              clickToUpload: t('orderFormClickToUpload'),
              dragFile: t('orderFormDragFile'),
              sizeFile: t('orderFormSizeFile'),
            }}
            urlAdd={ORDER_INVOICE_PDF_URL(orderUuid)}
            configsRequest={{
              fileField: 'invoice_pdf',
              method: 'PATCH',
            }}
            data={dataFilesInvoicePDF}
            onChangeData={setDataFilesInvoicePDF}
          />
        </div>

        <div>
          <Label>{t('orderFormInvoiceXMLLabel')}</Label>
          <DragDropFileList
            translations={{
              clickToUpload: t('orderFormClickToUpload'),
              dragFile: t('orderFormDragFile'),
              sizeFile: t('orderFormSizeFile'),
            }}
            urlAdd={ORDER_INVOICE_XML_URL(orderUuid)}
            configsRequest={{
              fileField: 'invoice_xml',
              method: 'PATCH',
            }}
            data={dataFilesInvoiceXML}
            onChangeData={setDataFilesInvoiceXML}
          />
        </div>

        <div>
          <Label>{t('orderFormPaymentProofLabel')}</Label>
          <DragDropFileList
            translations={{
              clickToUpload: t('orderFormClickToUpload'),
              dragFile: t('orderFormDragFile'),
              sizeFile: t('orderFormSizeFile'),
            }}
            urlAdd={ORDER_PAYMENT_PROOF_URL(orderUuid)}
            configsRequest={{
              fileField: 'payment_proof',
              method: 'PATCH',
            }}
            data={dataFilesPaymentProof}
            onChangeData={setDataFilesPaymentProof}
          />
        </div>
      </div>

      <div className={styles.footer}>
        <Button variant="secondary" onClick={() => onHandleOpen(false)}>
          {t('orderFormButtonClose')}
        </Button>
        <Button onClick={onHandleSubmit}>
          {isLoadingUpdate
            ? t('orderFormButtonSubmitLoading')
            : t('orderFormButtonSubmit')}
        </Button>
      </div>
    </Drawer>
  )
}
