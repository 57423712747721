import { DropDownContentOrientationEnums } from './drop-down.type'

import styles from './drop-down.module.css'

export const statesContent: Record<'isActive' | 'isInactive', string> = {
  isActive: styles['content-state-active'],
  isInactive: styles['content-state-inactive'],
}

export const orientationsContent: Record<
  keyof typeof DropDownContentOrientationEnums,
  string
> = {
  top: styles['content-orientation-top'],
  bottom: styles['content-orientation-bottom'],
  'bottom-left': styles['content-orientation-bottom-left'],
  'bottom-right': styles['content-orientation-bottom-right'],
}

export const generateClassesOfContent = (
  orientation: keyof typeof DropDownContentOrientationEnums,
  isActive?: boolean,
  className?: string,
) => {
  return `${styles.content} ${
    statesContent[isActive ? 'isActive' : 'isInactive']
  } ${orientationsContent[orientation]} ${className || ''}`
}
