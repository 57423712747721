import { useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { Button, DrawerStepper } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import {
  useValidationErrors,
  parseDateTimeToISO,
  formatDateWithTime,
  getDateUnities,
  onSuccessMessage,
  onErrorMessage,
} from '@/helpers'

import {
  useAddressList,
  useCreateAddress,
  useCreateQuotation,
  useUpdateQuotation,
  useUpdateProfile,
  QUERIES_ADMIN,
  useProfileList,
  useCreateProfile,
  useQuotationDetail,
  useDollarQuotationLatest,
} from '@/services'

import { useAuth } from '@/providers'

import {
  AddressProps,
  QuotationProps,
  ProfileProps,
  QuotationProductProps,
} from '@/types'

import {
  schemaProductList,
  shemaAddressItem,
  schemaProfileItem,
} from './drawer-form.constants'

import {
  AddressForm,
  AddressList,
  ProductForm,
  ProductList,
  ProfileForm,
  ProfileList,
  QuotationForm,
} from './components'

import type { DrawerNewQuotationProps } from './drawer-form.type'

export const QuotationDrawerForm = ({
  isOpen,
  onHandleOpen,
  quotationRequestSelected,
  quotationRequestItemsSelected,
  quotationUuid,
}: DrawerNewQuotationProps) => {
  const { t } = useTranslation('screenQuotationList')
  const { t: tEnums } = useTranslation('enums')

  const isNewRegister = !quotationUuid

  const queryClient = useQueryClient()

  const [data, setData] = useState<Partial<QuotationProps> | undefined>(
    undefined,
  )
  const [initEditData, setInitEditData] = useState(false)
  const [profileFilters, setProfileFilters] = useState({
    currentPage: 1,
    search: '',
  })

  const [profileItem, setProfileItem] = useState<Partial<ProfileProps>>({})
  const [profileHasBeenEdited, setProfileHasBeenEdited] = useState(false)

  const { whoAmI } = useAuth()

  const { data: dataQuotation } = useQuotationDetail(quotationUuid || '')

  const { data: dataDollarQuotationLatest } = useDollarQuotationLatest()

  const {
    isValid: isValidProfileItem,
    errors: profileItemErrors,
    onHandleListenErrors: onHandleListenErrorsProfile,
  } = useValidationErrors(schemaProfileItem(t), profileItem)

  const [productItem, setProductItem] = useState<
    Partial<QuotationProductProps>
  >({})

  const {
    isValid: isValidProductList,
    errors: productListErrorsList,
    onHandleListenErrors: onHandleListenErrorsProductList,
  } = useValidationErrors(schemaProductList(t), data)

  const [addressItem, setAddressItem] = useState<Partial<AddressProps>>({})

  const {
    isValid: isValidAddressItem,
    errors: addressItemErrors,
    onHandleListenErrors: onHandleListenErrorsAddressItem,
  } = useValidationErrors(shemaAddressItem(t), addressItem)

  const onChangeData = (newData: Partial<QuotationProps>) => {
    if (!initEditData) {
      setInitEditData(true)
    }

    setData({ ...data, ...newData })
  }

  const onSubmitQuotationForm = ({
    onNavigateToNextStep,
  }: {
    onNavigateToNextStep: (route: string) => void
  }) => {
    onNavigateToNextStep('profileList')
  }

  const onChangeProfileItem = (newData: Partial<ProfileProps>) => {
    if (!profileHasBeenEdited) {
      setProfileHasBeenEdited(true)
    }

    setProfileItem({ ...profileItem, ...newData })
  }

  const {
    mutateAsync: mutateAsyncUpdateProfile,
    isPending: isPendingUpdateProfile,
  } = useUpdateProfile()

  const onHandleNewProfile = ({
    onNavigateToNextStep,
  }: {
    onNavigateToNextStep: (route: string) => void
  }) => {
    setProfileItem({})
    setProfileHasBeenEdited(false)
    onNavigateToNextStep('profileForm')
  }

  const { data: profiles, isLoading: isLoadingProfiles } = useProfileList({
    params: {
      pagination: profileFilters.currentPage,
      search: profileFilters.search,
    },
  })

  const onSelectProfile = ({
    profile,
    onNavigateToNextStep,
  }: {
    profile: Partial<ProfileProps>
    onNavigateToNextStep: (route: string) => void
  }) => {
    setProfileItem(profile)
    setProfileHasBeenEdited(false)
    onChangeData({ ...data, buyerProfileUuid: profile.uuid })
    onNavigateToNextStep('profileForm')
  }

  const { mutateAsync: mutateAsyncCreateProfile } = useCreateProfile()

  const onSubmitNewProfile = async ({
    onNavigateToNextStep,
  }: {
    onNavigateToNextStep: (route: string) => void
  }) => {
    if (isValidProfileItem) {
      const response = await mutateAsyncCreateProfile(profileItem)

      if (response.success) {
        setProfileItem(response?.data || {})
        onHandleListenErrorsProfile(false)
        onNavigateToNextStep('productList')

        setProfileFilters({
          search: response.data?.email || '',
          currentPage: 1,
        })
        onChangeData({ ...data, buyerProfileUuid: response?.data?.uuid })
      }
    } else {
      onHandleListenErrorsProfile(true)
    }
  }

  const onSubmitEditProfile = async ({
    onNavigateToNextStep,
  }: {
    onNavigateToNextStep: (route: string) => void
  }) => {
    if (profileHasBeenEdited) {
      if (isValidProfileItem) {
        const response = await mutateAsyncUpdateProfile(profileItem)

        if (response.success) {
          onHandleListenErrorsProfile(false)
          onNavigateToNextStep('productList')
        }
      } else {
        onHandleListenErrorsProfile(true)
      }
    } else {
      onNavigateToNextStep('productList')
    }
  }

  const onSubmitProfile = async ({
    onNavigateToNextStep,
  }: {
    onNavigateToNextStep: (route: string) => void
  }) => {
    if (profileItem?.uuid) {
      onSubmitEditProfile({ onNavigateToNextStep })
    } else {
      onSubmitNewProfile({ onNavigateToNextStep })
    }
  }

  const onSubmitProducts = async ({
    onNavigateToNextStep,
  }: {
    onNavigateToNextStep: (route: string) => void
  }) => {
    if (isValidProductList) {
      onHandleListenErrorsProductList(false)

      onNavigateToNextStep('addressList')
    } else {
      onHandleListenErrorsProductList(true)
    }
  }

  const onHandleNewProduct = ({
    onNavigateToNextStep,
  }: {
    onNavigateToNextStep: (route: string) => void
  }) => {
    onNavigateToNextStep('newProduct')
    setProductItem({})
  }

  const onHandleEditProduct = ({
    onNavigateToNextStep,
    product,
  }: {
    onNavigateToNextStep: (route: string) => void
    product: Partial<QuotationProductProps>
  }) => {
    setProductItem(product)
    onNavigateToNextStep('newProduct')
  }

  const onHandleSubmitProduct = async () => {
    if (productItem?.uuid) {
      const newItems = data?.products?.map((item) =>
        item?.uuid === productItem?.uuid ? productItem : item,
      )

      onChangeData({
        ...data,
        products: newItems as QuotationProductProps[],
      })
    } else {
      onChangeData({
        ...data,
        products: [
          ...(data?.products || []),
          {
            ...(productItem as QuotationProductProps),
            uuid: `${data?.products?.length}`,
          },
        ],
      })
    }

    setProductItem({})
  }

  const onHandleClickRemove = (product: Partial<QuotationProductProps>) => {
    const newItems = data?.products?.filter(
      (item) => item?.uuid !== product?.uuid,
    )

    onChangeData({
      ...data,
      products: newItems as QuotationProductProps[],
    })
  }

  const { data: dataAddresses } = useAddressList(profileItem?.uuid || '')

  const onHandleNewAddress = ({
    onNavigateToNextStep,
  }: {
    onNavigateToNextStep: (route: string) => void
  }) => {
    onNavigateToNextStep('addressForm')
  }

  const { mutateAsync } = useCreateAddress(profileItem?.uuid || '')

  const onSubmitNewAddress = async ({
    onNavigateToPreviousStep,
  }: {
    onNavigateToPreviousStep: () => void
  }) => {
    if (isValidAddressItem) {
      const response = await mutateAsync(addressItem)

      if (response.success) {
        onHandleListenErrorsAddressItem(false)
        onNavigateToPreviousStep()

        setAddressItem(response?.data || {})
        onChangeData({ ...data, deliveryAddressUuid: response?.data?.uuid })

        queryClient.invalidateQueries({
          queryKey: [QUERIES_ADMIN.ADDRESS.module, profileItem?.uuid],
        })
      }
    } else {
      onHandleListenErrorsAddressItem(true)
    }
  }

  const { mutateAsync: mutateAsyncCreateQuotation } = useCreateQuotation()
  const { mutateAsync: mutateAsyncUpdateQuotation } =
    useUpdateQuotation(quotationUuid)

  const onSubmitCompleted = async () => {
    const deliveryAddressUuid =
      data?.deliveryAddressUuid ||
      data?.deliveryAddress?.uuid ||
      quotationRequestSelected?.address?.uuid

    let dollarExchangeRate: number | string = Number(data?.dollarExchangeRate)

    if (!isNaN(dollarExchangeRate)) {
      dollarExchangeRate = dollarExchangeRate.toFixed(4)
    }

    const payloadQuotation: Partial<QuotationProps> = {
      ...data,
      dollarExchangeRate: dollarExchangeRate as number,
      rejectedReason: data?.status === 'rejected' ? data.rejectedReason : '',
      creatorEmail: dataQuotation?.data?.creatorEmail || whoAmI?.email,
      expiresAt: parseDateTimeToISO(data?.expiresAt, 'es-MX'),
      quotationRequestUuid: quotationRequestSelected?.uuid,
      buyerProfileUuid: profileItem?.uuid,
      deliveryAddressUuid,
      products: data?.products?.map((item) => ({
        ...item,
        catalogProductUuid: item?.catalogProduct?.uuid,
        supplierUuid: item?.supplier?.uuid,
      })),
    }

    let response: {
      success: boolean
      message: string
      data?: QuotationProps
    } = {
      success: false,
      message: '',
      data: {} as QuotationProps,
    }

    if (isNewRegister) {
      response = await mutateAsyncCreateQuotation(
        payloadQuotation as QuotationProps,
      )
    } else {
      response = await mutateAsyncUpdateQuotation(
        payloadQuotation as QuotationProps,
      )
    }

    if (response.success) {
      if (isNewRegister) {
        onSuccessMessage(
          t('quotationFormSuccessCreateTitle'),
          t('quotationFormSuccessCreateMessage'),
        )
      } else {
        onSuccessMessage(
          t('quotationFormSuccessUpdateTitle'),
          t('quotationFormSuccessUpdateMessage'),
        )
      }

      queryClient.invalidateQueries({
        queryKey: [QUERIES_ADMIN.QUOTATION.module],
      })
      queryClient.invalidateQueries({
        queryKey: [QUERIES_ADMIN.QUOTATION.module, quotationUuid],
      })

      if (quotationRequestSelected?.uuid) {
        queryClient.invalidateQueries({
          queryKey: [
            QUERIES_ADMIN.QUOTATION_REQUEST.module,
            quotationRequestSelected?.uuid,
          ],
        })
      }

      setData({})
      onHandleOpen(false)
    } else {
      if (isNewRegister) {
        onErrorMessage(
          t('quotationFormErrorCreateTitle'),
          t('quotationFormErrorCreateMessage'),
        )
      } else {
        onErrorMessage(
          t('quotationFormErrorUpdateTitle'),
          t('quotationFormErrorUpdateMessage'),
        )
      }
    }
  }

  useEffect(() => {
    if (isOpen && !initEditData) {
      let newProfileItem: Partial<ProfileProps> = {}
      let newProfileFilters = {
        search: '',
        currentPage: 1,
      }
      let newData: Partial<QuotationProps> = {}

      if (isNewRegister) {
        if (quotationRequestSelected?.buyerProfile) {
          newProfileItem = {
            name: quotationRequestSelected?.buyerProfile?.name || '',
            email: quotationRequestSelected?.buyerProfile?.email || '',
            phone: quotationRequestSelected?.buyerProfile?.phone || '',
            companyName:
              quotationRequestSelected?.buyerProfile?.companyName || '',
            uuid: quotationRequestSelected?.buyerProfile?.uuid,
          }

          newProfileFilters = {
            search: quotationRequestSelected?.buyerProfile?.email || '',
            currentPage: 1,
          }
        }

        const expiresAtDate = new Date()
        expiresAtDate.setDate(expiresAtDate.getDate() + 3)

        const { day, month, year, hour, minute, second } =
          getDateUnities(expiresAtDate)

        newData.expiresAt = `${day}/${month}/${year} ${hour}:${minute}:${second}`
        newData.includeIva = true

        if (quotationRequestSelected?.address?.uuid) {
          newData.deliveryAddressUuid = quotationRequestSelected?.address?.uuid
        }

        if (
          quotationRequestSelected?.items &&
          quotationRequestItemsSelected &&
          quotationRequestItemsSelected?.length > 0
        ) {
          const items = quotationRequestSelected?.items.filter((item) => {
            return quotationRequestItemsSelected.includes(item?.uuid || '')
          })

          const formattedItems = items.map((item, index) => {
            if (item?.catalogProduct) {
              return {
                catalogProduct: item?.catalogProduct,
                catalogProductUuid: item?.catalogProduct?.uuid,
                uuid: `${index + 1}`,
                quantity: item?.quantity || 0,
              } as QuotationProductProps
            }

            return {
              uuid: `${index + 1}`,
              nonCatalogProduct: {
                description: `${item?.product?.title ? `${item?.product?.title} - ` : ''}${item?.product?.category ? `${tEnums(`product_category_${String(item?.product?.category).toLowerCase()}`)} - ` : ''}${item?.product?.density ? `${item?.product?.density} g/cm³ - ` : ''}${item?.product?.fluidity ? `${item?.product?.fluidity} g/min` : ''}`,
              },
              quantity: item?.quantity || 0,
            } as QuotationProductProps
          })

          newData.products = formattedItems
        }
      } else if (dataQuotation && dataQuotation.data) {
        newProfileItem = dataQuotation.data.buyerProfile || {}
        newProfileFilters = {
          search: dataQuotation.data.buyerProfile?.email || '',
          currentPage: 1,
        }
        newData = { ...dataQuotation.data }
        newData.expiresAt = formatDateWithTime(
          dataQuotation.data.expiresAt,
          'es-MX',
        )
        newData.products = newData?.products?.map((itemProduct) => ({
          ...itemProduct,
          catalogProductUuid: itemProduct?.catalogProduct?.uuid,
        }))
      }

      if (
        dataDollarQuotationLatest?.data?.value &&
        !newData?.dollarExchangeRate
      ) {
        newData.dollarExchangeRate = dataDollarQuotationLatest?.data?.value
      }

      setData({
        ...(data || {}),
        ...newData,
      })
      setProfileItem(newProfileItem)
      setProfileHasBeenEdited(false)
      setProfileFilters(newProfileFilters)
    }

    if (!isOpen) {
      setData(undefined)
      setInitEditData(false)
    }
  }, [
    quotationRequestSelected,
    quotationRequestItemsSelected,
    dataQuotation,
    isNewRegister,
    isOpen,
  ])

  return (
    <DrawerStepper
      translations={{
        optional: t('quotationFormOptional'),
      }}
      isOpen={isOpen}
      onHandleOpen={onHandleOpen}
      title={
        isNewRegister
          ? t('quotationFormTitleCreate')
          : t('quotationFormTitleUpdate')
      }
      initialStep="root"
      steps={{
        root: {
          title: t('quotationFormGeneralFormTitle'),
          isOptional: false,
          backNavigate: 'root',
          component: ({ onNavigateToNextStep }) => (
            <QuotationForm
              onChangeValues={onChangeData}
              values={data || {}}
              onHandleSubmit={() =>
                onSubmitQuotationForm({ onNavigateToNextStep })
              }
              onNavigateToPreviousStep={() => onHandleOpen(false)}
              onHandleSubmitCompleted={onSubmitCompleted}
              isNewRegister={isNewRegister}
              disabled={false}
            />
          ),
        },
        profileList: {
          title: t('quotationFormSearchProfileTitle'),
          isOptional: false,
          backNavigate: 'root',
          component: ({ onNavigateToNextStep }) => (
            <ProfileList
              onSelectedProfile={(profile) =>
                onSelectProfile({ profile, onNavigateToNextStep })
              }
              onClickNewProfile={() =>
                onHandleNewProfile({ onNavigateToNextStep })
              }
              profileSelected={profileItem}
              profiles={profiles?.data?.results || []}
              onChangeSearch={(search) =>
                setProfileFilters({ ...profileFilters, search })
              }
              search={profileFilters.search}
              isLoading={isLoadingProfiles}
            />
          ),
          footer: ({ onNavigateToPreviousStep, onNavigateToNextStep }) => (
            <>
              <Button variant="secondary" onClick={onNavigateToPreviousStep}>
                {t('quotationFormNavigateButtonBack')}
              </Button>

              <Button onClick={() => onNavigateToNextStep('profileForm')}>
                {t('quotationFormNavigateButtonNext')}
              </Button>
            </>
          ),
        },
        profileForm: {
          title: t('quotationFormProfileFormTitle'),
          isOptional: false,
          backNavigate: 'profileList',
          component: (
            <ProfileForm
              errors={profileItemErrors as Record<string, string>}
              onChangeValues={onChangeProfileItem}
              values={profileItem}
              isLoading={isPendingUpdateProfile}
            />
          ),
          footer: ({ onNavigateToNextStep, onNavigateToPreviousStep }) => (
            <>
              <Button variant="secondary" onClick={onNavigateToPreviousStep}>
                {t('navigateButtonCancel')}
              </Button>

              <Button
                onClick={() =>
                  onSubmitProfile({
                    onNavigateToNextStep,
                  })
                }
                disabled={isPendingUpdateProfile}
              >
                {t('quotationFormNavigateButtonNext')}
              </Button>
            </>
          ),
        },
        productList: {
          title: t('quotationFormProductListTitle'),
          isOptional: false,
          backNavigate: 'profileForm',
          component: ({ onNavigateToNextStep }) => (
            <ProductList
              onClickNewProduct={() =>
                onHandleNewProduct({ onNavigateToNextStep })
              }
              onClickRemoveProduct={onHandleClickRemove}
              onSelectedProduct={(product) =>
                onHandleEditProduct({ onNavigateToNextStep, product })
              }
              productItems={data?.products || []}
              errors={
                productListErrorsList as Record<string, Record<string, string>>
              }
            />
          ),
          footer: ({ onNavigateToNextStep, onNavigateToPreviousStep }) => (
            <>
              <Button variant="secondary" onClick={onNavigateToPreviousStep}>
                {t('quotationFormNavigateButtonBack')}
              </Button>

              <Button
                onClick={() => onSubmitProducts({ onNavigateToNextStep })}
              >
                {t('quotationFormNavigateButtonNext')}
              </Button>
            </>
          ),
        },
        newProduct: {
          title: t('quotationFormProductFormTitle'),
          isOptional: false,
          backNavigate: 'productList',
          component: ({ onNavigateToPreviousStep }) => (
            <ProductForm
              values={productItem}
              onChangeValues={(newData) => setProductItem(newData)}
              onHandleSubmit={onHandleSubmitProduct}
              onNavigateToPreviousStep={onNavigateToPreviousStep}
              isNewRegister={isNewRegister}
            />
          ),
        },
        addressList: {
          title: t('quotationFormAddressListTitle'),
          isOptional: false,
          backNavigate: 'productList',
          component: ({ onNavigateToNextStep }) => (
            <AddressList
              onClickNewAddress={() =>
                onHandleNewAddress({ onNavigateToNextStep })
              }
              onChangeAddressSelected={(uuid) =>
                onChangeData({ ...data, deliveryAddressUuid: uuid })
              }
              addressSelected={
                data?.deliveryAddressUuid || data?.deliveryAddress?.uuid
              }
              addresses={dataAddresses?.data?.addresses || []}
            />
          ),
          footer: ({ onNavigateToPreviousStep }) => (
            <>
              <Button variant="secondary" onClick={onNavigateToPreviousStep}>
                {t('quotationFormNavigateButtonBack')}
              </Button>

              <Button onClick={() => onSubmitCompleted()}>
                {t('quotationFormNavigateButtonFinish')}
              </Button>
            </>
          ),
        },
        addressForm: {
          title: t('quotationFormAddressFormTitle'),
          isOptional: false,
          backNavigate: 'addressList',
          component: (
            <AddressForm
              onChangeValues={setAddressItem}
              values={addressItem}
              errors={addressItemErrors as Record<string, string>}
            />
          ),
          footer: ({ onNavigateToPreviousStep }) => (
            <>
              <Button variant="secondary" onClick={onNavigateToPreviousStep}>
                {t('quotationFormNavigateButtonBack')}
              </Button>

              <Button
                onClick={() => onSubmitNewAddress({ onNavigateToPreviousStep })}
              >
                {t('quotationFormNavigateButtonFinish')}
              </Button>
            </>
          ),
        },
      }}
    />
  )
}
