import { useMutation } from '@tanstack/react-query'
import { convertObjectToCamelCase, convertObjectToSnakeCase } from '@/helpers'

import { CreditOrderProps } from '@/types'

import { QUERIES_ADMIN } from './api.constants'
import { api } from './api.service'
import { CREDIT_ORDERS_URL, CREDIT_ORDER_URL } from './api.urls'

export const useCreateCreditOrder = () => {
  const createCreditOrder = async (
    payload: Partial<CreditOrderProps>,
  ): Promise<{
    success: boolean
    message: string
    data?: CreditOrderProps
  }> => {
    try {
      const { data } = await api.post(
        CREDIT_ORDERS_URL,
        convertObjectToSnakeCase(payload),
      )

      return {
        success: true,
        message: 'Order credit create successfully!',
        data: convertObjectToCamelCase(data) as CreditOrderProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to create order credit!',
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.CREDIT_ORDER.module],
    mutationFn: (payload: Partial<CreditOrderProps>) =>
      createCreditOrder(payload),
  })
}

export const useUpdateCreditOrder = (creditOrderUuid?: string) => {
  const updateCreditOrder = async (
    payload: Partial<CreditOrderProps>,
  ): Promise<{
    success: boolean
    message: string
    data?: CreditOrderProps
  }> => {
    try {
      const { data } = await api.patch(
        CREDIT_ORDER_URL(creditOrderUuid || ''),
        convertObjectToSnakeCase(payload),
      )

      return {
        success: true,
        message: 'Order credit update successfully!',
        data: convertObjectToCamelCase(data) as CreditOrderProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to update order credit!',
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.CREDIT_ORDER.module],
    mutationFn: (payload: Partial<CreditOrderProps>) =>
      updateCreditOrder(payload),
  })
}
