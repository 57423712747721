import { useEffect, useState, useMemo } from 'react'
import { Button, CloseIcon, Drawer, Label, Select, Text } from '@mercai/clever'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from '@tanstack/react-query'

import { AutoGenerateForm } from '@/components'
import {
  useKaiProcessorDetail,
  useUpdateKaiProcessor,
  useCatalogCategoryDetail,
  useCatalogProductDetail,
  useCatalogCategoryList,
  useCreateCatalogProduct,
  useUpdateCatalogProduct,
  QUERIES_ADMIN,
} from '@/services'

import { onErrorMessage, onSuccessMessage } from '@/helpers'

import type { CatalogProcessorDrawerFormProps } from './drawer-form.type'
import styles from './drawer-form.module.css'

import type { CatalogProductProps } from '@/types'

export const CatalogProcessorDrawerForm = ({
  isOpen,
  onHandleOpen,
  registerUuid,
}: CatalogProcessorDrawerFormProps) => {
  const { t } = useTranslation('screenCatalogProcessorList')
  const queryClient = useQueryClient()

  const { data, isLoading } = useKaiProcessorDetail(registerUuid)

  const isNewRegister = !data?.data?.reference
  const catalogProductUuid = data?.data?.catalogProductUuid

  const [category, setCategory] = useState('')
  const [errors, setErrors] = useState<Record<string, string>>({})

  const { data: categoryList } = useCatalogCategoryList()

  const { data: categoryData, isLoading: isLoadingCategory } =
    useCatalogCategoryDetail(category)

  const { mutateAsync: mutateKaiProcessor } =
    useUpdateKaiProcessor(registerUuid)

  useEffect(() => {
    if (data?.data?.generatedPayload?.categoryUuid) {
      setCategory(data?.data?.generatedPayload?.categoryUuid)
    }

    if (data?.data?.previousPayload?.categoryUuid) {
      setCategory(data?.data?.previousPayload?.categoryUuid)
    }
  }, [
    data?.data?.generatedPayload?.categoryUuid,
    data?.data?.previousPayload?.categoryUuid,
  ])

  const { mutateAsync: mutateCreate, isPending: isPendingCreate } =
    useCreateCatalogProduct()

  const { mutateAsync: mutateUpdate, isPending: isPendingUpdate } =
    useUpdateCatalogProduct(catalogProductUuid || '')

  const categoryOptions = useMemo(() => {
    if (categoryList?.data?.results) {
      return categoryList.data.results.map((category) => ({
        label: category.name,
        value: category.uuid,
      }))
    }

    return []
  }, [data])

  const { data: catalogProductData, isLoading: isLoadingCatalogProduct } =
    useCatalogProductDetail(catalogProductUuid, false, { noop: 'true' })

  const getInitialValuesCatalog = () => {
    return (catalogProductData?.data?.attributes || {}) as Record<
      string,
      unknown
    >
  }

  const getInitialValuesKai = () => {
    if (isNewRegister) {
      return (data?.data?.generatedPayload?.attributes || {}) as Record<
        string,
        unknown
      >
    }

    return {
      ...(catalogProductData?.data?.attributes || {}),
      ...(data?.data?.generatedPayload?.attributes || {}),
    } as Record<string, unknown>
  }

  const onSubmit = async (data: unknown) => {
    let response: {
      success: boolean
      message: string
      data?: CatalogProductProps
    } = {
      success: false,
      message: '',
      data: {} as CatalogProductProps,
    }

    if (isNewRegister) {
      response = await mutateCreate({
        attributes: data as CatalogProductProps['attributes'],
        categoryUuid: category,
      })
    } else {
      response = await mutateUpdate({
        uuid: catalogProductUuid,
        attributes: data as CatalogProductProps['attributes'],
        categoryUuid: category,
      })
    }

    if (response.success) {
      if (isNewRegister) {
        onSuccessMessage(
          t('drawerFormSuccessTitle'),
          t('drawerFormSuccessCreateMessage'),
        )
      } else {
        onSuccessMessage(
          t('drawerFormSuccessTitle'),
          t('drawerFormSuccessUpdateMessage'),
        )
      }

      setErrors({})

      await mutateKaiProcessor({
        status: 'approved',
        reference: response?.data?.reference,
        catalogProductUuid: response?.data?.uuid,
      })

      onHandleOpen(false)

      queryClient.invalidateQueries({
        queryKey: [QUERIES_ADMIN.KAI_PROCESSOR.module],
      })
    } else {
      const attributes = (response?.data?.attributes || {}) as Record<
        string,
        string
      >

      const formattedErrors = Object.keys(attributes || {}).reduce(
        (acc, key) => {
          if (attributes?.[key]) {
            return {
              ...acc,
              [key]: attributes?.[key]?.[0],
            }
          }

          return acc
        },
        {} as Record<string, string>,
      )

      setErrors((formattedErrors || {}) as Record<string, string>)

      if (isNewRegister) {
        onErrorMessage(
          t('drawerFormErrorTitle'),
          t('drawerFormErrorCreateMessage'),
        )
      } else {
        onErrorMessage(
          t('drawerFormErrorTitle'),
          t('drawerFormErrorUpdateMessage'),
        )
      }
    }
  }

  const onSubmitReject = async () => {
    const response = await mutateKaiProcessor({ status: 'rejected' })

    if (response.success) {
      onSuccessMessage(
        t('drawerFormSuccessTitle'),
        t('drawerFormSuccessRejectMessage'),
      )

      onHandleOpen(false)

      queryClient.invalidateQueries({
        queryKey: [QUERIES_ADMIN.KAI_PROCESSOR.module],
      })
    } else {
      onErrorMessage(
        t('drawerFormErrorTitle'),
        t('drawerFormErrorRejectMessage'),
      )
    }
  }

  return (
    <Drawer
      isOpen={isOpen}
      onHandleOpen={onHandleOpen}
      size={isNewRegister ? 'default' : 'double'}
    >
      <header className={styles.header}>
        <div className={styles['header-box-content']}>
          <Text className={styles['header-title']} variant="title-large">
            {isNewRegister
              ? t('drawerFormNewRegisterTitle')
              : t('drawerFormEnhancementRegisterTitle')}
          </Text>
        </div>

        <button
          type="button"
          onClick={() => onHandleOpen(false)}
          className={styles['header-button']}
        >
          <CloseIcon />
        </button>
      </header>

      <main className={styles.main}>
        <div className={styles['main-box']}>
          <div className={`${styles['main-content']}`}>
            {!isNewRegister && (
              <div className={styles['form-division']}>
                <div className={styles['form-division-header']}>
                  <Text
                    className={styles['main-title']}
                    variant="text-large-bold"
                  >
                    {t('drawerFormPreviousPayloadTitle')}
                  </Text>
                </div>

                <div>
                  <Label>{t('drawerFormCategoryLabel')}</Label>
                  <Select
                    options={categoryOptions}
                    value={category}
                    onChange={setCategory}
                  />
                </div>

                <AutoGenerateForm
                  schema={categoryData?.data?.attributes || []}
                  onSubmit={onSubmit}
                  footerClassName={styles['main-footer']}
                  isLoading={isLoading || isLoadingCategory}
                  initialValues={getInitialValuesCatalog()}
                  isResetForm={!isOpen}
                  footer={() => <div />}
                />

                <div className={styles['form-division-line']} />
              </div>
            )}

            <div className={styles['form-division']}>
              {!isNewRegister && (
                <div className={styles['form-division-header']}>
                  <Text
                    className={styles['main-title']}
                    variant="text-large-bold"
                  >
                    {t('drawerFormGeneratedPayloadTitle')}
                  </Text>
                </div>
              )}

              <div>
                <Label>{t('drawerFormCategoryLabel')}</Label>
                <Select
                  options={categoryOptions}
                  value={category}
                  onChange={setCategory}
                />
              </div>

              <AutoGenerateForm
                schema={categoryData?.data?.attributes || []}
                onSubmit={onSubmit}
                isLoading={
                  isLoading || isLoadingCategory || isLoadingCatalogProduct
                }
                initialValues={getInitialValuesKai()}
                isResetForm={!isOpen}
                errors={errors}
                footer={({ onSubmit: onSubmitFooter }) => (
                  <div className={styles['main-footer']}>
                    {data?.data?.status !== 'approved' && (
                      <Button
                        onClick={onSubmitFooter}
                        disabled={isPendingCreate || isPendingUpdate}
                      >
                        {t('drawerFormAcceptButton')}
                      </Button>
                    )}

                    {data?.data?.status !== 'rejected' && (
                      <Button
                        variant="secondary"
                        onClick={onSubmitReject}
                        disabled={isPendingCreate || isPendingUpdate}
                      >
                        {t('drawerFormDeclinedButton')}
                      </Button>
                    )}
                  </div>
                )}
              />
            </div>
          </div>
        </div>
      </main>
    </Drawer>
  )
}
