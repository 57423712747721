export const currencyOptions = (t: (key: string) => string) => [
  {
    label: t('tabCurrencyMXN'),
    src: '/images/flag-mx.svg',
    currency: 'MXN',
  },
  {
    label: t('tabCurrencyUSD'),
    src: '/images/flag-us.svg',
    currency: 'USD',
  },
]
