import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SearchIcon, Input } from '@mercai/clever'

import { InputSearchProps } from './input-search.type'

export const InputSearch = ({
  onChange,
  value,
  className,
}: InputSearchProps) => {
  const { t } = useTranslation('screenCatalogProductList')

  const [localValue, setLocalValue] = useState(value)

  useEffect(() => {
    if (value !== localValue) setLocalValue(value)
  }, [value])

  useEffect(() => {
    const handler = setTimeout(() => {
      if (localValue === value) return
      onChange(localValue)
    }, 500)
    return () => clearTimeout(handler)
  }, [localValue, onChange])

  return (
    <Input
      className={className}
      iconLeft={<SearchIcon />}
      placeholder={t('searchFilterPlaceholder')}
      value={localValue}
      onChange={setLocalValue}
    />
  )
}
