export const cleanObject = <T>(obj: T): T => {
  if (typeof obj !== 'object' || obj === null) {
    return obj
  }

  if (Array.isArray(obj)) {
    return obj.map(cleanObject) as unknown as T
  }

  type Mutable<T> = {
    -readonly [P in keyof T]: T[P]
  }

  const result = {} as Mutable<T>

  for (const [key, value] of Object.entries(obj)) {
    if (value !== undefined && value !== null && value !== '') {
      result[key as keyof T] = cleanObject(value)
    }
  }

  return result as T
}
