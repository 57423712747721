import { useTranslation } from 'react-i18next'
import { Text, ProfileIcon, Skeleton } from '@mercai/clever'

import styles from './summary-profile.module.css'
import type { OrderSummaryProfileProps } from './summary-profile.type'

const OrderSummaryProfileDefault = ({
  name,
  companyName,
  email,
  phone,
}: OrderSummaryProfileProps) => {
  const { t } = useTranslation('screenOrderList')

  return (
    <div className={styles.core}>
      <div className={styles.title}>
        <ProfileIcon />
        <Text variant="title-base" className={styles.title}>
          {t('orderShowTitleSummaryProfile')}
        </Text>
      </div>

      <div className={styles.content}>
        <Text>
          <strong>{t('orderShowSummaryProfileName')}:</strong> {name || '-'}
        </Text>

        <Text>
          <strong>{t('orderShowSummaryProfileCompanyName')}:</strong>{' '}
          {companyName || '-'}
        </Text>

        <Text>
          <strong>{t('orderShowSummaryProfileEmail')}:</strong> {email || '-'}
        </Text>

        <Text>
          <strong>{t('orderShowSummaryProfilePhone')}:</strong> {phone || '-'}
        </Text>
      </div>
    </div>
  )
}

const OrderSummaryProfileLoading = () => {
  return (
    <div className={styles.core}>
      <div className={styles.title}>
        <Skeleton height="1.5rem" width="1.5rem" />
        <Skeleton height="1rem" width="8rem" />
      </div>

      <div className={styles.content}>
        <Skeleton height="1rem" width="90%" />
        <Skeleton height="1rem" width="80%" />
        <Skeleton height="1rem" width="100%" />
      </div>
    </div>
  )
}

export const OrderSummaryProfile = (props: OrderSummaryProfileProps) => {
  if (props.isLoading) {
    return <OrderSummaryProfileLoading />
  }

  return <OrderSummaryProfileDefault {...props} />
}
