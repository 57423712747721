import { useTranslation } from 'react-i18next'

import { CalendarIcon } from '@mercai/clever'

import { InfoList, InfoListListProps } from '@/components'
import { formatDate, textToSnakeCase } from '@/helpers'

import type { OrderFinanceStatusHistoryProps } from './order-finance-status-history.type'

export const OrderFinanceStatusHistory = ({
  invoiceStatusHistory,
  paymentStatusHistory,
  isLoading,
}: OrderFinanceStatusHistoryProps) => {
  const { t } = useTranslation('componentOrderCenterShow')
  const { t: tEnums } = useTranslation('enums')

  const mountStatus = () => {
    const statusList: InfoListListProps[] = []

    if (invoiceStatusHistory) {
      Object.keys(invoiceStatusHistory)?.forEach((status) => {
        statusList.push({
          label: tEnums(`order_invoice_status_${textToSnakeCase(status)}`),
          value: formatDate(invoiceStatusHistory[status]),
        })
      })
    }

    if (paymentStatusHistory) {
      Object.keys(paymentStatusHistory)?.forEach((status) => {
        statusList.push({
          label: tEnums(`order_payment_status_${textToSnakeCase(status)}`),
          value: formatDate(paymentStatusHistory[status]),
        })
      })
    }

    return statusList
  }

  const list = mountStatus()

  return (
    <div>
      <InfoList
        isLoading={isLoading}
        iconTitle={<CalendarIcon />}
        title={t('orderFinanceStatusHistoryTitle')}
        list={list}
      />
    </div>
  )
}
