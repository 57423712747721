import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { Tag } from '@mercai/clever'

import type { TagMoscowProps } from './tag-moscow.type'

export const TagMoscow = ({ moscowClassification }: TagMoscowProps) => {
  const { t } = useTranslation('enums')

  const moscowObject: Record<string, ReactNode> = {
    must: (
      <Tag variant="success" model="light">
        {t('profile_moscow_must')}
      </Tag>
    ),
    should: (
      <Tag variant="primary" model="light">
        {t('profile_moscow_should')}
      </Tag>
    ),
    could: (
      <Tag variant="warning" model="light">
        {t('profile_moscow_could')}
      </Tag>
    ),
    wont: (
      <Tag variant="danger" model="light">
        {t('profile_moscow_wont')}
      </Tag>
    ),
    unknown: (
      <Tag variant="basic" model="light">
        {t('profile_moscow_unknown')}
      </Tag>
    ),
  }

  return moscowObject[moscowClassification || 'unknown']
}
