import { useTranslation } from 'react-i18next'
import { ProfileIcon, Text } from '@mercai/clever'

import { InfoList } from '@/components'

import styles from './order-logistic-transport-option.module.css'

import type { OrderLogisticTransportOptionProps } from './order-logistic-transport-option.type'

export const OrderLogisticTransportOption = ({
  isLoading,
  shipment,
}: OrderLogisticTransportOptionProps) => {
  const { t } = useTranslation('componentOrderCenterShow')

  const objects = {
    pick_up: [
      {
        label: t('orderLogisticShipmentTransportOptionCompany'),
        value: shipment?.transportOptions?.transportCompany || '-',
      },
      {
        label: t('orderLogisticShipmentTransportOptionTypeVehicle'),
        value: shipment?.transportOptions?.vehicleType || '-',
      },
      {
        label: t('orderLogisticShipmentTransportOptionPlate'),
        value: shipment?.transportOptions?.vehicleIdentifier || '-',
      },
      {
        label: t('orderLogisticShipmentTransportOptionDriver'),
        value: shipment?.transportOptions?.driver || '-',
      },
    ],
    receive_at_address: [
      {
        label: t('orderLogisticShipmentTransportOptionPickUpName'),
        value: shipment?.transportOptions?.driver || '-',
      },
      {
        label: t('orderLogisticShipmentTransportOptionCompany'),
        value: shipment?.transportOptions?.transportCompany || '-',
      },
      {
        label: t('orderLogisticShipmentTransportOptionTypeVehicle'),
        value: shipment?.transportOptions?.vehicleType || '-',
      },
      {
        label: t('orderLogisticShipmentTransportOptionPlate'),
        value: shipment?.transportOptions?.vehicleIdentifier || '-',
      },
      {
        label: t('orderLogisticShipmentTransportOptionTrackingInfo'),
        value: shipment?.transportOptions?.trackingInfo ? (
          <Text
            variant="link"
            href={shipment?.transportOptions?.trackingInfo}
            target="_blank"
            className={styles.link}
          >
            {t('orderLogisticShipmentTransportOptionTrackingInfoClick')}
          </Text>
        ) : (
          '-'
        ),
      },
    ],
  }

  return (
    <div className={styles.core}>
      <InfoList
        isLoading={isLoading}
        iconTitle={<ProfileIcon />}
        title={
          shipment?.deliveryOption === 'pick_up'
            ? t('orderLogisticShipmentPickUp')
            : t('orderLogisticShipmentReceiveAtAddress')
        }
        list={objects[shipment?.deliveryOption || 'pick_up']}
      />
    </div>
  )
}
