/**
 * - pt-BR: BRL (Real Brasileiro)
 * - es-MX: MXN (Peso Mexicano)
 * - en-US: USD (Dólar Americano)
 */
export function formatCurrency(
  value: number | string,
  locale = 'es-MX',
  currency: string | null = null,
  precision = 2,
): string {
  const options: Intl.NumberFormatOptions = currency
    ? {
        style: 'currency',
        currency,
        minimumFractionDigits: precision,
        maximumFractionDigits: precision,
      }
    : {
        style: 'decimal',
        minimumFractionDigits: precision,
        maximumFractionDigits: precision,
      }

  const formattedValue = new Intl.NumberFormat(locale, options).format(+value)

  if (currency === 'MXN') {
    return `MX${formattedValue.replace('$', '$ ')}`
  }

  return formattedValue
}
