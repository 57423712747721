import styles from './tooltip.module.css'

import { TooltipOrientationEnum } from './tooltip.type'

export const orientations: Record<TooltipOrientationEnum, string> = {
  left: styles['orientation-left'],
  right: styles['orientation-right'],
  bottom: styles['orientation-bottom'],
  top: styles['orientation-top'],
}

export const generateClasses = (
  orientation: keyof typeof TooltipOrientationEnum,
  className?: string,
) => {
  return `${styles['orientation-core']} ${orientations[orientation]} ${
    className || ''
  }`
}
