import { Button, Label, Select } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { useValidationErrors } from '@/helpers'

import { schemaOrder } from './sale-order-form.constants'

import type { SaleOrderFormProps } from './sale-order-form.type'

import { SaleOrderStatusEnum } from '@/types'

import styles from './sale-order-form.module.css'

export const SaleOrderForm = ({
  onChangeValues,
  values,
  onBack,
  onHandleSubmit,
}: SaleOrderFormProps) => {
  const { t } = useTranslation('componentsOrderForm')
  const { t: tEnums } = useTranslation('enums')

  const { isValid, errors, onHandleListenErrors } = useValidationErrors(
    schemaOrder(t),
    values,
  )

  const onHandleValues = (key: string, value: string | number | boolean) => {
    onChangeValues({ ...values, [key]: value })
  }

  const onHandleSubmitLocal = () => {
    if (!isValid) {
      onHandleListenErrors(true)
      return
    }

    onHandleListenErrors(false)
    onHandleSubmit()
  }

  const getStatusValues = () => {
    return Object.keys(SaleOrderStatusEnum).map((status) => {
      return {
        label: tEnums(`sale_order_status_${status}`),
        value: status,
      }
    })
  }

  return (
    <>
      <div className={styles.core}>
        <div>
          <Label>{t('saleOrderFormStatusLabel')}</Label>
          <Select
            placeholder={t('saleOrderFormStatusPlaceholder')}
            value={values?.status}
            error={errors?.status as string}
            onChange={(newValue) => onHandleValues('status', newValue)}
            options={getStatusValues()}
          />
        </div>
      </div>
      <div className={styles.footer}>
        <Button variant="secondary" onClick={onBack}>
          {t('buttonBack')}
        </Button>

        <Button onClick={onHandleSubmitLocal}>{t('buttonSubmit')}</Button>
      </div>
    </>
  )
}
