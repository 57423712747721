import styles from './order-table-columns.module.css'

const ellipsesTable: Record<'active' | 'inactive', string> = {
  active: styles.active,
  inactive: styles.inactive,
}

export const generateClassesOfEllipsesTable = (isActive: boolean = false) => {
  return `${styles.indicator} ${ellipsesTable[isActive ? 'active' : 'inactive']}`
}
