import { TagProps, TagSizeEnum, TagVariantEnum, TagModelEnum } from './tag.type'

import { generateClasses } from './tag.constants'

const Tags = ({
  children,
  className,
  size = TagSizeEnum.medium,
  variant = TagVariantEnum.primary,
  model = TagModelEnum.fill,
}: TagProps) => {
  const spanClasses = generateClasses(size, variant, model, className)

  return <span className={spanClasses}>{children}</span>
}

export default Tags
