import { useCallback, useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Checkbox,
  CloseIcon,
  DropDown,
  FilterIcon,
  InputSearch,
  TableColumns,
  Tag,
  Tooltip,
  Text,
  Skeleton,
  Pagination,
  SettingIcon,
  TableColumnsColumnProps,
  Select,
} from '@mercai/clever'

import {
  TableColumnBuyer,
  TableColumnName,
  TableColumnCompany,
  TableTaxStatus,
  TableTagMoscow,
  TableColumnsPaymentStatus,
  TableColumnsInvoiceStatus,
  TableColumnsLogisticStatus,
  OrderDrawerFilter,
  TableColumnsSalesOrderStatus,
  TableColumnExpectedDeliveryDate,
  TableColumnLogisticActions,
  OrderCenterExpandedColumn,
} from './components'

import { DEFAULT_LIMIT_PAGE } from '@/constants'

import {
  trimString,
  useCookieState,
  formatCurrency,
  formatNumber,
  getOrderItemDescription,
  distanceToNow,
  formatDate,
} from '@/helpers'

import { SaleOrderProps, SaleOrderItemProps } from '@/types'

import { columnsForType } from './table.constants'

import { OrderCenterTableProps } from './table.type'

import styles from './table.module.css'

export const OrderCenterTable = ({
  type,
  data,
  isLoading,
  filter,
  onSetFilter,
  onEditRegister,
  onShowRegister,
  formattedFilter,
}: OrderCenterTableProps) => {
  const { t } = useTranslation('screenOrderCenterPage')

  const [isOpenDrawerFilter, setIsOpenDrawerFilter] = useState(false)

  const [totalPages, setTotalPages] = useState(1)

  const [columnsKeys, setColumnsKeys] = useCookieState<string[]>(
    `@mercai/order-center-${type}-columns`,
    columnsForType[type],
  )

  const getContentTotalFiltered = () => {
    const activeFilters = Object.keys(filter)?.filter(
      (key) => !!filter[key] && key !== 'page' && key !== 'limit',
    ).length

    if (activeFilters > 0) {
      return (
        <Tag variant="primary" model="light" size="small">
          {activeFilters}
        </Tag>
      )
    }

    return ''
  }

  const onHandleColumnsShow = (key: string) => {
    let newColumnsKeys = columnsKeys

    if (columnsKeys.includes(key)) {
      newColumnsKeys = columnsKeys.filter((item) => item !== key)
    } else {
      newColumnsKeys = [...columnsKeys, key]
    }

    setColumnsKeys(newColumnsKeys)
  }

  const getColumnsShow = useCallback(
    (key: string) => (type !== 'logistic' ? columnsKeys.includes(key) : true),
    [columnsKeys, type],
  )

  const handleFilter = (changedKey: string, newValue: string | number) => {
    if (changedKey === 'page') {
      onSetFilter({ ...filter, [changedKey]: `${newValue}` })
    } else {
      onSetFilter({ ...filter, [changedKey]: `${newValue}`, page: '1' })
    }
  }

  const getFormattedFilter = (filterKey: string | number) => {
    const filterString = filter?.[filterKey]

    if (filterKey === 'keyAccountManager') {
      return formattedFilter?.keyAccountManager || ''
    }

    if (filterKey === 'createdAtBefore') {
      return `${t('filterCreatedAtBefore')} ${formatDate(filterString)}`
    }

    if (filterKey === 'createdAtAfter') {
      return `${t('filterCreatedAtAfter')} ${formatDate(filterString)}`
    }

    if (!filterString || typeof filterString === 'number') return ''

    if (filterString?.[0] === ',') {
      return filterString?.slice(1)?.replace(/,/g, ', ')
    }

    return filterString?.replace(/,/g, ', ')
  }

  const getContentFilter = () => {
    const filtersKeys = Object.keys(filter).filter(
      (item) => !['limit', 'page', 'ordering'].includes(item) && !!filter[item],
    )

    if (filtersKeys && filtersKeys.length === 0) {
      return null
    }

    return (
      <div className={styles['filtered-options']}>
        <Text>{t('filterApplied')}:</Text>

        {filtersKeys?.map((item) => (
          <Button
            onClick={() => handleFilter(item, '')}
            variant="light"
            size="small"
            key={item}
          >
            {getFormattedFilter(item)}

            <CloseIcon />
          </Button>
        ))}
      </div>
    )
  }

  useEffect(() => {
    if (data?.count) {
      setTotalPages(
        Math.ceil(data?.count / +(filter?.limit || DEFAULT_LIMIT_PAGE)),
      )
    }
  }, [data])

  const getContentProduct = useCallback(
    (saleOrdersItems?: SaleOrderProps['items'], index = 0) => {
      const concatItems = saleOrdersItems
        ?.map((item) => getOrderItemDescription(item as SaleOrderItemProps))
        .join(', ')

      return (
        <Tooltip
          text={concatItems || ''}
          widthText="18.75rem"
          orientation={index >= 8 ? 'top' : 'bottom'}
        >
          {trimString(concatItems || '', 52, '...')}
        </Tooltip>
      )
    },
    [data],
  )

  const getQuantityProducts = useCallback(
    (saleOrdersItems: SaleOrderProps['items'] = []) => {
      return saleOrdersItems?.reduce((acc, current) => {
        return (acc += current?.quantity)
      }, 0)
    },
    [data],
  )

  const columns: TableColumnsColumnProps<SaleOrderProps>[] = [
    {
      title: t('tableReference'),
      key: 'reference',
      dataKey: 'reference',
      width: '9rem',
      render: ({ value }) => `#${value}`,
    },
    {
      title: t('tableBuyer'),
      key: 'buyer',
      width: '20rem',
      dataKey: 'buyer',
      render: ({ record }) => (
        <TableColumnBuyer
          buyerName={record?.buyer?.name}
          companyName={record?.buyer?.companyName}
          uuid={record?.buyer?.uuid}
        />
      ),
    },
    {
      title: t('tableBuyerName'),
      key: 'buyerName',
      width: '18rem',
      dataKey: 'buyer',
      render: ({ record }) => (
        <TableColumnName
          buyerName={record?.buyer?.name}
          uuid={record?.buyer?.uuid}
        />
      ),
    },
    {
      title: t('tableBusinessName'),
      key: 'companyName',
      width: '20rem',
      render: ({ record }) => (
        <TableColumnCompany
          companyName={record?.buyer?.companyName}
          uuid={record?.buyer?.uuid}
        />
      ),
    },
    {
      title: t('tableTaxStatus'),
      key: 'taxStatus',
      render: ({ record }) => (
        <TableTaxStatus taxStatus={record?.buyer?.taxStatus} />
      ),
    },
    {
      title: t('tableTime'),
      key: 'createdAtDistanceToNow',
      dataKey: 'buyer.createdAt',
      width: '16rem',
      render: ({ value }) => (value ? distanceToNow(value) : '-'),
    },
    {
      title: t('tableProducts'),
      key: 'items',
      width: '20rem',
      render: ({ record, index }) => getContentProduct(record?.items, index),
    },
    {
      title: t('tableAmount'),
      key: 'totalPrice',
      dataKey: 'items',
      render: ({ record }) =>
        record?.items
          ? formatCurrency(record.totalOrderPrice, 'es-MX', 'USD')
          : '-',
    },
    {
      title: t('tableQuantity'),
      key: 'totalQuantity',
      dataKey: 'items',
      render: ({ record }) =>
        formatNumber(getQuantityProducts(record.items) || 0, 'es-MX'),
    },
    {
      title: t('tableMoscow'),
      key: 'moscowClassification',
      dataKey: 'buyer.moscowClassification',
      render: ({ value }) => <TableTagMoscow moscowClassification={value} />,
    },
    {
      title: t('tableSellerName'),
      key: 'keyAccountManager',
      dataKey: 'keyAccountManager.email',
      width: '18.75rem',
    },
    {
      title: t('tableStatePayment'),
      key: 'paymentStatus',
      width: '12rem',
      render: ({ record }) => (
        <TableColumnsPaymentStatus
          paymentStatus={record?.payments?.[0]?.status}
        />
      ),
    },
    {
      title: t('tableStateInvoice'),
      key: 'invoiceStatus',
      render: ({ record }) => (
        <TableColumnsInvoiceStatus
          invoiceStatus={record?.invoices?.[0]?.status}
        />
      ),
    },
    {
      title: t('tablePaidAt'),
      key: 'paidAt',
      render: ({ record }) =>
        record?.payments?.[0]?.paidAt
          ? formatDate(record?.payments?.[0]?.paidAt)
          : '-',
    },
    {
      title: t('tableCogs'),
      key: 'cogs',
      render: ({ record }) =>
        record?.totalOrderCost
          ? formatCurrency(record?.totalOrderCost, 'es-MX', 'USD')
          : '-',
    },
    {
      title: t('tableLogisticStatus'),
      key: 'logisticStatus',
      width: '14rem',
      render: ({ record }) => (
        <TableColumnsLogisticStatus
          logisticStatus={record?.shipments?.[0]?.status}
        />
      ),
    },
    {
      title: t('tableSalesOrderStatus'),
      key: 'salesOrderStatus',
      render: ({ record }) => (
        <TableColumnsSalesOrderStatus salesOrderStatus={record?.status} />
      ),
    },
    {
      title: t('tableExpectedDeliveryDate'),
      key: 'expectedDeliveryDate',
      width: '14rem',
      render: ({ record }) => (
        <TableColumnExpectedDeliveryDate
          expectedDeliveryAt={record?.shipments?.[0]?.expectedDeliveryAt}
          deliveryDate={record?.shipments?.[0]?.deliveredAt}
        />
      ),
    },
    {
      title: t('tableTransportPrice'),
      key: 'transportPrice',
      render: ({ record }) =>
        formatCurrency(record?.totalFreightPrice || 0, 'es-MX', 'USD'),
    },
    {
      key: 'tableLogisticActions',
      dataKey: 'uuid',
      render: ({ record }) => (
        <TableColumnLogisticActions
          onClickEdit={() => onEditRegister(record.uuid)}
          onClickView={() => onShowRegister(record.uuid)}
        />
      ),
    },
  ]

  const memoizedColumns = useMemo(() => {
    const columnsFiltered = columnsForType?.[type]?.reduce((acc, current) => {
      const column = columns?.find((column) => column.key === current)

      if (column && getColumnsShow(column.key)) {
        return [...acc, column]
      }

      return acc
    }, [] as TableColumnsColumnProps<SaleOrderProps>[])

    return columnsFiltered
  }, [columns, getColumnsShow])

  return (
    <div className={styles.core}>
      <Text variant="title-large">{t('tableTitle')}</Text>

      <div className={styles.filter}>
        <div className={styles['filter-group']}>
          <InputSearch
            value={filter.search as string}
            onChange={(value) => handleFilter('search', value)}
            className={styles['search-input']}
            translations={{
              inputPlaceholder: t('tableSearchPlaceholder'),
            }}
          />

          <Button
            variant="secondary"
            onClick={() => setIsOpenDrawerFilter(true)}
          >
            <FilterIcon />
            {t('tableFilterButton')}
            {getContentTotalFiltered()}
          </Button>
        </div>

        <div className={styles['filter-group']}>
          {type === 'finance' && (
            <Select
              iconLeft={<FilterIcon />}
              value={filter.ordering || 'invoice_order'}
              onChange={(value) => handleFilter('ordering', value)}
              options={[
                {
                  label: t('tableOrderingInvoiceAsc'),
                  value: 'invoice_order',
                },
                {
                  label: t('tableOrderingPaymentAsc'),
                  value: 'payment_order',
                },
                {
                  label: t('tableOrderingInvoiceDesc'),
                  value: '-invoice_order',
                },
                {
                  label: t('tableOrderingPaymentDesc'),
                  value: '-payment_order',
                },
              ]}
            />
          )}

          {type !== 'logistic' && (
            <DropDown.Root>
              <DropDown.ButtonTrigger
                variant="secondary"
                model="square"
                hasArrow={false}
              >
                <SettingIcon />
              </DropDown.ButtonTrigger>

              <DropDown.Content orientation="bottom-right">
                {columns?.map((column) => (
                  <DropDown.Item key={column.key}>
                    <Checkbox
                      onChange={() => onHandleColumnsShow(column.key)}
                      value={getColumnsShow(column.key)}
                    >
                      {column.title}
                    </Checkbox>
                  </DropDown.Item>
                ))}
              </DropDown.Content>
            </DropDown.Root>
          )}
        </div>
      </div>

      {getContentFilter()}

      <TableColumns<SaleOrderProps>
        classNames={{
          bodyColumn: styles['body-column'],
        }}
        isLoading={isLoading}
        columns={memoizedColumns}
        fixedColumns={
          type !== 'logistic'
            ? [
                {
                  title: t('tableActions'),
                  key: 'tableActions',
                  dataKey: 'uuid',
                  render: ({ record }) => (
                    <div className={styles['action-buttons']}>
                      <Button
                        variant="secondary"
                        onClick={() => onEditRegister(record.uuid)}
                      >
                        {t('tableActionsEdit')}
                      </Button>
                      <Button
                        variant="secondary"
                        onClick={() => onShowRegister(record.uuid)}
                      >
                        {t('tableActionsView')}
                      </Button>
                    </div>
                  ),
                  renderLoading: (
                    <div className={styles['action-buttons']}>
                      <Skeleton height="2rem" width="5.15rem" />
                      <Skeleton height="2rem" width="5.7rem" />
                    </div>
                  ),
                },
              ]
            : undefined
        }
        data={data?.results || []}
        expandableComponent={
          type === 'logistic'
            ? ({ record }) => <OrderCenterExpandedColumn record={record} />
            : undefined
        }
      />

      <div className={styles.pagination}>
        <div className={styles['pagination-show-registers']}>
          <Text>
            {t('tableShowRegisters', {
              showNumber:
                (data?.count || 0) < +filter.limit
                  ? data?.count
                  : +filter.limit,
              totalNumber: data?.count || 0,
            })}
          </Text>

          <Select
            value={filter.limit}
            onChange={(value) => handleFilter('limit', value)}
            options={[
              { label: '10', value: '10' },
              { label: '20', value: '20' },
              { label: '50', value: '50' },
              { label: '100', value: '100' },
            ]}
          />
        </div>

        <Pagination
          totalPages={totalPages}
          onChange={(page) => handleFilter('page', page)}
          currentPage={+filter.page as number}
        />
      </div>

      <OrderDrawerFilter
        isOpen={isOpenDrawerFilter}
        onHandleOpen={() => setIsOpenDrawerFilter((prev) => !prev)}
        filter={filter}
        onHandleFilter={onSetFilter}
        type={type}
      />
    </div>
  )
}
