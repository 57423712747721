import { SkeletonProps } from './skeleton.type'

import styles from './skeleton.module.css'

export const Skeleton = ({
  isActive = true,
  height,
  width = '100%',
  className = '',
}: SkeletonProps) => {
  if (isActive) {
    return (
      <div
        className={`${styles['shimmer-placeholder']} ${className || ''}`}
        style={{
          height,
          width,
        }}
      />
    )
  }

  return null
}
