import { Button, Label, Select, InputNumber, DatePicker } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { useValidationErrors } from '@/helpers'

import { schemaOrder } from './credit-order-form.constants'

import type { CreditOrderFormProps } from './credit-order-form.type'

import { CreditOrderStatusEnum } from '@/types'

import styles from './credit-order-form.module.css'

export const CreditOrderForm = ({
  onChangeValues,
  values,
  onBack,
  onHandleSubmit,
}: CreditOrderFormProps) => {
  const { t } = useTranslation('componentsCreditOrderForm')
  const { t: tEnums } = useTranslation('enums')

  const { isValid, errors, onHandleListenErrors } = useValidationErrors(
    schemaOrder(t),
    values,
  )

  const onHandleValues = (
    key: string,
    value?: string | number | boolean | null,
  ) => {
    onChangeValues({ ...values, [key]: value })
  }

  const onChangePaymentTerm = (value: '15' | '30' | '45' | '60') => {
    const interests = {
      15: 0.01,
      30: 0.02,
      45: 0.03,
      60: 0.04,
    }

    onChangeValues({
      ...values,
      paymentTerm: value,
      interest: `${interests[value]}`,
    })
  }

  const onHandleSubmitLocal = () => {
    if (!isValid) {
      onHandleListenErrors(true)
      return
    }

    onHandleListenErrors(false)
    onHandleSubmit()
  }

  return (
    <>
      <div className={styles.core}>
        <div>
          <Label>{t('statusLabel')}</Label>
          <Select
            options={Object.keys(CreditOrderStatusEnum).map((key) => ({
              label: tEnums(`credit_order_status_${key}`),
              value: key,
            }))}
            placeholder={t('statusPlaceholder')}
            value={values?.status}
            onChange={(value) => onHandleValues('status', value)}
            error={errors?.status as string}
          />
        </div>

        <div>
          <Label>{t('paymentTermLabel')}</Label>
          <Select
            options={[
              { label: '15', value: '15' },
              { label: '30', value: '30' },
              { label: '45', value: '45' },
              { label: '60', value: '60' },
            ]}
            placeholder={t('paymentTermPlaceholder')}
            value={values?.paymentTerm}
            onChange={(value) =>
              onChangePaymentTerm(value as '15' | '30' | '45' | '60')
            }
            error={errors?.paymentTerm as string}
          />
        </div>

        <div>
          <Label>{t('interestLabel')}</Label>
          <InputNumber
            value={values?.interest ? +values?.interest * 100 : 0}
            onChange={(value) => onHandleValues('interest', value)}
            error={errors?.interest as string}
            decimalPrecision={2}
            disabled
            suffix="%"
            showIcons
          />
        </div>

        <div>
          <Label>{t('expectedPaymentDateLabel')}</Label>
          <DatePicker
            value={values?.expectedPaymentDate}
            onChange={(value) => onHandleValues('expectedPaymentDate', value)}
            error={errors?.expectedPaymentDate as string}
          />
        </div>

        <div>
          <Label>
            {t('paymentDateLabel')} <span>{t('paymentDateLabelOptional')}</span>
          </Label>
          <DatePicker
            value={values?.paidAt}
            onChange={(value) => onHandleValues('paidAt', value)}
            error={errors?.paidAt as string}
          />
        </div>
      </div>
      <div className={styles.footer}>
        <Button variant="secondary" onClick={onBack}>
          {t('buttonBack')}
        </Button>

        <Button onClick={onHandleSubmitLocal}>{t('buttonSubmit')}</Button>
      </div>
    </>
  )
}
