import { Card, Text, TruckIcon, Skeleton } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import type { QuotationRequestAddressDataProps } from './address-data.type'

import styles from './address-data.module.css'

export const QuotationRequestAddressData = ({
  address,
  isLoading,
}: QuotationRequestAddressDataProps) => {
  const { t } = useTranslation('screenQuotationRequestShow')

  if (isLoading) {
    return (
      <Card>
        <div className={styles.title}>
          <Skeleton height="1.5rem" width="1.5rem" />

          <Skeleton height="1.5rem" width="10rem" />
        </div>

        <div className={styles.content}>
          <div className={styles['content-row']}>
            <Skeleton height="1rem" width="8rem" />
            <Skeleton height="1rem" width="8rem" />
          </div>

          <div className={styles['content-row']}>
            <Skeleton height="1rem" width="8rem" />
            <Skeleton height="1rem" width="8rem" />
          </div>

          <div className={styles['content-row']}>
            <Skeleton height="1rem" width="8rem" />
            <Skeleton height="1rem" width="8rem" />
          </div>

          <div className={styles['content-row']}>
            <Skeleton height="1rem" width="8rem" />
            <Skeleton height="1rem" width="8rem" />
          </div>

          <div className={styles['content-row']}>
            <Skeleton height="1rem" width="8rem" />
            <Skeleton height="1rem" width="8rem" />
          </div>

          <div className={styles['content-row']}>
            <Skeleton height="1rem" width="8rem" />
            <Skeleton height="1rem" width="8rem" />
          </div>
        </div>
      </Card>
    )
  }

  return (
    <Card>
      <div className={styles.title}>
        <TruckIcon />
        <Text variant="title-large">{t('addressDataTitle')}</Text>
      </div>

      <div className={styles.content}>
        <div className={styles['content-row']}>
          <Text variant="title-base">{t('addressDataPostalCodeLabel')}: </Text>
          <Text>{address?.postalCode || '-'}</Text>
        </div>
        <div className={styles['content-row']}>
          <Text variant="title-base">{t('addressDataStateLabel')}: </Text>
          <Text>{address?.state || '-'}</Text>
        </div>

        <div className={styles['content-row']}>
          <Text variant="title-base">{t('addressDataCityLabel')}: </Text>
          <Text>{address?.city || '-'}</Text>
        </div>

        <div className={styles['content-row']}>
          <Text variant="title-base">
            {t('addressDataNeighborhoodLabel')}:{' '}
          </Text>
          <Text>{address?.neighborhood || '-'}</Text>
        </div>

        <div className={styles['content-row']}>
          <Text variant="title-base">{t('addressDataStreetLabel')}: </Text>
          <Text>
            {address?.street || '-'} - {address?.number || ''}
          </Text>
        </div>

        <div className={styles['content-row']}>
          <Text variant="title-base">{t('addressDataReferenceLabel')}: </Text>
          <Text>{address?.reference || '-'}</Text>
        </div>
      </div>
    </Card>
  )
}
